
import gql from 'graphql-tag';

const REMOVE_PARTNER = gql`
    mutation companyRemoveUser($id: ID!, $userId: ID!){
        companyRemoveUser(id:$id, userId: $userId){
            id
        }
    }
`;

export default REMOVE_PARTNER;

