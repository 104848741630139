import React, { useState } from 'react';
import MUITreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from './TreeItem';
;
const TreeView = ({ tree, onChange }) => {
    const [expanded, setExpanded] = useState([tree.root.id]);
    return React.createElement(MUITreeView, { defaultCollapseIcon: React.createElement(ExpandMoreIcon, null), defaultExpandIcon: React.createElement(ChevronRightIcon, null), expanded: expanded, onNodeToggle: (_event, expanded) => {
            setExpanded(expanded);
        } },
        React.createElement(TreeItem, Object.assign({}, tree.root, { onChange: onChange })));
};
export default TreeView;
