import gql from 'graphql-tag';

const ALL_PLAYLISTS = gql`
query playlistsTable(
  $offset: Int, 
  $limit: Int, 
  $sort: [PlaylistsTableSortInput!], 
  $filter: [PlaylistsTableFilterInput]){
    playlistsTable(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      items{
        id
        bid
        name
        description
        createdAt
        updatedAt
        deletedAt
        company
        createdBy
        media
      }
    }
  }
`;

export default ALL_PLAYLISTS;