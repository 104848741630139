import gql from 'graphql-tag';

const ALL_USERS = gql`
query usersTable($offset: Int, $limit: Int, $sort: [UsersTableSortInput!], $filter: [UsersTableFilterInput]) {
    usersTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
        totalCount,
        items {
            bid
            id
            fullName  
            status 
            email
            companies
          	groups
        }
    }
}
`;

export default ALL_USERS;