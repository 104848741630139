import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import moment from "moment";

const useStyles = makeStyles((theme) => styles(theme));

const InfoCard = (props) => {
    const {
        formikProps,
        intl,
        name,
        company,
        contactFirstName,
        contactLastName,
        contactEmail,
        contactPhone,
        description,
        createdAt,
        updatedAt,
        createdBy,
        isCanceled,
    } = props;

    const classes = useStyles();

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.infoCard.title' })}
            icon={<InfoIcon />}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <br />
                    {intl.formatMessage({ id: 'campaigns.infoCard.description' })}
                    <Box className={classes.floatRight}>
                        <p>{description.substring(0, 40)}</p>
                        <p>{description.substring(41, 80)}</p>
                        <p>{description.substring(81, 120)}</p>
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "15px" }} />
                    {intl.formatMessage({ id: 'campaigns.infoCard.createdBy' })}
                    <Box className={classes.floatRight}>
                        {createdBy}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.createdAt' })}
                    <Box className={classes.floatRight}>
                        {moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.updatedAt' })}
                    <Box className={classes.floatRight}>
                        {moment(createdAt).format("DD.MM.YYYY HH:mm:ss")}
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "15px" }} />
                    {intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                    <Box className={classes.floatRight}>
                        {company}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.infoCard.contact' })}
                    <Box className={classes.floatRight}>
                        {`${contactFirstName} ${contactLastName}`}
                    </Box>
                    
                </Grid>
                <Grid item sm={12}>
                    <Box className={classes.floatRight}>
                        {contactEmail}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box className={classes.floatRight}>
                        {contactPhone}
                    </Box>
                </Grid>
            </Grid>
        </IconCard>

    </>
};

export default injectIntl(InfoCard);
