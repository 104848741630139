import * as React from 'react';
import PageHeader from "components-lib/PageHeader/PageHeader";
import { injectIntl } from 'react-intl';
import { generatePath, withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import paths from 'paths';
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useState } from "react";
import { useEffect } from "react";
import IconCard from "components-lib/IconCard/IconCard";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Modal from "components-lib/Modal/Modal";
import { useSnackbar } from "notistack";
import ContactData from "./UserCards/ContactData";
import { Formik } from "formik";
import { getValidationSchemaDetail } from "./validationSchema";
import styles from "common/styles/widgets.js";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import RoleEnum from 'common/enums/RoleEnum';
import { isInRoles } from 'helpers/helpers';
import Box from "@material-ui/core/Box";
import StoreTable from "../Stores/StoreTable";
import CompanyTable from "../Companies/CompanyTable";
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconCardOneItem from "components-lib/IconCard/IconCardOneItem";
import { Business } from '@material-ui/icons';
import Loading from 'components-lib/Loading/Loading';

import USER_DETAIL from "queries/UsersQueries/userDetail";
import USER_BLOCK from "queries/UsersQueries/userBlock";
import USER_UNBLOCK from "queries/UsersQueries/userUnblock";
import USER_DELETE from "queries/UsersQueries/userDelete";
import PASSWORD_RESET from "queries/UsersQueries/passwordReset";
import COMPANY_BY_USER from "queries/UsersQueries/companyByUser";
import STORES_BY_USER from "queries/UsersQueries/storesByUser";
import PROFILE_QUERY from 'queries/ProfileQueries/userData';
import { userUpdate, storeCreate, storeList, companyCreate, companyList } from 'configFiles/roles'
const useStyles = makeStyles((theme) => styles(theme));

const UserDetailPage = ({ intl, match, history }) => {
    const id = pathOr(null, ['params', 'userId'], match);
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [userData, setUserData] = useState({});
    const [blockUserModalOpen, setBlockUserModalOpen] = useState(false);
    const [unblockModalOpen, setUnblockModalOpen] = useState(false);
    const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
    const [blockUserReason, setBlockuserReason] = useState('');
    const [filterStores, setFilterStores] = useState([]);
    const [filterCompanies, setFilterCompanies] = useState([]);

    const [blockUser] = useMutation(USER_BLOCK);
    const [deleteUser] = useMutation(USER_DELETE);
    const [unblockUser] = useMutation(USER_UNBLOCK);
    const [resetPassword] = useMutation(PASSWORD_RESET);
    const { data, loading, refetch: refetchUserData } = useQuery(USER_DETAIL, { variables: { id } });
    const { data: dataCompanies } = useQuery(COMPANY_BY_USER(id), { skip: !companyList });
    const { data: me } = useQuery(PROFILE_QUERY);
    const [loadStores, { data: dataStores }] = useLazyQuery(STORES_BY_USER, { skip: !storeList });
    let editableFields = (pathOr('', ['me', 'user', 'id'], me) === id ? false : true)
    let backButton = (history.length > 1) ? true : false

    useEffect(() => {
        let companiesSelectItems = pathOr([], 'companiesTableByMember.items', dataCompanies).map((company) => {
            return Number(company.id)
        })
        setFilterCompanies(companiesSelectItems);
    }, [dataCompanies]);

    useEffect(() => {
        let storesSelectItems = pathOr([], 'storesTableByUser.items', dataStores).map((store) => {
            return Number(store.id)
        })
        setFilterStores(storesSelectItems);
    }, [dataStores]);
    const userRole = userData.role || '';

    let canEditUser = true;
    if (isInRoles([RoleEnum.MAINTAINER]) && userRole === 'Maintainer') {
        canEditUser = false;
    }
    if (!id) {
        history.push(paths.dashboard);
    }

    useEffect(() => {
        setUserData(pathOr({}, ['user'], data));
    }, [data]);

    const handleUserBlock = () => {
        blockUser({
            variables: {
                id,
                reason: blockUserReason
            }
        }).then((response) => {
            const firstName = pathOr('', ['data', 'userBlock', 'firstName'], response);
            const lastName = pathOr('', ['data', 'userBlock', 'lastName'], response);
            setBlockuserReason('')
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.toast.block.success' }, [firstName, lastName]), { variant: 'success' });
            refetchUserData();
            setBlockUserModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err)
        })
    };

    const handleUserDelete = () => {
        deleteUser({
            variables: {
                id: id,
            }
        }).then(() => {
            history.push(`/admin${generatePath(paths.users.list)}`);
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.toast.delete.success' }), { variant: 'success' });
        }).catch(err => {
            console.log('[error]', err)
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.toast.delete.success' }), { variant: 'error' })
        });
    };

    const handleResetPassword = () => {
        resetPassword({
            variables: {
                email: userData.email
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.resetPassword.success' }, [pathOr('', ['user', 'email'], data)]), { variant: 'success' });
        }).catch((err) => {
            console.log('[error]', err)
        });
    };

    const handleUserActivate = () => {
        unblockUser({
            variables: {
                id: userData.id
            }
        }).then((response) => {
            const firstName = pathOr('', ['data', 'userBlock', 'firstName'], response);
            const lastName = pathOr('', ['data', 'userBlock', 'lastName'], response);
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.toast.unblock.success' }, [firstName, lastName]), { variant: 'success' });
            refetchUserData();
            setUnblockModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err)
        })
    };

    let headerActions =
        (userUpdate) ?
            [
                {
                    id: 'blockUserAction',
                    title: intl.formatMessage({ id: 'userDetail.headerActions.blockUser' }),
                    onClick: (e) => setBlockUserModalOpen(true)
                },
                {
                    id: 'resetPasswordAction',
                    title: intl.formatMessage({ id: 'userDetail.headerActions.resetPassword' }),
                    onClick: (e) => handleResetPassword()
                },
                {
                    title: intl.formatMessage({
                        id: "userDetail.headerActions.deleteUser",
                    }),
                    onClick: (e) => setDeleteUserModalOpen(true),
                }
            ] : []
    if (userData.isBlocked) {
        headerActions = headerActions.filter((action) => action.id !== 'blockUserAction');
        headerActions.unshift({
            id: 'allowUserAction',
            title: intl.formatMessage({ id: 'userDetail.headerActions.allowUser' }),
            color: 'success',
            onClick: (e) => setUnblockModalOpen(true)
        })
    }

    if (['Facebook', 'Google'].includes(userData.signInMethod)) {
        headerActions = headerActions.filter((action) => action.id !== 'resetPasswordAction');
    }

    const renderStoreButtons = () => {
        return (
            <>
                <hr />
                { (storeList) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        disabled={filterStores.length <= 0}
                        className={classes.floatRight}
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.stores.list)}`,
                            state: { id: { in: filterStores } }
                        })}
                    >
                        {intl.formatMessage({ id: 'storeList.table.allStore' })}
                    </Button>
                }
                { (storeCreate) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => history.push(`/admin${generatePath(paths.stores.new)}`)}
                    >
                        {intl.formatMessage({ id: 'storeList.table.addStore' })}
                    </Button>
                }
            </>
        )
    }

    const renderCompanyButtons = () => {
        return (
            <>
                <hr />
                { (companyList) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        disabled={filterCompanies.length <= 0}
                        className={classes.floatRight}
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.companies.list)}`,
                            state: { id: { in: filterCompanies } }
                        })}                >
                        {intl.formatMessage({ id: 'companyList.table.allCompanies' })}
                    </Button>
                }
                { (companyCreate) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        className={classes.floatRight}
                        onClick={() => history.push(`/admin${generatePath(paths.companies.new)}`)}
                    >
                        {intl.formatMessage({ id: 'companyList.table.addCompany' })}
                    </Button>
                }
            </>
        )
    }

    const processAccountStatus = () => {
        const isActivated = pathOr(false, ['isActivated'], userData);
        const isBlocked = pathOr(false, ['isBlocked'], userData);

        if (isActivated && !isBlocked) {
            return <Box color="success.main">{intl.formatMessage({ id: 'userStatus.activated' })}</Box>
        } else if (isBlocked) {
            return <Box color="error.main">{intl.formatMessage({ id: 'userStatus.blocked' })}</Box>
        } else if (!isActivated && !isBlocked) {
            return <Box color="warning.main">{intl.formatMessage({ id: 'userStatus.unactivated' })}</Box>
        }
    };

    const getSubtitle = () => {
        return <>
            {pathOr('', ['user', 'bid'], data)} <span> </span>
            {pathOr('', ['user', 'groups'], data).map((item, index) => (
                <>{((pathOr('', ['user', 'groups'], data).length) !== index + 1) ? (item.name + ", ") : (item.name)}</>
            ))}
        </>
    }

    if (loading) return <Loading />
    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'userDetail.title' }, { userName: `${userData.firstName} ${userData.lastName}` })}
                subTitle={getSubtitle()}
                handleBackAction={(e) => history.goBack()}
                actions={canEditUser ? headerActions : []}
                withBackButton={backButton}
            />
            <Formik
                enableReinitialize
                initialValues={userData}
                validationSchema={getValidationSchemaDetail(intl)}
            >
                {(formikProps) => (
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <Grid>
                                <IconCardOneItem
                                    icon={<NotificationsIcon />}
                                    headerText={intl.formatMessage({ id: 'userDetail.statusCard.status' })}
                                    bodyText={processAccountStatus()}
                                />
                            </Grid>
                            <Grid >
                                <ContactData
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    disabled={true}
                                    canEditUser={editableFields}
                                    editableFields={editableFields}
                                    id={id}
                                    refetchUserData={refetchUserData}
                                    created={false}
                                    userUpdate={userUpdate}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={8}>
                            {(companyList) &&
                                <IconCard
                                    icon={<Business />}
                                    title={intl.formatMessage({ id: "userDetail.companyTable.title" })}
                                >
                                    <CompanyTable
                                        query={COMPANY_BY_USER(id)}
                                        queryDataPath={['companiesTableByMember', 'items']}
                                        userId={id}
                                        defaultFilter={null}
                                        history={history}
                                        numberOfRows={5}
                                    />
                                    {renderCompanyButtons()}
                                </IconCard>
                            }
                            {(storeList) &&

                                <IconCard
                                    icon={<ShoppingBasketIcon />}
                                    title={intl.formatMessage({ id: 'userDetail.storeTable.title' })}
                                >
                                    <StoreTable
                                        data={dataStores}
                                        loadData={loadStores}
                                        queryDataPath={['storesTableByUser', 'items']}
                                        queryVariables={{ userId: id }}
                                        userId={id}
                                        defaultFilter={null}
                                        history={history}
                                        detail={true}
                                        numberOfRows={5}
                                    />
                                    {renderStoreButtons()}
                                </IconCard>
                            }
                        </Grid>
                    </Grid>
                )}
            </Formik>

            <Modal
                open={unblockModalOpen}
                onClose={() => setUnblockModalOpen(false)}
                title={intl.formatMessage({ id: 'userDetail.unblockUser.modalTitle' })}
                maxWidth="sm"
                fullWidth
                actions={
                    <>
                        <Button
                            onClick={() => handleUserActivate()}
                            color="success"
                            round
                            size="sm"
                        >
                            {intl.formatMessage({ id: 'userDetail.unblockUser.modalConfirm' })}
                        </Button>
                        <Button
                            size="sm"
                            color="primary"
                            onClick={() => setUnblockModalOpen(false)}
                            round
                        >
                            {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                        </Button>
                    </>
                }
            >
                <p>{intl.formatMessage({ id: 'userDetail.unblockUser.modalText' })}</p>
            </Modal>
            <Modal
                title={intl.formatMessage({ id: 'userDetail.blockUser.modalTitle' })}
                open={blockUserModalOpen}
                onClose={() => setBlockUserModalOpen(false)}
                maxWidth="xs"
                fullWidth
                actions={
                    <>
                        <Button
                            onClick={() => handleUserBlock()}
                            color="danger"
                            round
                            size="sm"
                        >
                            {intl.formatMessage({ id: 'userDetail.blockUser.modalConfirm' })}
                        </Button>
                        <Button
                            size="sm"
                            color="primary"
                            onClick={() => setBlockUserModalOpen(false)}
                            round
                        >
                            {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                        </Button>
                    </>
                }
            >
                <p>{intl.formatMessage({ id: 'userDetail.blockUser.modalSubtitle' })}: </p>
                <TextField
                    multiline
                    fullWidth
                    value={blockUserReason}
                    onChange={(e) => setBlockuserReason(e.target.value)}
                />
            </Modal>
            <Modal
                title={intl.formatMessage({ id: 'userDetail.deleteUser.modalTitle' })}
                open={deleteUserModalOpen}
                onClose={() => setDeleteUserModalOpen(false)}
                maxWidth="xs"
                fullWidth
                actions={
                    <>
                        <Button
                            onClick={() => handleUserDelete()}
                            color="danger"
                            round
                            size="sm"
                        >
                            {intl.formatMessage({ id: 'userDetail.deleteUser.modalConfirm' })}
                        </Button>
                        <Button
                            size="sm"
                            color="primary"
                            onClick={() => setDeleteUserModalOpen(false)}
                            round
                        >
                            {intl.formatMessage({ id: "invoicesList.assignModal.cancel" })}
                        </Button>
                    </>
                }
            />
        </>
    );
};

export default injectIntl(withRouter(UserDetailPage));