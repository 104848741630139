import Node from './Node';
export default class Tree {
    constructor(root) {
        this.root = root;
        this.setParents();
        this.setPartiallyChecked();
    }
    getRoot() {
        return this.root;
    }
    getNodes() {
        return [this.root, ...this.root.getDescendants()];
    }
    findNodes(id) {
        const ids = (typeof id === 'string') ? [id] : id;
        return this.getNodes().filter(node => ids.includes(node.getId()));
    }
    toObject() {
        this.clearIsPartiallyChecked();
        this.setPartiallyChecked();
        const descendantsArray = this.root.getDescendants();
        const descendantsObject = {
            [this.root.getId()]: this.root.toObject(false)
        };
        for (const descendant of descendantsArray) {
            descendantsObject[descendant.getId()] = descendant.toObject(false);
        }
        return {
            root: this.root.toObject(),
            nodes: descendantsObject
        };
    }
    getLeaves() {
        return this.root.getDescendants().filter(descendant => descendant.getChildren().length === 0);
    }
    static fromObject(tree) {
        return new Tree(Node.fromObject(tree.root));
    }
    clearIsPartiallyChecked() {
        this.root.clearIsPartiallyChecked();
    }
    setPartiallyChecked() {
        const checkedLeaves = this.getLeaves().filter(leaf => leaf.getIsChecked());
        for (const checkedLeaf of checkedLeaves) {
            checkedLeaf.setIsPartiallyChecked();
        }
    }
    setParents(node = this.root, parent = null) {
        if (parent !== null) {
            node.setParent(parent);
        }
        node.getChildren().forEach(child => {
            this.setParents(child, node);
        });
    }
}
