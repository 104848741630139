import { useEffect, useState } from 'react';
import CompanyApi from '../../service/api/Company';
import useTree from './useTree';
const useCompany = (axios, campaign, value) => {
    const [treeData, setTreeData] = useState(null);
    const [tree, checkedIds] = useTree(treeData, value.companyIds);
    useEffect(() => {
        if (!value.locationIds.length || !value.segmentIds.length) {
            setTreeData(null);
            return;
        }
        (async () => {
            try {
                const companyApi = new CompanyApi(axios, campaign);
                setTreeData((await companyApi.get({
                    locationIds: value.locationIds.filter(location => location !== '*'),
                    segmentIds: value.segmentIds.filter(segment => segment !== '*'),
                })).toObject());
            }
            catch (exception) {
                setTreeData(null);
                console.warn('Failed to retrieve tree data for filter. Exception: ' + exception);
            }
        })();
    }, [axios, campaign, value.locationIds, value.segmentIds]);
    return [tree, checkedIds];
};
export default useCompany;
