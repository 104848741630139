import gql from 'graphql-tag';

const ZONE_CREATE = gql`
mutation zoneCreate($input: ZoneCreateInput!){
    zoneCreate(input: $input){
        id
        name     
    }
}
`;

export default ZONE_CREATE;