import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
// import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CalendarCard from '../CampaignCreateCards/SummaryCalendarCard';
import { useQuery } from "@apollo/react-hooks";
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import Loading from "components-lib/Loading/Loading";

const useStyles = makeStyles((theme) => styles(theme));

const Step4 = ({ intl, initialData, companyId, basicInfo, stores, displays, medias, activeTimes }) => {
  const classes = useStyles();

  const { data: companyData, loading: companyLoading, refetch: refetchData } = useQuery(COMPANY_DETAIL, {
    variables: {
        id: companyId
    }
});

  useEffect(() => {
    // console.log('companyId: ', companyId);
    // console.log('basicInfo: ', basicInfo);
    // console.log('stores: ', stores);
    // console.log('displays: ', displays);
    // console.log('medias: ', medias);
    // console.log('activeTimes: ', activeTimes);
  }, []);

  if (companyLoading) return <Loading/>

  return (
    <Grid container spacing={1}>
      <Grid item sm={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.basicInfo' })}:
                </Typography>
                <br />

                <Grid item sm={12} color="textSecondary">
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.name' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {basicInfo.name}
                    </span>
                  </Box>
                </Grid>

                <Grid item sm={12} color="textSecondary">
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.company' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {companyData?.company.name}
                    </span>
                  </Box>
                </Grid>

                <Grid item sm={12}>
                  <span style={{ color: "gray" }}>
                    {intl.formatMessage({ id: 'campaigns.wizard.finalStep.description' })}:
                  </span>
                  <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                    <span style={{ color: "gray" }}>
                      {(basicInfo?.description) ? (basicInfo?.description) : "-"}
                    </span>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.finalStep.media' })}
                </Typography>
                <br />
                <Grid container spacing={1}>
                  <Grid item sm={12}>
                    {medias.map((med, index) =>
                      <>
                        <span style={{ color: "gray" }}>
                          {`${index+1}. ${med.name}`}
                        </span>
                        <br />
                      </>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={6}>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.finalStep.calendar' })}
                </Typography>
                <br />
                <Grid item sm={12}>
                  <CalendarCard
                    acitiveTimes={activeTimes}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {/* <Grid item sm={12}>
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography variant="h5" component="h2">
                  {intl.formatMessage({ id: 'campaigns.wizard.finalStep.stores' })}
                </Typography>
                <br />
                {storeTable?.length === 0 ?

                  <Grid item sm={12}>
                    <span style={{ color: "gray" }}>
                      {intl.formatMessage({ id: 'campaigns.wizard.finalStep.storesList' })}:
                    </span>
                    <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                      <span style={{ color: "gray" }}>
                        {intl.formatMessage({ id: 'campaigns.wizard.finalStep.all' })}
                      </span>
                    </Box>
                  </Grid>
                  :
                  <>
                    <span style={{ color: "gray" }}>
                      <b>{intl.formatMessage({ id: 'campaigns.wizard.finalStep.storesList' })}</b>
                    </span>
                    <br />
                    <Grid container spacing={1}>
                      {
                        storeTable?.map(store =>
                          <Grid item sm={12} key={store.bid}>
                            <span style={{ color: "gray" }}>
                              {store.bid}
                            </span>
                            <Box className={classes.floatRight} style={{ marginLeft: "1.5em" }}>
                              <span style={{ color: "gray" }}>
                                {store.name}
                              </span>
                            </Box>
                          </Grid>
                        )
                      }
                    </Grid>
                  </>
                }
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid >
  );
}

export default injectIntl(Step4);