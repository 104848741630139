import React from 'react';
import { injectIntl } from 'react-intl';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import paths from 'paths';
import { pathOr } from 'rambda';
import Button from "components/CustomButtons/Button.js";
import colors from 'common/styles/colors';
import { generatePath } from 'react-router-dom';
import { userDetail } from 'configFiles/roles'

export const NotificationTable = (props) => {
    const { history, query, intl, defaultFilter, queryDataPath } = props

    return (
        <QueryTable
            query={query}
            queryDataPath={queryDataPath}
            permanentFilter={defaultFilter}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'usersPage.table.id' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'usersPage.table.name' }),
                    accessor: 'fullName',
                    filterKey: 'fullName',
                    filterOperator: 'contains',
                    sortKey: 'fullName'
                },
                {
                    Header: intl.formatMessage({ id: 'usersPage.table.email' }),
                    accessor: 'email',
                    filterKey: 'email',
                    filterOperator: 'contains',
                    sortKey: 'email'
                },
                {
                    Header: "Spoločnosti",
                    accessor: (rowData) => (rowData.companies) ? rowData.companies : "-",
                    filterKey: 'companies',
                    filterOperator: 'contains',
                    sortKey: 'companies'
                },
                {
                    Header: intl.formatMessage({ id: 'usersPage.table.role' }),
                    accessor: (rowData) => (rowData.groups) ? rowData.groups : "-",
                    filterKey: 'groups',
                    filterOperator: 'contains',
                    sortKey: 'groups'
                },
                {
                    Header: intl.formatMessage({ id: 'usersPage.table.status' }),
                    accessor: (rowData) => {
                        const status = pathOr(false, ['status'], rowData);
                        if (status === 'Activated') {
                            return <span style={{ color: colors.success }}>
                                {intl.formatMessage({ id: 'userStatus.activated' }).toUpperCase()}
                            </span>
                        } else if (status === 'Blocked') {
                            return <span style={{ color: colors.danger }}>
                                {intl.formatMessage({ id: 'userStatus.blocked' }).toUpperCase()}
                            </span>
                        } else if (status === 'Unactivated') {
                            return <span style={{ color: colors.warning }}>
                                {intl.formatMessage({ id: 'userStatus.unactivated' }).toUpperCase()}
                            </span>
                        }
                    },
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: 'Activated',
                            label: intl.formatMessage({ id: 'userStatus.activated' })
                        },
                        {
                            value: 'Blocked',
                            label: intl.formatMessage({ id: 'userStatus.blocked' })
                        },
                        {
                            value: 'Unactivated',
                            label: intl.formatMessage({ id: 'userStatus.unactivated' })
                        }
                    ]
                },
                (userDetail) ?
                    {
                        Header: intl.formatMessage({ id: 'usersPage.table.actions' }),
                        accessor: (rowData) => (
                            <Button
                                color='info'
                                round
                                size='sm'
                                onClick={() => history.push(`/admin${generatePath(paths.users.detail, { userId: rowData.id })}`)}
                            >
                                {intl.formatMessage({ id: 'usersPage.table.detail' })}
                            </Button>
                        )
                    } : { Header: " " }
            ]}
        />
    )
}

export default injectIntl(NotificationTable);