const FlexSearch = require('flexsearch');
export default class ByNameNodeFilter {
    static async filter(nodes, value, limit = 10) {
        if (value.length < 3) {
            return [];
        }
        const index = new FlexSearch({
            profile: 'match',
            tokenize: 'strict'
        });
        for (const i in nodes) {
            index.add(i, nodes[i].name);
        }
        return await index.search({
            query: value,
            suggest: true,
            limit
        });
    }
}
