import { Grid } from "@material-ui/core";
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import FormikField from "components-lib/FormikField/FormikField";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";

const useStyles = makeStyles((theme) => styles(theme));

const MaintainerCard = (props) => {
  const { intl, formikProps, storeUpdate, multipleUpdate } = props;
  const classes = useStyles();

  const [firstNameChange, setFirstNameChange] = useState(multipleUpdate ? false : true);
  const [lastNameChange, setLastNameChange] = useState(multipleUpdate ? false : true);
  const [phoneChange, setPhoneChange] = useState(multipleUpdate ? false : true);
  const [emailChange, setEmailChange] = useState(multipleUpdate ? false : true);

  const renderContent = () => {
    if (!storeUpdate) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
            <Box className={classes.floatRight}>
              {formikProps.values.contactFirstName} {formikProps.values.contactLastName}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.maintainerCard.phone" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.contactPhone) ? formikProps.values.contactPhone : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.maintainerCard.email" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.contactEmail) ? formikProps.values.contactEmail : "-"}
            </Box>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            {!firstNameChange ?
              <MultipleValuesField
                callback={(newValue) => setFirstNameChange(newValue)}
                labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.firstName" })}
              />
              :
              <FormikField
                name="contactFirstName"
                labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.firstName" })}
                formikProps={formikProps}
                helperText={(multipleUpdate) &&
                  <span
                    className={classes.multiUpdate}
                    style={{ color: "blue" }}
                    onClick={() => { formikProps.setFieldValue("contactFirstName", undefined); setFirstNameChange(false) }}
                  >
                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange'})}
                  </span>
                }
                highlightChange
              />
            }
          </Grid>
          <Grid item sm={6}>
            {!lastNameChange ?
              <MultipleValuesField
                callback={(newValue) => setLastNameChange(newValue)}
                labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.lastName" })}
              />
              :
              <FormikField
                name="contactLastName"
                labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.lastName" })}
                formikProps={formikProps}
                helperText={(multipleUpdate) &&
                  <span
                    className={classes.multiUpdate}
                    style={{ color: "blue" }}
                    onClick={() => { formikProps.setFieldValue("contactLastName", undefined); setLastNameChange(false) }}
                  >
                    {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange'})}
                  </span>
                }
                highlightChange
              />
            }
          </Grid>
        </Grid>

        <Grid item sm={12}>
          {!phoneChange ?
            <MultipleValuesField
              callback={(newValue) => setPhoneChange(newValue)}
              labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.phone" })}
            />
            :
            <FormikField
              name="contactPhone"
              labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.phone" })}
              formikProps={formikProps}
              helperText={(multipleUpdate) &&
                <span
                  className={classes.multiUpdate}
                  style={{ color: "blue" }}
                  onClick={() => { formikProps.setFieldValue("contactPhone", undefined); setPhoneChange(false) }}
                >
                  {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange'})}
                  </span>
              }
              highlightChange
            />
          }
        </Grid>

        <Grid item sm={12}>
          {!emailChange ?
            <MultipleValuesField
              callback={(newValue) => setEmailChange(newValue)}
              labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.email" })}
            />
            :
            <FormikField
              name="contactEmail"
              labelText={intl.formatMessage({ id: "storeDetail.maintainerCard.email" })}
              formikProps={formikProps}
              helperText={(multipleUpdate) &&
                <span
                  className={classes.multiUpdate}
                  style={{ color: "blue" }}
                  onClick={() => { formikProps.setFieldValue("contactEmail", undefined); setEmailChange(false) }}
                >
                  {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange'})}
                  </span>
              }
              highlightChange
            />
          }
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.maintainerCard.title" })}
        icon={<TransferWithinAStationIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(MaintainerCard));
