import React from 'react';
import { userIsLogged } from 'helpers/helpers';
import paths from 'paths';
import { Route, Redirect } from "react-router-dom";

const  PublicRoute = (props) => {

    const isLogged = userIsLogged();

    return isLogged ? <Redirect to={`/admin${paths.dashboard}`} /> : <Route exact {...props} />;
};

export default PublicRoute;