import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from "components/CustomButtons/Button.js";
import { useMutation } from 'react-apollo';
import paths from "paths";
import { generatePath } from "react-router-dom";
import { injectIntl } from 'react-intl';
import { defaultColors } from "common/styles/configLayout.js";
import { useSnackbar } from "notistack";
import { pathOr } from "rambda";

// core components
import PageHeader from 'components-lib/PageHeader/PageHeader';
import { Grid } from "@material-ui/core";
import IconCard from 'components-lib/IconCard/IconCard';

import Step1 from "./WizardSteps/Step1.js";
import Step2 from "./WizardSteps/Step2.js";
import Step3 from "./WizardSteps/Step3.js";
import Step4 from "./WizardSteps/Step4.js";
import Step5 from "./WizardSteps/Step5.js";
import Step6 from "./WizardSteps/Step6.js";
import Box from "@material-ui/core/Box";
import clsx from 'clsx';
import StepConnector from '@material-ui/core/StepConnector';
import CREATE_CAMPAIGN from "queries/CampaignsQueries/campaignCreate";
import InfoIcon from '@material-ui/icons/Info';
import StoreIcon from '@material-ui/icons/Store';
import TvIcon from '@material-ui/icons/Tv';
import MediaIcon from '@material-ui/icons/PermMedia';
import CalendarIcon from '@material-ui/icons/DateRange';
import AssignemntIcon from '@material-ui/icons/AssignmentTurnedIn';
import QueueIcon from '@material-ui/icons/Queue';



const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);



const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#bdbdbd',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: defaultColors.five,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: defaultColors.five,
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    floatRight: {
        float: "right"
    },
}));


const CampaignNewPage = (props) => {
    const classes = useStyles();
    const { history, intl, axios } = props;

    const [activeStep, setActiveStep] = useState(0);

    const [valuesStep1, setValuesStep1] = useState({});
    const [valuesStep2, setValuesStep2] = useState({
        locationIds: ["*"],
        segmentIds: ["*"],
        companyIds: ["*"],
        storeIds: ["*"]
    });
    const [valuesStep3, setValuesStep3] = useState([]);
    const [valuesStep4, setValuesStep4] = useState([]);
    const [valuesStep5, setValuesStep5] = useState([]);
    const [initialData, setInitialData] = useState({});

    const [companyId, setCompanyId] = useState(null);

    const steps = getSteps();
    const { enqueueSnackbar } = useSnackbar();
    const [createCampaign] = useMutation(CREATE_CAMPAIGN);


    const handleNext = () => {
        setCompanyId(valuesStep1?.companyId);
        const step = activeStep + 1;
        setActiveStep(step);
        if(step === 0) setInitialData(valuesStep1);
        else if(step === 1) setInitialData(valuesStep2);
        else if(step === 2) setInitialData(valuesStep3);
        else if(step === 3) setInitialData(valuesStep4);
        else if(step === 4) setInitialData(valuesStep5);
    };

    const handleBack = () => {
        setCompanyId(valuesStep1?.companyId)

        const step = activeStep - 1;
        setActiveStep(step);
        if(step === 0) setInitialData(valuesStep1);
        else if(step === 1) setInitialData(valuesStep2);
        else if(step === 2) setInitialData(valuesStep3);
        else if(step === 3) setInitialData(valuesStep4);
        else if(step === 4) setInitialData(valuesStep5);

        // if (activeStep === 0) setInitialData(valuesStep1)
        // if (activeStep === 1) setInitialData(valuesStep2)
        // if (activeStep === 2) {
        //     setInitialData(valuesStep3);
        //     setTimes(times);
        //     setCriteria(criteria)
        // }
    };
    function getSteps() {
        return [
            "",
            "",
            "",
            "",
            "",
            "",
        ];
    }

    const [valid, setValid] = useState(false);
    const [criteria, setCriteria] = useState({
        locationIds: ["*"],
        segmentIds: ["*"],
        companyIds: ["*"],
        storeIds: ["*"]
    })

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <Step1
                    initialData={initialData}
                    callback={(newValue) => setValuesStep1(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                />;
            case 1:
                return <Step2
                    initialData={initialData}
                    axios={axios}
                    callback={(newValue) => setValuesStep2(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                    companyId={valuesStep1?.companyId}
                />;
            case 2:
                return <Step3
                    initialData={initialData}
                    callback={(newValue) => setValuesStep3(newValue)}
                    callbackValid={(newValue) => setValid(newValue)}
                    companyId={valuesStep1?.companyId}
                    storeIds={valuesStep2.storeIds}
                />;
            case 3:
                return <Step4
                    initialData={initialData}
                    companyId={valuesStep1?.companyId}
                    callback={(newValue) => setValuesStep4(newValue)}
                />;
            case 4:
                return <Step5
                    initialData={initialData}
                    callback={(newValue) => setValuesStep5(newValue)}
                />;
            case 5:
                return <Step6
                    companyId={valuesStep1?.companyId}
                    basicInfo={valuesStep1}
                    stores={valuesStep2}
                    displays={valuesStep3}
                    medias={valuesStep4}
                    activeTimes={valuesStep5}
                />;
            default:
                return 'Unknown step';
        }
    }




    const setTitle = (active) => {
        if (activeStep === 0) return intl.formatMessage({ id: 'campaigns.wizard.step_1.title' })
        else if (activeStep === 1) return intl.formatMessage({ id: 'campaigns.wizard.step_2.title' })
        else if (activeStep === 2) return intl.formatMessage({ id: 'campaigns.wizard.step_3.title' })
        else if (activeStep === 3) return intl.formatMessage({ id: 'campaigns.wizard.step_4.title' })
        else if (activeStep === 4) return intl.formatMessage({ id: 'campaigns.wizard.step_5.title' })
        else return intl.formatMessage({ id: 'campaigns.wizard.step_6.title' })
    }


    const handleCreateCampaign = () => {
        const CampaignCreateInput = {
            name: valuesStep1?.name,
            companyId: valuesStep1?.companyId,
            description: valuesStep1?.description,
            mediaIds: valuesStep4.map(med => med.id),
            groupIds: valuesStep3,
            activeTimes: valuesStep5,
            criteria: {
                locationIds: (valuesStep2?.locationIds[0] === "*") ? [] : valuesStep2?.locationIds,
                segmentIds: (valuesStep2?.segmentIds[0] === "*") ? [] : valuesStep2?.segmentIds,
                companyIds: (valuesStep2?.companyIds[0] === "*") ? [] : valuesStep2?.companyIds,
                storeIds: (valuesStep2?.storeIds[0] === "*") ? [] : valuesStep2?.storeIds
            }
        }

        createCampaign({
            variables: {
                input: CampaignCreateInput
            }
        }).then((response) => {
            const id = pathOr("", ["data", "campaignCreate", "id"], response);
            history.push(`/admin${generatePath(paths.campaigns.detail, { campaignId: Number(id) })}`);
        })
            .catch((err) => {
            console.log('[error]', err)
        });
    };

    //Minimalny interval opakovania pre pouzivatela (dni)
    function ColorlibStepIcon(props) {
        const classes = useColorlibStepIconStyles();
        const { active, completed } = props;

        const icons = {
            1: <InfoIcon />,
            2: <StoreIcon />,
            3: <TvIcon />,
            4: <MediaIcon />,
            5: <CalendarIcon />,
            6: <AssignemntIcon />,
        };

        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                    [classes.completed]: completed,
                })}
            >
                {icons[String(props.icon)]}
            </div>
        );
    }

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'campaigns.wizard.title' })}
                handleBackAction={(e) => history.goBack()}
            />
            <Grid item><br /></Grid>
            <Grid container justify="center">
                <Grid item sm={8}>
                    <IconCard
                        icon={<QueueIcon/>}
                        title={setTitle(activeStep)}
                    >
                        <Grid item><hr /></Grid>
                        <Grid item>
                            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>

                            {getStepContent(activeStep)}

                            <Box className={classes.floatRight}>
                                {(activeStep !== 0) &&
                                    <Button round disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                                        {intl.formatMessage({ id: 'deviceDetail.status.back' })}
                                    </Button>
                                }

                                <Button
                                    round
                                    disabled={valid ? false : true}
                                    variant="contained"
                                    color="success"
                                    onClick={(activeStep === 5) ? handleCreateCampaign : handleNext}
                                >
                                    {activeStep === steps.length - 1 ? intl.formatMessage({ id: 'campaigns.wizard.button.submit' }) : intl.formatMessage({ id: 'campaigns.wizard.button.continue' })}
                                </Button>
                            </Box>
                        </Grid>

                    </IconCard>
                </Grid>
            </Grid>
        </>
    );
}

export default injectIntl(CampaignNewPage);