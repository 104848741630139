import gql from 'graphql-tag';

const PARTNERS_BY_COMPANY = (companyId) => gql`
query usersTableByCompany(
  $offset: Int, 
  $limit: Int, 
  $sort: [UsersTableSortInput!], 
  $filter: [UsersTableFilterInput]){
    usersTableByCompany(
      companyId: ${companyId}
      offset:$offset, 
      limit: $limit, 
      sort: $sort, 
      filter: $filter){
      totalCount,
      items{
        id
        bid    
        fullName
      }
    }
  }
`;

export default PARTNERS_BY_COMPANY;