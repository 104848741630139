import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from "paths";
import { pathOr } from "rambda";
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import StatusCard from "./ZoneCards/StatusCard";
import TimePlanCard from "./ZoneCards/TimePlanCard"
import BasicInfoCard from "./ZoneCards/BasicInfoCard"
import { getValidationSchema } from "./validationSchema";
import { Formik } from "formik";
import DEVICE_REMOVE_ZONE from "queries/DevicesQueries/deviceRemoveZone";
import ZONE_DELETE from "queries/ZonesQueries/zoneDelete";
import ZONE_DETAIL from "queries/ZonesQueries/zoneDetail";
import ZONE_UPDATE from 'queries/ZonesQueries/zoneUpdate';
import moment from 'moment';
import DEVICES_QUERY from 'queries/DevicesQueries/getDevices';
import Loading from "components-lib/Loading/Loading";
import DisplayCard from "./ZoneCards/DisplayCard";
import { zoneUpdate, zoneDel, zoneCreate, deviceAssign } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const StoreDetailPage = (props) => {

    const classes = useStyles();
    const zoneId = props.match.params.zoneId;
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();

    //Hooks
    const [removePlayerOpen, setRemovePlayerOpen] = useState(false);
    const [removeZoneOpen, setRemoveZoneOpen] = useState(false);
    const [formikZoneData, setFormikZoneData] = useState({});
    const [deviceId, setDeviceId] = useState(null);

    const [inactiveTimes, setInactiveTimes] = useState([])
    const [availableDevices, setAvailableDevices] = useState({});
    const [times, setTimes] = useState([]);



    //Queries and Mutations
    const { data: zoneData, loading: zoneLoading, refetch: refetchData } = useQuery(ZONE_DETAIL, { variables: { id: zoneId, }, });
    const { data: devicesData, refetch: refetchDevice } = useQuery(DEVICES_QUERY,
        {
            variables: {
                filter: [
                    {
                        zone: {
                            isNull: true
                        }
                    }
                ]
            }
        }
    );
    const [deviceRemoveZone] = useMutation(DEVICE_REMOVE_ZONE);
    const [zoneDelete] = useMutation(ZONE_DELETE);
    const [updateZone] = useMutation(ZONE_UPDATE);

    //Mount component use effect

    useEffect(() => {
        setFormikZoneData(pathOr({}, ['zone'], zoneData));
        setTimes(pathOr('', ['zone', 'preferences', 'activeTimes'], zoneData))
        setInactiveTimes(((Object.values(pathOr('', ['zone', 'preferences', 'inactiveTimes'], zoneData))?.map(item =>
        (
            {
                from: moment(item?.from),
                to: moment(item?.to)
            }

        )))))
        setDeviceId(pathOr('', ['zone', 'device', 'id'], zoneData))
    }, [zoneData]);

    useEffect(() => {
        let deviceSelectItems = pathOr([], 'devices.items', devicesData).map((device) => {
            return {
                value: pathOr('', 'id', device),
                label: `${pathOr('', 'id', device)} - ${pathOr('', 'bid', device)}`
            }
        })
        setAvailableDevices(deviceSelectItems);
    }, [devicesData]);


    //Detail Page Head functionality
    const getZoneBid = () => {
        return (
            <>
                {intl.formatMessage({ id: "zoneDetail.title" })} &nbsp;
                {pathOr('', ['zone', 'bid'], zoneData)}
            </>
        );
    };

    /**
     * MUTATIONS
     */

    //Update store mutation
    const handleUpdateZone = (values) => {

        for (let i = 0; i < times.length; i++) {
            if (times[i].__typename)
                delete times[i].__typename;
        }

        for (let i = 0; i < times.length; i++) {
            setTimes(delete times[i].__typename);
        }
        let setInTimes = []
        for (let i = 0; i < inactiveTimes.length; i++) {
            if (inactiveTimes[i]?.from !== undefined || inactiveTimes[i]?.to !== undefined)
                setInTimes.push({
                    from: inactiveTimes[i]?.from,
                    to: inactiveTimes[i]?.to
                });
        }

        console.log("A", values?.preferences?.screenAGroup?.id)
        console.log("B", values?.preferences?.screenBGroup?.id)

        updateZone({
            variables: {
                id: values.id,
                input: {
                    name: values.name,
                    area: (values?.area) ? Number(values?.area) : undefined,
                    visitors: (values?.visitors) ? Number(values?.visitors) : undefined,
                    preferences: {
                        segmentId: (values?.preferences?.segment?.id) ? Number(values?.preferences?.segment?.id) : undefined,
                        screenAGroupId: (values?.preferences?.screenAGroup?.id)
                            ? (values?.preferences?.screenAGroup?.id === "without") ? null : (values?.preferences?.screenAGroup?.id)
                            : undefined,
                        screenBGroupId: (values?.preferences?.screenBGroup?.id)
                            ? (values?.preferences?.screenBGroup?.id === "without") ? null : (values?.preferences?.screenBGroup?.id)
                            : undefined,
                        isOpenedDuringHolidays: (values?.preferences?.isOpenedDuringHolidays) ? (values?.preferences?.isOpenedDuringHolidays) : false,
                        activeTimes: times,
                        playbackVolume: (values?.preferences?.playbackVolume) ? (values?.preferences?.playbackVolume) : undefined,
                        inactiveTimes: setInTimes
                    }
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "zoneDetail.updateZone.successful", }), { variant: 'success' });
            refetchData();
        }).catch((err) => {
            console.log(err);
        });
    };

    //Remove device from store mutation
    const removeDevice = () => {
        deviceRemoveZone({
            variables: {
                id: deviceId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "storeDetail.removePlayer.successful" }), { variant: "success" });
            setRemovePlayerOpen(false)
            refetchData()
            refetchDevice()
        }).catch((err) => {
            console.log('[error]', err);
        });
    };

    //Delete store mutation
    const deleteZone = () => {
        zoneDelete({
            variables: {
                id: zoneId,
            },
        })
            .then((response) => {
                let storeId = pathOr('', ['zone', 'store', 'id'], zoneData)
                history.push(`/admin${generatePath(paths.stores.detail, { storeId: storeId })}`);
                enqueueSnackbar(
                    intl.formatMessage({
                        id: "zoneDetail.removeZone.successful",
                    }),
                    {
                        variant: "success",
                    }
                );
            })
            .catch((err) => {
                console.log(err)
            });
    };

    const getActions = () => {
        let action = []
        if (pathOr('', ['zone', 'device'], zoneData)) {
            if (deviceAssign)
                action.push({
                    title: intl.formatMessage({ id: "storeDetail.removePlayer.title" }),
                    onClick: () => setRemovePlayerOpen(true),
                })
            if (zoneDel)
                action.push({
                    title: intl.formatMessage({ id: "zoneDetail.removeZone.button", }),
                    onClick: () => setRemoveZoneOpen(true),
                    color: "danger"
                })
        } else {
            action.push({
                title: intl.formatMessage({ id: "zoneDetail.removeZone.button", }),
                onClick: () => setRemoveZoneOpen(true),
                color: "danger"
            })
        }
        return action
    }

    //Remove Player Buttons
    const renderRemovePlayerActions = () => {
        return (
            <>

                <Button
                    onClick={() => removeDevice()}
                    color="warning"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "storeDetail.device.remove" })}
                </Button>

                <Button
                    onClick={() => setRemovePlayerOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    //Remove Store Buttons
    const renderRemoveZoneActions = () => {
        return (
            <>
                <Button
                    onClick={() => deleteZone()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "companyDetail.btn.remove" })}
                </Button>
                <Button
                    onClick={() => setRemoveZoneOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };


    //Renders
    //Render Media Widgets
    const renderDeviceCards = (formikProps) => {
        return (
            <StatusCard
                deviceId={pathOr('', ['zone', 'device', 'id'], zoneData)}
                deviceBid={pathOr('', ['zone', 'device', 'bid'], zoneData)}
                zoneId={pathOr('', ['zone', 'id'], zoneData)}
                status={pathOr('', ['zone', 'device', 'status'], zoneData)}
                lastHeartbeat={pathOr('', ['zone', 'device', 'onlineAt'], zoneData)}
                player={pathOr('', ['zone', 'device', 'player', 'status'], zoneData)}
                refetchData={refetchData}
                refetchDevice={refetchDevice}
                data={formikProps}
                isAssigned={(pathOr('', ['zone', 'device'], zoneData))}
                storeUpdate={zoneUpdate}
                availableDevices={availableDevices}
                formikProps={formikProps}
                balenaId={pathOr('', ['zone', 'device', 'balenaUuid'], zoneData)}
                deviceType={pathOr('', ['zone', 'device', 'deviceType'], zoneData)}
                clientVersion={pathOr('', ['zone', 'device', 'clientVersion'], zoneData)}
            />
        );
    };

    if (zoneLoading) return <Loading />
    return (
        <>
            <Formik
                onSubmit={(values) => { handleUpdateZone(values) }}
                enableReinitialize
                initialValues={formikZoneData}
                validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <>
                        <PageHeader
                            title={pathOr('', ['zone', 'name'], zoneData)}
                            subTitle={getZoneBid()}
                            actions={getActions()}
                            handleBackAction={(e) => history.goBack()}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <BasicInfoCard
                                    formikProps={formikProps}
                                    zoneData={zoneData}
                                    storeUpdate={zoneUpdate}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <TimePlanCard
                                    times={times}
                                    callbackTimes={(newValue) => setTimes(newValue)}
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    zoneData={zoneData}
                                    inactiveTimes={inactiveTimes}
                                    callbackInactiveTimes={(newValue) => setInactiveTimes(newValue)}
                                    zoneId={zoneId}
                                    storeUpdate={zoneUpdate}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                {renderDeviceCards(formikProps)}
                                {(pathOr('', ['zone', 'device', 'id'], zoneData)) &&
                                    <DisplayCard
                                        companyId={pathOr('', ['zone', 'store', 'company', 'id'], zoneData)}
                                        formikProps={formikProps}
                                        zoneData={zoneData}
                                        storeUpdate={zoneUpdate}
                                        deviceType={pathOr('', ['zone', 'device', 'deviceType'], zoneData)}
                                    />
                                }
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                {(zoneUpdate) &&
                                    <div style={{ position: "fixed", bottom: "20px", right: "20px", }} >
                                        <Button
                                            color="primary"
                                            round
                                            onClick={(e) => {
                                                formikProps.handleSubmit();
                                            }}
                                        >
                                            {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                                        </Button>
                                        <Button round onClick={() => formikProps.resetForm()}>
                                            {intl.formatMessage({ id: 'settings.cancel' })}
                                        </Button>
                                    </div>
                                }
                            </Grid>

                        </Grid>
                        <Modal
                            title={intl.formatMessage({ id: "storeDetail.removePlayer.title" })}
                            open={removePlayerOpen}
                            onClose={() => setRemovePlayerOpen(false)}
                            actions={renderRemovePlayerActions()}
                        >
                            {`${intl.formatMessage({ id: "deviceDetail.status.removeModal.text-1" })} 
                            ${deviceId} ${intl.formatMessage({ id: "deviceDetail.status.removeModal.text-2" })}
                            ${pathOr("", "zone.name", zoneData)} ${pathOr("", "zone.bid", zoneData)}?`}
                        </Modal>
                        <Modal
                            title={intl.formatMessage({ id: "storeDetail.removeStore.title" })}
                            open={removeZoneOpen}
                            onClose={() => setRemoveZoneOpen(false)}
                            actions={renderRemoveZoneActions()}
                        >
                            {`${intl.formatMessage({ id: "storeDetail.removeStore.text", })} 
                            ${pathOr("", "zone.name", zoneData)} ${pathOr("", "zone.bid", zoneData)}?`}
                        </Modal>
                    </>
                )}
            </Formik>
        </>
    );
};

export default injectIntl(withRouter(StoreDetailPage));
