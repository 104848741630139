import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import styles from "common/styles/widgets.js";
import CustomInput from 'components/CustomInput/CustomInput';
import { Grid } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { injectIntl } from "react-intl";

const useStyles = makeStyles(styles);

const MultipleValuesField = (props) => {
    const { intl, labelText, type, callback, icon } = props;

    const classes = useStyles();

    const classic = (
        <FormControl
            onClick={() => callback(true)}
            style={{ padding: "5px" }}
            className={classes.multiUpdate}
            fullWidth
        >
            <CustomInput
                style={{ zIndex: -1 }}
                type="text"
                fullWidth
                inputProps={{
                    value: intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'}),
                    disabled: true,
                }}
                labelText={labelText}
                labelProps={{
                    shrink: true,
                }}
            />
        </FormControl>
    );

    const slider = (
        <FormControl
            onClick={() => callback(true)}
            style={{ padding: "5px", border: '1px solid', borderRadius: "5px", borderColor: '#fce4ec' }}
            className={classes.multiUpdate}
            fullWidth
        >
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    <h4>{labelText}</h4>
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                    {icon && <Icon style={{ color: 'gray', width: "40px" }} fontSize="medium">{icon}</Icon>}
                    <br />
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
        </FormControl>
    );

    const toggle = (
        <FormControl
            onClick={() => callback(true)}
            style={{ padding: "5px", border: '1px solid', borderRadius: "5px", borderColor: '#fce4ec' }}
            className={classes.multiUpdate}
            fullWidth
        >
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    <h4>{labelText}</h4>
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                    <Icon style={{ color: 'gray' }} fontSize="medium"><ToggleOnIcon /></Icon>
                    <br />
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
        </FormControl>
    );

    const time = (
        <FormControl
            onClick={() => callback(true)}
            style={{ padding: "5px", border: '1px solid', borderRadius: "5px", borderColor: '#fce4ec' }}
            className={classes.multiUpdate}
            fullWidth
        >
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    <h4>{labelText}</h4>
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                <h4><QueryBuilderIcon /></h4>
                </Grid>
            </Grid>
            <Grid item sm={12} style={{ paddingTop: "0.2em" }}>
                <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
            </Grid>

            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Monday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Tuesday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Wednesday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Thursday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Friday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Saturday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item sm={4} style={{ paddingTop: "0.2em" }}>
                    {intl.formatMessage({ id: `weekDay.Sunday` })}
                </Grid>
                <Grid item sm={8} style={{ textAlign: "center", marginLeft: "-60px" }}>
                        {intl.formatMessage({ id: 'storeList.multiUpdate.state.withoutChange'})}
                </Grid>
            </Grid>
          
        </FormControl>
    );

    const helper = (
        <span
            className={classes.multiUpdate}
            style={{ color: "blue" }}
        //onClick={() => { formikProps.setFieldValue("zones.0.preferences.streamPlaybackVolume", undefined); setStreamVolumeChange(false) }}
        >
            Undo change
        </span>
    );


    const getInputByType = () => {
        if (type === 'slider') {
            return slider;
        } else if (type === 'helper') {
            return helper;
        } else if (type === 'toggle') {
            return toggle;
        } else if (type === 'time') {
            return time;
        } else {
            return classic;
        }
    };

    return getInputByType();
};

export default injectIntl(MultipleValuesField);
