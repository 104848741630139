import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import FullText from './fullText/FullText';
import Selected from './selected/Selected';
import TreeView from './treeView/TreeView';
const useStyles = makeStyles(theme => ({
    body: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5)
    },
}));
;
const Body = props => {
    const classes = useStyles();
    const [fullTextIsActive, setFullTextIsActive] = useState(false);
    return React.createElement(Box, { className: classes.body },
        React.createElement(Selected, Object.assign({}, props)),
        React.createElement(FullText, Object.assign({}, props, { onIsActiveChanged: isActive => setFullTextIsActive(isActive) })),
        !fullTextIsActive && (React.createElement(TreeView, Object.assign({}, props))));
};
export default Body;
