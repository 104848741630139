import gql from 'graphql-tag';

const USER_CREATE = gql`
    mutation userCreate($input:UserCreateInput!) {
        userCreate(input:$input) {
            id
            firstName
            lastName
        }
    }
`;

export default USER_CREATE;