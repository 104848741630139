import React, { useEffect, useState } from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import IconCard from 'components-lib/IconCard/IconCard';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import paths from 'paths';
import { withRouter, useLocation } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import UserTable from './UserTable'
import ALL_USERS from 'queries/UsersQueries/allUsers';
import { userCreate } from 'configFiles/roles'


const UsersPage = (props) => {
    const { history, intl } = props;
    let location = useLocation();
    let defaultFilter = location.state ? location.state : null

    const [backButton, setBackButton] = useState(false)

    useEffect(() => {
        setBackButton(location.state ? true : false)
    }, [backButton, location.state]);

    const getUsersCreateActions = () => {
        if (userCreate)
            return [
                {
                    title: intl.formatMessage({ id: 'usersPage.actions.newAccount' }),
                    onClick: (e) => {history.push(`/admin${paths.users.new}`)}
                }
            ]
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'usersPage.title' })}
                actions={getUsersCreateActions()}
                withBackButton={location.state ? true : false}
            />
            <IconCard
                icon={<AssignmentIndIcon />}
                title={intl.formatMessage({ id: 'usersPage.table.title' })}>
                <UserTable
                    queryDataPath={['usersTable', 'items']}
                    query={ALL_USERS}
                    defaultFilter={defaultFilter}
                    history={history}
                />
            </IconCard>

        </>
    )
};

export default withRouter(injectIntl(UsersPage));