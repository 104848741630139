import gql from 'graphql-tag';

const MEDIA_DOWNLOAD = gql`
    mutation mediaDownload($id:ID!) {
      mediaDownload(id: $id) {
        type
        url
        expiresAt
      }
    }
`;

export default MEDIA_DOWNLOAD;