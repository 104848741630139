import React from 'react';
import { Grid } from '@material-ui/core';
import styles from "common/styles/widgets.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from '@material-ui/core/styles';
import Business from "@material-ui/icons/Business";
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => styles(theme))

const CompanyDataCard = (props) => {

    const {intl} = props;
    const classes = useStyles();

    return(
        <IconCard title={intl.formatMessage({ id: 'profile.companyData.title' })} icon={<Business />}>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        labelText={intl.formatMessage({ id: 'profile.companyData.name' })}
                        id="companyName"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.companyName === null? "" :props.companyName
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.companyData.street' })}
                        id="comapnyStreet"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.companyStreet === null? "" :props.companyStreet
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.companyData.city' })}
                        id="companyCity"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.companyCity === null? "" :props.companyCity
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.companyData.postal' })}
                        id="companyPostal"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.companyPostal === null? "" :props.companyPostal
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.companyData.country' })}
                        id="companyCountry"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.companyCountry === null? "" :props.companyCountry
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
            </Grid>
        </IconCard>

    )
}

export default injectIntl(CompanyDataCard);