import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { pathOr } from "rambda";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PageHeader from "components-lib/PageHeader/PageHeader";
import CompaniesAndStoresCard from "./DashboardCards/CompaniesAndStoresCard"
import DevicesCard from "./DashboardCards/DevicesCard"
import PartnersCard from "./DashboardCards/PartnersCard"
import MediasCard from "./DashboardCards/MediasCard"
import PlaylistCard from "./DashboardCards/PlaylistCard"
import DisplayGroupCard from "./DashboardCards/DisplayGroupCard"
import { OVERVIEW } from "queries/DashboardQueries/overview"
import { dashboardList, deviceList, companyList, storeList, userList, playlistList, displayGroupList, mediaList, } from 'configFiles/roles'
import Paper from '@material-ui/core/Paper';

const DashboardPage = (props) => {
    const { intl, history } = props;
    const { data: overwiev } = useQuery(OVERVIEW, { skip: !dashboardList });

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "dashboardPage.title" })}
                withBackButton={false}
            />
            <Grid container spacing={2} >
                {deviceList &&
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "-40px" }}>
                        <DevicesCard
                            allDevices={pathOr(null, ['overview', 'devices', 'totalCount'], overwiev)}
                            devicesOnStores={pathOr(null, ['overview', 'devices', 'assignedCount'], overwiev)}
                            devicesOnRepository={pathOr(null, ['overview', 'devices', 'unassignedCount'], overwiev)}
                            offlineDevices={pathOr(null, ['overview', 'devices', 'offlineCount'], overwiev)}
                            history={history}
                        />
                    </Grid>
                }
                {(companyList || storeList) &&
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "-40px" }}>
                        <CompaniesAndStoresCard
                            allCompanies={pathOr(null, ['overview', 'companies', 'totalCount'], overwiev)}
                            allStores={pathOr(null, ['overview', 'stores', 'totalCount'], overwiev)}
                            storesWithoutDevice={pathOr(null, ['overview', 'stores', 'noPlayerCount'], overwiev)}
                            history={history}
                        />
                    </Grid>
                }
                {(userList) &&
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "-40px" }}>
                        <PartnersCard
                            allUsers={pathOr(null, ['overview', 'users', 'totalCount'], overwiev)}
                            activatedUsers={pathOr(null, ['overview', 'users', 'activatedCount'], overwiev)}
                            unactivatedUsers={pathOr(null, ['overview', 'users', 'unactivatedCount'], overwiev)}
                            blockedUsers={pathOr(null, ['overview', 'users', 'blockedCount'], overwiev)}
                            history={history}
                        />
                    </Grid>
                }
                {/*playlistList &&
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "-40px" }}>
                        <PlaylistCard
                            allMedia={pathOr(null, ['overview', 'media', 'totalCount'], overwiev)}
                            awaitingMedia={pathOr(null, ['overview', 'media', 'awaitingApprovalCount'], overwiev)}
                            history={history}
                        />
                    </Grid>
                */}
                {/*displayGroupList &&
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "-40px" }}>
                        <DisplayGroupCard
                            allMedia={pathOr(null, ['overview', 'media', 'totalCount'], overwiev)}
                            awaitingMedia={pathOr(null, ['overview', 'media', 'awaitingApprovalCount'], overwiev)}
                            history={history}
                        />
                    </Grid>
                */}
                {mediaList &&
                    <Grid item xs={12} sm={12} md={6} lg={4} style={{ marginBottom: "-40px" }}>
                        <MediasCard
                            allMedia={pathOr(null, ['overview', 'media', 'totalCount'], overwiev)}
                            awaitingMedia={pathOr(null, ['overview', 'media', 'awaitingApprovalCount'], overwiev)}
                            history={history}
                        />
                    </Grid>
                }
            </Grid>
        </>
    );
};

export default withRouter(injectIntl(DashboardPage));