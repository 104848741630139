import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LocationInput from "./tree/Tree";
import SegmentInput from "./tree/Tree";
import CompanyInput from "./tree/Tree";
import ZoneInput from "./tree/Tree";
import useLocation from "../../hook/form/useLocation";
import useSegment from "../../hook/form/useSegment";
import useCompany from "../../hook/form/useCompany";
import useStore from "../../hook/form/useStore";
import Lemmatizer from "../../service/lemmatize/Lemmatizer";
import getLocationName from "../../service/location/getName";
import TopCheckedRetriever from "../../service/tree/util/TopCheckedRetriever";
import TreeCheckedSetter from "../../service/tree/util/TreeCheckedSetter";
const Form = ({ axios, campaign, onChange, ...rest }) => {
    const value = {
        locationIds: rest?.value?.locationIds || [],
        segmentIds: rest?.value?.segmentIds || [],
        companyIds: rest?.value?.companyIds || [],
        storeIds: rest?.value?.storeIds || [],
    };
    const [locationTree, checkedLocationIds] = useLocation(axios, campaign, value);
    const [segmentTree, checkedSegmentIds] = useSegment(axios, campaign, value);
    const [companyTree, checkedCompanyIds] = useCompany(axios, campaign, value);
    const [storeTree, checkedStoreIds] = useStore(axios, campaign, value);
    const locationTreeData = locationTree?.toObject() || null;
    const segmentTreeData = segmentTree?.toObject() || null;
    const companyTreeData = companyTree?.toObject() || null;
    const storeTreeData = storeTree?.toObject() || null;
    const locationLemmatizer = new Lemmatizer('lokalít', 'lokalita', 'lokality', 'lokalít');
    const segmentLemmatizer = new Lemmatizer('segmentov', 'segment', 'segmenty', 'segmentov');
    const companyLemmatizer = new Lemmatizer('spoločností', 'spoločnosť', 'spoločnosti', 'spoločností');
    const storeLemmatizer = new Lemmatizer('prevádzok', 'prevádzka', 'prevádzky', 'prevádzok');
    const uniqueIds = (ids) => ids.filter((id, index) => ids.indexOf(id) === index);
    const preparePlaceholder = (lemmatizer, ids) => {
        return ids.length === 1 && ids[0] === '*' ? 'Všetko' : lemmatizer.get(ids.length);
    };
    const onLocationItemChange = (id, isChecked) => {
        onChange({
            ...value,
            locationIds: setItemChecked(locationTree, id, isChecked)
        });
    };
    const onSegmentItemChange = (id, isChecked) => {
        onChange({
            ...value,
            segmentIds: setItemChecked(segmentTree, id, isChecked)
        });
    };
    const onCompanyItemChange = (id, isChecked) => {
        onChange({
            ...value,
            companyIds: setItemChecked(companyTree, id, isChecked)
        });
    };
    const onStorItemChange = (id, isChecked) => {
        onChange({
            ...value,
            storeIds: setItemChecked(storeTree, id, isChecked)
        });
    };
    const setItemChecked = (tree, id, isChecked) => {
        if (tree === null)
            return [];
        TreeCheckedSetter.set(tree, [id], isChecked);
        return uniqueIds(TopCheckedRetriever.get(tree).map(node => node.getId()));
    };
    return (React.createElement(Grid, { container: true, spacing: 3 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h2" }, "Prev\u00E1dzky")),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(LocationInput, { label: "Lokalita", placeholder: preparePlaceholder(locationLemmatizer, checkedLocationIds), tree: locationTreeData, nodeNamePreparer: getLocationName, selectedIds: value.locationIds, onChange: onLocationItemChange })),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(SegmentInput, { label: "Segment", placeholder: preparePlaceholder(segmentLemmatizer, checkedSegmentIds), tree: segmentTreeData, selectedIds: value.segmentIds, onChange: onSegmentItemChange })),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(CompanyInput, { label: "Spolo\u010Dnos\u0165", placeholder: preparePlaceholder(companyLemmatizer, checkedCompanyIds), tree: companyTreeData, selectedIds: value.companyIds, onChange: onCompanyItemChange })),
        React.createElement(Grid, { item: true, xs: 12, md: 12, lg: 12 },
            React.createElement(ZoneInput, { label: "Prev\u00E1dzka", placeholder: preparePlaceholder(storeLemmatizer, checkedStoreIds), tree: storeTreeData, selectedIds: value.storeIds, onChange: onStorItemChange }))));
};
export default Form;
