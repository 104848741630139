import gql from 'graphql-tag';

const SETTINGS_UPDATE = gql`
mutation settingsUpdate($input: SettingsUpdateInput!){
    settingsUpdate(input: $input){
        creditValue,
        defaultSpotPlaybackPrice,
        commissionRate 
    }
}
`;

export default SETTINGS_UPDATE;