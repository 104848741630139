import gql from 'graphql-tag';

const PLAYLIST_CREATE = gql`
    mutation playlistCreate($input: PlaylistCreateInput!){
        playlistCreate(input: $input){
            name
            id
        }
    }
`;

export default PLAYLIST_CREATE;

