import gql from 'graphql-tag';

const ZONE_DETAIL = gql`
query zone($id: ID!){
  zone(id:$id){
      id,
      bid,
      store{
        id
        company{
          id
        }
      }
      name,
    	area,
    	visitors,
    	rank,
    	createdAt,
    	updatedAt,
    	deletedAt,
      device {
          id
          bid
          isOnline
          onlineAt
          clientVersion
          deviceType
          balenaUuid
          status
          player {
            status
          }
      },
      preferences {
      	activeTimes {
        	weekDay
          from
          to
        }
        inactiveTimes {
          from
          to
        }
        isOpenedDuringHolidays
        playbackVolume
        timezone
        activeTimesInherit
        inactiveTimesInherit
        screenAGroup{
          id
          bid
          name
        }
        screenBGroup{
          id
          bid
          name
        }
        segment {
          id
          name
        } 
      }
    }
  }
`;

export default ZONE_DETAIL;