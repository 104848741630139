import React from 'react';
import { Grid } from '@material-ui/core';
import styles from "common/styles/widgets.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from '@material-ui/core/styles';
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => styles(theme))

const CompanyInfoCard = (props) => {

    const {intl} = props;
    const classes = useStyles();

    return(
        <IconCard title="Kontaktné informácie" icon={<PermContactCalendar />}>
            <Grid container>
                <Grid item xs={6} sm={6}>
                    <CustomInput
                        labelText={intl.formatMessage({ id: 'profile.contactInfo.firstName' })}
                        id="firstName"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.firstName === null? "" :props.firstName
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={6} sm={6} spacing={5}>
                    <CustomInput
                        labelText={intl.formatMessage({ id: 'profile.contactInfo.lastName' })}
                        id="lastName"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.lastName === null? "" :props.lastName
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.contactInfo.gender' })}
                        id="gender"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.gender === null? "" :props.gender
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.contactInfo.email' })}
                        id="userEmail"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.email === null? "" :props.email
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.contactData.phone' })}
                        id="userPhone"
                        inputProps={{
                            type: "text",
                            disabled: true,
                            value: props.phone === null? "" :props.phone
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
            </Grid>
        </IconCard>

    )
}

export default injectIntl(CompanyInfoCard);