import React from 'react';
import IconCard from 'components-lib/IconCard/IconCard';
import Grid from "@material-ui/core/Grid";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { injectIntl } from 'react-intl';
import FormikField from "components-lib/FormikField/FormikField";
import { isEmpty } from "rambda";
import Button from "components/CustomButtons/Button.js";
import USER_UPDATE from "queries/UsersQueries/userUpdate";
import { useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";

const ContactData = (props) => {
    const { intl, formikProps, created, canEditUser, disabled = false, id, refetchUserData } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [updateUser] = useMutation(USER_UPDATE);

    const handleUserUpdate = (values) => {
        updateUser({
            variables: {
                id: id,
                input: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    gender: values.gender,
                    //email: values.email,
                    phone: values.phone,
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.userUpdate.success' }), { variant: 'success' });
            refetchUserData();
        }).catch((err) => {
            console.log('[error]', err);
        })
    };

    const renderContent = () => {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <FormikField
                            name="firstName"
                            labelText={intl.formatMessage({ id: 'createUser.contact.firstName' })}
                            formikProps={formikProps}
                        //disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikField
                            name="lastName"
                            labelText={intl.formatMessage({ id: 'createUser.contact.lastName' })}
                            formikProps={formikProps}
                        //disabled={disabled}
                        />
                    </Grid>
                </Grid>
                <FormikField
                    disabled={disabled}
                    name="email"
                    labelText={intl.formatMessage({ id: 'createUser.contact.email' })}
                    formikProps={formikProps}
                />
                <FormikField
                    // disabled={disabled}
                    name="phone"
                    labelText={intl.formatMessage({ id: 'createUser.contact.phone' })}
                    formikProps={formikProps}
                />
                {!created &&
                    <Grid item sm={12}>
                        <br />
                        <Button
                            color="primary"
                            size="sm"
                            round
                            onClick={(e) =>
                                isEmpty(formikProps.errors) &&
                                handleUserUpdate(formikProps.values)
                            }
                        >
                            {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                        </Button>
                        <Button
                            color="info"
                            size="sm"
                            round
                            onClick={() => formikProps.resetForm()}
                        >
                            {intl.formatMessage({ id: 'settings.cancel' })}
                        </Button>
                    </Grid>
                }
            </>
        )
    }

    return (
        <IconCard
            title={(canEditUser)
                ? intl.formatMessage({ id: 'createUser.contact.title' })
                : intl.formatMessage({ id: 'userDetail.contactInfo.create' })
            }
            icon={<AssignmentIndIcon />}
        >
            {renderContent()}
        </IconCard>
    )

};

export default injectIntl(ContactData);