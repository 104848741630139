import React from 'react';
import { getAxiosClient } from "configFiles/axios";
import CampaignNewPage from './CampaignNewPage';

const CampaignNewAxiosWrapperPage = (props) => {
    const { history } = props
    return (
        <CampaignNewPage axios={getAxiosClient()} history={history} />
    );
}

export default CampaignNewAxiosWrapperPage;