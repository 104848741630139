import React from 'react';
import { Grid } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { Formik } from 'formik';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import { validationSchemaStep1 } from "./validationSchema";
import { injectIntl } from 'react-intl';
import ALL_COMPANIES from "queries/CompaniesQueries/selectCompanies";
// import { ALL_TOPICS } from "queries/CampaignsQueries/allTopics"
import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";

const Step1 = ({ intl, callback, callbackValid, initialData }) => {
  const { data: companiesData } = useQuery(ALL_COMPANIES);
  const [companyId, setCompanyId] = useState(null);

  const getCompaniesOptions = () => {
    const comapniesOptions = [];
    pathOr([], ['companies', 'items'], companiesData).map(company => {
      return (comapniesOptions.push({
        value: pathOr("", "id", company),
        label: `${pathOr("", "name", company)}`,
      }))
    })
    return comapniesOptions
  }

  // const { data: companyData } = useQuery(COMPANY_DETAIL, { variables: { id: companyId }, skip: !companyId });

  const { data: mediasData } = useQuery(MEDIAS_BY_COMPANY,
    {
      variables: {
        companyId: companyId,
        filter: [
          {
            acceptanceStatus: {
              eq: "Approved"
            }
          }
        ]
      },
      skip: !companyId
    }
  );

  let media = pathOr('', ['mediasByCompany', 'totalCount'], mediasData)

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialData}
        validationSchema={validationSchemaStep1(intl)}
      >
        {formikProps => (
          <Grid container>
            {
              callbackValid(formikProps.isValid && formikProps.values.name &&
                formikProps.values.companyId && media > 0)
            }

            {callback(formikProps.values)}
            {setCompanyId(formikProps.values?.companyId)}
            <Grid item sm={12}>
              <FormikField
                name="name"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.name' })}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
              />
            </Grid>
            <Grid item sm={12}>
              <FormikField
                type="select"
                name="companyId"
                labelText={intl.formatMessage({ id: 'campaigns.infoCard.company' })}
                options={getCompaniesOptions()}
                formikProps={formikProps}
                data={formikProps.values}
                highlightChange
              />
            </Grid>
            <Grid item sm={12}>
              <br />
              <FormikField
                name="description"
                type="multiline"
                labelText={intl.formatMessage({ id: "invoicesList.assignModal.description" })}
                helperText={170 - pathOr('', ['values', 'description'], formikProps).length}
                formikProps={formikProps}
                inputProps={{ rows: 2 }}
                highlightChange
              />
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(Step1);