import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import BusinessIcon from '@material-ui/icons/Business';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgetStyle.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import WarningIcon from '@material-ui/icons/Warning';
import { companyList, storeList } from 'configFiles/roles'
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => styles(theme));

const CompaniesAndStoresCard = (props) => {
    const {
        history,
        intl,
        allCompanies,
        allStores,
        storesWithoutDevice
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        return (
            <Box>
                {(storeList) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.stores.list)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.stores.button" })}
                    </Button>
                }
                {(companyList) &&
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.companies.list)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.companies.button" })}
                    </Button>
                }
            </Box>

        )
    }

    const renderStoresWithoutDevice = () => {
        return <>
            {intl.formatMessage({ id: "dashboardCard.stores.storesWithoutDevice" })}
            <Box className={classes.floatRight}>
                {storesWithoutDevice}
            </Box>
        </>
    }

    return <>
        <IconCard
            title={(companyList) ? intl.formatMessage({ id: "dashboardCard.companies.title" }) : intl.formatMessage({ id: "storeList.title" })}
            icon={<BusinessIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={2}>
                {companyList &&
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "dashboardCard.companies.allCompanies" })}
                        <Box className={classes.floatRight}>
                            {allCompanies}
                        </Box>
                    </Grid>
                }
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.stores.allStores" })}
                    <Box className={classes.floatRight}>
                        {allStores}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {renderStoresWithoutDevice()}
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(CompaniesAndStoresCard);
