import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
    cardIconTitle: {
      ...cardTitle,
      marginTop: "15px",
      marginBottom: "0px"
    }
  };

const useStyles = makeStyles(styles);

const CardTitle = (props) => {

    const classes = useStyles();
    const { title, style = {} } = props;

    return (
        <h4 className={classes.cardIconTitle} style={style}>{title}</h4>
    );
};

export default CardTitle;