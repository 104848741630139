import PageHeader from "components-lib/PageHeader/PageHeader";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/react-hooks';
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import IconCard from "components-lib/IconCard/IconCard";
import Button from "components/CustomButtons/Button";
import styles from "common/styles/widgets.js";
import Modal from "components-lib/Modal/Modal";
import FormikField from 'components-lib/FormikField/FormikField';
import { Formik } from 'formik';
import ALL_GROUPS from 'queries/GroupsQueries/allGroups';
import CREATE_GROUP from 'queries/GroupsQueries/createGroup';
import DELETE_GROUP from 'queries/GroupsQueries/deleteGroup';
import { pathOr } from 'rambda';
import { useSnackbar } from "notistack";
import PermissionsCard from "./GroupCards/PermissionsCard"
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { colors } from "common/styles/configLayout.js";
import Box from "@material-ui/core/Box";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import validationSchema from './validationSchema';
import {
    groupList, groupDetail, groupDelete, groupUpdate, groupCreate
} from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: colors.main,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const GroupPage = (props) => {
    const { intl, } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [groupId, setGroupId] = useState(null);
    const [members, setMembers] = useState(0);
    const [editModeOpen, setEditModeOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [permissionRefetch, setPermissionRefetch] = useState(0);



    const { data, refetch: refetchGroups } = useQuery(ALL_GROUPS);
    const [createGroup] = useMutation(CREATE_GROUP);
    const [deleteGroup] = useMutation(DELETE_GROUP);
    const groupsData = pathOr([], ['userGroupsTable', 'items'], data)

    if (permissionRefetch === 1) {
        refetchGroups()
        setPermissionRefetch(0)
    }

    useEffect(() => {
        let groupsSelectItems = pathOr([], "userGroupsTable.items", data).filter(res => res.id !== "1").map(
            (group) => {
                return {
                    value: pathOr("", "id", group),
                    label: `${pathOr("", "name", group)}`,
                };
            }
        );
        setGroups(groupsSelectItems);
    }, [data]);


    const handleCreateGroup = (values) => {
        createGroup({
            variables: {
                input: values,
            },
        })
            .then((response) => {
                enqueueSnackbar(intl.formatMessage({ id: "groups.success.create" }), { variant: "success" });
                refetchGroups();
                (editModeOpen === false) && setEditModeOpen(true)
            })
            .catch((err) => {
                console.log('[error]', err)
            });
        setModalOpen(false)
    }

    const handleDeleteGroup = () => {
        deleteGroup({
            variables: {
                id: groupId,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "groups.success.delete" }), { variant: "success" });
            refetchGroups();
            (editModeOpen === true) && setEditModeOpen(false)
        })
            .catch((err) => {
                console.log(err)
            });
        setDeleteModalOpen(false)

    };

    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    onClick={() => handleCreateGroup(formikProps.values)}
                    color="success"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "groups.button.create" })}
                </Button>
                <Button
                    onClick={() => setModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderDeleteModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    onClick={() => handleDeleteGroup()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "groups.button.delete" })}
                </Button>
                <Button
                    onClick={() => setDeleteModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "groups.title" })}
                withBackButton={false}
            />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4} >
                    <IconCard
                        title={intl.formatMessage({ id: "groups.groups.title" })}
                        icon={<GroupAddIcon />}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} >
                                <Table stripedRows className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell ><b>{intl.formatMessage({ id: "groups.table.name" })}</b></TableCell>
                                            <TableCell ><b>{intl.formatMessage({ id: "groups.table.accounts" })}</b></TableCell>
                                            {(groupDelete) && <TableCell ><b>{intl.formatMessage({ id: "groups.table.action" })}</b></TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {groupsData?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {index + 1}
                                                </StyledTableCell>
                                                <StyledTableCell >{row.name}</StyledTableCell>
                                                <StyledTableCell >{row.members}</StyledTableCell>
                                                {(groupDelete) &&
                                                    <StyledTableCell >
                                                        {(row.name !== "Administrators") &&
                                                            <DeleteRoundedIcon
                                                                className={classes.pointer}
                                                                onClick={() => {
                                                                    setMembers(row.members)
                                                                    setGroupId(row.id);
                                                                    setDeleteModalOpen(true)
                                                                }}
                                                            />
                                                        }
                                                    </StyledTableCell>
                                                }

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                        < br />
                        {(groupCreate) &&
                            <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <Box className={classes.floatRight}>
                                        <Button
                                            round
                                            size="sm"
                                            color="primary"
                                            onClick={() => setModalOpen(true)}
                                        >
                                            {intl.formatMessage({ id: "groups.button.addGroup" })}
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        }


                    </IconCard>
                </Grid>
                <Grid item xs={12} sm={12} md={8} >
                    <PermissionsCard
                        groups={groups}
                        callback={newValue => setPermissionRefetch(newValue)}
                        groupUpdate={groupUpdate}
                    />
                </Grid>
            </Grid>
            <Formik
                initialValues={{
                    permissions: [],
                }}
                //onSubmit={(values, { resetForm }) => {goToNotificationConfirmation(values, resetForm);}}
                validationSchema={validationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: 'groups.createModal.title' })}
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                        actions={renderModalButtons(formikProps)}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormikField
                                    name="name"
                                    formikProps={formikProps}
                                    labelText={intl.formatMessage({ id: 'groups.form.name' })}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikField
                                    type="multiline"
                                    name="description"
                                    formikProps={formikProps}
                                    labelText={intl.formatMessage({ id: 'groups.form.description' })}
                                    helperText={100 - pathOr('', ['values', 'description'], formikProps).length}
                                    inputProps={{
                                        rows: 2
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                )}
            </Formik>
            <Modal
                title={intl.formatMessage({ id: 'groups.deleteModal.title' })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={renderDeleteModalButtons()}
            >
                {(members > 0) &&
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'groups.deleteModal.body' })}: <b>{members}</b>
                        </Grid>
                        <Grid item sm={12}>
                            {intl.formatMessage({ id: 'groups.deleteModal.body2' })}
                        </Grid>
                    </Grid>
                }
            </Modal>
        </>


    );
};

export default withRouter(injectIntl(GroupPage));