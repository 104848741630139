
import gql from 'graphql-tag';

const ZONES_SELECT = gql`
query zones($offset: Int, $limit: Int, $sort: [ZoneSortInput!], $filter: [ZoneFilterInput]){
  zones(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id,
      bid,
      name,
       store{
        id
        bid
        name
      }   
    }
  }
}
`;


export default ZONES_SELECT;

