import * as React from 'react';

const CreditIcon = ({ raw = false }) => {
    return (
        <svg 
            id="Layer_1" 
            data-name="Layer 1" 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="0 0 100 100"
            className={`MuiSvgIcon-root`}
            style={ !raw ? {
                top: '3px',
                color: 'inherit',
                float: 'left',
                width: '30px',
                height: '24px',
                opacity: '0.8',
                position: 'inherit',
                textAlign: 'center',
                marginRight: '15px',
                verticalAlign: 'middle',
            } : {}}
        >
            <path className="cls-1" d="M50,0a50,50,0,1,0,50,50A50,50,0,0,0,50,0Zm1.22,14A36.05,36.05,0,0,1,80.07,30.17H41.82ZM47.9,14,28.77,47l-9.32-16A36,36,0,0,1,47.9,14ZM14,50a35.62,35.62,0,0,1,3.7-15.93L36.61,66.64H18.05A36.09,36.09,0,0,1,14,50ZM48.43,86a36.08,36.08,0,0,1-28.52-16.2H57.84Zm3.48,0L71,53.05l9.41,16.2A36,36,0,0,1,51.91,86ZM82.18,66.21,63,33.22H81.87a36.15,36.15,0,0,1,.31,33Z"/>
        </svg>
    );
}

export default CreditIcon;