import { useEffect, useState } from 'react';
import LocationApi from '../../service/api/Location';
import useTree from './useTree';
const useLocation = (axios, campaign, value) => {
    const [treeData, setTreeData] = useState(null);
    const [tree, checkedIds] = useTree(treeData, value.locationIds);
    useEffect(() => {
        (async () => {
            try {
                const locationApi = new LocationApi(axios, campaign);
                setTreeData((await locationApi.get({})).toObject());
            }
            catch (exception) {
                setTreeData(null);
                console.warn('Failed to retrieve tree data for filter. Exception: ' + exception);
            }
        })();
    }, [axios, campaign]);
    return [tree, checkedIds];
};
export default useLocation;
