import * as Yup from 'yup';

const getValidationSchema = (intl) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .nullable(),
        password: Yup.string()
            .nullable()
    });

export default getValidationSchema;