import gql from 'graphql-tag';

const MEDIAS_BY_COMPANY = gql`
query mediasByCompany(
  $companyId: ID!,
  $offset: Int,
  $limit: Int,
  $sort: [MediaSortInput!],
  $filter: [MediaFilterInput]){
    mediasByCompany(
      companyId: $companyId
      offset:$offset, 
      limit: $limit, 
      sort: $sort, 
      filter: $filter){
        totalCount,
        items{
          id
          bid
          name
          category    
        }
    }
  }
`;

export default MEDIAS_BY_COMPANY;