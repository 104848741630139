import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { Button as MaterialButton } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from "components/CustomButtons/Button.js";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import { defaultColors } from "common/styles/configLayout";
import { playlistUpd, playlistDel } from 'configFiles/roles';
import moment from 'moment';
import { pathOr } from 'rambda';
import MEDIA_DOWNLOAD from "queries/MediaQueries/mediaDownload";
import MEDIAS from "queries/MediaQueries/medias";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Modal from "components-lib/Modal/Modal";


const useStyles = makeStyles((theme) => styles(theme));

const MediaCard = (props) => {
    const {
        intl,
        medias,
        company,
        onMediasUpdate
    } = props;

    const [mediaDownload] = useMutation(MEDIA_DOWNLOAD);

    const [mediaModalOpen, setMediaModalOpen] = React.useState(false);
    const [removeModalOpen, setRemoveModalOpen] = React.useState(false);

    const [mediaUpdated, setMediaUpdated] = React.useState(false);
    const [refetch, setRefetch] = React.useState(1);
    const [click, setClick] = React.useState(-1);
    const [monitorA, setMonitorA] = React.useState([]);
    const [monitorB, setMonitorB] = React.useState([]);
    const [clickMonitorA, setClickMonitorA] = React.useState(-1);
    const [clickMonitorB, setClickMonitorB] = React.useState(-1);

    const classes = useStyles();

    React.useEffect(() => {
        setMonitorB(medias);
    }, []);

    React.useEffect(() => {
        if(mediaUpdated){
            setMediaUpdated(false);
            onMediasUpdate(monitorB);
        }
    }, [mediaUpdated]);

    // const handleDeletePlaylist = () => {
    //     playlistDelete({
    //         variables: {
    //             id: playlistId,
    //         },
    //     })
    //         .then((response) => {
    //             history.push(`/admin${generatePath(paths.playlists.list)}`)
    //             enqueueSnackbar(intl.formatMessage({ id: "playlist.deletePlaylist.successful", }), { variant: "success", });
    //         }).catch((err) => {
    //             console.log(err)
    //         });
    //     setRemoveModalOpen(false)
    // };

    const handleDownloadMedia = (id) => {
        mediaDownload({
            variables: {
                id: Number(id)
            }
        }).then(response => {
            let url = ((pathOr('', ['data', 'mediaDownload', 'url'], response)));
            window.open(url);
        }
        )
    }

    const handleDelete = (item, monitor) => {
        let helpMonitor = (monitor === "A") ? monitorA : monitorB;
        const items = helpMonitor.filter(it => it.id !== item.id);
        (monitor === "A") ? setMonitorA(items) : setMonitorB(items);
        (monitor === "A") ? setClickMonitorA(items) : setClickMonitorB(items);

        setMediaUpdated(true);
    };

    const handleReorder = (indexA, indexB, monitor, course) => {
        let help = (monitor === "A") ? monitorA : monitorB;
        let notEquals = (course === "U") ? 0 : help.length - 1;
        if (indexA !== notEquals) {
            let tmp = help[indexA];
            help[indexA] = help[indexB];
            help[indexB] = tmp;
            (monitor === "A") ? setMonitorA(help) : setMonitorB(help);
            (monitor === "A") ? setClickMonitorA(indexB) : setClickMonitorB(indexB)
        }
        if (refetch === 1) setRefetch(0)
        else setRefetch(1)

        setMediaUpdated(true);
    }

    const renderMediaModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => setMediaModalOpen(false)}
                    color="primary"
                    round
                    size="sm">
                    Zatvoriť okno
                </Button>

            </>
        );
    };

    const getLibraryContent = (item, index) => {
        let rightArrow = 0;
        let leftArrow = 0;
        monitorB.map((u, i) => {
            if (item.id === u.id)
                rightArrow = 1
            return "";
        })

        return <Grid container spacing={2} key={index}>
            {index === click ? <>
                <Grid item sm={(rightArrow === 1) ? 12 : 10}>
                    <Button
                        round
                        color="success"
                        fullWidth
                        size="sm"
                        onClick={(e) => { setClick(-1); setClickMonitorA(-1); setClickMonitorB(-1) }}
                    >
                        <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{item.bid} | {item.name}</span>
                    </Button>

                </Grid>
                {rightArrow === 0 ?
                    <Grid item sm={2}>
                        <Button round fullWidth size="sm" onClick={() => handleAddOnMonitor(item, "B")} color="success"><ArrowForwardIcon /></Button>
                    </Grid>
                    : ""
                }
            </>
                :
                <>
                    <Grid item sm={12}>
                        <Card>
                            <Grid item sm={12}>
                                <Grid container justify="center" alignItems="center" style={{ paddingRight: "5px" }} >
                                    <Grid item sm={4} style={{ display: "inline-block", position: "relative" }} className={classes.hoverImg}
                                        onClick={() => handleDownloadMedia(item.id)}>
                                        <img
                                            src={item?.previews?.[0]?.url}
                                            alt={item?.previews?.[0]?.url}
                                            width="100%"
                                        //height=""
                                        />
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "30%",
                                                left: "35%",
                                                transform: "translate(-50 %, -50 %)",
                                                width: "96px",
                                                height: "96px"

                                            }}
                                        >
                                            <PlayCircleOutlineIcon
                                                style={{
                                                    fontSize: 50,
                                                    color: "white",


                                                }} />
                                        </span>
                                    </Grid>
                                    <Grid item sm={5} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                        <CardContent style={{ paddingTop: "7px", paddingBottom: 0 }}>
                                            <Typography component="h5" variant="h5" style={{ fontSize: "1.3em" }}>
                                                {item.name}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                ID: {item.bid}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                Trvanie: {moment.utc(item.duration * 1000).format('mm:ss')}
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box className={classes.floatRight}>

                                            <Button
                                                style={{ marginTop: "10px", marginRight: "10px" }}
                                                size="sm"
                                                round
                                                disabled={(rightArrow === 1) ? true : false}
                                                color={"success"}
                                                onClick={() => handleAddOnMonitor(item, "B")}
                                            >
                                                Pridať
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </>

            }
        </Grid >
    }

    const handleAddOnMonitor = (item, monitor) => {

        let helpMonitor = monitorB;
        let isInArray = 0
        for (var i = 0; i < helpMonitor.length; i++) {
            if (helpMonitor[i].id === item.id) {
                isInArray = 1;
            }
        }
        if (isInArray === 0) {
            setMonitorB([...helpMonitor, item]);
        }

        setMediaUpdated(true);
    };

    const { data: mediasData } = useQuery(MEDIAS,
        {
            variables: {
                filter: [
                    {
                        status: {
                            eq: "Processed"
                        },
                        acceptanceStatus: {
                            eq: "Approved"
                        },
                        companyId: {
                            eq: Number(company.id)
                        }
                    }
                ]
            }, skip: !(company.id)
        }
    )

    let data = pathOr([], 'medias.items', mediasData)

    const getMonitorContent = (item, index, monitor) => {
        return <Grid container spacing={1} key={index}>
            <Grid item sm={12}>
                <Card style={{ margin: 0 }}>
                    <Grid item sm={12}>
                        <Grid container justify="center" alignItems="center" style={{ paddingRight: "5px" }} >
                            <Grid item sm={4} style={{ display: "inline-block", position: "relative" }} className={classes.hoverImg}
                                onClick={() => handleDownloadMedia(item.id)}>
                                <img
                                    src={item?.previews?.[0]?.url}
                                    alt={item?.previews?.[0]?.url}
                                    width="100%"
                                />
                                <span
                                    style={{
                                        position: "absolute",
                                        top: "30%",
                                        left: "35%",
                                        transform: "translate(-50 %, -50 %)",
                                        width: "96px",
                                        height: "96px"

                                    }}
                                >
                                    <PlayCircleOutlineIcon
                                        style={{
                                            fontSize: 50,
                                            color: "white",


                                        }} />
                                </span>
                            </Grid>
                            <Grid item sm={6} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Typography component="h5" variant="h5" style={{ fontSize: "1.3em" }}>
                                        {item.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        ID: {item.bid}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Trvanie: {moment.utc(item.duration * 1000).format('mm:ss')}
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid item sm={2} >
                                <Box className={classes.floatRight}>
                                    {playlistUpd &&
                                        <ButtonGroup
                                            orientation="vertical"
                                            aria-label="vertical contained primary button group"
                                            variant="contained"
                                            size="small"
                                        >
                                            <MaterialButton style={{ backgroundColor: (index !== 0) ? defaultColors.five : '', color: (index !== 0) ? 'white' : '', }} disabled={index === 0} onClick={() => handleReorder(index, index - 1, monitor, "U")}><ArrowUpwardIcon /></MaterialButton>
                                            <MaterialButton style={{ backgroundColor: (index !== monitorB?.length - 1) ? defaultColors.five : '', color: (index !== monitorB?.length - 1) ? 'white' : '', }} disabled={index === monitorB?.length - 1} onClick={() => handleReorder(index, index + 1, monitor, "D")}><ArrowDownwardIcon /></MaterialButton>
                                            <MaterialButton style={{ backgroundColor: defaultColors.five, color: 'white' }} onClick={() => handleDelete(item, monitor)}><DeleteIcon /></MaterialButton>
                                        </ButtonGroup>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    }

    const renderRemoveModalActions = () => {
        return (
            <>
                {/* <Button
                    onClick={() => handleDeletePlaylist()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "playlist.modal.removeButton" })}
                </Button> */}
                <Button
                    onClick={() => setRemoveModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.mediaCard.title' })}
            icon={<DesktopMacIcon />}
        >
            <hr />
                {monitorB.map((item, index) =>
                    getMonitorContent(item, index, "B")
                )}
            <br />
            {(playlistUpd) &&
                <Button
                    //size="sm"
                    round
                    fullWidth
                    color={"success"}
                    onClick={() => setMediaModalOpen(true)}
                >
                    {intl.formatMessage({ id: 'campaigns.mediaCard.addMedia' })} &nbsp; <AddCircleOutlineIcon />
                </Button>
            }
        </IconCard>
        <Modal
            title="Vyberte médiá z knižnice"
            open={mediaModalOpen}
            onClose={() => setMediaModalOpen(false)}
            actions={renderMediaModalActions()}
            fullWidth
        >
            <br />
            {data?.map((item, index) =>
                getLibraryContent(item, index)
            )}
        </Modal>
        {/* <Modal
            title={intl.formatMessage({ id: "playlist.modal.title" })}
            open={removeModalOpen}
            onClose={() => setRemoveModalOpen(false)}
            actions={renderRemoveModalActions()}
        >
        </Modal> */}

    </>
};

export default injectIntl(MediaCard);
