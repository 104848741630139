import gql from 'graphql-tag';

const USER_DETAIL = gql`
query user($id:ID!) {
  user(id: $id) {
      id
      bid
      firstName,
      lastName,
      fullName,
      phone,
      email
      createdAt
      isActivated,
      isBlocked,
      updatedAt,
    	groups{
        id
        name
        permissions
      }
  }
}
`;

export default USER_DETAIL;