export default class IsCheckedConsolidator {
    static consolidateTree(tree) {
        const leaves = tree.getLeaves();
        if (leaves.length === 0) {
            return;
        }
        for (const leaf of leaves) {
            IsCheckedConsolidator.consolidateNode(leaf);
        }
    }
    static consolidateNode(node) {
        const parent = node.getParent();
        if (parent === null) {
            return;
        }
        const siblings = node.getSiblings();
        parent.setIsChecked(node.getIsChecked() && siblings.every(sibling => sibling.getIsChecked()));
        IsCheckedConsolidator.consolidateNode(parent);
    }
}
