import React from 'react';
import { Grid } from '@material-ui/core';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => styles(theme))

const ContactDataCard = (props) => {
    const { intl } = props;
    const classes = useStyles();

    return (
        <IconCard
            title={intl.formatMessage({ id: 'profile.contactData.title' })}
            icon={<AssignmentIndIcon />}
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
                    <Box className={classes.floatRight}>
                        {props.name} {props.surname}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'profile.contactData.email' })}
                    <Box className={classes.floatRight}>
                        {props.email ? props.email : "-"}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'profile.contactData.phone' })}
                    <Box className={classes.floatRight}>
                        {props.phone ? props.phone : "-"}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'campaigns.companyCard.groups' })}
                    <Box className={classes.floatRight}>
                        {((props.groups)?.length > 0)
                            ? (props.groups)?.map((item, index) => (
                                <>{((props.groups).length !== index + 1) ? (item.name + ", ") : (item.name)}</>
                            ))
                            : "-"
                        }
                    </Box>
                </Grid>
            </Grid>
        </IconCard >

    )
}

export default injectIntl(ContactDataCard);