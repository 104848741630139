import gql from 'graphql-tag';

const DEVICES_QUERY = gql`
  query devices($offset: Int, $limit: Int, $sort: [DeviceSortInput!], $filter: [DeviceFilterInput]){
    devices(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount,
      items{
        id,
        bid,
        warnings,
        errors,
        status,
        isOnline,
        onlineAt,
        statusUpdatedAt,
        isEnabled,
        createdAt,
        updatedAt,
        zone{
          id,
          bid,
          name,
          area,
          visitors,
          rank,
          createdAt,
          updatedAt,
          store{
            id,
            bid,
            name,
            address,
            postal
            city,
            country,
            latitude,
            longtitude,
            fullAddress,
            contactFirstName,
            contactLastName,
            contactPhone,
            contactEmail,
            createdAt,
            updatedAt,
            company{
              id,
              bid,
              name,
              ico,
              dic,
              icDPH,
              registrationCourt,
              address,
              postal,
              city,
              country,
              latitude,
              longtitude,
              bankName,
              bankIBAN,
              bankBIC,
              contactFirstName,
              contactLastName,
              contactPhone,
              contactEmail,
              createdAt,
              updatedAt,
            }
          }
        }
      }
    }
  }
`;

export default DEVICES_QUERY;