import { Grid } from "@material-ui/core";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const ContactPersonCard = (props) => {
  const { intl, formikProps, readData, companyUpdate, newCompany } = props;
  const classes = useStyles();

  const renderContent = () => {
    if (!newCompany) {
      if (!companyUpdate) {
        return (
          <Grid container spacing={1}>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
              <Box className={classes.floatRight}>
                {readData.contactFirstName} {readData.contactLastName}
              </Box>
            </Grid>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.contactPersonCard.phone" })}
              <Box className={classes.floatRight}>
                {(readData.contactPhone) ? readData.contactPhone : "-"}
              </Box>
            </Grid>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.contactPersonCard.email" })}
              <Box className={classes.floatRight}>
                {(readData.contactEmail) ? readData.contactEmail : "-"}
              </Box>
            </Grid>
          </Grid>
        )
      }
    }

    return (
      <Grid container>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <FormikField
              name="contactFirstName"
              labelText={intl.formatMessage({ id: "companyDetail.contactPersonCard.firstName" })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
          <Grid item sm={6}>
            <FormikField
              name="contactLastName"
              labelText={intl.formatMessage({ id: "companyDetail.contactPersonCard.lastName" })}
              formikProps={formikProps}
              highlightChange
            />
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="contactPhone"
            labelText={intl.formatMessage({ id: "companyDetail.contactPersonCard.phone" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="contactEmail"
            labelText={intl.formatMessage({ id: "companyDetail.contactPersonCard.email" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.contactPerson.title" })}
        icon={<AccountBoxIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(ContactPersonCard));
