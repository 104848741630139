import gql from 'graphql-tag';

const USER_UPDATE = gql`
    mutation userUpdate ($id: ID!, $input: UserUpdateInput!) {
        userUpdate (id: $id, input: $input) {
            firstName
            lastName
        }
    }
`;

export default USER_UPDATE;
