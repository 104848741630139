import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../paths';
import Loading from "components-lib/Loading/Loading";

const ChanhgeDashboard = (props) => {

    if (window.location.hostname === "localhost") {
        let url = window.location.replace("https://test.echo.cultify.studio/admin/dashboard")
        window.open(url, "_self")
    } else if (window.location.hostname === "test.echo-ds.cultify.studio") {
        let url = window.location.replace("https://test.echo.cultify.studio/admin/dashboard")
        window.open(url, "_self")
    } else {
        let url = window.location.origin.replace(/^(https?:\/\/)(?:\w+)((?:\.\w+){2,})$/, '$1radio$2')
        window.open(url, "_self")
    }

    return (
        <Loading />
    )

};

export default ChanhgeDashboard;