import gql from 'graphql-tag';

const ALL_VILLAGES = gql`
query villages($offset: Int, $limit: Int, $sort: [VillageSortInput!], $filter: [VillageFilterInput]){
  villages(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id,
      name     
        }
      }
    }
`;

export default ALL_VILLAGES;