import gql from 'graphql-tag';

const PROFILE_QUERY = gql`
query user{
  me{
    user{
      id
      bid
      firstName,
      lastName,
      email,
      phone,
      groups{
        id
        name
        permissions
      }
    }
  }
}
`;

export default PROFILE_QUERY;