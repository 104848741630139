import gql from 'graphql-tag';

const COMPANY_ADD_USER = gql`
    mutation companyAddUser ($id: ID!, $userId: ID!) {
        companyAddUser (id: $id, userId: $userId){
            id
            users { id }
        }
    }
`;

export default COMPANY_ADD_USER;