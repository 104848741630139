import gql from 'graphql-tag';

const MEDIA_UPLOAD = gql`
mutation mediaUpload(
  $input: [MediaUploadInput!]!
) {
mediaUpload(input: $input) {
  media{
    id
  }
  url{
    url
    type
    expiresAt
  }
}
}
`;

export default MEDIA_UPLOAD;