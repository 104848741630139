import { useEffect, useState } from 'react';
import Tree from '../../service/tree/Tree';
import TopCheckedRetriever from '../../service/tree/util/TopCheckedRetriever';
import TreeCheckedSetter from '../../service/tree/util/TreeCheckedSetter';
const useTree = (data, selectedIds) => {
    const [tree, setTree] = useState(null);
    const [checkedLeavesIds, setCheckedLeavesIds] = useState([]);
    const uniqueIds = (ids) => ids.filter((id, index) => ids.indexOf(id) === index);
    useEffect(() => {
        if (data === null) {
            setTree(null);
            setCheckedLeavesIds([]);
            return;
        }
        const tree = Tree.fromObject(data);
        TreeCheckedSetter.set(tree, selectedIds, true);
        const checkedLeaves = TopCheckedRetriever.getLeaves(tree);
        setTree(tree);
        setCheckedLeavesIds(uniqueIds(checkedLeaves.map(descendant => descendant.getId())));
    }, [data, selectedIds]);
    return [
        tree,
        checkedLeavesIds
    ];
};
export default useTree;
