import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgetStyle.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import { userList } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const DevicesCard = (props) => {
    const {
        history,
        intl,
        allUsers,
        activatedUsers,
        unactivatedUsers,
        blockedUsers
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        if (userList)
            return (
                <Box >
                    <Button
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.users.list)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.users.button" })}
                    </Button>
                </Box>
            )
    }
    return <>
        <IconCard
            title={intl.formatMessage({ id: "dashboardCard.users.title" })}
            icon={<AccountBoxIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.users.allUsers" })}
                    <Box className={classes.floatRight}>
                        {allUsers}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.users.activatedUsers" })}
                    <Box className={classes.floatRight}>
                        {activatedUsers}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.users.unactivatedUsers" })}
                    <Box className={classes.floatRight}>
                        {unactivatedUsers}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.users.blockedUsers" })}
                    <Box className={classes.floatRight}>
                        {blockedUsers}
                    </Box>
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(DevicesCard);
