import gql from 'graphql-tag';

const MEDIA_DELETE = gql`
    mutation mediaDelete(
        $id: ID!
    ) {
      mediaDelete(id: $id)
    }
`;

export default MEDIA_DELETE;