export default class TopCheckedRetriever {
    static get(tree) {
        const collected = [];
        TopCheckedRetriever.collect(tree.getRoot(), collected);
        return collected;
    }
    static getLeaves(tree) {
        const descendants = tree.getRoot().getDescendants();
        const checkedDescendants = descendants.filter(descendant => descendant.getIsChecked());
        return checkedDescendants.filter(descendant => descendant.getChildren().length === 0);
    }
    static collect(from, collected) {
        if (from.getIsChecked() && from.getId().charAt(0) !== '_') {
            collected.push(from);
            return;
        }
        for (const child of from.getChildren()) {
            if (child.getIsChecked() && child.getId().charAt(0) !== '_') {
                collected.push(child);
            }
            else {
                TopCheckedRetriever.collect(child, collected);
            }
        }
    }
}
