import * as Yup from 'yup';

const getValidationSchema = (intl) =>
    Yup.object().shape({
        email: Yup.string()
            .email(intl.formatMessage({ id: 'validation.email' }))
            .required(intl.formatMessage({ id: 'validation.required' }))
            .nullable()
});

export default getValidationSchema;