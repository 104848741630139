import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import styles from "common/styles/widgets.js";
import InfoIcon from '@material-ui/icons/Info';
import FormikField from "components-lib/FormikField/FormikField";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => styles(theme));

const BasicInfoCard = (props) => {
    const classes = useStyles();
    const {
        intl,
        formikProps,
        create,
        companies,
        playlistUpdate
    } = props;

    return (
        <Grid container>
            <Grid item sm={12}>
                {(!create) ?
                    (playlistUpdate) ?
                        <Grid container spacing={2}>
                            <Grid item sm={6}>
                                <FormikField
                                    name="name"
                                    labelText={intl.formatMessage({ id: "playlist.basicInfo.name" })}
                                    formikProps={formikProps}
                                    highlightChange
                                />
                            </Grid>
                            <Grid item sm={6}>
                                <FormikField
                                    name="company.name"
                                    disabled={true}
                                    labelText={intl.formatMessage({ id: "playlist.basicInfo.company" })}
                                    formikProps={formikProps}
                                    highlightChange
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <FormikField
                                    name="description"
                                    labelText={intl.formatMessage({ id: "playlist.basicInfo.description" })}
                                    formikProps={formikProps}
                                    highlightChange
                                />
                            </Grid>
                        </Grid>
                        :
                        <Grid container spacing={3}>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: "playlist.basicInfo.name" })}
                                <Box className={classes.floatRight}>
                                    {formikProps?.values?.name}
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: "playlist.basicInfo.company" })}
                                <Box className={classes.floatRight}>
                                    {formikProps?.values?.company?.name}
                                </Box>
                            </Grid>
                            <Grid item sm={12}>
                                {intl.formatMessage({ id: "playlist.basicInfo.description" })}
                                <Box className={classes.floatRight}>
                                    {(formikProps?.values?.description) ? formikProps?.values?.description : "-"}
                                </Box>
                            </Grid>
                        </Grid>
                    :
                    <>
                        <Grid item sm={12}>
                            <FormikField
                                name="name"
                                labelText={intl.formatMessage({ id: "playlist.basicInfo.name" })}
                                formikProps={formikProps}
                                highlightChange
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <FormikField
                                name="companyId"
                                type="select"
                                options={companies}
                                labelText={intl.formatMessage({ id: "playlist.basicInfo.company" })}
                                formikProps={formikProps}
                                highlightChange
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <FormikField
                                name="description"
                                labelText={intl.formatMessage({ id: "playlist.basicInfo.description" })}
                                formikProps={formikProps}
                                highlightChange
                            />
                        </Grid>
                    </>
                }

            </Grid>
        </Grid>
    );
};
export default withRouter(injectIntl(BasicInfoCard));
