import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
// import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
// import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import MediaCard from '../CampaignCreateCards/MediaCard';
// import moment from 'moment';
// import { useQuery } from 'react-apollo';
// import { ALL_TOPICS } from "queries/CampaignsQueries/allTopics"
// import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";
// import STORES_SELECT from "queries/StoresQueries/storesSelect";
// import HoursCard from "components-lib/HoursCard/HoursCard";
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
// import CardMedia from '@material-ui/core/CardMedia';
// import Modal from "components-lib/Modal/Modal";
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';

const useStyles = makeStyles((theme) => styles(theme));

const Step4 = ({ intl, initialData, companyId, callback }) => {
  const classes = useStyles();

  // useEffect(() => {
  //   console.log(initialData);
  // }, [initialData]);

  const onMediasUpdate = (medias) => {
    callback(medias);
  }

  const formatMediasInput = () => {
    if(!initialData || initialData?.length === 0) return [];

    return initialData;
  }

  return (
    <Grid container spacing={1}>
      <MediaCard
          medias={formatMediasInput()}
          companyId={companyId}
          onMediasUpdate={onMediasUpdate}
      />
    </Grid >
  );
}

export default injectIntl(Step4);