import React, { useState } from "react";
import { isSameDay } from "date-fns";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import './styles.css';
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";
import 'dayjs/locale/sk';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";

const useStyles = makeStyles((theme) => styles(theme));

const MuiCalendarComponent = (props) => {
    const {
        history,
        intl,
        layout,
        highlightedDays,
        highlitedDaysModal,
        onModalDayClick,
        onMonthChange
    } = props;

    // React.useEffect(() => {
    //     console.log(highlitedDaysModal);
    // }, [highlitedDaysModal]);

    const CustomDay = (props) => {
        const matchedStyles = highlightedDays.reduce((a, v) => {
            const date = new Date(props.day);
            return isSameDay(date, v) ? { backgroundColor: "#005E5299", color: 'white' } : a;
        }, {});
    
        return <PickersDay 
                    {...props}
                    selected={false}
                    sx={{ ...matchedStyles, pointerEvents: 'none' }}
                />;
    };

    const CustomDayModal = (props) => {
        const matchedStyles = [...highlitedDaysModal].reduce((a, v) => {
            const date = new Date(props.day);
            return isSameDay(date, v) ? { backgroundColor: "#005E5299", color: 'white' } : a;
        }, {});



        return <PickersDay 
                    {...props}
                    selected={false}
                    sx={{ ...matchedStyles }}
                    onClick={(e) => onModalDayClick(e)}
                />;
    };

    // const onDaySelectModal = (value) => {
    //     if(highlitedDaysModal.some(d => d.getTime() == value.$d.getTime())){
    //         setHighlitedDaysModal(highlitedDaysModal.filter(day => day.getTime() !== value.$d.getTime()));
    //     }
    //     else{
    //         setHighlitedDaysModal([...highlitedDaysModal, value.$d]);
    //     }
    // }

    const classes = useStyles();
    const today = dayjs();

    return <>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sk">
            {
                (layout === 'modal') ?
                    <StaticDatePicker
                        disablePast={true}
                        disableHighlightToday={false}
                        onMonthChange={(date) => onMonthChange(date)}
                        // defaultValue={today}
                        // onAccept={(newDate) => {
                        //     console.log(newDate);
                        // }}
                        // onChange={(value) => {
                        //     onDaySelectModal(value);
                        // }}
                        dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase() + day.charAt(1)}
                        slots={{
                            day: CustomDayModal
                        }}
                        // slotProps={{
                        //     day: {
                        //         highlitedDaysModal,
                        //     },
                        // }}
                    />
                :
                    <StaticDatePicker
                        disablePast={true}
                        disableHighlightToday={false}
                        // defaultValue={today}
                        dayOfWeekFormatter={(day) => day.charAt(0).toUpperCase() + day.charAt(1)}
                        slots={{
                            day: CustomDay
                        }}
                        // slotProps={{
                        //     day: {
                        //         highlightedDays,
                        //     },
                        // }}
                    />
            }
        </LocalizationProvider>
    </>
};

export default injectIntl(MuiCalendarComponent);
