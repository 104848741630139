import gql from 'graphql-tag';

const COMPANY_CREATE = gql`
    mutation companyCreate($input: CompanyCreateInput!){
        companyCreate(input: $input){
            name
            id
        }
    }
`;

export default COMPANY_CREATE;

