import { useQuery } from '@apollo/react-hooks';
import Business from '@material-ui/icons/Business';
import IconCard from 'components-lib/IconCard/IconCard';
import { pathOr } from 'rambda';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import FormikField from "../../../components-lib/FormikField/FormikField";
import SELECT_COMPANIES from 'queries/CompaniesQueries/selectCompanies';

const SelectCompanyCard = (props) => {
    const { intl, formikProps } = props;
    const { data: companiesData } = useQuery(SELECT_COMPANIES)
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
            (company) => {
                return {
                    value: pathOr("", "id", company),
                    label: `${pathOr("", "name", company)}`,
                };
            }
        );
        setCompanies(companiesSelectItems);
    }, [companiesData]);

    return (
        <IconCard
            title={intl.formatMessage({ id: 'storeList.table.company' })}
            icon={<Business />}>
            <FormikField
                disabled={false}
                name="companyId"
                labelText={intl.formatMessage({ id: 'createUser.company.name' })}
                formikProps={formikProps}
                value={formikProps}
                type="select"
                options={companies}
            />
        </IconCard>
    )
}

export default withRouter(injectIntl(SelectCompanyCard));