import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import {  companyDetail } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme))

export const CompanyTable = (props) => {
    const { history, queryVariables, query, intl, defaultFilter = null, numberOfRows = 10, queryDataPath = ['companiesTable', 'items'] } = props
    const classes = useStyles();

    const onCompanyDetail = (id) => {
        history.push(`/admin${generatePath(paths.companies.detail, { companyId: id })}`);
    }

    return (
        <QueryTable
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'companyList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'companyList.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'companyList.table.stores' }),
                    accessor: 'stores',
                    sortKey: "stores",
                    filterKey: "stores",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                (companyDetail) ?
                    {
                        Header: intl.formatMessage({ id: 'companyList.table.actions' }),
                        accessor: (rowData) => <Button round className={classes.tenantButtons} size="sm" color="info"
                            onClick={() => onCompanyDetail(rowData.id)}>{intl.formatMessage({ id: 'companyList.table.actions.detail' })}</Button>
                    }
                    : { Header: " " }
            ]}
        />
    )
}

export default injectIntl(CompanyTable);