import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import SELECT_DISPLAY_GROUPS from "queries/DisplaysQueries/selectDisplayGroups";

const useStyles = makeStyles((theme) => styles(theme));

const DisplayCard = (props) => {
    const { intl, formikProps, companyId, deviceType, storeUpdate } = props;
    const classes = useStyles();

    let secondDisplay = (deviceType?.includes("rypi3")) ? false : true

    const { data: displayGroupsData } = useQuery(SELECT_DISPLAY_GROUPS, {
        variables: {
            filter: {
                companyId: {
                    eq: Number(companyId)
                }
            }
        }
    });
    const [displayGroups, setDisplayGroups] = useState([]);

    useEffect(() => {
        const displays = pathOr([], ['displayGroups', 'items'], displayGroupsData).map(displayGroup => {
            return {
                label: pathOr('', 'name', displayGroup),
                value: pathOr('', 'id', displayGroup)
            }
        });
        displays.unshift({
            label: intl.formatMessage({ id: "zoneDetail.displayCard.without" }),
            value: "without",
        })
        setDisplayGroups(displays);
    }, [displayGroupsData])

    const renderContent = () => {
        if (!storeUpdate) {
            return (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "zoneDetail.displayCard.displayGroupA" })}
                        <Box className={classes.floatRight}>
                            {(formikProps?.values?.preferences?.screenAGroup?.id) ? formikProps?.values?.preferences?.screenAGroup?.name : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "zoneDetail.displayCard.displayGroupB" })}
                        <Box className={classes.floatRight}>
                            {(formikProps?.values?.preferences?.screenBGroup?.id) ? formikProps?.values?.preferences?.screenBGroup?.name : "-"}
                        </Box>
                    </Grid>
                </Grid>
            )
        }
        return (
            <>
                <FormikField
                    name={"preferences.screenAGroup.id"}
                    labelText={intl.formatMessage({ id: "zoneDetail.displayCard.displayGroupA" })}
                    formikProps={formikProps}
                    type="select"
                    options={displayGroups}
                />
                {(secondDisplay) &&
                    <FormikField
                        name={"preferences.screenBGroup.id"}
                        labelText={intl.formatMessage({ id: "zoneDetail.displayCard.displayGroupB" })}
                        formikProps={formikProps}
                        type="select"
                        options={displayGroups}
                    />
                }
            </>
        )
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "zoneDetail.displayCard.title" })}
                icon={<DesktopMacIcon />}>
                {renderContent()}
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(DisplayCard));
