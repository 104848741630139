
import gql from 'graphql-tag';

const DEVICE_IDENTIFY = gql`
mutation deviceIdentify($id: ID!){
  deviceIdentify(id:$id)
}
`;

export default DEVICE_IDENTIFY;

