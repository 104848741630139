import { gql } from "apollo-boost";

export const OVERVIEW = gql`
query Overview {
  overview {
      companies {
      totalCount
    }
    stores {
      totalCount
      noPlayerCount
    }
    users {
      totalCount
      activatedCount
      unactivatedCount
      blockedCount
    }
    devices {
      totalCount
      assignedCount
      unassignedCount
      offlineCount
    }
    media {
      totalCount
      awaitingApprovalCount
    }
  }
}
`;