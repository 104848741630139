import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import Checkbox from '@material-ui/core/Checkbox';
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import QueryTable from 'components-lib/QueryTable/QueryTable';

const useStyles = makeStyles((theme) => styles(theme));

const DisplayCard = (props) => {
    const {
        history,
        intl,
        initialData,
        queryVariables,
        defaultFilter,
        queryDataPath,
        numberOfRows,
        query,
        onDisplayGroupsUpdate,
    } = props;

    // useEffect(() => {
    //     console.log(initialData);
    // }, [initialData]);

    const classes = useStyles();
    const [changeDisplaysModalOpen, setChangeDisplaysModalOpen] = React.useState(false);
    const [displayCheckedGroups, setDisplayCheckedGroups] = React.useState((initialData) ? initialData : []);

    const displayModalCheckboxCheck = (e, displayGroupId) => {
        let displayGroupList = displayCheckedGroups;

        if(e.target.checked){
            displayGroupList.push(displayGroupId);
        } 
        else{
            displayGroupList = displayGroupList.filter((e) => e !== displayGroupId);
        }

        setDisplayCheckedGroups(displayGroupList);
        onDisplayGroupsUpdate(displayGroupList);
    }

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: " ",
                    accessor: (rowData) => 
                        <>
                            {
                                (!initialData.includes(rowData.id)) ?
                                    <Checkbox 
                                        color="primary" 
                                        style={{float: 'right'}}
                                        onChange={(e) => displayModalCheckboxCheck(e, rowData.id)}
                                    />
                                :
                                    <Checkbox 
                                        color="primary" 
                                        style={{float: 'right'}}
                                        onChange={(e) => displayModalCheckboxCheck(e, rowData.id)}
                                        defaultChecked
                                    />
                            }
                        </>
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.description' }),
                    accessor: 'description',
                    sortKey: 'description',
                    filterKey: 'description',
                    filterOperator: 'contains'
                }
            ]
        )
    }

    return <>
        <Grid container spacing={1}>
            <Grid item sm={12}>
                <QueryTable
                    query={query}
                    queryVariables={queryVariables}
                    permanentFilter={defaultFilter}
                    queryDataPath={queryDataPath}
                    numberOfRows={numberOfRows}
                    columns={getColumnsDetail()}
                />
            </Grid>
        </Grid>
    </>
};

export default injectIntl(DisplayCard);
