import * as Yup from 'yup';

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        creditValue: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        defaultSpotPlaybackPrice: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        commissionRate: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' })))
            .max(100, (intl.formatMessage({ id: 'validation.number.max' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getMaintainerValidationSchema = (intl) =>
    Yup.object().shape({
        creditValue: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        defaultSpotPlaybackPrice: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        commissionRate: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' })))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .required(intl.formatMessage({ id: 'validation.required' })).nullable(),

    });



export default getValidationSchema;