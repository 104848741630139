import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgetStyle.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import WarningIcon from '@material-ui/icons/Warning';
import { mediaList } from 'configFiles/roles'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';

const useStyles = makeStyles((theme) => styles(theme));

const PlaylistCard = (props) => {
    const {
        history,
        intl,
        allPlaylists,
        awaitingPlaylists
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        return (
            <Box>
                <Button
                    className={classes.floatRight}
                    color="info"
                    size="sm"
                    round
                    onClick={() => history.push({
                        pathname: `/admin${generatePath(paths.playlists.list)}`
                    })}
                >
                    {intl.formatMessage({ id: "dashboardCard.playlist.button" })}
                </Button>
            </Box>
        )
    }

    const renderAwaitingPlaylists = () => {
        return <>
            {intl.formatMessage({ id: "dashboardCard.playlist.awaitingPlaylists" })}
            <Box className={classes.floatRight}>
                {awaitingPlaylists}
            </Box>
        </>
    }


    return <>
        <IconCard
            title={intl.formatMessage({ id: "dashboardCard.playlist.title" })}
            icon={<PlaylistPlayIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={3}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "dashboardCard.playlist.allPlaylist" })}
                    <Box className={classes.floatRight}>
                        {allPlaylists}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {renderAwaitingPlaylists()}
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(PlaylistCard);
