import gql from 'graphql-tag';

const ZONE_UPDATE = gql`
    mutation zoneUpdate($id: ID!, $input: ZoneUpdateInput!){
        zoneUpdate(id: $id, input: $input){
                id
        bid
        name
        }
    }
`;

export default ZONE_UPDATE;