import * as React from 'react';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";


const CustomCheckbox = (props) => {
    const { checked = false, onChange = () => {}, label = '' } = props;

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return (
        <>
            <div className={ classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal }>
                <FormControlLabel
                    classes={{ label: classes.label }}
                    label={label}
                    control={
                        <Checkbox
                            tabIndex={-1}
                            onClick={() => onChange()}
                            checkedIcon={<Check className={classes.checkedIcon} />}
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                                checked: classes.checked,
                                root: classes.checkRoot
                            }}
                            checked={checked}
                        />
                    }
                />
            </div>
        </>
    );
};

export default CustomCheckbox;