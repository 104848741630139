import gql from 'graphql-tag';

const SELECT_DISPLAY_GROUPS = gql`
query displayGroups($offset: Int, $limit: Int, $sort: [DisplayGroupSortInput!], $filter: [DisplayGroupFilterInput]){
  displayGroups(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id
      bid
      name
      description
      createdAt
  }
}
}
`;

export default SELECT_DISPLAY_GROUPS;