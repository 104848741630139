import React, { useState } from 'react';
import { DateTime } from "luxon";
import { useMutation } from "@apollo/react-hooks";
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import DeviceErrorCodesEnum from 'common/enums/DeviceErrorCodesEnum';
import Box from '@material-ui/core/Box';
import paths from 'paths';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import DEVICE_IDENTIFY from 'queries/DevicesQueries/deviceIdentify';
import { useSnackbar } from "notistack";
import AdjustIcon from '@material-ui/icons/Adjust';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { pathOr } from 'rambda';
import { deviceDetail } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme))

const DevicesTable = (props) => {
    const { history, query, intl, defaultFilter = null, queryDataPath = ['devicesTable', 'items'], disabled } = props
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [deviceIdentify] = useMutation(DEVICE_IDENTIFY);

    const onDeviceDetail = (id) => {
        history.push(`/admin${generatePath(paths.devices.detail, { deviceId: id })}`);
    }
    const onDeviceIdentiy = (id) => {
        deviceIdentify({
            variables: {
                id: id,
            },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.success.deviceIdentify" }), { variant: "success", });
        }).catch((err) => {
            console.log('[error]', err)
        });
    }

    const getDeviceStatus = (deviceStatus) => {
        switch (deviceStatus) {
            case "Online":
                return (
                    <Tooltip title={<span style={{ textAlign: "center", color: "#dcedc8" }}>{intl.formatMessage({ id: 'deviceList.deviceStatus.tooltip.online' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ textAlign: "center", color: "green", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Offline":
                return (
                    <Tooltip title={<span style={{ color: "#ffcdd2" }}>{intl.formatMessage({ id: 'deviceList.deviceStatus.tooltip.offline' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "red", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return ("-");
        }
    }


    const getPlayerStatus = (playerStatus) => {
        switch (playerStatus) {
            case "Playing":
                return (
                    <Tooltip title={<span style={{ textAlign: "center", color: "#dcedc8" }}>{intl.formatMessage({ id: 'deviceList.playerStatus.tooltip.online' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ textAlign: "center", color: "green", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Stopped":
                return (
                    <Tooltip title={<span style={{ color: "#ffcdd2" }}>{intl.formatMessage({ id: 'deviceList.playerStatus.tooltip.offline' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "red", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return ("-");
        }
    }

    const getEnabledColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: 'deviceList.table.ID' }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.company' }),
                accessor: 'company',
                sortKey: 'company',
                filterKey: 'company',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.store' }),
                accessor: 'store',
                sortKey: 'store',
                filterKey: 'store',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.type' }),
                accessor: (rowData) => (rowData.deviceType === "raspberrypi4-64")
                    ? "RPi4"
                    : (rowData.deviceType === "raspberrypi3") ? "RPi3" : "Neuvedené",
                sortKey: 'deviceType',
                filterKey: 'deviceType',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'raspberrypi4-64',
                        label: "RPi4"
                    },
                    {
                        value: 'raspberrypi3',
                        label: "RPi3"
                    },
                    {
                        value: 'unknown',
                        label: "Neuvedené"
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.state' }),
                accessor: (rowData) => getDeviceStatus(rowData.status),
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'Online',
                        label: intl.formatMessage({ id: 'deviceStatus.online' })
                    },
                    {
                        value: 'Offline',
                        label: intl.formatMessage({ id: 'deviceStatus.offline' })
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.playerStatus' }),
                accessor: (rowData) => getPlayerStatus(rowData.playerStatus),
                show: false,
                sortKey: 'playerStatus',
                filterKey: 'playerStatus',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: "Playing",
                        label: intl.formatMessage({ id: 'deviceDetail.devicePlayback.online' })
                    },
                    {
                        value: "Stopped",
                        label: intl.formatMessage({ id: 'deviceDetail.devicePlayback.noplay' })
                    },
                ]
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.balenaId' }),
                accessor: (rowData) =>
                    <Tooltip title={<span>{rowData.balenaUuid} ({intl.formatMessage({ id: "tableTexts.copyToClipboardShort" })})</span>} >
                        <span
                            className={classes.copyToClipboard}
                            onClick={() => { navigator.clipboard.writeText(rowData.balenaUuid); enqueueSnackbar(intl.formatMessage({ id: "tableTexts.copyToClipboard.success" }), { variant: 'success' }); }}
                        >

                            {(rowData.balenaUuid)?.substring(0, 10)}...
                        </span>
                    </Tooltip>,
                sortKey: 'balenaUuid',
                filterKey: 'balenaUuid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.heartbeat' }),
                accessor: (rowData) => rowData.heartbeat !== null ? DateTime.fromISO(rowData.heartbeat).toFormat("dd.MM.yyyy HH:mm") : <p> - </p>,
                sortKey: 'heartbeat',
                filterKey: "heartbeat",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            (deviceDetail) ?
                {
                    Header: intl.formatMessage({ id: 'deviceList.table.actions' }),
                    accessor: (rowData) => <>
                        <Button round size="sm" color="info" onClick={() => onDeviceDetail(rowData.id)}>{intl.formatMessage({ id: 'deviceList.table.actions.detail' })}</Button>
                    </>
                }
                : { Header: " " }
        ])
    }

    const getDisabledColumns = () => {
        return ([
            {
                Header: intl.formatMessage({ id: 'deviceList.table.ID' }),
                accessor: 'bid',
                sortKey: 'bid',
                filterKey: 'bid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.type' }),
                accessor: (rowData) => (rowData.deviceType === "raspberrypi4-64")
                    ? "RPi4"
                    : (rowData.deviceType === "raspberrypi3") ? "RPi3" : "Neuvedené",
                sortKey: 'deviceType',
                filterKey: 'deviceType',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'raspberrypi4-64',
                        label: "RPi4"
                    },
                    {
                        value: 'raspberrypi3',
                        label: "RPi3"
                    },
                    {
                        value: 'unknown',
                        label: "Neuvedené"
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.state' }),
                accessor: (rowData) => getDeviceStatus(rowData.status),
                sortKey: 'status',
                filterKey: 'status',
                filterComponent: 'select',
                filterOperator: 'eq',
                filterOptions: [
                    {
                        value: 'Online',
                        label: intl.formatMessage({ id: 'deviceStatus.online' })
                    },
                    {
                        value: 'Offline',
                        label: intl.formatMessage({ id: 'deviceStatus.offline' })
                    }
                ]
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.balenaId' }),
                accessor: (rowData) =>
                    <Tooltip title={<span>{rowData.balenaUuid} ({intl.formatMessage({ id: "tableTexts.copyToClipboardShort" })})</span>} >
                        <span
                            className={classes.copyToClipboard}
                            onClick={() => { navigator.clipboard.writeText(rowData.balenaUuid); enqueueSnackbar(intl.formatMessage({ id: "tableTexts.copyToClipboard.success" }), { variant: 'success' }); }}
                        >

                            {(rowData.balenaUuid)?.substring(0, 10)}...
                        </span>
                    </Tooltip>,
                sortKey: 'balenaUuid',
                filterKey: 'balenaUuid',
                filterOperator: 'contains'
            },
            {
                Header: intl.formatMessage({ id: 'deviceList.table.heartbeat' }),
                accessor: (rowData) => rowData.heartbeat !== null ? DateTime.fromISO(rowData.heartbeat).toFormat("dd.MM.yyyy HH:mm") : <p> - </p>,
                sortKey: 'heartbeat',
                filterKey: "heartbeat",
                filterOperator: 'between',
                filterDataType: "dateBetween",
                filterComponent: 'date',
            },
            (deviceDetail) ?
                {
                    Header: intl.formatMessage({ id: 'deviceList.table.actions' }),
                    accessor: (rowData) => <>
                        <Button round size="sm" color="info" onClick={() => onDeviceDetail(rowData.id)}>{intl.formatMessage({ id: 'deviceList.table.actions.detail' })}</Button>
                    </>
                }
                : { Header: " " }
        ])
    }

    return (
        <QueryTable
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            columns={(disabled) ? getDisabledColumns() : getEnabledColumns()}
            query={query}
        />
    )
}

export default injectIntl(DevicesTable);