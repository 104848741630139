import { Grid, withWidth } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CalendarIcon from '@material-ui/icons/CalendarToday';
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState, useEffect } from "react";
import { isSameDay, isFuture } from "date-fns";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import MuiCalendar from "../Components/MuiCalendar";
import Button from "components/CustomButtons/Button.js";
import Modal from "components-lib/Modal/Modal";
import {format } from 'date-fns';

const useStyles = makeStyles((theme) => styles(theme));

const CalendarCard = (props) => {
    const {
        history,
        intl,
        initialData,
        onActiveTimesUpdate
    } = props;
    
    const [highlightedDays, setHighlitedDays] = useState([]);
    const [highlitedDaysModal, setHighlitedDaysModal] = useState([]);
    const [modalCalendarDisplayedMonth, setModalCalendarDisplayedMonth] = useState(new Date());

    const classes = useStyles();

    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
      
        const dates = [];
      
        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      
        return dates;
    }

    const getDatesFromActiveTimes = () => {
        let dates = [];
        initialData.forEach((at) => {
            const dts = getDatesInRange(new Date(at.from), new Date(at.to));
            dates = dates.concat(dts);
        });

        dates = dates.filter(date => format(new Date(), 'yyyy-MM') <= format(new Date(date), 'yyyy-MM'));
        return dates;
    }

    useEffect(() => {
        setHighlitedDaysModal(getDatesFromActiveTimes());
    }, [initialData]);

    useEffect(() => {
        onActiveTimesUpdate(highlitedDaysModal);
    }, [highlitedDaysModal]);

    const handleModalDayClick = (event) => {
        const date = new Date(Number(event.target.getAttribute('data-timestamp')));

        if(highlitedDaysModal.some(d => isSameDay(d, date))){
            setHighlitedDaysModal(highlitedDaysModal.filter(day => !isSameDay(day,date)));
        }
        else{
            setHighlitedDaysModal([...highlitedDaysModal, date]);
        }
    }

    const handleModalMonthChange = (date) => {
        setModalCalendarDisplayedMonth(new Date(date));
    }

    function getWorkingDays(date){
        const y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        const days = [];

        for (let currentDate = new Date(firstDay); currentDate <= lastDay; currentDate.setDate(currentDate.getDate() + 1)) {
            if (!isFuture(currentDate)) {
                continue;
            }

            const weekDay = currentDate.getDay();
            if (weekDay !== 0 && weekDay !== 6) {
                days.push(format(new Date(currentDate), 'yyyy-MM-dd'));
            }
        }

        return days;
    }

    function getWeekends(date){
        const y = date.getFullYear(), m = date.getMonth();
        const firstDay = new Date(y, m, 1);
        const lastDay = new Date(y, m + 1, 0);

        const days = [];

        for (let currentDate = new Date(firstDay); currentDate <= lastDay; currentDate.setDate(currentDate.getDate() + 1)) {
            if (!isFuture(currentDate)) {
                continue;
            }

            const weekDay = currentDate.getDay();
            if (weekDay === 0 || weekDay === 6) {
                days.push(format(new Date(currentDate), 'yyyy-MM-dd'));
            }
        }

        return days;
    }

    function getDaysInMonth(date) {
        const month = date.getMonth();
        const year = date.getFullYear();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        const days = [];

        for (let currentDate = new Date(firstDay); currentDate <= lastDay; currentDate.setDate(currentDate.getDate() + 1)) {
            if (isFuture(currentDate)) {
                days.push(format(new Date(currentDate), 'yyyy-MM-dd'));
            }
        }

        return days;
    }

    const modalHighlightDaysInMonth = (days) => {
        const datesModal = highlitedDaysModal.map(day => format(new Date(day), 'yyyy-MM-dd'));

        // Fill with missing days
        const additionalDaysInMonth = days.filter(day => !datesModal.includes(day)).map(day => new Date(day));

        // If days already filled -> clear it
        if(additionalDaysInMonth.length === 0){
            const clearDays = days.filter(day => datesModal.includes(day));
            const withClearedDays = highlitedDaysModal.filter(day => !clearDays.includes(format(new Date(day), 'yyyy-MM-dd'))).map(day => new Date(day));
            setHighlitedDaysModal(withClearedDays);
            return;
        }

        setHighlitedDaysModal(highlitedDaysModal.concat(additionalDaysInMonth));
    }

    const modalHighlightWeekDays = () => {
        const days = getWorkingDays(modalCalendarDisplayedMonth);
        modalHighlightDaysInMonth(days);
    }

    const modalHighlightWeekends = () => {
        const days = getWeekends(modalCalendarDisplayedMonth);
        modalHighlightDaysInMonth(days);
    }

    const modalHighlightMonth = () => {
        const days = getDaysInMonth(modalCalendarDisplayedMonth);
        modalHighlightDaysInMonth(days);
    }

    return <>
            <Grid container spacing={2} style={{borderBottom: '1px solid', marginBottom: '0'}}>
                <Grid item lg={9} styl={{padding: '8px 8px 8px 0'}}>
                    <MuiCalendar
                        dates={[]}
                        layout={'modal'}
                        highlightedDays={highlightedDays}
                        highlitedDaysModal={highlitedDaysModal}
                        onModalDayClick={handleModalDayClick}
                        onMonthChange={handleModalMonthChange}
                    />
                </Grid>
                <Grid item lg={3} style={{paddingTop: '65px', borderLeft: '1px solid'}}>
                    <Grid item lg={12}>
                        <Button
                            onClick={() => modalHighlightWeekends()}
                            // color="secondary"
                            round
                            size="sm"
                            className={`${classes.floatLef} simple-text-button`}
                        >
                            {intl.formatMessage({ id: "campaigns.calendarCard.updateModal.weekends" })}
                        </Button>
                    </Grid>
                    <Grid item lg={12}>
                        <Button
                            onClick={() => modalHighlightWeekDays()}
                            // color="secondary"
                            round
                            size="sm"
                            className={`${classes.floatLef} simple-text-button`}
                        >
                            {intl.formatMessage({ id: "campaigns.calendarCard.updateModal.workDays" })}
                        </Button>
                    </Grid>
                    <Grid item lg={12}>
                        <Button
                            onClick={() => modalHighlightMonth()}
                            // color="secondary"
                            round
                            size="sm"
                            className={`${classes.floatLef} simple-text-button`}
                        >
                            {intl.formatMessage({ id: "campaigns.calendarCard.updateModal.month" })}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
    </>
};

export default injectIntl(CalendarCard);