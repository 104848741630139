import React from 'react';
import { Redirect } from 'react-router-dom';
import paths from '../../paths';
import { processLogout } from '../../helpers/helpers';

const LogoutPage = (props) => {

    processLogout();

    return (
        <Redirect to={`/auth${paths.auth.login}`} />
    );
};

export default LogoutPage;