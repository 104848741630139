import * as React from 'react';
import selectStyles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";


const useSelectStyles = makeStyles(selectStyles);

const CustomSelect = ({ options = [], labelText = '', onChange = (e) => { }, value = null, ...rest }) => {
    const selectClasses = useSelectStyles();

    return (
        <FormControl fullWidth className={selectClasses.selectFormControl}>
            <InputLabel
                className={selectClasses.selectLabel}
            >
                {labelText}
            </InputLabel>
            <Select
                MenuProps={{
                    className: selectClasses.selectMenu
                }}
                classes={{
                    select: selectClasses.select
                }}
                value={value}
                onChange={onChange}
                // inputProps={{}}
                {...rest}
            >
                {options.map((option, key) => (
                    <MenuItem
                        key={key}
                        classes={{
                            root: selectClasses.selectMenuItem,
                            selected: selectClasses.selectMenuItemSelected
                        }}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomSelect;