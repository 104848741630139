import gql from 'graphql-tag';

const PLAYLIST_DETAIL = gql`
query playlist($id: ID!){
    playlist(id:$id){
      id
      bid
      name
      description
      createdAt
      updatedAt
      deletedAt
      company{
        id
        name
      }
      createdBy{
        id
        fullName
      }
      media{
        id
        bid
        name
        duration
        previews{
          id
          url
        }
      }

    }
}
`;

export default PLAYLIST_DETAIL;