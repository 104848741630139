import gql from 'graphql-tag';

const LANGUAGE_CAHNGE = gql`
    mutation preferencesUpdate($input: UserPreferencesInput!) {
        preferencesUpdate(input: $input) {
            language
        }
    }
`;

export default LANGUAGE_CAHNGE;