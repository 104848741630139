import { useState, useEffect } from 'react';
import ByNameNodeFilter from '../../service/tree/util/ByNameNodeFilter';
const useFullText = (data) => {
    const [value, setValue] = useState('');
    const [nodes, setNodes] = useState([]);
    useEffect(() => {
        (async () => {
            const nodes = [];
            for (const id of await ByNameNodeFilter.filter(Object.values(data.nodes), value)) {
                nodes.push(data.nodes[id]);
            }
            setNodes(nodes);
        })();
    }, [value, data.nodes]);
    return [
        value,
        nodes,
        setValue
    ];
};
export default useFullText;
