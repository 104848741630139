import React from 'react';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { pathOr } from 'rambda';
import FormikField from 'components-lib/FormikField/FormikField';
import Button from "components/CustomButtons/Button.js";
import { removeTypenameFromObject } from "../../helpers/helpers";
import { useSnackbar } from "notistack";
import getValidationSchema from "./validationSchema";
import { injectIntl } from 'react-intl';
import CreditIcon from 'components-lib/Icons/CreditIcon/CreditIcon';

import SETTINGS_QUERY from './queries/settingsQuery'
import SETTINGS_UPDATE from './queries/settingsUpdate'

const SettingsPage = ({ intl }) => {

    const [settings, setSettings] = useState(null);
    const { data, refetch: refetchSettingsData } = useQuery(SETTINGS_QUERY);
    const [updateSettings] = useMutation(SETTINGS_UPDATE);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        let set = (pathOr([], ['settings'], data));        
        setSettings({
            "creditValue": set.creditValue,
            "defaultSpotPlaybackPrice": set.defaultSpotPlaybackPrice,
            "commissionRate": (set.commissionRate * 100)
        })
    }, [data]);
    return (
        <>
            <PageHeader
                withBackButton={false}
                title={intl.formatMessage({ id: 'settings.title' })}
            />

            <Formik
                enableReinitialize
                initialValues={settings}
                validationSchema={getValidationSchema(intl)}
                onSubmit={(values, actions) => {
                    console.log(values)
                    removeTypenameFromObject(values);
                    console.log(values.commissionRate);
                    const rate = (values.commissionRate / 100)
                    updateSettings({
                        variables: {
                            input: {
                                creditValue: values.creditValue,
                                defaultSpotPlaybackPrice: values.defaultSpotPlaybackPrice,
                                commissionRate: rate
                            }
                        },
                    }).then((response) => {
                        enqueueSnackbar(intl.formatMessage({ id: 'settings.update.success' }), {
                            variant: 'success'
                        });
                        //console.log(response)
                        refetchSettingsData();
                    }).catch((err) => { });
                }}
            >
                {props => (
                    <>
                        <Grid container spacing={3}>
                            <Grid item md={6} lg={5} sm={12}>
                                <IconCard icon={<CreditIcon raw />} title={intl.formatMessage({ id: 'settings.globalSettings.title' })}>
                                    <FormikField
                                        name="creditValue"
                                        labelText={intl.formatMessage({ id: 'settings.globalSettings.creditValue' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="defaultSpotPlaybackPrice"
                                        labelText={intl.formatMessage({ id: 'settings.globalSettings.defaultSpotPlaybackPrice' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                    <FormikField
                                        name="commissionRate"
                                        labelText={intl.formatMessage({ id: 'settings.globalSettings.commissionRate' })}
                                        formikProps={props}
                                        highlightChange
                                    />
                                </IconCard>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Button color="primary" onClick={(e) => props.handleSubmit()}>
                                    {intl.formatMessage({ id: 'settings.save' })}
                                </Button>
                                <Button onClick={() => props.resetForm()}>
                                    {intl.formatMessage({ id: 'settings.cancel' })}
                                </Button>
                            </Grid>
                        </Grid>

                    </>
                )}
            </Formik>
        </>
    );
}

export default injectIntl(SettingsPage);