export default class Lemmatizer {
    constructor(zero, one, twoToFour, fiveAndMore) {
        this.zero = zero;
        this.one = one;
        this.twoToFour = twoToFour;
        this.fiveAndMore = fiveAndMore;
    }
    get(count) {
        count = Math.abs(count);
        if (count === 0) {
            return `${count} ${this.zero}`;
        }
        if (count === 1) {
            return `${count} ${this.one}`;
        }
        if (count < 5) {
            return `${count} ${this.twoToFour}`;
        }
        return `${count} ${this.fiveAndMore}`;
    }
}
