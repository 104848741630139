import React from "react";
import MUICheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
const Item = ({ id, name, level, isChecked, isPartiallyChecked, nodeNamePreparer, onChange, }) => {
    const handleOnClick = (event) => {
        // onChange(id, !isChecked);
    };
    return (React.createElement(FormControlLabel, { control: React.createElement(MUICheckbox, { checked: isChecked, indeterminate: !isChecked && isPartiallyChecked, onClick: handleOnClick }), label: nodeNamePreparer ? nodeNamePreparer(name, level) : name }));
};
export default Item;
