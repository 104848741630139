import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlayedIcon from '@material-ui/icons/OndemandVideo';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';

const useStyles = makeStyles((theme) => styles(theme));

const MediaCountCard = (props) => {
    const {
        history,
        intl,
        queryVariables,
        // defaultFilter,
        queryDataPath,
        numberOfRows,
        query,
        data,
        loadData,
        refetch,
    } = props;

    const classes = useStyles();

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'campaigns.mediaCountCard.table.ID' }),
                    accessor: 'media.bid',
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.mediaCountCard.table.name' }),
                    accessor: "media.name",
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.mediaCountCard.table.count' }),
                    accessor: 'playCount',
                }
            ]
        )
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.mediaCountCard.title' })}
            icon={<PlayedIcon/>}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <QueryTableRefresh
                        data={data}
                        loadData={loadData}
                        query={query}
                        queryVariables={queryVariables}
                        // permanentFilter={defaultFilter}
                        queryDataPath={queryDataPath}
                        numberOfRows={numberOfRows}
                        columns={getColumnsDetail()}
                        togglePagination={false}
                    />
                </Grid>
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(MediaCountCard);
