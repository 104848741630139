import React, { useState, useEffect } from 'react';
import IconCard from 'components-lib/IconCard/IconCard';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { injectIntl } from 'react-intl';
import ALL_GROUPS from 'queries/GroupsQueries/allGroups'
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/react-hooks';
import Input from "@material-ui/core/Input";

const GroupData = (props) => {
    const { callbackGroups } = props;

    const { data: groupsData } = useQuery(ALL_GROUPS)
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        let groupsSelectItems = pathOr([], "userGroupsTable.items", groupsData).map(
            (group) => {
                return {
                    value: pathOr("", "id", group),
                    label: `${pathOr("", "name", group)}`,
                };
            }
        );
        setGroups(groupsSelectItems);
    }, [groupsData]);

    return (
        <IconCard title="Zaradenie do skupín" icon={<GroupAddIcon />}>
            <AutocompleteSelect
                style={{ marginBottom: "15px" }}
                placeholder="Vyberte skupinu..."
                label="Skupina"
                isMulti
                options={groups}
                onChange={(e) => callbackGroups(e)}
                input={<Input />}
            />

        </IconCard>
    )
};

export default injectIntl(GroupData);