import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StoreIcon from '@material-ui/icons/Store';
import Checkbox from '@material-ui/core/Checkbox';
import IconCard from "components-lib/IconCard/IconCard";
import Modal from "components-lib/Modal/Modal";
import { storeDetail, campaignStoreDelete } from 'configFiles/roles';
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js";
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import { generatePath } from 'react-router-dom';

const useStyles = makeStyles((theme) => styles(theme));

const StoresCard = (props) => {
    const {
        history,
        intl,
        queryVariables,
        modalQueryVariables,
        // defaultFilter,
        queryDataPath,
        numberOfRows,
        query,
        data,
        dataModal,
        loadData,
        modalLoadData,
        refetch,
        companyId,
        queryDataPathStoresByCompany,
        onAddStores
    } = props;

    const [removeModalOpen, setRemoveModalOpen] = React.useState(false);
    const [addStoresModalOpen, setAddStoresModalOpen] = React.useState(false);
    const [storesModalCheckedStores, setStoresModalCheckedStores] = React.useState([]);
    const [deleteStoreId, setDeleteStoreId] = React.useState(0);
    const [deleteStoreModalData, setDeleteStoreModalData] = React.useState({});
    // const [locationChange, setLoacationChange] = React.useState(location); 

    React.useEffect(() => {
        setStoresModalCheckedStores(data.storesTableByCampaign.items.map(store => store.id));
    }, [data]);

    const classes = useStyles();

    const onStoreDetail = (id) => {
        history.push(`/admin${generatePath(paths.stores.detail, { storeId: id })}`);
    }

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.address' }),
                    accessor: 'address',
                    sortKey: 'address',
                    filterKey: 'address',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.zones' }),
                    accessor: "zones",
                    sortKey: "zones",
                    filterKey: "zones",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                (storeDetail || campaignStoreDelete) ?
                    {
                        Header: intl.formatMessage({ id: 'campaigns.storesCard.table.actions' }),
                        accessor: (rowData) =>
                        <Grid container spacing={1}>
                            {(storeDetail) ?
                                <Button
                                    round
                                    // className={classes.tenantButtons}
                                    size="sm"
                                    color="info"
                                    onClick={() => onStoreDetail(rowData.id)}
                                >
                                    {intl.formatMessage({ id: 'campaigns.storesCard.table.actions.detail' })}
                                </Button>
                                :
                                <></>
                            }
                
                            {(campaignStoreDelete) ?
                                <Button
                                    round
                                    // className={classes.tenantButtons}
                                    size="sm"
                                    color="danger"
                                    onClick={() => {setDeleteStoreModalData(rowData);setDeleteStoreId(rowData.id); setRemoveModalOpen(true)}}
                                >
                                    {intl.formatMessage({ id: 'campaigns.storesCard.table.actions.remove' })}
                                </Button>
                                :
                                <></>
                            }
                        </Grid>
                    } : { Header: " " },
            ]
        )
    }

    const storesModalCheckboxCheck = (e, storeId) => {
        let storeList = storesModalCheckedStores;

        if(e.target.checked){
            storeList.push(storeId);
        } 
        else{
            storeList = storeList.filter((e) => e !== storeId);
        }

        setStoresModalCheckedStores(storeList);
    }

    const shortenAddress = (address) => {
        if(!address) return "";

        if(address.length < 20) return address;
        
        return address.substring(0, 17) + "...";
    }

    const getColumnsStoresModal = () => {
        return (
            [
                {
                    Header: " ",
                    accessor: (rowData) => 
                        <>
                            {
                                (!data.storesTableByCampaign.items.some(store => store.id == rowData.id)) ?
                                    <Checkbox 
                                        color="primary" 
                                        style={{float: 'right'}}
                                        onChange={(e) => storesModalCheckboxCheck(e, rowData.id)}
                                    />
                                :
                                    <Checkbox 
                                        color="primary" 
                                        style={{float: 'right'}}
                                        onChange={(e) => storesModalCheckboxCheck(e, rowData.id)}
                                        defaultChecked
                                    />
                            }
                        </>
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.storesCard.table.address' }),
                    accessor: (rowData) => shortenAddress(rowData.address),
                    sortKey: 'address',
                    filterKey: 'address',
                    filterOperator: 'contains'
                }
            ]
        )
    }

    const removeStoreFromCampaign = () => {
        const storeIds = storesModalCheckedStores.filter((store) => store !== deleteStoreId);

        if(storeIds.length > 0)
            onAddStores(storeIds);
    }

    const renderRemoveModalButtons = () => {
        return (
            <>
                <Button
                    onClick={() => {removeStoreFromCampaign(); setRemoveModalOpen(false)}}
                    color="warning"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "campaigns.removeModal.remove" })}
                </Button>
                <Button
                    onClick={() => setRemoveModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "campaigns.removeModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderStoreButtons = () => {
        return (
            <>
                <hr />
                <Button
                    onClick={() => setAddStoresModalOpen(true)}
                    color="info"
                    size="sm"
                    round
                    className={classes.floatRight}
                >
                    {intl.formatMessage({ id: 'campaigns.storesCard.table.addStore' })}
                </Button>
            </>
        )
    }

    const addStoresToCampaign = () => {
        const storeIds = storesModalCheckedStores;
        if(storeIds.length > 0)
            onAddStores(storeIds);
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.storesCard.title' })}
            icon={<StoreIcon/>}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <QueryTableRefresh
                        data={data}
                        loadData={loadData}
                        query={query}
                        // queryVariables={queryVariables}
                        // permanentFilter={defaultFilter}
                        queryDataPath={queryDataPath}
                        numberOfRows={numberOfRows}
                        columns={getColumnsDetail()}
                        toggle={false}
                    />
                </Grid>
            </Grid>
            {renderStoreButtons()}
        </IconCard>

        <Modal
            title={intl.formatMessage({ id: "campaigns.stores.removeModal.title" })}
            open={removeModalOpen}
            onClose={() => setRemoveModalOpen(false)}
            actions={renderRemoveModalButtons()}
        >
            {`${intl.formatMessage({ id: "campaigns.stores.removeModal.text" })} ${deleteStoreModalData.bid} - ${deleteStoreModalData.name}`}
        </Modal>


        <Modal
            title={intl.formatMessage({ id: "campaigns.stores.addModal.title" })}
            open={addStoresModalOpen}
            onClose={() => {
                history.replace(window.location.pathname);
                setAddStoresModalOpen(false);
            }}
            // actions={renderAddStoresModalButtons()}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <QueryTableRefresh
                        data={dataModal}
                        loadData={modalLoadData}
                        queryVariables={modalQueryVariables}
                        // permanentFilter={defaultFilter}
                        queryDataPath={queryDataPathStoresByCompany}
                        numberOfRows={numberOfRows}
                        columns={getColumnsStoresModal()}
                        // search={locationChange}
                    />
                </Grid>
            </Grid>
            <>
                <hr/>
                <Button
                    onClick={() => {
                        history.replace(window.location.pathname);
                        setAddStoresModalOpen(false);
                        addStoresToCampaign();
                    }}
                    color="info"
                    size="sm"
                    round
                    className={classes.floatRight}
                >
                    {intl.formatMessage({ id: 'campaigns.storesCard.table.addStore.save' })}
                </Button>
            </>
        </Modal>
    </>
};

export default injectIntl(StoresCard);
