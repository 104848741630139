import React, { useCallback } from 'react';
// import { Grid } from '@material-ui/core';
// import { useState } from 'react';
// import { useQuery } from 'react-apollo';
// import { Formik } from 'formik';
// import { useEffect } from 'react';
// import { pathOr } from 'rambda';
// import FormikField from 'components-lib/FormikField/FormikField';
// import { validationSchemaStep3, validationSchemaStep3Internal } from "./validationSchema";
import { injectIntl } from 'react-intl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import CalendarCard from '../CampaignCreateCards/CalendarCard';
import {format, differenceInDays } from 'date-fns';
// import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
// import Form from "components-lib/CampaignWidget/component/form/Form";
// import HoursCard from "components-lib/HoursCard/HoursCard";
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import FormHelperText from "@material-ui/core/FormHelperText";
// import colors from "common/styles/colors";
// import { commercialCampaignCreate, internalCampaignCreate } from 'configFiles/roles';

const useStyles = makeStyles((theme) => styles(theme));

const Step3 = ({ intl, callback, callbackCriteria, callbackValid, initialData, companyId, times, callbackTimes, criteria, axios, campaignType }) => {

  const datesToRanges = (dates) => {
    dates = dates.sort((a, b) => a.getTime() - b.getTime());
    const ranges = [];
    
    for (let i = 0; i < dates.length; i++) {
      const current = dates[i];
    
      if (i > 0) {
        const previous = dates[i - 1];
        const range = ranges[ranges.length - 1];
    
        if (differenceInDays(current, previous) === 1) {
          range.to = current;
          continue;
        }
      }
      
      ranges.push({ from: current, to: current });
    }
  
    const finalRanges = ranges.map((e) => {
        return {
            from: format(new Date(e.from), 'yyyy-MM-dd'),
            to: format(new Date(e.to), 'yyyy-MM-dd')
        }
    })

    return finalRanges;
  }

  const onActiveTimesUpdate = (dates) => {
    callback(datesToRanges(dates));
  }

  return (
    <>
      <CalendarCard
        initialData={initialData}
        onActiveTimesUpdate={onActiveTimesUpdate}
      />
    </>
  );
}

export default injectIntl(Step3);