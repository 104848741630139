import gql from 'graphql-tag';

const ALL_DISPLAY_GROUPS = gql`
query displayGroupsTable (
  $offset: Int
  $limit: Int
  $sort: [DisplayGroupsTableSortInput!]
  $filter: [DisplayGroupsTableFilterInput]
) {
  displayGroupsTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      name
      description
      mediaId
      media
      company
      companyId
    }
  }
}
`;

export default ALL_DISPLAY_GROUPS;