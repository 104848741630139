import Tree from '../tree/Tree';
import Node from '../tree/Node';
;
const isINode = (object) => {
    return object.id !== undefined
        && object.name !== undefined
        && (object.children === undefined
            || (Array.isArray(object.children)
                && object.children.map(child => isINode(child)).every(value => value === true)));
};
export default class Api {
    constructor(axios, campaign) {
        this.campaign = campaign;
        this.axios = axios;
    }
    async call(query, variables) {
        const response = await this.axios.post('', { query, variables });
        if (response.status !== 200) {
            throw new Error('Response status was: ' + response.status);
        }
        if (typeof response.data !== 'object') {
            throw new Error('Response data were empty');
        }
        return response.data.data || {};
    }
    prepareTree(nodes) {
        const tree = new Tree(new Node('*', 'Všetko', false, false, 0, nodes
            .filter(isINode)
            .map(node => this.prepareNode(node, null, 1))));
        return tree;
    }
    prepareNode(node, parent = null, level) {
        return new Node(node.id, node.name, false, false, level, node.children
            ? node.children.map(child => this.prepareNode(child, null, level + 1))
            : []);
    }
}
