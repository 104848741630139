
import gql from 'graphql-tag';

const DEVICE_TERMINAL = gql`
query deviceTerminal($id:ID!){
	deviceTerminal(id: $id){
  	type
    url
    expiresAt
  }
}
`;

export default DEVICE_TERMINAL;

