import React from 'react';
import { userIsLogged } from '../../helpers/helpers';
import { Route, Redirect } from 'react-router-dom';
import paths from '../../paths';

const ProtectedRoute = (props) => {

    const isLogged = userIsLogged();

    return isLogged ? <Route exact {...props} /> : <Redirect to={`/auth${paths.auth.login}`} />
};

export default ProtectedRoute;