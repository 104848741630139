import React from 'react';
import { Grid } from '@material-ui/core';
import styles from "common/styles/widgets.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import { PasswordValidityEnum } from 'common/enums/PasswordValidityEnum';
import Lock from "@material-ui/icons/Lock";
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';
import Button from "components/CustomButtons/Button.js";
const useStyles = makeStyles((theme) => styles(theme))

const ChangePasswordCard = (props) => {

    const { intl } = props;
    const classes = useStyles();


    return (
        <IconCard title={intl.formatMessage({ id: 'profile.changePassword.title' })} icon={<Lock />}>
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        labelText={intl.formatMessage({ id: 'profile.changePassword.oldPassword' })}
                        id="oldPassword"
                        inputProps={{
                            type: "password",
                            onChange: event => {
                                props.setNewPasswordData({ ...props.newPasswordData, oldPassword: event.target.value });
                            },
                            value: props.newPasswordData.oldPassword ? props.newPasswordData.oldPassword : "",
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        labelText={intl.formatMessage({ id: 'profile.changePassword.newPassword' })}
                        id="password"
                        inputProps={{
                            type: "password",
                            onChange: event => {
                                props.setNewPasswordData({ ...props.newPasswordData, newPassword: event.target.value });
                            },
                            value: props.newPasswordData.newPassword ? props.newPasswordData.newPassword : "",
                            endAdornment:
                                [PasswordValidityEnum.INVALID, PasswordValidityEnum.EMPTY, PasswordValidityEnum.NOT_SAME].includes(props.newPasswordData.passwordValidity) ? (
                                    <InputAdornment position="end">
                                        <Close className={classes.danger} />
                                    </InputAdornment>
                                ) : (
                                        undefined
                                    )
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CustomInput
                        className={classes.inputMargin}
                        labelText={intl.formatMessage({ id: 'profile.changePassword.verifyPassword' })}
                        id="passwordVerification"
                        inputProps={{
                            type: "password",
                            onChange: event => {
                                props.setNewPasswordData({ ...props.newPasswordData, passwordVerification: event.target.value });
                            },
                            value: props.newPasswordData.passwordVerification ? props.newPasswordData.passwordVerification : "",
                            endAdornment:
                                [PasswordValidityEnum.NOT_SAME].includes(props.newPasswordData.passwordValidity) ? (
                                    <InputAdornment position="end">
                                        <Close className={classes.danger} />
                                    </InputAdornment>
                                ) : (
                                        undefined
                                    )
                        }}
                        formControlProps={{
                            fullWidth: true
                        }}
                    />
                </Grid>
                <Grid item sm={12}>
                    <br />
                    <Button
                        color="primary"
                        size="sm"
                        round
                        onClick={props.onChangePassword}
                    >
                        {intl.formatMessage({ id: 'profile.changePassword.change' })}
                    </Button>
                </Grid>
            </Grid>
        </IconCard>
    )
}

export default injectIntl(ChangePasswordCard);