import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EuroIcon from '@material-ui/icons/Euro';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import paths from 'paths';
import { generatePath } from "react-router-dom";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles((theme) => styles(theme));

const AccountStatusCard = (props) => {
  const { intl, balance, history, companyId, operationList } = props;
  const classes = useStyles();

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "companyDetail.accountStatus.title" })}
        icon={<EuroIcon />}
      >
        <Grid container spacing={3}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.accountStatus.status" })}
            <Box className={classes.floatRight}>
              {balance} C
            </Box>
          </Grid>
          {(operationList) &&
            <Grid item sm={12}>
              <hr />
              <Button
                className={classes.floatRight}
                color="info"
                size="sm"
                round
                onClick={() => history.push({
                  pathname: `/admin${generatePath(paths.operations.list)}`,
                  state: companyId
                })}
              >
                {intl.formatMessage({ id: "operationsList.table.title" })}
              </Button>
            </Grid>
          }
        </Grid>
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(AccountStatusCard));
