import gql from 'graphql-tag';

const STORES_BY_CRITERIA_OPTIONS = gql`
query campaignCriteriaOptions($input: CampaignCriteriaOptionsInput){
    campaignCriteriaOptions(input:$input){
        stores{
            id
            name
        }
    }
  }
`;

export default STORES_BY_CRITERIA_OPTIONS;
