const widgetStyle = (theme) => {
    return{
      iconAlign : {
        verticalAlign: "text-bottom"
      },
      floatRight: {
        float: "right"
      },
      tenantButtons: {
        marginRight:theme.spacing(3)
      },
      marginBottom: {
        marginBottom: theme.spacing(6)
      },
      tenantCardButtons: {
        position: "absolute",
        width: "100%",
        bottom: "0",
      },
      tenantCardButtonsMargin:{
        float: "right",
        marginRight: theme.spacing(2)
      },
      selectStyle: {
        margin: "0 10px 20px 10px",
        width: "300px"
      },
      warning: {
        color: "#EE9600"
      },
      warningIcon: {
        marginRight: "15px",
        verticalAlign: "middle",
        lineHeight: "normal"
      }
    }

}
export default widgetStyle
