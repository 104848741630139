import gql from 'graphql-tag';

const COMPANY_DETAIL = gql`
query company($id: ID!){
    company(id:$id){
        id,
        bid,
        name,
        ico,
        dic,
        icDPH,
        registrationCourt,
        address,
        postal,
        city,
        country,
        latitude,
        longtitude,
        bankName,
        bankIBAN,
        bankBIC,
        contactFirstName,
        contactLastName,
        contactPhone,
        contactEmail,
        createdAt,
        updatedAt,

    }
}
`;

export default COMPANY_DETAIL;