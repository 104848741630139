const pageHeaderStyles = (theme) => ({
    backIcon: {
        position: 'relative',
        left: '-5px'
    },
    title: {
        margin: '5px',
        fontSize: '32px',
        color: 'black'
    },
    pageHeaderWrapper: {
        marginBottom: theme.spacing(4)
    },
    subTitle: {
        margin: '5px',
        fontSize: '18px'
    }
});

export default pageHeaderStyles;
