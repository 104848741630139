export let colors = {
    main: '#00695c',
    buttons: '#00695c',
    selectedItemInSidebar: '#00695c',
    background: '#eceff1',
    sidebarHidden: "white",
    sidebar: 'white'
};

export const shadows = {
    icons: false,
};

export const rounded = {
    buttons: "30px",
};

export const defaultColors = {
    one: '#1565c0',
    two: '#263238',
    three: '#283593',
    four: '#2196f3',
    five: '#00695c'
}