import LoginPage from "views/Pages/LoginPage.js";
import SettingsIcon from '@material-ui/icons/Settings';
import paths from "paths";
import LogoutPage from "pages/Logout/LogoutPage";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsPage from "pages/SettingsPage/SettingsPage";
import DevicesListPage from "pages/Devices/DevicesListPage";
import DeviceDetailPage from "pages/Devices/DeviceDetailPage";
import CameraEnhance from '@material-ui/icons/CameraEnhance';
import ForgottenPassword from "./pages/ForgottenPassword/ForgottenPassword";
import RoleEnum from "common/enums/RoleEnum";
import UserListPage from "pages/Users/UserListPage";
import UserDetailPage from "pages/Users/UserDetailPage";
import ProfilePage from "pages/Profile/ProfilePage"
import UserNewPage from "pages/Users/UserNewPage";
import StoreListPage from 'pages/Stores/StoreListPage';
import StoreNewPage from 'pages/Stores/StoreNewPage';
import StoreDetailPage from 'pages/Stores/StoreDetailPage';
import ZoneDetailPage from 'pages/Zones/ZoneDetailPage';
import CampaignListPage from "pages/Campaigns/CampaignListPage";
import CampaignDetailPage from "pages/Campaigns/CampaignDetailPage";
import CampaignNewAxiosWrapperPage from "pages/Campaigns/CampaignNewAxiosWrapperPage";
import CompanyListPage from 'pages/Companies/CompanyListPage';
import CompanyDetailPage from 'pages/Companies/CompanyDetailPage';
import CompanyNewPage from 'pages/Companies/CompanyNewPage';
import MediaPage from "./pages/Media/MediaListPage";
import PlaylistListPage from "./pages/Playlists/PlaylistListPage";
import PlaylistDetailPage from "./pages/Playlists/PlaylistDetailPage";
import PlaylistNewPage from "./pages/Playlists/PlaylistNewPage";
import DisplayListPage from "./pages/Displays/DisplayListPage";
import ChangeDashboard from "./pages/ChangeDashboard/ChangeDashboard";
import GroupPage from "pages/Groups/GroupPage";
import DashboardPage from "pages/Dashboard/DashboardPage";

import StoreIcon from '@material-ui/icons/Store';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import BusinessIcon from '@material-ui/icons/Business';
import TheatersIcon from '@material-ui/icons/Theaters';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import CampaignQueueIcon from '@material-ui/icons/Queue';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import DashboardIcon from "@material-ui/icons/Dashboard";
import LiveTvIcon from '@material-ui/icons/LiveTv';

import {
  campaignList, campaignDetail, campaignCreate,
  companyList, companyDetail, companyCreate,
  deviceList, deviceDetail,
  storeList, storeCreate, storeDetail,
  displayList, playlistList, playlistDetail, playlistCreate,
  dashboardList, mediaList,
  userList, userDetail, userCreate, groupList
} from 'configFiles/roles'

const getRoutes = (intl) => ([
  {
    path: paths.changeDashboard,
    name: intl.formatMessage({ id: 'changeDashboard.menu' }),
    icon: DoubleArrowIcon,
    changeDashboard: true,
    component: ChangeDashboard,
    layout: '/admin'
  },
  (dashboardList) ?
    {
      path: paths.dashboard,
      name: intl.formatMessage({ id: 'routes.dashboard' }),
      hideInMenu: (!dashboardList) && true,
      icon: DashboardIcon,
      component: DashboardPage,
      //decorate: true,
      layout: '/admin'
    } : { hideInMenu: true },

  (deviceList) ?
    {
      path: paths.devices.list,
      name: intl.formatMessage({ id: 'routes.devices' }),
      icon: LiveTvIcon,
      component: DevicesListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (deviceDetail) ?
    {
      path: paths.devices.detail,
      name: intl.formatMessage({ id: 'routes.devices.detail' }),
      hideInMenu: true,
      icon: CameraEnhance,
      component: DeviceDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (storeList) ?
    {
      path: paths.stores.list,
      name: intl.formatMessage({ id: 'routes.stores' }),
      decorateSpecial: (!dashboardList && !deviceList) ? true : false,
      hideInMenu: (!storeList) && true,
      icon: StoreIcon,
      component: StoreListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (storeCreate) ?
    {
      path: paths.stores.new,
      name: intl.formatMessage({ id: 'routes.stores.new' }),
      hideInMenu: true,
      icon: StoreIcon,
      component: StoreNewPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (storeDetail) ?
    {
      path: paths.stores.detail,
      name: intl.formatMessage({ id: 'routes.stores.detail' }),
      hideInMenu: true,
      icon: StoreIcon,
      component: StoreDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (storeDetail) ?
    {
      path: paths.stores.zoneDetail,
      name: intl.formatMessage({ id: 'routes.stores.detail' }),
      hideInMenu: true,
      icon: StoreIcon,
      component: ZoneDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (companyList) ?
    {
      path: paths.companies.list,
      name: intl.formatMessage({ id: 'routes.companies' }),
      icon: BusinessIcon,
      component: CompanyListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (companyDetail) ?
    {
      path: paths.companies.detail,
      name: intl.formatMessage({ id: 'routes.companies.detail' }),
      hideInMenu: true,
      icon: BusinessIcon,
      component: CompanyDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (companyCreate) ?
    {
      path: paths.companies.new,
      name: intl.formatMessage({ id: 'routes.companies.new' }),
      hideInMenu: true,
      icon: BusinessIcon,
      component: CompanyNewPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (displayList) ?
    {
      path: paths.displays.list,
      name: intl.formatMessage({ id: 'routes.displays' }),
      icon: QueuePlayNextIcon,
      component: DisplayListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  // (playlistList) ?
  //   {
  //     path: paths.playlists.list,
  //     name: intl.formatMessage({ id: 'routes.playlists' }),
  //     icon: PlaylistPlayIcon,
  //     component: PlaylistListPage,
  //     layout: '/admin'
  //   } : { hideInMenu: true },
  // (playlistDetail) ?
  //   {
  //     path: paths.playlists.detail,
  //     name: intl.formatMessage({ id: 'routes.playlists.detail' }),
  //     hideInMenu: true,
  //     icon: PlaylistPlayIcon,
  //     component: PlaylistDetailPage,
  //     layout: '/admin'
  //   } : { hideInMenu: true },
  // (playlistCreate) ?
  //   {
  //     path: paths.playlists.new,
  //     name: intl.formatMessage({ id: 'routes.playlists.new' }),
  //     hideInMenu: true,
  //     icon: PlaylistPlayIcon,
  //     component: PlaylistNewPage,
  //     layout: '/admin'
  //   } : { hideInMenu: true },

  (campaignList) ?
    {
      path: paths.campaigns.list,
      name: intl.formatMessage({ id: 'routes.campaigns' }),
      icon: CampaignQueueIcon,
      component: CampaignListPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (campaignDetail) ?
    {
      path: paths.campaigns.detail,
      name: intl.formatMessage({ id: 'routes.campaigns.detail' }),
      icon: CampaignQueueIcon,
      component: CampaignDetailPage,
      hideInMenu: true,
      layout: '/admin'
    } : { hideInMenu: true },

  (campaignCreate) ?
    {
      path: paths.campaigns.new,
      name: intl.formatMessage({ id: 'routes.campaigns.new' }),
      icon: CampaignQueueIcon,
      component: CampaignNewAxiosWrapperPage,
      hideInMenu: true,
      layout: '/admin'
    } : { hideInMenu: true },

  (mediaList) ?
    {
      path: paths.media,
      name: intl.formatMessage({ id: 'routes.media' }),
      icon: TheatersIcon,
      component: MediaPage,
      layout: '/admin'
    } : { hideInMenu: true },

  (userList) ?
    {
      path: paths.users.list,
      name: intl.formatMessage({ id: 'routes.users' }),
      hideInMenu: (!userList) && true,
      icon: AssignmentIndIcon,
      component: UserListPage,
      layout: '/admin'
    } : { hideInMenu: true },
  (userCreate) ?
    {
      path: paths.users.new,
      name: intl.formatMessage({ id: 'routes.users.new' }),
      component: UserNewPage,
      hideInMenu: true,
      layout: '/admin'
    } : { hideInMenu: true },
  (userDetail) ?
    {
      path: paths.users.detail,
      name: intl.formatMessage({ id: 'routes.users.detail' }),
      hideInMenu: true,
      component: UserDetailPage,
      layout: '/admin'
    } : { hideInMenu: true },

  {
    path: paths.auth.login,
    name: intl.formatMessage({ id: 'routes.login' }),
    hideInMenu: true,
    component: LoginPage,
    layout: '/auth'
  },
  {
    path: paths.profile,
    name: intl.formatMessage({ id: 'routes.profile' }),
    icon: AccountCircleIcon,
    decorate: true,
    component: ProfilePage,
    layout: '/admin'
  },

  (groupList) ?
    {
      path: paths.groups.list,
      name: intl.formatMessage({ id: 'routes.groups' }),
      hideInMenu: (!groupList) && true,
      component: GroupPage,
      icon: GroupAddIcon,
      layout: '/admin'
    } : { hideInMenu: true },
  {
    path: paths.settings,
    name: intl.formatMessage({ id: 'routes.settings' }),
    component: SettingsPage,
    hideInMenu: true,
    icon: SettingsIcon,
    layout: '/admin'
  },
  {
    path: paths.auth.logout,
    name: intl.formatMessage({ id: 'routes.logout' }),
    component: LogoutPage,
    icon: ExitToAppIcon,
    layout: '/admin'
  },
  {
    path: paths.auth.forgottenPassword,
    name: intl.formatMessage({ id: 'routes.forgottenPassword' }),
    component: ForgottenPassword,
    hideInMenu: true,
    layout: '/auth'
  }
]);

export default getRoutes;
