import React from 'react';
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardTitle from "components-lib/CardTitle/CardTitle";
import Card from "components/Card/Card.js";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/components/cardIconStyle.js";
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(styles);

const IconCard = (props) => {
    const { children, title = '', icon = null, color = 'primary', height, className, actions = null, titleStyles = {}, isCustom = false } = props;

    const cardIconStyles = useStyles();

    const cardIconClasses = classNames({
        [cardIconStyles.cardIcon]: true,
        [cardIconStyles[color + "CardHeader"]]: color,
        [className]: className !== undefined
    });

    return (
        <Card style={{ height: height }} className={className}>
            <CardHeader color={color} icon>
                {icon && !isCustom && (
                    <CardIcon color={color}>
                        {icon}
                    </CardIcon>
                )}
                {icon && isCustom && (
                    <div className={cardIconClasses}>
                        {icon}
                    </div>
                )}
                <CardTitle title={title} style={titleStyles} />
            </CardHeader>
            <CardBody>
                {children}
                {(actions) && <hr style={{ marginBottom: "-20px" }} />}
            </CardBody>
            {actions &&
                <CardActions>
                    {(actions) && <hr style={{ marginBottom: "-20px" }} />}
                    {actions}
                </CardActions>
            }
        </Card>
    );
};

export default IconCard;