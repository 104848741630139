import IsCheckedConsolidator from './IsCheckedConsolidator';
export default class TreeCheckedSetter {
    static set(tree, ids, isChecked) {
        const nodes = tree.findNodes(ids);
        for (const node of nodes) {
            node.setIsChecked(isChecked);
            const allDescendants = tree.findNodes(node.getDescendants().map(node => node.getId()));
            for (const node of allDescendants) {
                node.setIsChecked(isChecked);
            }
        }
        IsCheckedConsolidator.consolidateTree(tree);
    }
}
