import { useEffect, useState } from 'react';
import ZoneApi from '../../service/api/Store';
import useTree from './useTree';
const useStore = (axios, campaign, value) => {
    const [treeData, setTreeData] = useState(null);
    const [tree, checkedIds] = useTree(treeData, value.storeIds);
    useEffect(() => {
        if (!value.locationIds.length || !value.segmentIds.length || !value.companyIds.length) {
            setTreeData(null);
            return;
        }
        (async () => {
            try {
                const zoneApi = new ZoneApi(axios, campaign);
                setTreeData((await zoneApi.get({
                    locationIds: value.locationIds.filter(location => location !== '*'),
                    segmentIds: value.segmentIds.filter(segment => segment !== '*'),
                    companyIds: value.companyIds.filter(company => company !== '*')
                })).toObject());
            }
            catch (exception) {
                setTreeData(null);
                console.warn('Failed to retrieve tree data for filter. Exception: ' + exception);
            }
        })();
    }, [axios, campaign, value.locationIds, value.segmentIds, value.companyIds]);
    return [tree, checkedIds];
};
export default useStore;
