import gql from 'graphql-tag';

const PLAYLIST_UPDATE = gql`
mutation playlistUpdate ($id: ID!, $input: PlaylistUpdateInput!) {
  playlistUpdate (id: $id, input: $input) {
      bid
      name
  }
}
`;

export default PLAYLIST_UPDATE;