import React, { Fragment, useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Body from './body/Body';
;
const Tree = ({ label, placeholder, tree, nodeNamePreparer, selectedIds, onChange }) => {
    const [inputAnchorElement, setInputAnchorElement] = useState(null);
    const inputRef = useRef();
    const handleInputClick = () => {
        setInputAnchorElement(inputRef.current);
    };
    const handlePopoverClose = () => {
        setInputAnchorElement(null);
    };
    if (tree === null || tree.root.children.length === 0) {
        return React.createElement(TextField, { label: label, disabled: true, fullWidth: true });
    }
    return React.createElement(Fragment, null,
        React.createElement(TextField, { label: label, value: placeholder, focused: false, variant: "filled", fullWidth: true, inputProps: {
                readOnly: true
            }, inputRef: inputRef, onClick: handleInputClick }),
        React.createElement(Popover, { open: inputAnchorElement !== null, anchorEl: inputAnchorElement, onClose: handlePopoverClose, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            } },
            React.createElement(Box, { style: {
                    width: inputRef.current !== undefined && inputRef.current !== null
                        ? `${inputRef.current.offsetWidth}px`
                        : '500px'
                } },
                React.createElement(Body, { tree: tree, nodeNamePreparer: nodeNamePreparer, selectedIds: selectedIds, onChange: onChange }))));
};
export default Tree;
