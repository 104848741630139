
import gql from 'graphql-tag';

const RESTART_DEVICE = gql`
    mutation deviceRestart($id: ID!){
        deviceRestart(id:$id)
    }
`;
// {
//     "id": 888
//   }

export default RESTART_DEVICE;

