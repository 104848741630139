import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import StoreIcon from '@material-ui/icons/Store';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const BasicInfoCard = (props) => {
    const { intl, formikProps, storeUpdate, storeCreate, storeDetail, multipleUpdate } = props;
    const classes = useStyles();
 
    const renderContent = () => {
        if (!storeUpdate) {
            return (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.name" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.name) ? formikProps.values.name : "-"}
                        </Box>
                    </Grid>
                </Grid>
            )
        }

        return (
            <>
                {(!multipleUpdate) &&
                    < FormikField
                        name="name"
                        labelText={intl.formatMessage({ id: "storeDetail.storeCard.name" })}
                        formikProps={formikProps}
                        highlightChange
                    />
                }
            </>
        )
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "storeDetail.title" })}
                icon={<StoreIcon />}>
                {renderContent()}
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(BasicInfoCard));
