import React, { Fragment, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Item from '../item/Item';
import useFullText from '../../../../../hook/form/useFullText';
const useStyles = makeStyles(theme => ({
    box: {
        maxHeight: '150px',
        overflowY: 'scroll',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1)
    }
}));
;
const FullText = ({ tree, onChange, nodeNamePreparer, onIsActiveChanged }) => {
    const classes = useStyles();
    const [value, nodes, setValue] = useFullText(tree);
    const handleInputOnChange = (event) => {
        setValue(event.target.value);
    };
    useEffect(() => {
        onIsActiveChanged(nodes.length > 0);
    }, [nodes, onIsActiveChanged]);
    return React.createElement(Fragment, null,
        React.createElement(TextField, { label: "vyh\u013Eada\u0165", fullWidth: true, value: value, onChange: handleInputOnChange }),
        nodes.length > 0 && (React.createElement(Fragment, null,
            React.createElement(Box, { className: classes.box }, nodes.map(node => (React.createElement(Box, { key: node.id },
                React.createElement(Item, Object.assign({}, node, { nodeNamePreparer: nodeNamePreparer, onChange: onChange })))))))));
};
export default FullText;
