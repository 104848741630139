import gql from 'graphql-tag';

const DEVICES_QUERY_ENABLED = gql`
query devicesTable($offset: Int, $limit: Int, $sort: [DevicesTableSortInput!], $filter: [DevicesTableFilterInput!]){
  devicesTable(offset: $offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount,
      items{
          id
          bid
          store
          company
          heartbeat
          status
          isAssigned
          balenaUuid
          clientVersion
          deviceType
          playerStatus
      }
  }
}
`;

export default DEVICES_QUERY_ENABLED;