import gql from 'graphql-tag';

const ALL_ZONES = gql`
query zonesTable($offset: Int, $limit: Int, $sort: [ZonesTableSortInput!], $filter: [ZonesTableFilterInput]){
  zonesTable(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id
      bid
      name
      area
      visitors
      rank
      createdAt
      updatedAt
      deletedAt
      storeId
      store
      status
      deviceId
      device
      createdById
      createdBy 
      deviceStatus
      playerStatus    
    }
  }
}
`;

export default ALL_ZONES;