import Api from '../api/Api';
export default class Company extends Api {
    async get(value) {
        const response = await this.call(`
            query CampaignCrtieriaOptions($input: CampaignCriteriaOptionsInput!) {
                campaignCriteriaOptions(input: $input) {
                    segments {
                        id
                        name
                        groups
                    }
                    companies {
                        id
                        name
                        groups
                    }
                }
            }
        `, {
            input: {
                campaign: this.campaign,
                criteria: value
            }
        });
        if (!Array.isArray(response.campaignCriteriaOptions.companies)) {
            throw new Error('Response data does not contain companies key');
        }
        const segments = response.campaignCriteriaOptions.segments;
        const options = response.campaignCriteriaOptions.companies;
        const groups = options.flatMap(c => c.groups)
            .filter((v, i, s) => s.indexOf(v) === i && segments.find(s => s.name === v))
            .map((v, i) => ({ id: (i + 1).toString(), name: v }));
        const nodes = [];
        for (const group of groups) {
            nodes.push({
                id: `_${group.id}`,
                name: group.name,
                children: options
                    .filter(company => company.groups.includes(group.name))
                    .map(company => ({
                    id: company.id,
                    name: company.name,
                    children: []
                }))
            });
        }
        return this.prepareTree(nodes);
    }
}
