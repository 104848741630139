;
export default class Node {
    constructor(id, name, isChecked = false, isPartiallyChecked = false, level = 0, children = [], parent = null) {
        this.id = id;
        this.name = name;
        this.isChecked = isChecked;
        this.isPartiallyChecked = isPartiallyChecked;
        this.level = level;
        this.children = children;
        this.parent = parent;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getIsChecked() {
        return this.isChecked;
    }
    getIsPartiallyChecked() {
        return this.isPartiallyChecked;
    }
    getLevel() {
        return this.level;
    }
    setIsChecked(isChecked) {
        this.isChecked = isChecked;
    }
    clearIsPartiallyChecked() {
        this.isPartiallyChecked = false;
        for (const child of this.children) {
            child.clearIsPartiallyChecked();
        }
    }
    setIsPartiallyChecked() {
        this.isPartiallyChecked = true;
        if (this.parent === null) {
            return;
        }
        this.parent.setIsPartiallyChecked();
    }
    getChildren() {
        return this.children;
    }
    getParent() {
        return this.parent;
    }
    setParent(parent) {
        this.parent = parent;
    }
    getSiblings() {
        if (this.parent === null) {
            return [];
        }
        const siblings = [];
        for (const child of this.parent.getChildren()) {
            if (child !== this) {
                siblings.push(child);
            }
        }
        return siblings;
    }
    getDescendants(node = this, allDescendants = []) {
        for (const nodeChild of node.getChildren()) {
            allDescendants.push(nodeChild);
            this.getDescendants(nodeChild, allDescendants);
        }
        return allDescendants;
    }
    toObject(withChildren = true, level = 0) {
        return {
            id: this.id,
            name: this.name,
            isChecked: this.isChecked,
            isPartiallyChecked: this.isPartiallyChecked,
            level: this.level,
            children: withChildren ? this.children.map(child => child.toObject()) : [],
        };
    }
    static fromObject(node) {
        return new Node(node.id, node.name, node.isChecked, node.isPartiallyChecked, node.level, node.children.map(child => Node.fromObject(child)));
    }
}
