import gql from 'graphql-tag';

const COMPANY_BY_USER = (id) => gql`
query companiesTableByMember($offset: Int, $limit: Int, $sort: [CompaniesTableSortInput!], $filter: [CompaniesTableFilterInput]){
    companiesTableByMember(userId: ${id}, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
        totalCount,
        items{
        id
        bid
        name   
        stores
      }
    }
  }
`;

export default COMPANY_BY_USER;