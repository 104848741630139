import gql from 'graphql-tag';

const ALL_SEGMENTS = gql`
    query segments{
        segments{
            items{
                id
                name
            }
        }
    }
`;

export default ALL_SEGMENTS;