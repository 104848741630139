import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import { ALL_CAMPAIGNS } from "queries/CampaignsQueries/allCampaigns";
import paths from "paths";
import PageHeader from "components-lib/PageHeader/PageHeader";
import IconCard from "components-lib/IconCard/IconCard";
import QueueIcon from '@material-ui/icons/Queue';
import CampaignTable from "./CampaignTable";
import { campaignCreate } from 'configFiles/roles';

const CampaignListPage = (props) => {

    const { intl, history } = props

    const [queryVariables, setQueryVariables] = useState(null);

    const getActions = () => {
        if (campaignCreate)
          return [
            {
              title: intl.formatMessage({ id: "campaigns.button.new" }),
              onClick: () => history.push(`/admin${generatePath(paths.campaigns.new)}`)
            },
        ];
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "campaigns.title" })}
                withBackButton={false}
                actions={getActions()}
            />
            <IconCard
                icon={<QueueIcon/>}
                title={intl.formatMessage({ id: "campaigns.header" })}
            >
                <CampaignTable
                    query={ALL_CAMPAIGNS}
                    queryVariables={queryVariables}
                    history={history}
                />
            </IconCard>
        </>
    )
}

export default injectIntl(withRouter(CampaignListPage))
