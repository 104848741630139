import React, { useState, useEffect } from 'react';
import IconCard from 'components-lib/IconCard/IconCard';
import Grid from "@material-ui/core/Grid";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { injectIntl } from 'react-intl';
import FormikField from "components-lib/FormikField/FormikField";
import RoleEnum from "common/enums/RoleEnum";
import { isInRoles } from "helpers/helpers";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty } from "rambda";
import Button from "components/CustomButtons/Button.js";
import USER_UPDATE from "queries/UsersQueries/userUpdate";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import ALL_GROUPS from 'queries/GroupsQueries/allGroups'
import { pathOr } from 'rambda';
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import Input from "@material-ui/core/Input";

const useStyles = makeStyles((theme) => styles(theme));

const ContactData = (props) => {
    const { intl, formikProps, created, canEditUser, disabled = false, id, refetchUserData, editableFields, userUpdate } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const { data: groupsData } = useQuery(ALL_GROUPS)
    const [groups, setGroups] = useState([]);
    const [groupsId, setGroupsId] = useState([])

    const [updateUser] = useMutation(USER_UPDATE);

    const handleUserUpdate = (values) => {
        let setGroupIds = (groupsId?.length > 0) ? (groupsId?.map(group => group.value)) : [];
        console.log(setGroupIds)
        updateUser({
            variables: {
                id: id,
                input: {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    gender: values.gender,
                    //email: values.email,
                    phone: values.phone,
                    groupIds: setGroupIds
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'userDetail.userUpdate.success' }), { variant: 'success' });
            refetchUserData();
        }).catch((err) => {
            console.log('[error]', err);
        })
    };

    useEffect(() => {
        let groupsSelectItems = pathOr([], "userGroupsTable.items", groupsData).map(
            (group) => {
                return {
                    value: pathOr("", "id", group),
                    label: `${pathOr("", "name", group)}`,
                };
            }
        );
        setGroups(groupsSelectItems);
    }, [groupsData]);

    useEffect(() => {
        setGroupsId(formikProps.values.groups?.map(item =>
        (
            {
                value: item.id,
                label: item.name
            }

        )))
    }, [formikProps.values.groups]);
    
    const renderContent = () => {
        if ((isInRoles([RoleEnum.MAINTAINER]) && isInRoles([RoleEnum.ADMIN]) && !created) || (editableFields === false)) {
            return (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
                        <Box className={classes.floatRight}>
                            {formikProps.values.firstName} {formikProps.values.lastName}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'createUser.contact.email' })}
                        <Box className={classes.floatRight}>
                            {formikProps.values.email}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.phone' })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.phone) ? formikProps.values.phone : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.groups' })}
                        <Box className={classes.floatRight}>
                            {((formikProps.values.groups)?.length > 0)
                                ? (formikProps.values.groups)?.map((item, index) => (
                                    <>{((formikProps.values.groups).length !== index + 1) ? (item.name + ", ") : (item.name)}</>
                                ))
                                : "-"
                            }
                        </Box>
                    </Grid>
                </Grid>
            )
        }
        return (
            (userUpdate) ?
                <>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormikField
                                name="firstName"
                                labelText={intl.formatMessage({ id: 'createUser.contact.firstName' })}
                                formikProps={formikProps}
                            //disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikField
                                name="lastName"
                                labelText={intl.formatMessage({ id: 'createUser.contact.lastName' })}
                                formikProps={formikProps}
                            //disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                    <FormikField
                        disabled={disabled}
                        name="email"
                        labelText={intl.formatMessage({ id: 'createUser.contact.email' })}
                        formikProps={formikProps}
                    />
                    <FormikField
                        // disabled={disabled}
                        name="phone"
                        labelText={intl.formatMessage({ id: 'createUser.contact.phone' })}
                        formikProps={formikProps}
                    />
                    <Grid item sm={12}>
                        < br />
                    </Grid>
                    <AutocompleteSelect
                        style={{ marginBottom: "15px" }}
                        placeholder="Vyberte skupinu..."
                        label="Skupina"
                        isMulti
                        options={groups}
                        value={groupsId}
                        onChange={(e) => setGroupsId(e)}
                        input={<Input />}
                    />
                    {!created &&
                        <Grid item sm={12}>
                            <hr style={{ margin: "10px" }} />
                            <Button
                                color="primary"
                                size="sm"
                                round
                                onClick={(e) =>
                                    isEmpty(formikProps.errors) &&
                                    handleUserUpdate(formikProps.values)
                                }
                            >
                                {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                            </Button>
                            <Button
                                color="info"
                                size="sm"
                                round
                                onClick={() => formikProps.resetForm()}
                            >
                                {intl.formatMessage({ id: 'settings.cancel' })}
                            </Button>
                        </Grid>
                    }
                </>
                : <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "orderDetail.customerCard.name" })}
                        <Box className={classes.floatRight}>
                            {formikProps.values.firstName} {formikProps.values.lastName}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'createUser.contact.email' })}
                        <Box className={classes.floatRight}>
                            {formikProps.values.email}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.phone' })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.phone) ? formikProps.values.phone : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: 'campaigns.companyCard.groups' })}
                        <Box className={classes.floatRight}>
                            {((formikProps.values.groups)?.length > 0)
                                ? (formikProps.values.groups)?.map((item, index) => (
                                    <>{((formikProps.values.groups).length !== index + 1) ? (item.name + ", ") : (item.name)}</>
                                ))
                                : "-"
                            }
                        </Box>
                    </Grid>
                </Grid>
        )
    }

    return (
        <IconCard
            title={(canEditUser)
                ? intl.formatMessage({ id: 'createUser.contact.title' })
                : intl.formatMessage({ id: 'userDetail.contactInfo.create' })
            }
            icon={<AssignmentIndIcon />}
        >
            {renderContent()}
        </IconCard>
    )

};

export default injectIntl(ContactData);