import gql from 'graphql-tag';

const MEDIA_APPROVE = gql`
    mutation mediaApprove(
        $id: ID!
        $description: String
    ) {
      mediaApprove(id: $id, description: $description) {
        id
      }
    }
`;

export default MEDIA_APPROVE;