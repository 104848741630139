import gql from 'graphql-tag';

const DEVICE_ENABLE = gql`
    mutation deviceEnable($id: ID!){
        deviceEnable(id:$id){
            id
        }
    }
`;

export default DEVICE_ENABLE;