const LEVEL_TYPES = [
    null,
    null,
    'okres',
    'obec'
];
const getNameWithLevelType = (name, level) => {
    const levelType = LEVEL_TYPES[level];
    if (!levelType) {
        return name;
    }
    return `${name} (${LEVEL_TYPES[level]})`;
};
export default getNameWithLevelType;
