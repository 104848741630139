import gql from 'graphql-tag';

const DISPLAY_GROUPS_BY_STORES = gql`
query displayGroupsByStores (
    $storeIds: [ID!]!
    $offset: Int
    $limit: Int
    $sort: [DisplayGroupSortInput!]
    $filter: [DisplayGroupFilterInput]
) {
    displayGroupsByStores(storeIds: $storeIds, offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
    totalCount,
    items {
      id
      bid
      name
      description
    }
  }
}
`;

export default DISPLAY_GROUPS_BY_STORES;