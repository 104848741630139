import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import PageHeader from "components-lib/PageHeader/PageHeader";
import IconCard from "components-lib/IconCard/IconCard";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Button from "components/CustomButtons/Button.js";
import { useState } from "react";
import MEDIAS from "queries/MediaQueries/medias"
import styles from "common/styles/widgets.js"
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Loading from "components-lib/Loading/Loading";
import { useQuery, useMutation } from "@apollo/react-hooks";
import paths from 'paths';
import { pathOr } from 'rambda';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import { Formik } from "formik";
import FormikField from "components-lib/FormikField/FormikField";
import InfoIcon from '@material-ui/icons/Info';
import SELECT_COMPANIES from "queries/CompaniesQueries/selectCompanies"
import SnackbarContent from '@material-ui/core/SnackbarContent';
import MEDIA_DOWNLOAD from "queries/MediaQueries/mediaDownload";
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
import PLAYLIST_UPDATE from "queries/PlaylistsQueries/playlistUpdate";
import { generatePath, withRouter } from "react-router-dom";
import { getApolloClient } from "configFiles/apollo";
import PLAYLIST_DETAIL from "queries/PlaylistsQueries/playlistDetail";
import PLAYLIST_DELETE from "queries/PlaylistsQueries/playlistDelete";
import BasicInfoCard from "./DetailCards/BasicInfoCard";
import Modal from "components-lib/Modal/Modal";
import { getValidationSchemaDetail } from "./validationSchema";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import picture from './picture.jpg';
import Box from "@material-ui/core/Box";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Button as MaterialButton } from '@material-ui/core';
import moment from 'moment';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { defaultColors } from "common/styles/configLayout";
import DeleteIcon from '@material-ui/icons/Delete';
import { playlistUpd, playlistDel } from 'configFiles/roles';

const useStyles = makeStyles((theme) => styles(theme));

const PlaylistListPage = (props) => {
    const {
        intl,
        history,
    } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const playlistId = props.match.params.playlistId;
    const queryParams = { id: playlistId }
    const { loading: playlistLoading, data: playlistData, refetch: refetchPlaylistData } = useQuery(PLAYLIST_DETAIL, { variables: queryParams, });
    const theme = useTheme();

    const [playlist, setPlaylist] = useState({})
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [mediaModalOpen, setMediaModalOpen] = useState(false);
    const [playlistDelete] = useMutation(PLAYLIST_DELETE);
    const [mediaDownload] = useMutation(MEDIA_DOWNLOAD);

    const handleDeletePlaylist = () => {
        playlistDelete({
            variables: {
                id: playlistId,
            },
        })
            .then((response) => {
                history.push(`/admin${generatePath(paths.playlists.list)}`)
                enqueueSnackbar(intl.formatMessage({ id: "playlist.deletePlaylist.successful", }), { variant: "success", });
            }).catch((err) => {
                console.log(err)
            });
        setRemoveModalOpen(false)
    };

    const handleDownloadMedia = (id) => {
        mediaDownload({
            variables: {
                id: Number(id)
            }
        }).then(response => {
            let url = ((pathOr('', ['data', 'mediaDownload', 'url'], response)));
            window.open(url);
        }
        )
    }

    const renderRemoveModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => handleDeletePlaylist()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "playlist.modal.removeButton" })}
                </Button>
                <Button
                    onClick={() => setRemoveModalOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderMediaModalActions = () => {
        return (
            <>
                <Button
                    onClick={() => setMediaModalOpen(false)}
                    color="primary"
                    round
                    size="sm">
                    Zatvoriť okno
                </Button>

            </>
        );
    };

    const getActions = () => {
        if (playlistDel)
            return ([
                {
                    title: intl.formatMessage({ id: "playlist.removePlaylist.button" }),
                    onClick: () => setRemoveModalOpen(true),
                    color: "danger"
                },
            ]
            );
    }

    const { data: mediasData } = useQuery(MEDIAS,
        {
            variables: {
                filter: [
                    {
                        status: {
                            eq: "Processed"
                        },
                        acceptanceStatus: {
                            eq: "Approved"
                        },
                        companyId: {
                            eq: Number(playlist?.company?.id)
                        }
                    }
                ]
            }, skip: !(playlist?.company?.id)
        }
    )

    let data = pathOr([], 'medias.items', mediasData)

    const [playlistUpdate] = useMutation(PLAYLIST_UPDATE);
    const { data: companiesData } = useQuery(SELECT_COMPANIES)

    const [monitorA, setMonitorA] = useState([])
    const [monitorB, setMonitorB] = useState([])
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
            (company) => {
                return {
                    value: pathOr("", "id", company),
                    label: `${pathOr("", "name", company)}`,
                };
            }
        );
        setCompanies(companiesSelectItems);
    }, [companiesData]);

    const [refetch, setRefetch] = useState(1)
    const [click, setClick] = useState(-1)
    const [clickMonitorA, setClickMonitorA] = useState(-1)
    const [clickMonitorB, setClickMonitorB] = useState(-1)

    const updatePlaylist = (values) => {

        let setMonitorB = monitorB.map(item => Number(item.id));

        playlistUpdate({
            variables: {
                id: playlistId,
                input: {
                    name: values?.name,
                    description: values?.description,
                    mediaIds: setMonitorB
                }
            }
        }).then((response) => {
            const id = pathOr("", ["data", "playlistCreate", "id"], response);
            enqueueSnackbar(intl.formatMessage({ id: "playlist.save.success" }), { variant: 'success' });
            refetchPlaylistData()
        }).catch((err) => {
            console.log('[error]', err);
        });
    }

    const handleAddOnMonitor = (item, monitor) => {
        let helpMonitor = monitorB;
        let isInArray = 0
        for (var i = 0; i < helpMonitor.length; i++) {
            if (helpMonitor[i].id === item.id) {
                isInArray = 1;
            }
        }
        if (isInArray === 0) {
            setMonitorB([...helpMonitor, item]);
        }
    };

    const handleDelete = (item, monitor) => {
        let helpMonitor = (monitor === "A") ? monitorA : monitorB;
        const items = helpMonitor.filter(it => it.id !== item.id);
        (monitor === "A") ? setMonitorA(items) : setMonitorB(items);
        (monitor === "A") ? setClickMonitorA(items) : setClickMonitorB(items);
    };

    const handleReorder = (indexA, indexB, monitor, course) => {
        let help = (monitor === "A") ? monitorA : monitorB;
        let notEquals = (course === "U") ? 0 : help.length - 1;
        if (indexA !== notEquals) {
            let tmp = help[indexA];
            help[indexA] = help[indexB];
            help[indexB] = tmp;
            (monitor === "A") ? setMonitorA(help) : setMonitorB(help);
            (monitor === "A") ? setClickMonitorA(indexB) : setClickMonitorB(indexB)
        }
        if (refetch === 1) setRefetch(0)
        else setRefetch(1)
    }

    const getLibraryContent = (item, index) => {
        let rightArrow = 0
        let leftArrow = 0
        monitorB.map((u, i) => {
            if (item.id === u.id)
                rightArrow = 1
            return "";
        })

        return <Grid container spacing={2} key={index}>
            {index === click ? <>
                <Grid item sm={(rightArrow === 1) ? 12 : 10}>
                    <Button
                        round
                        color="success"
                        fullWidth
                        size="sm"
                        onClick={(e) => { setClick(-1); setClickMonitorA(-1); setClickMonitorB(-1) }}
                    >
                        <span style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{item.bid} | {item.name}</span>
                    </Button>

                </Grid>
                {rightArrow === 0 ?
                    <Grid item sm={2}>
                        <Button round fullWidth size="sm" onClick={() => handleAddOnMonitor(item, "B")} color="success"><ArrowForwardIcon /></Button>
                    </Grid>
                    : ""
                }
            </>
                :
                <>
                    <Grid item sm={12}>
                        <Card>
                            <Grid item sm={12}>
                                <Grid container justify="center" alignItems="center" style={{ paddingRight: "5px" }} >
                                    <Grid item sm={4} style={{ display: "inline-block", position: "relative" }} className={classes.hoverImg}
                                        onClick={() => handleDownloadMedia(item.id)}>
                                        <img
                                            src={item?.previews?.[0]?.url}
                                            alt={item?.previews?.[0]?.url}
                                            width="100%"
                                        //height=""
                                        />
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "30%",
                                                left: "35%",
                                                transform: "translate(-50 %, -50 %)",
                                                width: "96px",
                                                height: "96px"

                                            }}
                                        >
                                            <PlayCircleOutlineIcon
                                                style={{
                                                    fontSize: 50,
                                                    color: "white",


                                                }} />
                                        </span>
                                    </Grid>
                                    <Grid item sm={5} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                        <CardContent style={{ paddingTop: "7px", paddingBottom: 0 }}>
                                            <Typography component="h5" variant="h5" style={{ fontSize: "1.3em" }}>
                                                {item.name}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                ID: {item.bid}
                                            </Typography>
                                            <Typography color="textSecondary">
                                                Trvanie: {moment.utc(item.duration * 1000).format('mm:ss')}
                                            </Typography>
                                        </CardContent>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box className={classes.floatRight}>

                                            <Button
                                                style={{ marginTop: "10px", marginRight: "10px" }}
                                                size="sm"
                                                round
                                                disabled={(rightArrow === 1) ? true : false}
                                                color={"success"}
                                                onClick={() => handleAddOnMonitor(item, "B")}
                                            >
                                                Pridať
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </>

            }
        </Grid >
    }

    const getMonitorContent = (item, index, monitor) => {
        return <Grid container spacing={1} key={index}>
            <Grid item sm={12}>
                <Card style={{ margin: 0 }}>
                    <Grid item sm={12}>
                        <Grid container justify="center" alignItems="center" style={{ paddingRight: "5px" }} >
                            <Grid item sm={4} style={{ display: "inline-block", position: "relative" }} className={classes.hoverImg}
                                onClick={() => handleDownloadMedia(item.id)}>
                                <img
                                    src={item?.previews?.[0]?.url}
                                    alt={item?.previews?.[0]?.url}
                                    width="100%"
                                />
                                <span
                                    style={{
                                        position: "absolute",
                                        top: "30%",
                                        left: "35%",
                                        transform: "translate(-50 %, -50 %)",
                                        width: "96px",
                                        height: "96px"

                                    }}
                                >
                                    <PlayCircleOutlineIcon
                                        style={{
                                            fontSize: 50,
                                            color: "white",


                                        }} />
                                </span>
                            </Grid>
                            <Grid item sm={6} style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                                    <Typography component="h5" variant="h5" style={{ fontSize: "1.3em" }}>
                                        {item.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        ID: {item.bid}
                                    </Typography>
                                    <Typography color="textSecondary">
                                        Trvanie: {moment.utc(item.duration * 1000).format('mm:ss')}
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid item sm={2} >
                                <Box className={classes.floatRight}>
                                    {playlistUpd &&
                                        <ButtonGroup
                                            orientation="vertical"
                                            aria-label="vertical contained primary button group"
                                            variant="contained"
                                            size="small"
                                        >
                                            <MaterialButton style={{ backgroundColor: (index !== 0) ? defaultColors.five : '', color: (index !== 0) ? 'white' : '', }} disabled={index === 0} onClick={() => handleReorder(index, index - 1, monitor, "U")}><ArrowUpwardIcon /></MaterialButton>
                                            <MaterialButton style={{ backgroundColor: (index !== monitorB?.length - 1) ? defaultColors.five : '', color: (index !== monitorB?.length - 1) ? 'white' : '', }} disabled={index === monitorB?.length - 1} onClick={() => handleReorder(index, index + 1, monitor, "D")}><ArrowDownwardIcon /></MaterialButton>
                                            <MaterialButton style={{ backgroundColor: defaultColors.five, color: 'white' }} onClick={() => handleDelete(item, monitor)}><DeleteIcon /></MaterialButton>
                                        </ButtonGroup>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    }
    useEffect(() => {
        setPlaylist(pathOr({}, ['playlist'], playlistData));
        setMonitorB(pathOr([], ['playlist', 'media'], playlistData))
    }, [playlistData]);

    if (playlistLoading) return <Loading />
    return (
        <>
            <PageHeader
                title={pathOr('', ['playlist', 'name'], playlistData)}
                subTitle={"Playlist " + pathOr('', ['playlist', 'bid'], playlistData)}
                handleBackAction={(e) => history.goBack()}
                withBackButton={true}
                actions={getActions()}
            />
            <Formik
                enableReinitialize
                onSubmit={(values => updatePlaylist(values))}
                initialValues={playlist}
                validationSchema={getValidationSchemaDetail(intl)}
            >
                {(formikProps) => (
                    <>
                        <Grid container spacing={1}>
                            <Grid item sm={12} lg={6}>
                                <IconCard
                                    title={'Zoznam a poradie médií v playliste'}
                                    icon={<DesktopMacIcon />}
                                >
                                    <hr />
                                    {monitorB.map((item, index) =>
                                        getMonitorContent(item, index, "B")
                                    )}
                                    <br />
                                    {(playlistUpd) &&
                                        <Button
                                            //size="sm"
                                            round
                                            fullWidth
                                            color={"success"}
                                            onClick={() => setMediaModalOpen(true)}
                                        >
                                            Pridať médiá &nbsp; <AddCircleOutlineIcon />
                                        </Button>
                                    }
                                </IconCard>
                            </Grid>
                            <Grid item sm={12} lg={6}>
                                <IconCard
                                    title={intl.formatMessage({ id: "playlist.basicInfo.title" })}
                                    icon={<InfoIcon />}
                                >
                                    <BasicInfoCard
                                        formikProps={formikProps}
                                        companies={companies}
                                        playlistUpdate={playlistUpd}
                                    />
                                </IconCard>
                            </Grid>
                            {(playlistUpd) &&
                                <div style={{ position: "fixed", bottom: "20px", right: "20px", }} >
                                    <Button
                                        color="primary"
                                        onClick={(e) => formikProps.handleSubmit()}
                                    >
                                        {/*intl.formatMessage({ id: "playlist.button.change" })*/ "Aktualizovať playlist"}
                                    </Button>
                                    <Button
                                        onClick={() => history.push(`/admin${paths.operations.list}`)}
                                    >
                                        {intl.formatMessage({ id: "createUser.cancel" })}
                                    </Button>
                                </div>
                            }
                        </Grid>
                    </>
                )}
            </Formik>
            <Modal
                title="Vyberte médiá z knižnice"
                open={mediaModalOpen}
                onClose={() => setMediaModalOpen(false)}
                actions={renderMediaModalActions()}
                fullWidth
            >
                <br />
                {data?.map((item, index) =>
                    getLibraryContent(item, index)
                )}
            </Modal>
            <Modal
                title={intl.formatMessage({ id: "playlist.modal.title" })}
                open={removeModalOpen}
                onClose={() => setRemoveModalOpen(false)}
                actions={renderRemoveModalActions()}
            >
            </Modal>
        </>
    );
};

export default withRouter(injectIntl(PlaylistListPage));
