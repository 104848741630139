import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TvIcon from '@material-ui/icons/Tv';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import Checkbox from '@material-ui/core/Checkbox';
import Modal from "components-lib/Modal/Modal";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import QueryTable from 'components-lib/QueryTable/QueryTable';
import QueryTableRefresh from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => styles(theme));

const DisplayCard = (props) => {
    const {
        history,
        intl,
        queryVariables,
        // defaultFilter,
        queryDataPath,
        numberOfRows,
        query,
        data,
        loadData,
        refetch,
        modalQuery,
        modalQueryVariables,
        modalQueryDataPath,
        onDisplayGroupsUpdate,
    } = props;

    const classes = useStyles();
    const [changeDisplaysModalOpen, setChangeDisplaysModalOpen] = React.useState(false);
    const [displayModalCheckedGroups, setDisplayModalCheckedGroups] = React.useState([]);

    let location = useLocation();
    let defaultFilter = location.state ? location.state : null;

    React.useEffect(() => {
        setDisplayModalCheckedGroups(data.campaign.groups.map(group => group.id));
    }, [data]);

    const displayModalCheckboxCheck = (e, displayGroupId) => {
        let displayGroupList = displayModalCheckedGroups;

        if(e.target.checked){
            displayGroupList.push(displayGroupId);
        } 
        else{
            displayGroupList = displayGroupList.filter((e) => e !== displayGroupId);
        }

        setDisplayModalCheckedGroups(displayGroupList);
    }

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.ID' }),
                    accessor: 'bid',
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.name' }),
                    accessor: "name",
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.description' }),
                    accessor: 'description',
                }
            ]
        )
    }

    const getColumnsModalDetail = () => {
        return (
            [
                {
                    Header: " ",
                    accessor: (rowData) => 
                        <>
                            {
                                (!data.campaign.groups.some(group => group.id == rowData.id)) ?
                                    <Checkbox 
                                        color="primary" 
                                        style={{float: 'right'}}
                                        onChange={(e) => displayModalCheckboxCheck(e, rowData.id)}
                                    />
                                :
                                    <Checkbox 
                                        color="primary" 
                                        style={{float: 'right'}}
                                        onChange={(e) => displayModalCheckboxCheck(e, rowData.id)}
                                        defaultChecked
                                    />
                            }
                        </>
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'campaigns.displayCard.table.description' }),
                    accessor: 'description',
                    sortKey: 'description',
                    filterKey: 'description',
                    filterOperator: 'contains'
                }
            ]
        )
    }

    const renderDisplayButtons = () => {
        return (
            <>
                <hr />
                <Button
                    onClick={() => setChangeDisplaysModalOpen(true)}
                    color="info"
                    size="sm"
                    round
                    className={classes.floatRight}
                >
                    {intl.formatMessage({ id: 'campaigns.displayCard.table.changeButton' })}
                </Button>
            </>
        )
    }

    const addDisplayGroupsToCampaign = () => {
        const displayGroupsIds = displayModalCheckedGroups;
        if(displayGroupsIds.length > 0)
            onDisplayGroupsUpdate(displayGroupsIds);
    }

    return <>
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.displayCard.title' })}
            icon={<TvIcon />}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <QueryTableRefresh
                        data={data}
                        loadData={loadData}
                        queryVariables={queryVariables}
                        // permanentFilter={defaultFilter}
                        queryDataPath={queryDataPath}
                        // numberOfRows={numberOfRows}
                        togle={true}
                        togglePagination={false}
                        columns={getColumnsDetail()}
                    />
                </Grid>
            </Grid>
            {renderDisplayButtons()}
        </IconCard>

        <Modal
            title={intl.formatMessage({ id: "campaigns.displayCard.changeModal.title" })}
            open={changeDisplaysModalOpen}
            onClose={() => setChangeDisplaysModalOpen(false)}
            // actions={}
        >
            <Grid container spacing={1}>
                <Grid item sm={12}>
                    <QueryTable
                        query={modalQuery}
                        toggle={true}
                        queryVariables={modalQueryVariables}
                        // permanentFilter={defaultFilter}
                        togglePagination={false}
                        queryDataPath={modalQueryDataPath}
                        numberOfRows={numberOfRows}
                        columns={getColumnsModalDetail()}
                    />
                </Grid>
            </Grid>
            <>
                <hr/>
                <Button
                    onClick={() => {
                        setChangeDisplaysModalOpen(false);
                        addDisplayGroupsToCampaign();
                    }}
                    color="info"
                    size="sm"
                    round
                    className={classes.floatRight}
                >
                    {intl.formatMessage({ id: 'campaigns.displayCard.changeModal.save' })}
                </Button>
            </>
        </Modal>
    </>
};

export default injectIntl(DisplayCard);
