import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from 'react-intl';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { useMutation } from '@apollo/react-hooks';
import { gql } from "apollo-boost";
import { useSnackbar } from 'notistack';
import { withRouter } from "react-router-dom";
import paths from "../../paths";
import logo from '../../assets/img/echo-logo-dark.svg';
import { Formik } from 'formik';
import FormikField from '../../components-lib/FormikField/FormikField';
import validationSchema from './validationSchema';


const useStyles = makeStyles(styles);

const PASSWORD_RESET_MUTATION = gql`
mutation resetPassword ($email: EmailAddress!) {
  passwordReset(email: $email)
}
`;

const ForgottenPassword = (props) => {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    const { history, intl } = props;

    const [resetPassword] = useMutation(PASSWORD_RESET_MUTATION);

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        let id = setTimeout(function () {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    const handlePasswordReset = (values) => {
        resetPassword({
            variables: {
                email: values.email,
            }
        }).then(response => {
            enqueueSnackbar(intl.formatMessage({ id: 'loginPage.forgottenPassword.success' }), {
                variant: 'success'
            })
        }).catch(err => {
            console.log('[err]', err);
        })
    };

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                    <Card login className={classes[cardAnimaton]}>
                        <Formik
                            initialValues={{ email: '@' }}
                            onSubmit={(values) => {
                                handlePasswordReset(values);
                            }}
                            validationSchema={validationSchema(intl)}
                        >
                            {(formikProps) => (
                                <>
                                    <CardBody>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            margin: '20px'
                                        }}>
                                            <img src={logo} alt={logo} style={{
                                                width: '200px'
                                            }} />
                                        </div>
                                        <p style={{
                                            textAlign: 'center',
                                            fontSize: '14px',
                                            marginTop: '25px'
                                        }}>
                                            {intl.formatMessage({ id: 'loginPage.forgottenPassword.infoText' })}
                                        </p>
                                        <FormikField
                                            name="email"
                                            formikProps={formikProps}
                                            labelText={intl.formatMessage({ id: 'loginPage.forgottenPassword.email' })}
                                        />
                                    </CardBody>
                                    <CardFooter
                                        className={classes.justifyContentCenter}
                                        style={{
                                            width: '100%',
                                            margin: '0px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            padding: '20px'
                                        }}
                                    >
                                        <Button
                                            color="primary"
                                            size="md"
                                            round
                                            onClick={() => {
                                                formikProps.handleSubmit();
                                            }}
                                        >
                                            {intl.formatMessage({ id: 'loginPage.forgottenPassword.submit' })}
                                        </Button>
                                        <Button
                                            simple
                                            color="primary"
                                            size="sm"
                                            round
                                            onClick={() => {
                                                history.push(`/auth${paths.auth.login}`)
                                            }}
                                        >
                                            {intl.formatMessage({ id: 'loginPage.forgottenPassword.cancel' })}
                                        </Button>
                                    </CardFooter>
                                </>
                            )}
                        </Formik>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default injectIntl(withRouter(ForgottenPassword));
