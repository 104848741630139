import gql from 'graphql-tag';

const SELECT_PLAYLISTS = gql`
query playlists($offset: Int, $limit: Int, $sort: [PlaylistSortInput!], $filter: [PlaylistFilterInput]){
  playlists(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,
    items{
      id
      bid
      name
      description
      createdAt
  }
}
}
`;

export default SELECT_PLAYLISTS;