import gql from 'graphql-tag';

const USER_BLOCK = gql`
    mutation userBlock ($id: ID!, $reason: String) {
        userBlock(id: $id, reason: $reason) {
            firstName
            lastName
        }
    }
`;

export default USER_BLOCK;