/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Switch, Redirect } from "react-router-dom";
import {useSnackbar} from 'notistack';
import { ApolloProvider } from 'react-apollo';

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

import "assets/fonts/SofiaPro-SemiBold/styles.css";
import "assets/fonts/SofiaProRegular/styles.css"
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";
import ProtectedRoute from "components-lib/ProtectedRoute/ProtectedRoute";
import PublicRoute from "components-lib/PublicRoute/PublicRoute";
import {getApolloClient} from "./configFiles/apollo";
import WithSnackbar from "./components-lib/WithSnackbar/WithSnackbar";
import {IntlProvider, addLocaleData} from 'react-intl';
import {getSelectedLanguage} from "./helpers/helpers";
import skConfig from './locales/config/sk';
import enConfig from './locales/config/en';
import { dashboardList } from 'configFiles/roles'

import { injectIntl } from 'react-intl';


const hist = createBrowserHistory();

const App = injectIntl(({ intl }) => {

    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, hist, intl);

    return (
        <ApolloProvider client={client}>
            <Router history={hist}>
                <Switch>
                    <ProtectedRoute path="/admin" component={AdminLayout} />
                    <PublicRoute path="/auth" component={AuthLayout} />
                    <Redirect from="/" to={(dashboardList) ? "/admin/dashboard" : "/admin/profile"} />
                    {/* <Redirect to={`/auth${paths.auth.login}`} /> */}
                </Switch>
            </Router>
        </ApolloProvider>
    )
});



const Context = React.createContext();

const IntlProviderWrapper = ({ children }) => {
    const selectedLanguageStored = getSelectedLanguage() === 'sk' ? skConfig : enConfig;

    const [selectedLanguage, setSelectedLanguage] = React.useState(selectedLanguageStored);

    addLocaleData([
        { locale: 'sk', pluralRuleFunction: (n, ord) => null },
        { locale: 'en', pluralRuleFunction: (n, ord) => null }
    ]);

    const handleLanguageChange = (locale) => {
        if (locale === 'sk') {
            return setSelectedLanguage(skConfig);
        } else if (locale === 'en') {
            return setSelectedLanguage(enConfig);
        } else {
            return setSelectedLanguage(skConfig);
        }
    }

    return (
        <Context.Provider 
            value={{
                handleLanguageChange: handleLanguageChange,
                selectedLanguage: selectedLanguage.locale
            }}
        >
            <IntlProvider 
                locale={selectedLanguage.locale} 
                messages={selectedLanguage.messages}
                defaultLocale="sk"
            >
                {children}
            </IntlProvider>
        </Context.Provider>
    );
};

export { Context as IntlContext };

ReactDOM.render(
    <IntlProviderWrapper>
        <WithSnackbar>
            <App />
        </WithSnackbar>
    </IntlProviderWrapper>,
  document.getElementById("root")
);
