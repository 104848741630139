import gql from 'graphql-tag';

const CREATE_CAMPAIGN = gql`
mutation campaignCreate($input: CampaignCreateInput!) {
  campaignCreate(input: $input) {
    id
    bid
    name
    description
    status
    createdAt
    createdBy{
        id
        fullName
    }
    criteria{
        locations{
            id
            name
        }
        segments{
            id
            name
        }
        companies{
            id
            name
        }
        stores{
            id
            name
        }
        match{
            locationsCount
            segmentsCount
            companiesCount
            storesCount
        }
    }

    company{
        id
        bid
        name
        address
        postal
        city
        contactEmail
        contactPhone
        contactFirstName
        contactLastName
        
        stores{
            address
            postal
            city
            country
            latitude
            longtitude
            fullAddress
        }
    }
    
    media{
        id
        bid
        name
        duration
        previews{
            timemark
            width
            height
            url
        }
    }

    groups{
        bid
        name
        description
    }

    activeTimes{
        from
        to
    }
  }
}
`;

export default CREATE_CAMPAIGN;