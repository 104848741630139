import gql from 'graphql-tag';

const USER_UNBLOCK = gql`
    mutation userUnblock ($id: ID!) {
        userUnblock(id: $id) {
            firstName
            lastName
        }
    }
`;

export default USER_UNBLOCK;