
  
import gql from 'graphql-tag';

const DEVICE_DISABLE = gql`
    mutation deviceDisable($id: ID!){
        deviceDisable(id:$id){
            id
        }
    }

`;
// {
//     "id": 888
//   }

export default DEVICE_DISABLE;

