import React, { useState, useEffect, useCallback } from "react";
import { Grid } from '@material-ui/core';
// import { useQuery, useMutation } from 'react-apollo';
// import { Formik } from 'formik';
// import { pathOr } from 'rambda';
// import FormikField from 'components-lib/FormikField/FormikField';
// import { validationSchemaStep2 } from "./validationSchema";
import { injectIntl } from 'react-intl';
// import MEDIAS_BY_COMPANY from "queries/MediaQueries/mediasByCompany";
import styles from "common/styles/widgets.js";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Form from './echo-ds-criteria-form/component/form/Form';
// import Form from '@artcode.sk/echo-ds-criteria-form/lib/component/form/Form';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Typography from '@material-ui/core/Typography';
// import MEDIA_DOWNLOAD from "queries/MediaQueries/mediaDownload";
// import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
// import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => styles(theme));

const Step2 = ({ intl, callback, callbackCriteria, callbackValid, initialData, companyId, axios }) => {

  const classes = useStyles();

  const [ value, setValue ] = useState({
    locationIds: ["*"],
    segmentIds: ["*"],
    companyIds: ["*"],
    storeIds: ["*"]
  });

  useEffect(() => {
    callbackValid(true)
  }, []);

  const onChange = useCallback((value) => {
    setValue(value);
    callback(value);
  }, [])

  return (
    <>
        <Grid container spacing={1}>
          <Grid item sm={12}>
            {callbackValid(value.storeIds.length > 0)}
            <Paper style={{ padding: "10px" }}>
              <Form
                axios={axios}
                campaign={{companyId: companyId}}
                value={value}
                onChange={onChange}
              />
            </Paper>
          </Grid>
        </Grid>
    </>
  );
}

export default injectIntl(Step2);