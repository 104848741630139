import {
    primaryColor
} from "assets/jss/material-dashboard-pro-react.js";

const loginPageStyle = theme => ({
    languagesWrapper: {
        marginTop: '25px',
        cursor: 'pointer'
    },
    language: {
        margin: '0px 5px'
    },
    selected: {
        color: primaryColor[0],
        textDecoration: 'underline'
    }
});

export default loginPageStyle;
