import gql from 'graphql-tag';

const UPDATE_COMPANY_MUTATION = gql`
    mutation companyUpdate ($id: ID!, $input: CompanyUpdateInput!) {
        companyUpdate (id: $id, input: $input) {
            bid
            name
        }
    }
`;

export default UPDATE_COMPANY_MUTATION;