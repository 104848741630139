import gql from 'graphql-tag';

const DETAIL_GROUP = gql`
query userGroup($id: ID!) {
  userGroup(id: $id) {
    id
    name
    description
    permissions
    createdAt
    updatedAt
    createdBy{
      fullName
    }
  }
}
`;

export default DETAIL_GROUP;