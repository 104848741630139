import { Grid, withWidth } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CalendarIcon from '@material-ui/icons/CalendarToday';
import IconCard from "components-lib/IconCard/IconCard";
import React, { useState, useEffect } from "react";
import { isSameDay, isFuture } from "date-fns";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgets.js"
import MuiCalendar from "../Components/MuiCalendar";
import Button from "components/CustomButtons/Button.js";
import Modal from "components-lib/Modal/Modal";
import {format } from 'date-fns';

const useStyles = makeStyles((theme) => styles(theme));

const CalendarCard = (props) => {
    const {
        history,
        intl,
        acitiveTimes,
        onActiveTimesUpdate
    } = props;

    const getDatesFromActiveTimes = () => {
        let dates = [];
        acitiveTimes.forEach((at) => {
            const dts = getDatesInRange(new Date(at.from), new Date(at.to));
            dates = dates.concat(dts);
        });

        dates = dates.filter(date => format(new Date(), 'yyyy-MM') <= format(new Date(date), 'yyyy-MM'));
        return dates;
    }
    
    const [highlightedDays, setHighlitedDays] = useState([]);

    useEffect(() => {
        setHighlitedDays(getDatesFromActiveTimes());
    }, [acitiveTimes]);

    const classes = useStyles();

    function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
      
        const dates = [];
      
        while (date <= endDate) {
          dates.push(new Date(date));
          date.setDate(date.getDate() + 1);
        }
      
        return dates;
    }

    return <>
        <Grid container spacing={1} style={{marginBottom: '0'}}>
            <Grid item sm={12}>
                <MuiCalendar
                    layout={'card'}
                    dates={getDatesFromActiveTimes()}
                    highlightedDays={highlightedDays}
                    highlitedDaysModal={null}
                    onModalDayClick={() => {}}
                />
            </Grid>
        </Grid>
    </>
};

export default injectIntl(CalendarCard);