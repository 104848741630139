import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import paths from "paths";
import { pathOr } from "rambda";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { isInRoles } from "helpers/helpers";
import { makeStyles } from "@material-ui/core/styles";
import RoleEnum from "common/enums/RoleEnum";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import IconCard from "components-lib/IconCard/IconCard";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import NavPills from "components/NavPills/NavPills.js";
import Input from "@material-ui/core/Input";
import styles from "common/styles/widgets.js";
import DevicesTable from "./DevicesTable";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ZONES_SELECT from "queries/ZonesQueries/zonesSelect";
import DEVICE_ASSIGN_ZONE from "queries/DevicesQueries/deviceAssignZone";
import DEVICES_QUERY_DISABLED from "queries/DevicesQueries/getDevicesDisabled";
import DEVICES_QUERY_ENABLED from "queries/DevicesQueries/getDevicesEnabled";
import DEVICES_QUERY from 'queries/DevicesQueries/getDevices';
import DEVICE_DISCOVER from 'queries/DevicesQueries/deviceDiscover';
import FormikField from "components-lib/FormikField/FormikField";
import { Formik } from "formik";
import { deviceDetail, storeList, deviceDisc, deviceAssign } from 'configFiles/roles'
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';


const useStyles = makeStyles((theme) => styles(theme));

const DevicesListPage = (props) => {
    const { intl, history } = props;
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const [deviceEnable] = useMutation(DEVICE_ASSIGN_ZONE);
    const [deviceDiscover] = useMutation(DEVICE_DISCOVER);
    const { data: zonesData } = useQuery(ZONES_SELECT, { skip: !deviceAssign });
    const { data: devData } = useQuery(DEVICES_QUERY,
        {
            variables: {
                filter: [
                    {
                        zone: {
                            isNull: true
                        }
                    }
                ]
            }
        },
        { skip: !deviceAssign }
    );

    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [deviceId, setDeviceId] = useState(-1);
    const [zoneId, setZoneId] = useState(-1);
    const [zones, setZones] = useState([]);
    const [avDevices, setAvDevices] = useState({});
    const [activeState, setActiveState] = useState(0);
    const [discoverResponse, setDiscoverResponse] = useState([]);
    const [discoverModalOpen, setDiscoverModalOpen] = useState(false);

    useEffect(() => {
        let deviceSelectItems = pathOr([], 'devices.items', devData).map((device) => {
            return {
                value: pathOr('', 'id', device),
                label: `${pathOr('', 'id', device)} - ${pathOr('', 'bid', device)}`
            }
        })
        setAvDevices(deviceSelectItems);
    }, [devData]);

    useEffect(() => {
        let zoneSelectItems = pathOr([], "zones.items", zonesData).map(
            (zone) => {
                return {
                    value: pathOr("", "id", zone),
                    label: <span>
                        <span>{pathOr("", ["store", "name"], zone)} ( {pathOr("", ["store", "bid"], zone)}) </span>
                        <span style={{ color: "gray", fontSize: "0.8em" }}>Zóna: {pathOr("", "name", zone)} ({pathOr("", "bid", zone)})</span>
                    </span>,
                };
            }
        );
        setZones(zoneSelectItems);
    }, [zonesData]);

    const assignDevice = (formikProps) => {
        deviceEnable({
            variables: {
                id: formikProps.values.id,
                zoneId: formikProps.values.zoneId,
            },
        }).then((response) => {
            history.push(`/admin${generatePath(paths.devices.detail, { deviceId: formikProps.values.id })}`);
            enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.player.assignPlayerSuccessful" }), { variant: "success", });
            formikProps.resetForm();
        }).catch((err) => {
            console.log('[error]', err)
        });
        setAssignModalOpen(false);
        formikProps.resetForm();
    };

    const discoverDevice = () => {
        deviceDiscover()
            .then((response) => {
                setDiscoverResponse(response.data.deviceDiscover)
                setDiscoverModalOpen(true);
                enqueueSnackbar(intl.formatMessage({ id: "deviceDetail.success.browseStock" }), { variant: "success", });
            }).catch((err) => {
                console.log('[error]', err)
            });
    };



    const renderModalButtons = (formikProps) => {
        return (
            <>
                <Button
                    disabled={(!formikProps.values.zoneId || !formikProps.values.id)}
                    onClick={() => assignDevice(formikProps)}
                    color="success"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "deviceList.assignModal.assign" })}
                </Button>
                <Button
                    onClick={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "deviceList.assignModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderDiscoverModalButtons = () => {
        return (
            <Button
                onClick={() => setDiscoverModalOpen(false)}
                color="success"
                round
                size="sm"
            >
                ok
            </Button>
        );
    };

    const getActions = () => {
        if (activeState === 0) {
            if (deviceAssign)
                return [
                    {
                        title: intl.formatMessage({ id: "deviceList.assignDevice" }),
                        onClick: () => setAssignModalOpen(true),
                    },
                ];
        }
        else {
            if (deviceDisc)
                return [
                    {
                        title: intl.formatMessage({ id: "deviceList.discoverDevice" }),
                        onClick: () => discoverDevice(),
                    },
                ];
        }

    };

    const renderTables = () => {
        return (
            <>
                {deviceDisc ?
                    <NavPills
                        color="info"
                        active={activeState}
                        callback={(newValue) => setActiveState(newValue)}
                        tabs={[
                            {
                                tabButton: intl.formatMessage({ id: "deviceList.enabledToggle" }),
                                tabContent: (
                                    <DevicesTable
                                        defaultFilter={{ isAssigned: { eq: true } }}
                                        history={history}
                                        query={DEVICES_QUERY_ENABLED}
                                    />
                                ),
                            },
                            {
                                tabButton: intl.formatMessage({ id: "deviceList.disabledToggle" }),
                                tabContent: (
                                    <DevicesTable
                                        defaultFilter={{ isAssigned: { eq: false } }}
                                        history={history}
                                        disabled={true}
                                        query={DEVICES_QUERY_DISABLED}
                                    />
                                ),
                            },
                        ]}
                    />
                    :
                    <DevicesTable
                        defaultFilter={{ isAssigned: { eq: true } }}
                        history={history}
                        query={DEVICES_QUERY_ENABLED}
                    />
                }
            </>
        );
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: "deviceList.title" })}
                actions={getActions()}
                withBackButton={false}
            />
            <IconCard
                icon={<LiveTvIcon />}
                title={intl.formatMessage({ id: "deviceList.table.title" })}
            >
                {renderTables()}
            </IconCard>
            <Formik
                onSubmit={(values, { resetForm }) => assignDevice(values, resetForm)}
                initialValues={{}}
            //validationSchema={getValidationSchema(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "deviceList.assignModal.title" })}
                        open={assignModalOpen}
                        onClose={() => setAssignModalOpen(false)}
                        actions={renderModalButtons(formikProps)}
                        fullWidth
                    >
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                <FormikField
                                    type="select"
                                    name="id"
                                    labelText={intl.formatMessage({ id: "deviceDetail.title" })}
                                    options={avDevices}
                                    formikProps={formikProps}
                                    highlightChange
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <FormikField
                                    type="select"
                                    name="zoneId"
                                    labelText="Zóna"
                                    options={zones}
                                    formikProps={formikProps}
                                    highlightChange
                                />
                            </Grid>
                        </Grid>

                        {/*<Grid container className={classes.selectStyle} spacing={2}>
                            <Grid item sm={12}>
                                <AutocompleteSelect
                                    placeholder={intl.formatMessage({ id: "deviceList.assignModal.device" })}
                                    label={intl.formatMessage({ id: "deviceDetail.title" })}
                                    options={avDevices}
                                    onChange={(item) => setDeviceId(item.value)}
                                    input={<Input />}
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <AutocompleteSelect
                                    label={intl.formatMessage({ id: "deviceList.table.store" })}
                                    placeholder={intl.formatMessage({ id: "deviceList.assignModal.store", })}
                                    options={zones}
                                    onChange={(item) => setZoneId(item.value)}
                                    input={<Input />}
                                />
                            </Grid>
                            </Grid>*/}
                    </Modal>
                )}

            </Formik>
            <Modal
                title={intl.formatMessage({ id: "deviceList.discoverModal.title" })}
                open={discoverModalOpen}
                onClose={() => setDiscoverModalOpen(false)}
                actions={renderDiscoverModalButtons()}
            >
                {(discoverResponse?.length !== 0) ?
                    <>
                        {intl.formatMessage({ id: "deviceList.discoverModal.textYes" }, [discoverResponse.length])}
                        <hr />
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><b>#</b></TableCell>
                                    <TableCell><b>ID</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {discoverResponse.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {i + 1}
                                        </TableCell>
                                        <TableCell>{row.bid}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </>
                    :
                    <>{intl.formatMessage({ id: "deviceList.discoverModal.textNo" })}</>
                }
            </Modal>
        </>
    );
};

export default withRouter(injectIntl(DevicesListPage));
