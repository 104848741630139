import React from 'react';
import MUITreeItem from '@material-ui/lab/TreeItem';
import Item from '../item/Item';
;
const TreeItem = props => {
    const { id, isChecked, children, onChange } = props;
    const handleOnLabelClick = (event) => {
        event.preventDefault();
        onChange(id, !isChecked);
    };
    return React.createElement(MUITreeItem, { nodeId: id, onLabelClick: handleOnLabelClick, label: React.createElement(Item, Object.assign({}, props)) }, children.map(child => (React.createElement(TreeItem, Object.assign({ key: child.id }, child, { onChange: onChange })))));
};
export default TreeItem;
