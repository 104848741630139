import React from 'react';
import { Grid } from '@material-ui/core';
import styles from "common/styles/widgets.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from '@material-ui/core/styles';
import AccountBalance from "@material-ui/icons/AccountBalance";
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => styles(theme))

const BankDataCard = (props) => {

    const {intl} = props;
    const classes = useStyles();

    return(
        <IconCard title={intl.formatMessage({ id: 'profile.bankData.title' })} icon={<AccountBalance />}>
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            labelText={intl.formatMessage({ id: 'profile.bankData.iban' })}
                            id="iban"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.iban === null? "" :props.iban
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            className={classes.inputMargin}
                            labelText={intl.formatMessage({ id: 'profile.bankData.bic' })}
                            id="bic-swift"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.bic === null? "" :props.bic
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            className={classes.inputMargin}
                            labelText={intl.formatMessage({ id: 'profile.bankData.bankName' })}
                            id="bank"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.bankName === null? "" :props.bankName
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                </Grid>
        </IconCard>

    )
}

export default injectIntl(BankDataCard);