import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import IconCard from "components-lib/IconCard/IconCard";
import PageHeader from "components-lib/PageHeader/PageHeader";
import React from "react";
import { injectIntl } from "react-intl";
import DisplayTable from "./DisplayTable";
import ALL_DISPLAY_GROUPS from 'queries/DisplaysQueries/allDisplayGroups';
import { useLocation } from 'react-router-dom';
import { useLazyQuery } from "@apollo/react-hooks";

const DisplayListPage = (props) => {
  const { intl, history } = props;
  let location = useLocation();
  let defaultFilter = location.state ? location.state : null
  const [loadDisplayGroups, { data: dataDisplayGroups, refetch: refetchDisplayGroups }] = useLazyQuery(ALL_DISPLAY_GROUPS);

  const renderTables = () => {
    return (
      <div>
        <DisplayTable
          data={dataDisplayGroups}
          loadData={loadDisplayGroups}
          refetch={refetchDisplayGroups}
          query={ALL_DISPLAY_GROUPS}
          defaultFilter={defaultFilter}
          history={history}
        />
      </div>
    );
  };
  return (
    <>
      <PageHeader
        title={intl.formatMessage({ id: "displayList.title" })}
        withBackButton={false}
      />
      <IconCard
        icon={<QueuePlayNextIcon />}
        title={intl.formatMessage({ id: "displayList.table.title" })}
      >
        {renderTables()}
      </IconCard>
    </>
  );
};
export default injectIntl(DisplayListPage);