import * as Yup from 'yup';

const getValidationSchema = (intl) =>
    Yup.object().shape({
        name: Yup.string()
            .required(intl.formatMessage({ id: 'validation.required' }))
            .max(20, intl.formatMessage({ id: 'validation.string.max' }, [20]))
            .nullable(),
        description: Yup.string()
            .max(100, intl.formatMessage({ id: 'validation.string.max' }, [100]))
            .nullable()
    });

export default getValidationSchema;