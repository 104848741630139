import gql from 'graphql-tag';

const DISPLAY_GROUP_UPDATE = gql`
mutation displayGroupUpdate ($id: ID!, $input: DisplayGroupUpdateInput!) {
    displayGroupUpdate (id: $id, input: $input) {
            id
        bid
        name
    }
}
`;

export default DISPLAY_GROUP_UPDATE;