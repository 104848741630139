import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from '@material-ui/icons/Info';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router';
import Box from "@material-ui/core/Box";
import styles from "common/styles/widgets.js";
import Tooltip from '@material-ui/core/Tooltip';
import { useSnackbar } from "notistack";
import Button from "components/CustomButtons/Button.js";
import { deviceIdentify, deviceTerminal } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const StatusAndVolumeCard = (props) => {
    const classes = useStyles();
    const {
        intl,
        deviceBid,
        balenaId,
        deviceType,
        clientVersion,
        identify,
        terminal
    } = props;
    const { enqueueSnackbar } = useSnackbar();

    const renderDeviceStatus = () => {
        return <>
            <br />
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.ID" })}
                    <Box className={classes.floatRight}>
                        <Tooltip title={intl.formatMessage({ id: "tableTexts.copyToClipboard" })}>
                            <Box className={classes.floatRight}>
                                <span
                                    className={classes.copyToClipboard}
                                    onClick={() => { navigator.clipboard.writeText(deviceBid); enqueueSnackbar(intl.formatMessage({ id: "tableTexts.copyToClipboard.success" }), { variant: 'success' }); }}
                                >
                                    {deviceBid}
                                </span>
                            </Box>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.balenaID" })}
                    <Box className={classes.floatRight}>
                        <Tooltip title={intl.formatMessage({ id: "tableTexts.copyToClipboard" })}>
                            <Box className={classes.floatRight}>
                                <span
                                    className={classes.copyToClipboard}
                                    onClick={() => { navigator.clipboard.writeText(balenaId); enqueueSnackbar(intl.formatMessage({ id: "tableTexts.copyToClipboard.success" }), { variant: 'success' }); }}
                                >
                                    {balenaId}
                                </span>
                            </Box>
                        </Tooltip>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.deviceType" })}
                    <Box className={classes.floatRight}>
                        {deviceType}
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: "storeDetail.deviceStatusCard.clientVersion" })}
                    <Box className={classes.floatRight}>
                        {clientVersion}
                    </Box>
                </Grid>
            </Grid>
        </>
    }

    return (
        <>

            <IconCard
                title={intl.formatMessage({ id: "storeDetail.deviceInfoCard.title" })}
                className={classes.cardStyle}
                icon={<InfoIcon />}
            >
                {renderDeviceStatus()}
                {(deviceIdentify || deviceTerminal) && <hr />}
                {deviceTerminal &&
                    <Button
                        className={classes.floatRight}
                        color="info"
                        size="sm"
                        round
                        onClick={() => window.open(terminal)}
                    >
                        {intl.formatMessage({ id: "deviceDetail.status.console" })}
                    </Button>
                }
                {deviceIdentify &&
                    <Button
                        className={classes.floatRight}
                        color="info"
                        size="sm"
                        round
                        onClick={identify}
                    >
                        {intl.formatMessage({ id: "deviceDetail.status.identify" })}
                    </Button>
                }
            </IconCard>
        </>
    );
};
export default withRouter(injectIntl(StatusAndVolumeCard));
