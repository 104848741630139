import gql from 'graphql-tag';

const CREATE_GROUP = gql`
mutation userGroupCreate($input: UserGroupCreateInput!) {
    userGroupCreate(input: $input) {
        id
        name
        description
        permissions
        createdAt
        updatedAt
        createdBy{
            fullName
      }
    }
  }
`;

export default CREATE_GROUP;