const defaultValidationErrors = {
    'validation.required': 'This field is required',
    'validation.number': 'The value must be a number',
    'validation.number.min': 'Minimal value for this field is {0}',
    'validation.number.max': 'Maximal value for this field is {0}',
    'validation.number.integer': 'The value must be an integer',
    'validation.number.positive': 'The value for this field must be grater than 0',
    'validation.email': 'Email address is not valid',
    'validation.phoneNumber': 'Required format for phone number is +421999123123',
    'validation.spaces': 'Phone number must not contain spaces (+421903123123)',
    'validation.iban': 'Invalid IBAN format',
    'validation.date': 'Invalid Date format',
    'validation.graterDate': 'End date must be greater than start date',
    'validation.pastDate': 'The date and time must be higher than the current one',
    'validation.string.max': 'Input can be max {0} characters length',
    'validation.string.exact': 'This field must contain exactly {0} characters'
};

const queryTable = {
    'queryTable.select.all': 'All',
    'queryTable.filter.placeholder': 'Filter..',
    "queryTable.filter.dateBetween": "Start - End",
    "queryTable.filter.setDateRange": "Set Date Range: ",
    "queryTable.filter.pickerStart": "Start Date",
    "queryTable.filter.pickerEnd": "End Date"
};

const apiErrors = {
    'apiErrors.InsufficientPermissionsError': 'You are not authorized for this section',
    'apiErrors.INTERNAL_SERVER_ERROR': 'Unexpected error occurred',
    'apiErrors.GRAPHQL_VALIDATION_FAILED': 'Internal database error occurred',
    'apiErrors.UnauthorizedUserError': 'Security token expired or you are not authorised for this action',
    'apiErrors.InvalidRequestError': 'Sent data contain invalid values',
    'apiErrors.AccountDoesNotExistsError': 'Account with provided email does not exists',
    'apiErrors.AccountExistsError': 'Account with provided email already exists',
    'apiErrors.PasswordResetPendingError': 'Account has pending password reset request',
    'apiErrors.PasswordResetNotAllowedError': 'Requested account does not support password reset',
    'apiErrors.InvalidLoginError': 'Provided user email or password is invalid',
    'apiErrors.InvalidLoginMethodError': 'Account with provided email address does not support used authentication method',
    'apiErrors.UnactivatedAccountError': 'Account requires activation',
    'apiErrors.BlockedAccountError': 'Account has been block by system administrator',
    'apiErrors.EntityDoesNotExistsError': 'Requested entity does not exist',
    'apiErrors.DeviceOfflineError': 'Requested device is offline',
    'apiErrors.EntityUniqueConstraintError': 'Device with same name already exists',
    'apiErrors.CannotPerformOnOwnAccountError': 'This operation cannot be performed on own account',
    'apiErrors.CannotRemoveEntityInUseError': 'You cannot delete this entity because it is in use somewhere',
    'apiErrors.PasswordChangeError': 'The current password is incorrect',
    'apiErrors.WeakPasswordError': 'Provided current user password is incorrect',
    'apiErrors.SubscriptionNotActivatedError': 'User dont have activated any subscription',
    'apiErrors.InvalidInputDataError': 'Some of the input data is incorrect'
};


const routes = {
    'routes.dashboard': 'Dashboard',
    'routes.profile': 'Profile',
    'routes.devices': 'Players',
    'routes.devices.detail': 'Player detail',
    'routes.campaigns.freeFoto': 'FF Campaign',
    'routes.campaigns.qr': 'Coupon Campaign',
    "routes.campaigns.new": "New Campaign",
    "routes.campaigns.detail": "Campaign detail",
    'routes.stores': 'Stores',
    'routes.stores.new': 'New Store',
    "routes.stores.detail": "Store detail ",
    'routes.notifications': 'Notifications',
    'routes.users': 'Accounts',
    'routes.users.new': 'New user account',
    'routes.users.detail': 'User detail',
    'routes.orders': 'Orders',
    'routes.media': 'Media',
    'routes.playlist': 'Playlist',
    'routes.playlists': 'Playlists',
    'routes.playlists.new': 'Playlists',
    'routes.playlists.detail': 'Playlists',
    'routes.displays': 'Display groups',
    'routes.orders.detail': 'Order detail',
    "routes.campaigns": "Campaigns",
    "routes.companies": "Companies",
    "routes.companies.detail": "Company detail",
    "routes.companies.new": "New company",
    'routes.payments': 'Payments',
    "routes.invoices": "Invoices",
    "routes.invoices.detail": "Invoice detail",
    "routes.commissions": "Commissions",
    "routes.commissions.detail": "Commission detail",
    "routes.operations": "Operations",
    'routes.login': 'Login',
    'routes.settings': 'Options',
    'routes.logout': 'Logout',
    'routes.forgottenPassword': 'Forgotten Password',
    'routes.loading': 'Loading...',
    'routes.groups': 'Groups'
};

const usersPage = {
    'usersPage.title': 'Accounts',
    'usersPage.actions.newPartner': 'NEW PARTNER',
    'usersPage.actions.newAdvertiser': 'NEW ADVERTISER',
    'usersPage.actions.newMaintainer': 'NEW MAINTAINER',
    'usersPage.actions.newAccount': 'NEW ACCOUNT',
    'usersPage.table.title': 'Account list',
    'usersPage.table.id': 'ID',
    'usersPage.table.name': 'Name',
    'usersPage.table.company': 'Company',
    'usersPage.table.email': 'Email',
    'usersPage.table.role': 'Groups',
    'usersPage.table.status': 'Status',
    'usersPage.table.credits': 'Credits',
    'usersPage.table.status.activated': 'ACTIVE',
    'usersPage.table.status.blocked': 'BLOCKED',
    'usersPage.table.status.notActive': 'INACTIVE',
    'usersPage.table.subscription': 'Subscription',
    'usersPage.table.actions': 'Actions',
    'usersPage.table.detail': 'DETAIL',
};

const createUserPage = {
    'createUser.title': 'New account',
    'createUser.submit': 'CREATE',
    'createUser.cancel': 'CANCEL',
    'createUser.company.title': 'Company information',
    'createUser.company.name': 'Company name',
    'createUser.company.address': 'Address',
    'createUser.company.city': 'City',
    'createUser.company.postal': 'ZIP code',
    'createUser.company.country': 'Country',
    'createUser.company.country.slovakia': 'Slovakia',
    'createUser.contact.title': 'User',
    'createUser.contact.firstName': 'Name',
    'createUser.contact.lastName': 'Surname',
    'createUser.contact.birth': 'Date of birth',
    'createUser.contact.gender': 'Gender',
    'createUser.contact.gender.male': 'Male',
    'createUser.contact.gender.female': 'Female',
    'createUser.contact.gender.unspecified': 'Unspecified ',
    'createUser.contact.email': 'Email',
    'createUser.contact.phone': 'Phone (Format: +421903123123)',
    'createUser.identificators.title': 'Company identification',
    'createUser.identificators.ico': 'Business ID',
    'createUser.identificators.dic': 'Tax ID',
    'createUser.identificators.icDph': 'VAT number',
    'createUser.identificators.reg': 'Court of registry',
    'createUser.bank.title': 'Bank information',
    'createUser.bank.iban': 'IBAN',
    'createUser.bank.bic': 'BIC/SWIFT',
    'createUser.bank.name': 'Bank',
    'createUser.success': 'Account {0} {1} was created',
};

const settingsPage = {
    "settings.title": "Settings",
    "settings.update.success": "System settings have been updated",
    "settings.globalSettings.title": "Global settings",
    "settings.globalSettings.creditValue": "The value of 1 credit (EUR)",
    "settings.globalSettings.defaultSpotPlaybackPrice": "Global price for playing the spot (C)",
    "settings.globalSettings.commissionRate": "Global commission amount (%)",
    'settings.save': 'SAVE',
    'settings.cancel': 'CANCEL'
};

const userDetail = {
    'userDetail.title': '{userName}',
    'userDetail.accountStatus': 'Account status',
    'userDetail.ordersCount': 'Orders',
    'userDetail.printedPhotosCount': 'Printed photos',
    'userDetail.contactInfo': 'User',
    'userDetail.contactInfo.create': 'Contact information',
    'userDetail.orders': 'Orders',
    'userDetail.payments': 'Payments',
    'userDetail.activityHistory': 'Activity history',
    'userDetail.unblockUser.modalTitle': 'Unblock account',
    'userDetail.unblockUser.modalText': 'You are going to unblock the account. Do you want to continue?',
    'userDetail.unblockUser.modalConfirm': 'Unblock',
    'userDetail.blockUser.modalTitle': 'You realy want to block account?',
    'userDetail.blockUser.modalSubtitle': 'Block reason',
    'userDetail.blockUser.modalConfirm': 'BLOCK',
    'userDetail.deleteUser.modalTitle': 'You realy want to remove account?',
    'userDetail.deleteUser.modalConfirm': 'REMOVE',
    'userDetail.headerActions.blockUser': 'BLOCK ACCOUNT',
    'userDetail.headerActions.allowUser': 'UNBLOCK ACCOUNT',
    'userDetail.headerActions.resetPassword': 'RESET PASSWORD',
    'userDetail.headerActions.deleteUser': 'REMOVE ACCOUNT',
    'userDetail.accountStatus.paymentList': 'PAYMENT LIST',
    'userDetail.contactInfo.firstName': 'Name',
    'userDetail.contactInfo.lastName': 'Surname',
    'userDetail.contactInfo.gender': 'Gender',
    'userDetail.contactInfo.email': 'E-mail',
    'userDetail.orders.id': 'ID',
    'userDetail.orders.date': 'Date',
    'userDetail.orders.size': 'Amount',
    'userDetail.orders.actions': 'Actions',
    'userDetail.orders.detail': 'Detail',
    'userDetail.orders.list': 'ORDER LIST',
    'userDetail.transactions.list': 'TRANSACTIONS LIST',
    'userDetail.invoice.pair': 'PAIR INVOICE',
    'userDetail.accountStatus.title': 'Account status',
    'userDetail.accountStatus.registrationService': 'Registration service',
    'userDetail.accountStatus.blocation': 'Blocked',
    'userDetail.provision': 'Account balance',
    'userDetail.processedPhotosCount': 'Processed photos',
    'userDetail.photomatsCount': 'Players',
    'userDetail.photomatsList': 'Player list',
    'userDetail.photomats.id': 'ID',
    'userDetail.photomats.status': 'Status',
    'userDetail.photomats.hearthbeat': 'Heartbeat',
    'userDetail.photomats.actions': 'Actions',
    'userDetail.photomats.showList': 'PLAYER LIST',
    'userDetail.totalSales': 'Total revenue',
    'userDetail.transactions': 'Transactions',
    'userDetail.campaigns': 'Campaigns',
    'userDetail.activeFreePhotoCampaigns': 'Active FreeFoto campaigns',
    'userDetail.totalFreePhotoCampaigns': 'Total FreeFoto campaigns',
    'userDetail.activeQrCouponsCampains': 'Active coupon sets',
    'userDetail.totalQrCouponsCampains': 'Total coupon sets',
    'userDetail.buttons.cancel': 'CANCEL',
    'userDetail.buttons.confirm': 'SAVE',
    'userDetail.resetPassword.success': 'A password reset link has been sent to the email: {0}',
    'userDetail.userUpdate.success': 'Account information has been updated',
    "userDetail.statusCard.title": "Basic information",
    "userDetail.statusCard.status": "Account status",
    "userDetail.statusCard.numberOfCredits": "Coupon credit",
    "userDetail.statusCard.numberOfSubscriptionCredits": "Subscription credit",
    "userDetail.statusCard.deleteModal.text": "are you sure you want to cancel the subscription for the user?",
    "userDetail.statusCard.subscriptionStatus": "Subscription account status",
    "userDetail.statusCard.subscriptionStatus.activated": "Activated",
    "userDetail.statusCard.subscriptionStatus.unactivated": "without subscription",
    "userDetail.statusCard.subscriptionStatus.canceled": "canceled",
    "userDetail.statusCard.numberOfOrders": "Number of orders",
    "userDetail.statusCard.numberOfPhotos": "Number of printed photos",
    "userDetail.statusCard.registrationDate": "Registration date",
    "userDetail.statusCard.registrationType": "Registration type",
    "userDetail.statusCard.lastActivity": "Last activity",
    "userDetail.statusCard.creditTransfer": "Transfer credit",
    "userDetail.statusCard.subscriptionDelete": "Delete subscription",
    "userDetail.statusCard.operationList": "Operations list",
    "userDetail.statusCard.sourceUser": "Source user",
    "userDetail.statusCard.destinationUser": "Destination user",
    "userDetail.statusCard.sendCredits": "Number of transfer credits:",
    "userDetail.statusCard.remains": "remains",
    "userDetail.statusCard.credits": "credits",
    "userDetail.statusCard.lessThanZero": "User not have enough",
    "userDetail.statusCard.chooseUser": "Choose user...",
    "userDetail.statusCard.maxCredits": "Number of credits: max.",
    "userDetail.statusCard.confirmButton": "Confirm",
    "userDetail.statusCard.modalTitle": "You are trying to transfer credit between users",
    "userDetail.statusCard.successTransform": "Credits have been successfully transferred between users",
    "userDetail.statusCard.successDeactivateSubscription": "User subscription was deactivated",
    "userDetail.companyTable.title": "Companies",
    "userDetail.storeTable.title": "Stores",
    "userDetail.toast.block.success": "Account was blocked",
    "userDetail.toast.unblock.success": "Account was unblocked",
    "userDetail.toast.delete.success": "Account was removed",
    "userDetail.toast.delete.fail": "This action is not allowed",
};

const groups = {
    'groups.title': 'Groups and permissions',
    'groups.groups.title': 'Groups',
    'groups.update.title': 'Permission settings',
    'groups.table.name': 'Name',
    'groups.table.action': 'Action',
    'groups.table.actions': 'Actions',
    'groups.table.disabled': 'Disabled',
    'groups.table.own': 'Own',
    'groups.table.all': 'All',
    'groups.table.accounts': 'Accounts total',
    'groups.update.setGroup': 'Choosen group',
    'groups.choosenGroup': 'Choose group...',
    'groups.form.name': 'Group name',
    'groups.form.description': 'Group description',
    'groups.button.create': 'Create',
    'groups.button.delete': 'Delete',
    'groups.button.addGroup': 'Add group',
    'groups.deleteModal.title': 'Are you sure you want to delete this group?',
    'groups.deleteModal.body': 'The number of users in the group is',
    'groups.deleteModal.body2': 'If you delete a group, those users will lose the rights that belong to that group',
    'groups.createModal.title': 'Creating a group',
    'groups.success.update': 'Group Details was updated',
    'groups.success.create': 'The group has been created',
    'groups.success.delete': 'The group has been deleted',

    'groups.scope.company': 'Company',
    'groups.scope.device': 'Device',
    'groups.scope.store': 'Store',
    'groups.scope.zone': 'Zone',
    'groups.scope.campaign': 'Campaign',
    'groups.scope.playlist': 'Playlist',
    'groups.scope.display-group': 'Display group',
    'groups.scope.campaign': 'Campaign',
    'groups.scope.user': 'User',
    'groups.scope.invoice': 'Invoice',
    'groups.scope.payout': 'Payout',
    'groups.scope.commission': 'Commission',
    'groups.scope.operation': 'Operation',
    'groups.scope.stream': 'Stream',
    'groups.scope.media': 'Media',
    'groups.scope.user-group': 'Group',
    'groups.scope.settings': 'Settings',
    'groups.scope.overview': 'Overview',

    'groups.action.list': 'List',
    'groups.action.detail': 'Detail',
    'groups.action.create': 'Create',
    'groups.action.update': 'Update',
    'groups.action.approve': 'Approve',
    'groups.action.reject': 'Reject',
    'groups.action.cancel': 'Cancel',
    'groups.action.discover': 'Discover',
    'groups.action.restart': 'Restart',
    'groups.action.delete': 'Delete',
    'groups.action.enable': 'Enable',
    'groups.action.disable': 'Disable',
    'groups.action.upload': 'Upload',
    'groups.action.download': 'Download',
    'groups.action.export': 'Export',
    'groups.action.block': 'Block',
    'groups.action.unblock': 'Unblock',
    'groups.action.identify': 'Identify',
    'groups.action.identify': 'Terminal',
    'groups.action.password-reset': 'Password reset',
    'groups.action.manage-assignment': 'Assign to store',
    'groups.action.manage-members': 'Assign / unassign users',
}

const devices = {
    'deviceList.title': 'Players',
    'deviceList.header': 'Player list',
    'deviceList.table.title': 'Player list',
    'deviceList.assignDevice': 'ASSIGN PLAYER',
    'deviceList.discoverDevice': 'SEARCH STOCK',
    'deviceList.table.active': 'Active',
    'deviceList.table.changePartnerSuccess': 'Partner changed successfuly',
    'deviceList.table.changePartnerFailed': 'Failed to change partner',
    'deviceList.table.ID': 'ID',
    'deviceList.table.state': 'Status',
    'deviceList.table.heartbeat': 'Heartbeat',
    'deviceList.table.type': 'Type',
    'deviceList.table.clientVersion': 'Version',
    'deviceList.table.partner': 'Partner',
    'deviceList.table.company': 'Company',
    'deviceList.table.store': "Store",
    'deviceList.table.printedPhotos': 'Printed Photos',
    'deviceList.table.remainingPhotos': 'Zostáva',
    'deviceList.table.playerStatus': 'Video',
    'deviceList.table.placement': 'Location',
    'deviceList.table.actions': 'Actions',
    'deviceList.table.balenaId': 'Balena ID',
    'devicesList.filter.active': 'Active',
    'devicesList.filter.nonActive': 'Deactive',
    'deviceList.table.actions.detail': 'Detail',
    'deviceList.enabledToggle': 'Store',
    'deviceList.disabledToggle': 'Stock',
    'deviceList.assignModal.title': 'Assign player',
    'deviceList.assignModal.assign': 'ASSIGN',
    'deviceList.assignModal.cancel': 'CANCEL',
    'deviceList.assignModal.partner': 'Choose partner...',
    'deviceList.assignModal.device': 'Choose player...',
    'deviceList.discoverModal.title': 'Stock status',
    'deviceList.discoverModal.textNo': 'No new Players have been added',
    'deviceList.discoverModal.textYes': 'Number of new Players to the stock: {0}',
    'deviceDetail.title': 'Player',
    'deviceDetail.status.deviceState': 'Player status',
    'deviceDetail.status.lastHeartbeat': 'Latest heartbeat',
    'deviceDetail.status.remainingPhotoCount': 'Remaining photos',
    'deviceDetail.status.printerCounter': 'Printer counter',
    'deviceDetail.status.photosPerMonth': 'Photos per month',
    'deviceDetail.status.restart': 'RESTART',
    'deviceDetail.status.deactivate': 'DEACTIVATE',
    'deviceDetail.status.identify': 'IDENTIFY',
    'deviceDetail.status.console': 'TERMINAL',
    'deviceDetail.status.reportError': 'Report error',
    'deviceDetail.status.activate': 'Activate',
    'deviceDetail.status.back': 'Back',
    'deviceDetail.status.restartModal.title': 'Restart player',
    'deviceDetail.status.restartModal.text': 'Do you want to restart the player?',
    'deviceDetail.status.deactivateModal.title': 'Deactivate player',
    'deviceDetail.status.deactivateModal.text': 'Do you want to deactivate the player',
    'deviceDetail.status.activateModal.title': 'Activate player',
    'deviceDetail.status.activateModal.text': 'Do you want to activate the player?',
    'deviceDetail.partner.title': 'Partner',
    'deviceDetail.partner.printerPhotos': 'Printer photo count',
    'deviceDetail.partner.orderPhotos': 'Order photo count',
    'deviceDetail.partner.changePartner': 'CHANGE PARTNER',
    'deviceDetail.partner.removePartner': 'REMOVE PARTNER',
    'deviceDetail.partner.detailPartner': 'PARTNER DETAIL',
    'deviceDetail.partner.changePartnerModal.title': 'Change partner',
    'deviceDetail.partner.changePartnerModal.action': 'CHANGE',
    'deviceDetail.partner.removePartnerModal.title': 'Remove partner',
    'deviceDetail.partner.removePartnerModal.text': 'Do you want to remove the partner?',
    'deviceDetail.partner.removePartnerModal.action': 'REMOVE',
    'deviceDetail.partner.back': 'Back',
    'deviceDetail.orders.title': 'Orders',
    'deviceDetail.orders.ordersList': 'Order list',
    'deviceDetail.orders.table.id': 'ID',
    'deviceDetail.orders.table.date': 'Date',
    'deviceDetail.orders.table.volume': 'Amount',
    'deviceDetail.orders.table.actions': 'Actions',
    'deviceDetail.orders.table.actions.detail': 'Detail',
    'deviceDetail.provisions.title': 'Commissions',
    'deviceDetail.provisions.totalProvision': 'Total commission',
    'deviceDetail.provisions.provisionsList': 'Commission list',
    'deviceDetail.address.title': 'Location address',
    'deviceDetail.address.placeholder': "Enter address",
    'deviceDetail.address.undefinedLocation': "Location not specified",
    'deviceDetail.partner.removePartnerSuccessful': 'Partner removed successfuly',
    'deviceDetail.partner.removePartnerFailed': 'Failed to remove partner',
    'deviceDetail.partner.changePartnerSuccessful': 'Partner changed  successfuly',
    'deviceDetail.partner.changePartnerFailedl': 'Failed to change partner',
    'deviceDetail.status.deviceDeactivationSuccessful': 'Deactivated successfuly',
    'deviceDetail.status.deviceActivationSuccessful': 'Activated successfuly',
    'deviceDetail.status.deviceDeactivationFailed': 'Failed to deactivate',
    'deviceDetail.status.deviceActivationFailed': 'Failed to activate',
    'deviceDetail.status.deviceRestartSuccessful': 'Restarted successfuly',
    'deviceDetail.status.deviceRestartFailed': 'Failed to restart',
    'deviceDetail.partner.changeAddressFailed': 'Failed to change address',
    'deviceDetail.partner.changeAddressSuccessful': 'Address changed successfuly',
    'deviceDetail.player.changePlayer.title': 'Change player',
    'deviceDetail.player.changePlayer.text': 'To the operation {name of the operation} ({ID of operation}) have been assigned player {ID of player}. Choose new player: ',
    'deviceDetail.player.newPlayer.text': 'Please choose new player for this Store: ',
    'deviceDetail.player.changePlayer.change': 'Change',
    'deviceDetail.player.assignPlayer.title': 'Assign player',
    'deviceDetail.player.assignPlayer.text': 'To the operation {name of the operation} ({ID of operation}) assign new player: ',
    'deviceDetail.player.assignPlayer.assign': 'Assign',
    "deviceDetail.player.assignPlayer.change": "Change",
    "deviceDetail.player.withoutPlayer.title": "Without Player",
    'deviceDetail.player.assignPlayerSuccessful': 'Player assigned successfully',
    "deviceDetail.store.detail": "Store detail",
    "deviceList.removeDevice": "Remove",
    "deviceList.assignModal.store": "Choose store...",
    "deviceDetail.status.removeModal.text-1": "You really want to remove player",
    "deviceDetail.status.removeModal.text-2": "from store",
    "deviceDetail.status.remove": "Remove",
    "deviceDetail.status.deviceRemoveSuccessful": "Player remove successful",
    "deviceDetail.deviceStatusCard.deviceActivated": "Activated",
    "deviceDetail.deviceStatusCard.deviceDeactivated": "Deactivated",
    "deviceDetail.deviceStatusCard.deviceAppRunning": "Running",
    "deviceDetail.deviceStatusCard.deviceAppStopped": "Stopped",
    "deviceDetail.deviceStatusCard.deviceApp": "Player App",
    "deviceDetail.status.removeModal.title": "Remove player",
    "deviceDetail.success.browseStock": "The stock with Players was searched",
    'deviceDetail.status.playbackStatus': 'Playback status',
    "deviceDetail.devicePlayback.noplay": "No playing",
    "deviceDetail.devicePlayback.online": "Playing",
    "deviceDetail.devicePlayback.error": "Error",
    "deviceDetail.statusCard.volume": "Playback volume",
    "deviceDetail.success.deviceIdentify": "The green LED on the selected device lights up for 5 seconds.",    
    'deviceList.playerStatus.tooltip.online': 'Video is playing',
    'deviceList.playerStatus.tooltip.offline': 'Video does not play',
    'deviceList.deviceStatus.tooltip.online': 'Player is online',
    'deviceList.deviceStatus.tooltip.offline': 'Player is online',

    "storeDetail.deviceStatusCard.deviceType": "Device type",
    "storeDetail.deviceStatusCard.balenaID": "Balena ID",
    "storeDetail.deviceStatusCard.clientVersion": "Client version",
    "storeDetail.deviceInfoCard.title": "Basic info",
}

const campaigns = {
    "campaigns.title": "Campaigns",
    "campaigns.header": "Campaign list",
    "campaigns.button.new": "New campaign",
    "campaigns.storesCard.title": "Stores",
    "campaigns.storesCard.table.actions": "Action",
    "campaigns.storesCard.table.actions.detail": "Detail",
    "campaigns.storesCard.table.actions.remove": "Remove",
    "campaigns.storesCard.table.addStore":"Add store",
    "campaigns.storesCard.table.addStore.save":"Add",
    "campaigns.storesCard.table.ID": "ID",
    "campaigns.storesCard.table.name": "Name",
    "campaigns.storesCard.table.address": "Address",
    "campaigns.storesCard.table.zones": "Zones",
    "campaigns.stores.removeModal.text": "Remove from campaign store",
    "campaigns.stores.addModal.title":"Choose store",
    "campaigns.stores.removeModal.title":"Remove store",
    'campaigns.displayCard.title': "Display groups",
    "campaigns.displayCard.table.ID": "ID",
    "campaigns.displayCard.table.name": "Name",
    "campaigns.displayCard.table.description": "Description",
    "campaigns.displayCard.table.changeButton": "Change",
    "campaigns.displayCard.changeModal.title": "Choose display groups",
    "campaigns.displayCard.changeModal.save": "Save",
    "campaigns.calendarCard.updateModal.reset": "Reset",
    "campaigns.calendarCard.updateModal.clear": "Clear",
    "campaigns.calendarCard.update": "Update",
    "campaigns.calendarCard.title": "Playback calendar",
    "campaigns.calendarCard.updateModal.title": "Playback calendar",
    "campaigns.calendarCard.updateModal.save": "Save",
    "campaigns.calendarCard.updateModal.weekends": "Weekends",
    "campaigns.calendarCard.updateModal.workDays": "Monday - friday",
    "campaigns.calendarCard.updateModal.month": "Whole month",
    "campaigns.mediaCountCard.title": "Play count",
    "campaigns.mediaCountCard.table.ID": "ID",
    "campaigns.mediaCountCard.table.name": "Name",
    "campaigns.mediaCountCard.table.count": "Played",
    "campaigns.removeModal.remove": "Remove",
    "campaigns.removeModal.cancel": "Cancel",
    "campaigns.removeModal.yes": "Remove",
    "campaigns.removeModal.no": "Cancel",
    "campaigns.wizard.button.submit": "Submit",
    "campaigns.wizard.button.continue": "Continue",
    "campaigns.wizard.title": "New campaign",
    "campaigns.wizard.step_1.title": "Basic info",
    "campaigns.wizard.step_2.title": "Stores",
    "campaigns.wizard.step_3.title": "Display groups",
    "campaigns.wizard.step_4.title": "Media",
    "campaigns.wizard.step_5.title": "Calendar",
    "campaigns.wizard.step_6.title": "Summary",
    "campaigns.detail.success.update": "Campaign was updated",
    "campaigns.wizard.finalStep.media": "Media",
    "campaigns.wizard.finalStep.description": "Description",
    "campaigns.wizard.finalStep.name": "Name",
    "campaigns.wizard.finalStep.company": "Company",
    "campaigns.wizard.basicInfo": "Basic info",
    "campaigns.wizard.finalStep.calendar": "Calendar",
    "campaigns.new": "New campaign",
    "campaigns.duplicate.button": "Duplicate campaign",
    "campaigns.detail": "Detail",
    "campaigns.internal.title": "Internal campaigns",
    "campaigns.internal.header": "Campaign list",
    "campaigns.commercial.title": "Commercial campaigns",
    "campaigns.commercial.header": "Campaign list",
    "campaigns.detail.basicInfo.title": "Basic information",
    "campaigns.detail.budget.title": "Budget",
    "campaigns.detail.spots.title": "Campaign spots",
    "campaigns.detail.schedule.title": "Time schedule",
    "campaigns.detail.stores.title": "Location and stores",
    "campaigns.detail.company.title": "Company",
    "campaigns.detail.map.title": "Stores map",
    "campaigns.budgetCard.title": "Budget",
    "campaigns.budgetCard.budgetTotal": "Max campaign budget",
    "campaigns.budgetCard.budgetSpent": "Spent budget",
    "campaigns.budgetCard.budgetRemaining": "Remaining budget",
    "campaigns.budgetCard.AvgBid": "Average spot play price",
    "campaigns.budgetCard.MaxBid": "Max spot play price",
    "campaigns.budgetCard.balanceOfCredits": "Balance of credits: ",
    "campaigns.budgetCard.lackOfCredits": "Balance of credits: The company does not have enough credits",
    "campaigns.spotsCard.title": "Campaign spots",
    "campaigns.spotsCard.count": "runs",
    "campaigns.companyCard.title": "Company",
    "campaigns.companyCard.name": "Company",
    "campaigns.companyCard.address": "Address",
    "campaigns.companyCard.contact": "Contact",
    "campaigns.companyCard.phone": "Phone",
    "campaigns.companyCard.email": "E-mail",
    "campaigns.companyCard.details.button": "Company detail",
    "campaigns.infoCard.title": "Basic information",
    "campaigns.infoCard.owner": "Ordered by",
    "campaigns.scheduleCard.title": "Time schedule",
    "campaigns.scheduleCard.repeat": "Repeat period (min)",
    "campaigns.mapCard.title": "Store map",
    "campaigns.targetingCard.title": "Location and stores",
    "campaigns.targetingCard.placeholder.location": "Location",
    "campaigns.targetingCard.placeholder.category": "Store category",
    "campaigns.targetingCard.placeholder.company": "Company",
    "campaigns.targetingCard.placeholder.store": "Store",
    "campaigns.new.createButton": "Create campaign",
    "campaigns.new.cancelButton": "Cancel",
    "campaigns.delete.response.success": "Campaign was successfully removed",
    "campaigns.cancel.response.success": "Campaign was successfully finished",
    "campaigns.delete.response.error": "Campaign was not removed",
    "campaigns.submit": "Submit",
    "campaigns.freefoto.title": "Free Foto campaigns",
    "campaigns.freefoto.header": "FF campaigns list",
    "campaigns.qr.title": "Coupon campaigns",
    "campaigns.qr.header": "Coupon campaigns list",
    "campaigns.coupons.header": "Unassigned coupon sets list",
    "campaigns.new.freeFoto.title": "New Free Foto campaign",
    "campaigns.new.qr.title": "New Coupon campaign",
    "campaigns.status.running": "Running",
    "campaigns.status.awaiting": "Processes",
    "campaigns.status.rejected": "Rejected",
    "campaigns.status.finished": "Finished",
    "campaigns.status.scheduled": "Scheduled",
    "campaigns.status.inactive": "Inactive",
    "campaigns.table.ID": "ID",
    "campaigns.table.name": "Name",
    "campaigns.table.owner": "Owner",
    "campaigns.table.type": "Type",
    "campaigns.table.status": "Status",
    "campaigns.table.start": "Start date",
    "campaigns.table.end": "End date",
    "campaigns.table.maxPrice": "Max. price",
    "campaigns.table.budget": "Budget",
    "campaigns.table.cupons": "Coupons",
    "campaigns.table.couponCount": "Coupons count",
    "campaigns.table.couponNominalValue": "Nom. value",
    "campaigns.table.author": "Author",
    "campaigns.table.updatedAt": "Date",
    "campaigns.table.totalSetValue": "Total (C)",
    "campaigns.newCoupons.nominalValue": "Nominal value (C)",
    "campaigns.newCoupons.title": "Create coupons set",
    "campaigns.newCoupons.create": "Create",
    "campaigns.newCoupons.success": "Coupons set was created",
    "campaigns.button.newCampaign": "New campaign",
    "campaigns.button.newCouponSet": "New coupons set",
    "campaigns.toggle.couponSet": "Coupons sets",
    "campaigns.toggle.qrCampaign": "Coupon campaigns",
    "campaigns.approved.response.success": "Campaign was approved",
    "campaigns.rejected.response.success": "Campaign was rejected",
    "campaigns.end.button": "Close campaign",
    "campaigns.approved.button": "Approve campaign",
    "campaigns.rejected.button": "Reject campaign",
    "campaigns.removeModal.text": "Do you really want to close campaign",
    "campaigns.rejectedModal.text": "Do you really want to reject campaign",
    "campaigns.approvedModal.text": "Do you really want to approve campaign",
    "campaigns.pictureCard.title": "Preview",
    "campaigns.creditChart.title": "Credits",
    "campaigns.creditChart.remainingCredits": "Remaining credits",
    "campaigns.creditChart.usedCredits": "Used credits",
    "campaigns.photosChart.title": "Copies",
    "campaigns.photosChart.remainingPhotos": "Remaining",
    "campaigns.photosChart.usedPhotos": "Used",
    "campaigns.photosChart.allocatedPhotos": "Allocated",
    "campaigns.infoCard.name": "Campaign name",
    "campaigns.infoCard.company": "Campaign customer:",
    "campaigns.infoCard.contact": "Contact:",
    "campaigns.infoCard.startDate": "Start date",
    "campaigns.infoCard.endDate": "End date",
    "campaigns.infoCard.description": "Description",
    "campaigns.infoCard.createdBy": "Created by:",
    "campaigns.infoCard.createdAt": "Created at:",
    "campaigns.infoCard.updatedAt": "Updated at:",
    "campaigns.mediaCard.title": "List and order of media in campaign",
    "campaigns.mediaCard.addMedia": "Add media",
    "campaigns.budgetCard.account": "Account:",
    "campaigns.budgetCard.qrBudget": "Number of credits:",
    "campaigns.budgetCard.maxPhotos": "Max. copies:",
    "campaigns.budgetCard.freeFotoBudget": "Number of copies:",
    "campaigns.coupons.removeWarning": "Are you sure you want to delete the coupon set?",
    "campaigns.coupons.removeButton": "Delete",
    "campaigns.coupons.removeSuccess": "Coupon Set was deleted",
    "campaigns.graficCard.errorResolution": "You have selected an incorrect resolution image",
    "campaigns.graficCard.successUpload": "Ad banner loaded successfully",
    "campaigns.graficCard.title": "Campaign graphics: ",
    "campaigns.graficCard.rules": "Rules: ",
    "campaigns.graficCard.changeRules": "Choose other picture: ",
    "campaigns.graficCard.firstRule": "1.) Advertising banner resolution: 600 x 1200 px",
    "campaigns.graficCard.secondRule": "2.) Supported formats: .jpg, .png",
    "campaigns.graficCard.choosePicture": "Press the button to choose a picture: ",
    "campaigns.graficCard.changePicture": "Press the button to change a picture: ",
    "campaign.new.successCreated": "Campaign was created",
    "campaigns.setCouponSet.button": "Set coupons",
    "campaigns.setCouponSet.assigned": "Set",
    "campaigns.setCouponSet.label": "Unassigned coupons",
    "campaigns.setCouponSet.placeholder": "Choose coupon set...",
    "campaigns.setCouponSet.success": "Coupon set was assigned",
    "campaigns.setCouponSet.confirm": "Are you sure you want to assign this coupon set to your campaign?",
    "campaigns.setCouponSet.choosenCouponSet": "Selected set of coupons:",
    "campaigns.setCouponSet.credits": "Credit balance:",
    "campaigns.couponsChart.title": "Coupons",
    "campaigns.couponsChart.remainingCoupons": "Remaining coupons",
    "campaigns.couponsChart.usedCoupons": "Used coupons",
    "campaigns.couponsChart.allCoupons": "All coupons",
    "campaigns.couponsChart.remainingBudget": "Remaining credits",
    "campaigns.couponsChart.allocatedBudget": "Allocated credits",
    "campaigns.couponsChart.allBudget": "All credits",
    "campaigns.couponsChart.usedBudget": "Used credits",
    "campaigns.couponsChart.unusedBudget": "Unused credits",
    "campaigns.coupons.couponSetExport": "Coupon set was exported to CSV file",
    "campaigns.couponsChart.noCoupons": "No coupon set assigned",
    "campaigns.couponsChart.noAllocated": "No credits allocated or used",
    "campaigns.couponsTable.title": "Coupons List",
    "campaigns.couponWidget.title": "Coupon sets",
    "campaigns.table.couponIdDetail": "ID",
    "campaigns.table.couponStatusDetail": "Status",
    "campaigns.table.couponNominalValueDetail": "Value",
    "campaigns.table.couponCountDetail": "Count",
    "campaigns.table.couponUsedDetail": "Used",
    "campaigns.table.totalSetValueDetail": "Credit",
    "campaigns.table.couponUsedCreditsDetail": "Used cr.",
    "campaigns.table.actions": "Actions",
    "campaigns.table.detailButton": "Detail",
    "campaigns.table.exportButton": "Export",
    "campaigns.table.couponDate": "Use",
    "campaigns.table.couponUser": "User",
    "campaigns.table.couponToken": "Token",
    "campaigns.table.coupon.active": "Active",
    "campaigns.table.coupon.depleted": "Depleted",
    "campaigns.table.coupon.used": "Used",
    "campaigns.table.coupon.unused": "Unused",
    "campaigns.couponSetDetail.title": "Coupon set",
    "campaigns.couponSetDetail.id": "Coupon set ID:",
    "campaigns.couponSetInfo.couponsNumber": "Total coupons",
    "campaigns.couponSetInfo.couponsNominalValue": "Niminal value",
    "campaigns.couponSetInfo.couponsTotalValue": "Total value",
    "campaigns.couponSetUse.title": "Use",
    "campaigns.couponSetUse.couponsAllNumber": "Total coupons",
    "campaigns.couponSetUse.couponsUsedNumber": "Used coupons",
    "campaigns.couponSetUse.couponsRemainigNumber": "Remaining coupons",
    "campaigns.couponSetUse.couponsAllCredits": "Total credits",
    "campaigns.couponSetUse.couponsUsedCredits": "Used credits",
    "campaigns.couponSetUse.couponsRemainingCredits": "Remaining credits",
    "campaigns.couponSetInfo.table": "Coupons",
    "campaigns.couponSetDetail.exportButton": "Export",
    "campaigns.unassignedCoupons.couponSet": "Coupon set",
    "campaigns.unassignedCoupons.value": "Value",
    'campaigns.companyCard.groups': 'Groups'
}

const dashboard = {
    "dashboardPage.title": "Dashboard",
    "dashboardCard.campaigns.title": "Campaigns",
    "dashboardCard.commercialCampaigns.button": "Commercial campaigns",
    "dashboardCard.internalCampaigns.button": "Internal campaigns",
    "dashboardCard.campaigns.allCommercialCampaigns": "Active commercial campaigns:",
    "dashboardCard.campaigns.awaitingCommercialCampaigns": "Awaiting commercial campaigns:",
    "dashboardCard.campaigns.allInternalCampaigns": "Active internal campaigns:",
    "dashboardCard.campaigns.awaitingInternalCampaigns": "Awaiting internal campaigns:",
    "dashboardCard.devices.title": "Devices",
    "dashboardCard.devices.button": "Devices",
    "dashboardCard.devices.allDevices": "All devices:",
    "dashboardCard.devices.devicesOnStores": "Devices on stores:",
    "dashboardCard.devices.devicesOnRepository": "Devices on repository:",
    "dashboardCard.devices.offlineDevices": "Offline devices:",
    "dashboardCard.companies.title": "Companies and stores",
    "dashboardCard.companies.button": "Companies",
    "dashboardCard.companies.allCompanies": "All companies",
    "dashboardCard.stores.button": "Stores",
    "dashboardCard.stores.allStores": "All stores",
    "dashboardCard.stores.storesWithoutDevice": "Stores without device",
    "dashboardCard.users.title": "Partners",
    "dashboardCard.users.button": "Partners",
    "dashboardCard.users.allUsers": "All partners:",
    "dashboardCard.users.activatedUsers": "Active partners:",
    "dashboardCard.users.unactivatedUsers": "Unactive partners:",
    "dashboardCard.users.blockedUsers": "Blocked partners:",
    "dashboardCard.media.title": "Medias",
    "dashboardCard.streams.button": "Streams",
    "dashboardCard.streams.allStreams": "All streams:",
    "dashboardCard.streams.mostListenedStream": "Most listened stream:",
    "dashboardCard.media.button": "Media",
    "dashboardCard.media.allMedia": "All media:",
    "dashboardCard.media.awaitingMedia": "Awaiting media:",
    "dashboardCard.invoices.title": "Invoices and Payouts",
    "dashboardCard.invoices.button": "Invoices",
    "dashboardCard.invoices.allInvoices": "All invoices:",
    "dashboardCard.invoices.invoicesVolume": "Invoices volume:",
    "dashboardCard.payouts.button": "Payouts",
    "dashboardCard.payouts.allPayouts": "All payouts:",
    "dashboardCard.payouts.payoutsVolume": "Payouts volume:",
    "dashboardCard.commissions.title": "Commissions",
    "dashboardCard.commissions.button": "Commissions",
    "dashboardCard.commissions.allCommissions": "All commissions:",
    'dashboardCard.playlist.button': 'Playlists',
    "dashboardCard.playlist.title": "Playlists",
    "dashboardCard.playlist.allPlaylist": "All playlists:",
    "dashboardCard.playlist.awaitingPlaylists": "Unassigned playlists:"
};

const companies = {
    'companyList.title': 'Company',
    'companyList.table.title': 'Company list',
    "companyList.table.allCompanies": "Companies list",
    "companyList.table.addCompany": "Create company",
    'companyList.table.active': 'Active',
    'companyList.table.changePartnerSuccess': 'Partner changed successfuly',
    'companyList.table.changePartnerFailed': 'Failed to change partner',
    'companyList.table.ID': 'ID',
    'companyList.table.name': 'Title',
    'companyList.table.category': 'Category',
    'companyList.table.stores': 'Stores',
    'companyList.table.address': 'Address',
    'companyList.table.state': 'Status',
    'companyList.table.partner': 'Partner',
    'companyList.table.company': 'Company',
    'companyList.table.placement': 'Location',
    'companyList.table.actions': 'Actions',
    'companyList.table.actions.detail': 'Detail',
    'companyList.enabledToggle': 'Operation',
    'companyList.disabledToggle': 'Stock',
    'companyList.addModal.title': 'CREATE COMPANY',
    'companyList.addModal.add': 'ADD',
    'companyList.addModal.cancel': 'CANCEL',
    'companyList.assignModal.partner': 'Partner',
    'companyList.assignModal.operation': 'Operation',
    "companyList.assignModal.company": "Choose company...",
    'companiesList.filter.active': 'Active',
    'companiesList.filter.nonActive': 'Inactive',
    "company.partner.assign.success": "Partner successfully assigned",
    "company.partner.remove.success": "Partner removed",
    "company.store.assign.success": "Store successfully assigned",
    "companyList.addCompany.title": "New Company",
    'companyDetail.title': 'Company',
    'companyDetail.status.operationState': 'Company status',
    'companyDetail.status.restart': 'RESTART',
    'companyDetail.status.deactivate': 'DEACTIVATE',
    'companyDetail.status.reportError': 'Report error',
    'companyDetail.status.activate': 'Activate',
    'companyDetail.status.back': 'Back',
    'companyDetail.status.restartModal.title': 'Restart company',
    'companyDetail.status.restartModal.text': 'Do you want to restart the company?',
    'companyDetail.status.deactivateModal.title': 'Deactivate company',
    'companyDetail.status.deactivateModal.text': 'Do you want to deactivate the company?',
    'companyDetail.status.activateModal.title': 'Activate company',
    'companyDetail.status.activateModal.text': 'Do you want to activate the company?',
    'companyDetail.partner.title': 'Partner',
    'companyDetail.partner.printerPhotos': 'Printer photo count',
    'companyDetail.partner.orderPhotos': 'Order photo count',
    'companyDetail.partner.changePartner': 'CHANGE PARTNER',
    'companyDetail.partner.removePartner': 'REMOVE PARTNER',
    'companyDetail.partner.detailPartner': 'PARTNER DETAIL',
    'companyDetail.partner.changePartnerModal.title': 'Change partner',
    'companyDetail.partner.changePartnerModal.action': 'CHANGE',
    'companyDetail.partner.removePartnerModal.title': 'Remove partner',
    'companyDetail.partner.removePartnerModal.text': 'Do you want to remove the partner?',
    'companyDetail.partner.removePartnerModal.action': 'REMOVE',
    'companyDetail.partner.back': 'Back',
    'companyDetail.orders.title': 'Orders',
    'companyDetail.orders.ordersList': 'Order list',
    'companyDetail.orders.table.id': 'ID',
    'companyDetail.orders.table.date': 'Date',
    'companyDetail.orders.table.volume': 'Amount',
    'companyDetail.orders.table.actions': 'Actions',
    'companyDetail.orders.table.actions.detail': 'Detail',
    'companyDetail.provisions.title': 'Commissions',
    'companyDetail.provisions.totalProvision': 'Total commission',
    'companyDetail.provisions.provisionsList': 'Commission list',
    'companyDetail.address.title': 'Location address',
    'companyDetail.address.placeholder': "Enter address",
    'companyDetail.address.undefinedLocation': "Location not specified",
    'companyDetail.partner.removePartnerSuccessful': 'Partner removed successfuly',
    'companyDetail.partner.removePartnerFailed': 'Failed to remove partner',
    'companyDetail.partner.changePartnerSuccessful': 'Partner changed  successfuly',
    'companyDetail.partner.changePartnerFailedl': 'Failed to change partner',
    'companyDetail.status.companyDeactivationSuccessful': 'Deactivated successfuly',
    'companyDetail.status.companyActivationSuccessful': 'Activated successfuly',
    'companyDetail.status.companyDeactivationFailed': 'Failed to deactivate',
    'companyDetail.status.companyActivationFailed': 'Failed to activate',
    'companyDetail.status.companyRestartSuccessful': 'Restarted successfuly',
    'companyDetail.status.companyRestartFailed': 'Failed to restart',
    'companyDetail.partner.changeAddressFailed': 'Failed to change address',
    'companyDetail.partner.changeAddressSuccessful': 'Address changed successfuly',
    'companyDetail.partnersTable.title': 'Partners List',
    'companyDetail.partnersTable.id': 'ID',
    'companyDetail.partnersTable.name': 'Name',
    'companyDetail.partnersTable.action': 'Action',
    'companyDetail.addressCard.name': 'Company name',
    'companyDetail.addressCard.address': 'Street and street number',
    'companyDetail.addressCard.postal': 'Postal Code',
    'companyDetail.addressCard.city': 'City',
    'companyDetail.addressCard.country': 'Country',
    'companyDetail.contactPersonCard.title': 'Contact person',
    'companyDetail.contactPersonCard.firstName': 'First name',
    'companyDetail.contactPersonCard.lastName': 'Last name',
    'companyDetail.contactPersonCard.phone': 'Phone  (Format: +421903123123)',
    'companyDetail.contactPersonCard.email': 'E-mail',
    'companyDetail.billingInfoCard.ico': 'IČO',
    'companyDetail.billingInfoCard.dic': 'DIČ',
    'companyDetail.billingInfoCard.icDPH': 'IČ DPH',
    'companyDetail.billingInfoCard.registrationCourt': 'Registration court',
    'companyDetail.billingInfoCard.iban': 'IBAN',
    'companyDetail.billingInfoCard.bankBIC': 'BIC/SWIFT',
    'companyDetail.billingInfoCard.bankName': 'Bank name',
    "companyDetail.accountStatus.title": "Account status",
    "companyDetail.accountStatus.status": "Bank account status: ",
    "companyDetail.accountStatus.stores": "Stores",
    "companyDetail.accountStatus.commissions": "Commissions",
    'companyDetail.btn.assignPartner': 'Assign partner',
    'companyDetail.btn.assign': 'ASSIGN',
    'companyDetail.btn.assignStore': 'Assign store',
    'companyDetail.btn.removeCompany': 'Remove company',
    'companyDetail.btn.remove': 'REMOVE',
    'companyDetail.store.title': 'Prevádzka',
    'companyDetail.company.removeCompanySuccessful': 'Company removed succesfully',
    'companyDetail.company.updateCompanySuccessful': 'Company updated succesfully',

}

const stores = {
    'storeList.title': 'Stores',
    'storeList.table.title': 'Store list',
    'storeList.table.active': 'Active',
    'storeList.table.changePartnerSuccess': 'Partner changed successfuly',
    'storeList.table.changePartnerFailed': 'Failed to change partner',
    'storeList.table.ID': 'ID',
    'storeList.table.name': 'Title',
    'storeList.table.partnerName': 'Name',
    'storeList.table.zones': 'Zones',
    'storeList.table.segment': 'Segment',
    "storeList.table.heartbeat": "Heartbeat",
    'storeList.table.address': 'Address',
    'storeList.table.state': 'Player status',
    'storeList.table.partner': 'Partner',
    'storeList.table.company': 'Company',
    "storeList.table.deviceStatus": "Devices",
    "storeList.table.playerStatus": "Video",
    'storeList.table.printedPhotos': 'Printed Photos',
    'storeList.table.placement': 'Location',
    'storeList.table.actions': 'Actions',
    'storeList.table.actions.detail': 'Detail',
    'storeList.table.actions.remove': 'Remove',
    'storeList.table.actions.removeWarning': "You are about to remove a company partner",
    'storeList.enabledToggle': 'Store',
    'storeList.disabledToggle': 'Stock',
    'storeList.assignModal.title': 'Add store',
    'storeList.assignModal.cancel': 'CANCEL',
    'storeList.assignModal.partner': 'Partner',
    'storeList.assignModal.operation': 'Store',
    'storeList.addModal.add': 'ADD',
    "storeList.addModal.title": "Add store",
    'storeList.addOperation.title': 'New store',
    'storeList.operator.name': 'Operator name',
    'storeList.partner.name': "Partner name",
    'storeList.company.name': 'Company name',
    'storeList.table.addStore': 'Create store',
    'storeList.table.allStore': 'Stores list',
    "storeList.online": "Online",
    "storeList.offline": "Offline",
    "storeList.playing": "Playing",
    "storeList.stopped": "Not playing",
    "storeList.partial": "Partial",
    "storeList.unassigned": "Without device",
    "storeList.withoutDevice": "Without device",
    "storeList.error": "Error",
    "storeList.addStore.title": "New store",
    "storeList.addStore.success": "Store {0} was created",
    'storeDetail.title': 'Store',
    'storeDetail.titleName': 'Store name',
    'storeDetail.category': 'Category',
    'storeDetail.area': 'Area of store(m2)',
    'storeDetail.status.operationState': 'Store status',
    'storeDetail.status.lastHeartbeat': 'Latest heartbeat',
    'storeDetail.status.remainingPhotoCount': 'Remaining photos',
    'storeDetail.status.printerCounter': 'Printer counter',
    'storeDetail.status.photosPerMonth': 'Photos per month',
    'storeDetail.status.restart': 'RESTART',
    'storeDetail.status.deactivate': 'DEACTIVATE',
    'storeDetail.status.reportError': 'Report error',
    'storeDetail.status.activate': 'Activate',
    'storeDetail.status.back': 'Back',
    'storeDetail.status.restartModal.title': 'Restart operation',
    'storeDetail.status.restartModal.text': 'Do you want to restart the operation?',
    'storeDetail.status.deactivateModal.title': 'Deactivate operation',
    'storeDetail.status.deactivateModal.text': 'Do you want to deactivate the operation?',
    'storeDetail.status.activateModal.title': 'Activate operation',
    'storeDetail.status.activateModal.text': 'Do you want to activate the operation?',
    'storeDetail.partner.title': 'Partner',
    'storeDetail.partner.printerPhotos': 'Printer photo count',
    'storeDetail.partner.orderPhotos': 'Order photo count',
    'storeDetail.partner.changePartner': 'CHANGE PARTNER',
    'storeDetail.partner.removePartner': 'REMOVE PARTNER',
    'storeDetail.partner.detailPartner': 'PARTNER DETAIL',
    'storeDetail.partner.changePartnerModal.title': 'Change partner',
    'storeDetail.partner.changePartnerModal.action': 'CHANGE',
    'storeDetail.partner.removePartnerModal.title': 'Remove partner',
    'storeDetail.partner.removePartnerModal.text': 'Do you want to remove the partner?',
    'storeDetail.partner.removePartnerModal.action': 'REMOVE',
    'storeDetail.partner.back': 'Back',
    'storeDetail.orders.title': 'Orders',
    'storeDetail.orders.ordersList': 'Order list',
    'storeDetail.orders.table.id': 'ID',
    'storeDetail.orders.table.date': 'Date',
    'storeDetail.orders.table.volume': 'Amount',
    'storeDetail.orders.table.actions': 'Actions',
    'storeDetail.orders.table.actions.detail': 'Detail',
    'storeDetail.provisions.title': 'Commissions',
    'storeDetail.provisions.totalProvision': 'Total commission',
    'storeDetail.provisions.provisionsList': 'Commission list',
    'storeDetail.address.title': 'Location address',
    'storeDetail.address.placeholder': "Enter address",
    'storeDetail.address.undefinedLocation': "Location not specified",
    "storeDetail.storeAddressCard.title": "Address",
    "storeDetail.storeAddressCard.address": "Street and number",
    "storeDetail.storeAddressCard.postal": "Postal",
    "storeDetail.storeAddressCard.city": "City",
    "storeDetail.storeAddressCard.cityPlaceholder": "Choose city...",
    "storeDetail.storeAddressCard.country": "Country",
    "storeDetail.storeAddressCard.latitude": "Latitude",
    "storeDetail.storeAddressCard.longtitude": "Longtitude",
    "storeDetail.updateStore.successful": "Store updated succesfully",
    'storeDetail.partner.removePartnerSuccessful': 'Partner removed successfuly',
    'storeDetail.partner.removePartnerFailed': 'Failed to remove partner',
    'storeDetail.store.addStoreSuccessful': 'Operation has been added successfuly',
    'storeDetail.partner.changePartnerFailed': 'Failed to change partner',
    'storeDetail.status.operationDeactivationSuccessful': 'Deactivated successfuly',
    'storeDetail.status.operationActivationSuccessful': 'Activated successfuly',
    'storeDetail.status.operationDeactivationFailed': 'Failed to deactivate',
    'storeDetail.status.operationActivationFailed': 'Failed to activate',
    'storeDetail.status.operationRestartSuccessful': 'Restarted successfuly',
    'storeDetail.status.operationRestartFailed': 'Failed to restart',
    'storeDetail.partner.changeAddressFailed': 'Failed to change address',
    'storeDetail.partner.changeAddressSuccessful': 'Address changed successfuly',
    "storeDetail.store.updatedSuccessful": "Store has been updated",
    'storeDetail.openingHours.title': 'Opening hours',
    'storeDetail.openingHours.open': 'Open',
    'storeDetail.openingHours.closed': 'Closed',
    'storeDetail.openingHours.holiday': 'Open during the holidays',
    "storeDetail.openingHours.specialClosed": "Special closed",
    'storeDetail.playtime.open': 'Start of playing since open',
    'storeDetail.playtime.close': 'End of playing before closure',
    'storeDetail.countAdSpots': 'Number of commercial spots',
    'storeDetail.storeCard.name': 'Store name',
    "storeDetail.storeCard.address": "Store address",
    'storeDetail.storeCard.category': 'Category',
    'storeDetail.storeCard.area': 'Store area (m2)',
    'storeDetail.storeCard.visitRate': 'Visit rate (person/hour)',
    'storeDetail.companyCard.title': 'Company',
    'storeDetail.companyCard.name': 'Company name',
    'storeDetail.companyCard.address': 'Company address',
    'storeDetail.companyCard.contactPerson': 'Name of contact person',
    'storeDetail.companyCard.phone': 'Phone  (Format: +421903123123)',
    'storeDetail.companyCard.email': 'E-mail',
    'storeDetail.partnerCard.firstName': 'First name',
    'storeDetail.partnerCard.lastName': 'Last name',
    'storeDetail.partnerCard.partnerAddress': `Partner's Adress`,
    'storeDetail.partnerCard.phone': 'Phone  (Format: +421903123123)',
    'storeDetail.partnerCard.email': 'E-mail',
    'storeDetail.partnerCard.addPartner': 'Create partner',
    'storeDetail.partnerCard.allPartners': 'Partners list',
    "storeDetail.partnerCard.allOrders": "Orders list",
    "storeDetail.partnerCard.allPayments": "All payments",
    'storeDetail.maintainer.title': 'Operator',
    'storeDetail.removeStore.text': 'Do you really want to remove the store',
    'storeDetail.contactPerson.title': 'Contact person',
    'storeDetail.billing.title': 'Billing information',
    'storeDetail.removeStore.title': 'Remove store',
    'storeDetail.removePlayer.title': 'Remove player',
    'storeDetail.removePlayer.successful': 'Player was removed successfully',
    'storeDetail.removeStore.successful': 'Store was removed successfully',
    'storeDetail.changePlayer.text-1': 'To the store ',
    'storeDetail.changePlayer.text-2': 'is assigned player',
    'storeDetail.changePlayer.text-3': 'Choose new player',
    'storeDetail.mediaCard.fileName': 'File name',
    "storeDetail.maintainerCard.title": "Contact person",
    "storeDetail.maintainerCard.firstName": "Name",
    "storeDetail.maintainerCard.lastName": "Surname",
    "storeDetail.maintainerCard.phone": "Phone number",
    "storeDetail.maintainerCard.email": "E-mail",
    "storeDetail.device.remove": "Remove",
    "storeDetail.commissionCard.allCommissions": "All commissions: ",
    "storeDetail.commissionCard.button": "Commission list ",
    "storeDetail.commissionCard.title": "Commissions",
    "storeDetail.commissionCard.com": "Commissions",
    "storeDetail.printedPhotosCard.title": "The number of photos printed in the last 30 days (dummy)",
    "storeDetail.printedPhotosCard.allPhotos": "Total",
    "storeDetail.printedPhotosCard.threshold": "Commission level threshold",
    "storeDetail.printedPhotosCard.photos": "Printed photos",
    "storeDetail.printedPhotosCard.day": "Day",
    "storeDetail.deviceStatusCard.title": "Player status",
    "storeDetail.deviceStatusCard.ID": "ID",
    "storeDetail.deviceStatusCard.status": "Player status",
    "storeDetail.deviceStatusCard.lastHeartbeat": "Last heartbeat",
    "storeDetail.deviceStatusCard.statusUpdatedAt": "Updated before:",
    "storeDetail.deviceStatusCard.days": "days",
    "storeDetail.deviceStatusCard.hours": "hours",
    "storeDetail.deviceStatusCard.minutes": "minutes",
    "storeDetail.deviceStatusCard.numberOfPhotos": "The remaining number of photos",
    "storeDetail.deviceStatusCard.printerCounter": "Printer counter",
    "storeDetail.deviceStatusCard.averagePhotos": "average number of photos per month",
    "storeDetail.deviceStatusCard.isEnabled": "Connection status",
    "storeDetail.deviceStatusCard.deviceDetail": "Player detail",
    "storeDetail.deviceStatusCard.deviceType": "Device type",
    "storeDetail.deviceStatusCard.balenaID": "Balena ID",

    "storeDetail.zoneTable.title": "Zones list",
    "storeDetail.createZone.title": "Add zone",
    "storeDetail.createZone.name": "Zone name",
    "storeDetail.createZone.button": "Add",
    "storeDetail.createZone.successful": "Zone added successfully",
    'storeDetail.statusCard.musicVolume': 'Playback volume',
    
    'storeList.playerStatus.tooltip.online': 'Video is playing',
    'storeList.playerStatus.tooltip.offline': 'Video does not play',
    'storeList.playerStatus.tooltip.partial': 'Video does not play on any player',
    'storeList.deviceStatus.tooltip.online': 'All players are online',
    'storeList.deviceStatus.tooltip.offline': 'All players are offline',
    'storeList.deviceStatus.tooltip.partial': 'One of the players is offline',
    'storeList.playerStatus.tooltip.without': 'No player is assigned to the store',
}


const displays = {
    "displayList.title": "Display Groups",
    "displayList.table.title": "Display Groups list",
    "displayList.table.ID": "ID",
    "displayList.table.name": "Name",
    "displayList.table.media": "Default media",
    "displayList.table.playlist": "Playlist",
    "displayList.table.company": "Company",
    "displayList.table.description": "Description",
    "displayList.table.actions": "Playlists actions",
    "displayList.table.actions.assign": "Assign",
    "displayList.table.actions.change": "Change video",
    "displayList.table.actions.delete": "Remove",
    "displayList.modal.media": "Media",
    "displayList.modal.title": "Assign a playlist to the selected display group",
    "displayList.modal.playlist": "Playlist",
    "displayList.modal.confirmButton": "Assign",
    "displayList.modal.cancelButton": "Cancel",
    "displayList.table.actions.delete": "Remove",
    "displayList.deleteModal.title": "You are about to remove a default media from a group of displays. Do you want to continue?",
    'displayList.assignPlaylist.success': "operation was successful"
}

const orders = {
    "orderList.title": "Orders",
    "orderList.table.title": "Orders list",
    "orderList.table.ID": "ID",
    "orderList.table.status": "Status",
    "orderList.table.created": "Created",
    "orderList.table.notCreated": "Not created",
    "orderList.table.email": "Email",
    "orderList.table.createdAt": "Created At",
    "orderList.table.expiration": "Expiration",
    "orderList.table.credit": "Credits",
    "orderList.table.type": "Type",
    "orderList.table.creditType": "Credit",
    "orderList.table.freeFotoType": "FreeFoto",
    "orderList.table.actions": "Actions",
    "orderList.table.actions.detail": "Detail",
    "orderDetail.credit.title": "Credit order",
    "orderDetail.freeFoto.title": "FreeFoto order",
    "orderDetail.cancelModal.title": "You really want to cancel your order?",
    "orderDetail.cancelModal.button": "Cancel order",
    "orderDetail.cancel.button": "Cancel order",
    "orderDetail.statusCard.title": "Basic information",
    "orderDetail.statusCard.firstPhotoCount": "Number of photos 10x15",
    "orderDetail.statusCard.secondPhotoCount": "Number of photos 15x15",
    "orderDetail.statusCard.thirdPhotoCount": "Number of photos 20x15",
    "orderDetail.statusCard.totalCount": "Total number of photos",
    "orderDetail.statusCard.totalCredits": "Order value",
    "orderDetail.customerCard.title": "Customer",
    "orderDetail.customerCard.name": "Name and Surname",
    "orderDetail.customerCard.email": "E-mail",
    "orderDetail.customerCard.phone": "Phone",
    "orderDetail.customerCard.userDetail": "User detail",
    "orderDetail.historyCard.title": "Order History",
    "orderDetail.historyCard.Created": "Created",
    "orderDetail.historyCard.Processing": "Processing",
    "orderDetail.historyCard.Ready": "Waiting for print",
    "orderDetail.historyCard.InProgress": "Printing",
    "orderDetail.historyCard.Completed": "Completed",
    "orderDetail.historyCard.Incomplete": "Incomplete",
    "orderDetail.historyCard.Partial": "Partially completed",
    "orderDetail.historyCard.Expired": "Expired",
    "orderDetail.historyCard.Canceled": "Canceled",
    "orderDetail.removeOrder.successful": "Order was canceled"
}

const invoices = {
    "invoicesList.title": "Invoices",
    "invoicesList.enabledToggle": "Invoices",
    "invoicesList.disabledToggle": "Payouts",
    "invoicesList.table.title": "Invoices and Payouts",
    "invoicesList.table.ID": "ID",
    "invoicesList.table.identifier": "Identifier",
    "invoicesList.table.company": "Company",
    "invoicesList.table.description": "Description",
    "invoicesList.table.price": "Price",
    "invoicesList.table.credit": "Credits",
    "invoicesList.table.date": "Date",
    "invoicesList.table.createdBy": "Created By",
    "invoicesList.addModal.title": "PAIR THE INVOICE",
    "invoicesList.assignModal.cancel": "CANCEL",
    "invoicesList.assignModal.title": "Pair the Invoice",
    "invoicesList.assignModal.company": "Company",
    "invoicesList.assignModal.number": "Invoice number",
    "invoicesList.assignModal.price": "Price",
    "invoicesList.assignModal.credit": "Credits",
    "invoicesList.assignModal.description": "Description",
    "invoicesList.assignModal.identifier": "Identifier",
    "invoicesList.createInvoiceSuccessfull": "Invoice was successfully created",
    "invoicesList.assignModal.create": "Create",
    'invoiceDetail.widget.title': "Payment",
    "invoiceDetail.title": "Invoice",
    'invoiceDetail.price': "Price payment",
    'invoiceDetail.credit': "Volume of credits",
    'invoiceDetail.ID': "Payment ID",
    'invoiceDetail.identifier': "Payment number",
    'invoiceDetail.type': "Payment type",
    'invoiceDetail.description': "Description",
    'invoiceDetail.createdBy': "Created By",
    'invoiceDetail.createdAt': "Created At",
    'invoiceDetail.phone': "Phone"
};

const payouts = {
    "payoutsList.title": "Payouts",
    "payoutsList.table.ID": "ID",
    "payoutsList.table.company": "Company",
    "payoutsList.table.description": "Description",
    "payoutsList.table.price": "Price",
    "payoutsList.table.credit": "Credits",
    "payoutsList.table.date": "Date",
    "payoutsList.table.createdBy": "Created By",
    "payoutsList.addModal.title": "CREATE PAYOUT",
    "payoutsList.assignModal.title": "Create Payout",
    "payoutsList.assignModal.company": "Company",
    "payoutsList.assignModal.price": "Price",
    "payoutsList.assignModal.credit": "Credits",
    "payoutsList.assignModal.description": "Description",
    "payoutsList.assignModal.identifier": "Identifier",
    "payoutsList.createPayoutSuccessfull": "Payout was successfully created",
    "payoutsList.assignModal.create": "Create",
    "payoutDetail.title": "Payout",

};

const operations = {
    "operationsList.title": "Activities",
    "operationsList.table.title": "List of Activities",
    "operationsList.table.ID": "ID",
    "operationsList.table.date": "Date",
    "operationsList.table.sourceID": "Source ID",
    "operationsList.table.operation": "Operation type",
    "operationsList.table.destinationID": "Destination ID",
    "operationsList.table.price": "Price",
};

const payments = {
    "paymentsList.title": "Payments",
    "paymentsList.table.title": "Payments list",
    "paymentsList.table.ID": "ID",
    "paymentsList.table.type": "Type",
    "paymentsList.table.value": "Value",
    "paymentsList.table.price": "Price",
    "paymentsList.table.date": "Date",
    "paymentsList.table.purpose": "Purpose",
    "paymentsList.table.user": "User",
    "paymentsList.table.operationID": "Operation",
    "paymentsList.table.payoutID": "Payout ID",
    "paymentsList.table.type.GooglePay": "Google Pay",
    "paymentsList.table.type.ApplePay": "Apple Pay",
    "paymentsList.table.type.CreditCard": "Credit Card",
    "paymentsList.table.purpose.OrderPayment": "Order payment",
    "paymentsList.table.purpose.Subscription": "Subscription"
};

const commissions = {
    "commissionsList.title": "Commissions",
    "commissionsList.table.title": "Commissions List",
    "commissionsList.addModal.title": "EXPORT",
    'commissionsList.table.commissionID': "Commission ID",
    'commissionsList.table.companyID': "Company ID",
    'commissionsList.table.companyName': "Company",
    'commissionsList.table.stores': "Stores",
    "commissionsList.table.email": "Email",
    "commissionsList.table.date": "Period",
    "commissionsList.table.credit": "Credit",
    "commissionsList.table.margin": "Margin",
    "commissionsList.table.price": "Price",
    "commissionsList.table.actions": "Actions",
    'commissionsList.table.storeID': "Store ID ",
    'commissionsList.table.storeName': "Store name",
    "commissionsList.table.address": "Address",
    "commissionsList.table.ID": "ID",
    "commissionList.table.subtitle": "Completely",
    "commissionsList.assignModal.title": "Data Export",
    "commissionsList.assignModal.pickerStart": "Start Month: ",
    "commissionsList.assignModal.pickerEnd": "End Month: ",
    "commissionsList.assignModal.monthFrom": "Choose start month",
    "commissionsList.assignModal.monthTo": "Choose end month",
    "commissionsList.table.pickerStart": "Start: ",
    "commissionsList.table.pickerEnd": "End: ",
    "commissionsList.month.january": "January",
    "commissionsList.month.february": "February",
    "commissionsList.month.march": "March",
    "commissionsList.month.april": "April",
    "commissionsList.month.may": "May",
    "commissionsList.month.june": "Jun",
    "commissionsList.month.july": "July",
    "commissionsList.month.august": "August",
    "commissionsList.month.september": "September",
    "commissionsList.month.october": "October",
    "commissionsList.month.november": "November",
    "commissionsList.month.december": "December",
    "commissionsList.export.success": "Commissions was succesfully exproted to csv file",
    "commissionDetail.title": "Company commissions",
    "commissionDetail.photo.title": "Photos",
    "commissionDetail.photo.body": "Total number of photos",
    "commissionDetail.commission.title": "Commission",
    "commissionDetail.commission.body": "Total Commissions",
    "commissionDetail.margin.title": "Margin",
    "commissionDetail.margin.body": "Average margin",
};

const profilePage = {
    'profile.contactInfo.title': 'Contact information',
    'profile.contactInfo.firstName': 'Name',
    'profile.contactInfo.lastName': 'Surname',
    'profile.contactInfo.gender': 'Gender',
    'profile.contactInfo.email': 'Email',
    'profile.contactData.title': 'Contact information',
    'profile.contactData.phone': 'Phone',
    'profile.contactData.email': 'Email',
    'profile.bankData.title': 'Bank information',
    'profile.bankData.bic': 'BIC',
    'profile.bankData.iban': 'IBAN',
    'profile.bankData.swift': 'BIC/SWIFT',
    'profile.bankData.bankName': 'Bank',
    'profile.changePassword.title': 'Password change',
    'profile.changePassword.oldPassword': 'Current password',
    'profile.changePassword.newPassword': 'New password',
    'profile.changePassword.verifyPassword': 'Repeat new password',
    'profile.changePassword.change': 'Change',
    'profile.identificators.title': 'Company identification',
    'profile.identificators.ico': 'Business ID',
    'profile.identificators.dic': 'Tax ID',
    'profile.identificators.icDph': 'VAT number',
    'profile.identificators.registerCode': 'Court of Registry',
    'profile.companyData.title': 'Company information',
    'profile.companyData.name': 'Company name',
    'profile.companyData.street': 'Street',
    'profile.companyData.city': 'City',
    'profile.companyData.postal': 'ZIP code',
    'profile.companyData.country': 'Country',
    'profile.language': 'Language',
    'profile.language.success': 'Jazyková preferencia bola uložená',
    'profile.password.empty': 'You have not entered a password',
    'profile.password.success': 'Password changed successfully',
    'profile.password.failed': 'The password has not been changed',
    'profile.password.notEquals': 'Passwords do not match',
};

const userRoles = {
    'userRoles.partner': 'Partner',
    'userRoles.maintainer': 'Maintainer',
    'userRoles.advertiser': 'Advertiser',
    'userRoles.user': 'User',
    'userRoles.admin': 'Administrator'
};

const loginPage = {
    'loginPage.cardTitle': 'Login',
    'loginPage.email': 'Email',
    'loginPage.password': 'Password',
    'loginPage.submit': 'Login',
    'loginPage.stayLogged': 'Stay logged',
    'loginPage.forgottenPassword': 'Forgotten password',
    'loginPage.forgottenPassword.email': 'Your email',
    'loginPage.forgottenPassword.infoText': 'Enter e-mail of your account and we will send you a link to reset your password.',
    'loginPage.forgottenPassword.cancel': 'Cancel',
    'loginPage.forgottenPassword.submit': 'Send',
    'loginPage.forgottenPassword.success': 'An email with link to reset password was sent.'
};

const notificationsPage = {
    'notificationsPage.title': 'Notifications',
    'notificationsPage.create': 'CREATE NOTIFICATION',
    'notificationsPage.table.title': 'Notifications',
    'notificationsPage.table.id': 'ID',
    'notificationsPage.table.date': 'Date',
    'notificationsPage.table.author': 'Author',
    'notificationsPage.table.header': 'Header',
    'notificationsPage.table.text': 'Text',
    'notificationsPage.modal.title': 'New notification',
    'notificationsPage.modal.header': 'Header',
    'notificationsPage.modal.text': 'Text',
    'notificationsPage.modal.screen': 'Screen',
    'notificationsPage.modal.submit': 'Send',
    'notificationsPage.modal.cancel': 'Back',
    'notificationsPage.create.success': 'Notification was sent successfully',
    'notificationsPage.modal.confirm.title': 'Confirmation',
    'notificationsPage.modal.confirm.text': 'You are going to send the notification',
    'notificationsPage.modal.confirm.submit': 'SEND',
    'notificationsPage.modal.confirm.cancel': 'BACK'
};

const streams = {
    "streams.page.title": "Streams",
    "streams.page.successRefresh": "Streams was successfully refreshed",
    "streams.page.refreshButton": "Refresh streams",
    "streams.table.title": "Streams list",
    "streams.table.id": "ID",
    "streams.table.name": "Name",
    "streams.table.url": "URL",
    "streams.table.state": "Status",
    "streams.table.actions": "Actions",
}

const media = {
    "media.page.title": "Media list",
    "media.page.header": "Media",
    "media.page.info": "Upload a video from your local storage here",
    "media.uploadModal.title": "Upload file",
    "media.uploadModal.cancelButton": "Cancel",
    "media.uploadModal.confirmButton": "Upload ",
    "media.uploadModal.name": "File name",
    "media.uploadModal.company": "Company",
    "media.uploadModal.chooseCompany": "Choose company...",
    "media.uploadModal.category": "Category",
    "media.uploadModal.chooseCategory": "Choose category...",
    "media.uploadModal.success": "File was successfully uploaded",
    "media.table.approved": "Approved",
    "media.table.pending": "Pending",
    "media.table.rejected": "Rejected",
    "media.table.approvedButton": "Approve",
    "media.table.rejectedButton": "Reject",
    "media.table.deletedButton": "Delete",
    "media.table.approvedModalTitle": "Do you want to approve this file?",
    "media.table.rejectedModalTitle": "Do you want to reject this file?",
    "media.table.deletedModalTitle": "Do you want to delete this file?",
    "media.table.rejectedReason": "State the reason for the rejection",
    "media.table.successApprove": "File approved successfully",
    "media.table.successReject": "File rejected successfully",
    "media.table.successDelete": "File deleted successfully",
    "media.table.id": "ID",
    "media.table.date": " Date",
    "media.table.status": "Status",
    "media.table.name": "Name",
    "media.table.owner": "Owner",
    "media.table.category": "Category",
    "media.table.duration": "Duration",
    "media.table.reason": "Reason",
    "media.table.size": "Size",
    "media.table.actions": "Actions",
    "media.page.uploadButton": "Upload file",
    "media.table.category.music": "Music",
    "media.table.category.video": "Video",
    "media.table.category.Jingel": "Jingel",
    "media.table.category.CommercialSpot": "Commercial spot",
    "media.table.category.InternalSpot": "Internal spot",
    "media.table.category.Announcement": "Announcement",
    "media.table.category.ButtonAnnouncement": "Button announcement",
    "media.table.status.pending": "Pending",
    "media.table.status.uploaded": "Uploaded",
    "media.table.status.processing": "Processing",
    "media.table.status.processed": "Processed",
    "media.table.status.invalid": "Invalid",
    "media.table.status.failed": "Failed",
}

const screenTypeEnums = {
    'screenType.Home': 'Home',
    'screenType.Menu': 'Menu',
    'screenType.Orders': 'Orders',
    'screenType.Profile': 'Profile',
    'screenType.Credits': 'Credits',
    'screenType.Players': 'Players',
};

const userStatuses = {
    'userStatus.activated': 'Activated',
    'userStatus.unactivated': 'Unactivated',
    'userStatus.blocked': 'Blocked',
    'userSubscription.Nothing': 'without subscription'
}

const deviceStatus = {
    'deviceStatus.offline': 'Offline',
    'deviceStatus.online': 'Online',
    'deviceStatus.error': 'Error',
};

const pageHeader = {
    'pageHeader.back': 'Back'
};

const tableTexts = {
    'tableTexts.rowsPerPage': 'Rows per page',
    'tableTexts.of': 'of',
    'tableTexts.moreThan': 'more than',
    'tableTexts.piece': ' pcs',
    'tableTexts.piece': ' spots',
    'tableTexts.minutes': ' minute',
    'tableTexts.copyToClipboard': 'Click to copy text',
    'tableTexts.copyToClipboard.success': 'Text has been copied to clipboard'

};

const userDevicesPage = {
    'userDevicesPage.title': 'User\'s devices {0}'
};

const playlist = {
    'playlistList.title': 'Playlists',
    'playlistList.table.title': 'Playlists list',
    'playlistList.table.ID': 'ID',
    'playlistList.table.name': 'Name',
    'playlistList.table.company': 'Company',
    'playlistList.table.media': 'Media',
    'playlistList.table.date': 'Date',
    'playlistList.table.createdBy': 'Created By',
    'playlistList.table.description': 'Description',
    'playlistList.table.actions': 'Actions',
    'playlistList.table.actions.detail': 'Detail',
    'playlistList.button.new': 'New playlist',
    'playlist.button.change': 'Save changes',
    'playlist.new.title': 'New playlist',
    'playlist.basicInfo.title': 'Basic information',
    'playlist.basicInfo.name': 'Playlist name',
    'playlist.basicInfo.company': 'Company',
    'playlist.basicInfo.description': 'Description',
    'playlist.media.title': 'Move media from the library to the playlist',
    'playlist.removePlaylist.button': 'Delete playlist',
    'playlist.modal.title': 'You are about to delete a playlist. Do you want to continue?',
    'playlist.modal.removeButton': 'Delete',
    'playlist.save.success': 'Playlist was updated',
    "playlist.deletePlaylist.successful": "Playlist was deleted"
}

const zones = {
    'zoneList.table.device': 'Device',
    'zoneDetail.title': 'Zone',
    'zoneDetail.basicInfoCard.name': 'Zone name',
    'zoneDetail.removeZone.button': 'Remove zone',
    'zoneDetail.displayCard.title': 'Display',
    'zoneDetail.displayCard.displayGroupA': 'Display A',
    'zoneDetail.displayCard.displayGroupB': 'Display B',
    'zoneDetail.displayCard.without': 'Without',
    "zoneDetail.updateZone.successful": "Zone was updated",
    "zoneDetail.removeZone.successful": "Zone was removed"
}
const weekDay = {
    'weekDay.Monday': 'Monday',
    'weekDay.Tuesday': 'Tuesday',
    'weekDay.Wednesday': 'Wednesday',
    'weekDay.Thursday': 'Thursday',
    'weekDay.Friday': 'Friday',
    'weekDay.Saturday': 'Saturday',
    'weekDay.Sunday': 'Sunday',
}


const changeDashboard = {
    'changeDashboard.menu': 'Go to radio Dash'
}


export default {
    ...displays,
    ...apiErrors,
    ...userRoles,
    ...defaultValidationErrors,
    ...settingsPage,
    ...userDetail,
    ...routes,
    ...usersPage,
    ...devices,
    ...invoices,
    ...orders,
    ...payouts,
    ...operations,
    ...payments,
    ...commissions,
    ...companies,
    ...campaigns,
    ...stores,
    ...profilePage,
    ...createUserPage,
    ...queryTable,
    ...loginPage,
    ...notificationsPage,
    ...media,
    ...streams,
    ...screenTypeEnums,
    ...userStatuses,
    ...deviceStatus,
    ...pageHeader,
    ...tableTexts,
    ...userDevicesPage,
    ...playlist,
    ...zones,
    ...weekDay,
    ...changeDashboard,
    ...groups,
    ...dashboard,
};