import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "components-lib/Modal/Modal";
import PageHeader from "components-lib/PageHeader/PageHeader";
import Button from "components/CustomButtons/Button.js";
import { useSnackbar } from "notistack";
import paths from "paths";
import { pathOr } from "rambda";
import { default as React, useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import BasicInfoCard from "./StoreCards/BasicInfoCard";
import CompanyCard from "./StoreCards/CompanyCard";
import MaintainerCard from "./StoreCards/MaintainerCard";
import AddressCard from "./StoreCards/AddressCard";
import { getDetailValidationSchema, getDetailValidationSchemaZone } from "./validationSchema";
import { Formik } from "formik";
import STORE_DELETE_MUTATION from "queries/StoresQueries/storeDelete";
import STORE_DETAIL from "queries/StoresQueries/storeDetail";
import STORE_UPDATE from 'queries/StoresQueries/storeUpdate';
import ZONE_CREATE from 'queries/ZonesQueries/zoneCreate';
import Loading from "components-lib/Loading/Loading";
import ALL_ZONES from 'queries/ZonesQueries/allZones';
import ZoneTable from "../Zones/ZoneTable";
import IconCard from "components-lib/IconCard/IconCard";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FormikField from "components-lib/FormikField/FormikField";
import TimePlanCard from "./StoreCards/TimePlanCard"
import moment from 'moment';
import { storeUpdate, storeDel, zoneCreate, deviceAssign, zoneList, companyDetail } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme));

const StoreDetailPage = (props) => {

    const classes = useStyles();
    const storeId = props.match.params.storeId;
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [removeStoreOpen, setRemoveStoreOpen] = useState(false);
    const [createZoneOpen, setCreateZoneOpen] = useState(false);
    const [formikStoreData, setFormikStoreData] = useState({});
    const [city, setCity] = useState(null);
    const [times, setTimes] = useState([]);
    const [inactiveTimes, setInactiveTimes] = useState([])
    const [loadZones, { data: dataZones, refetch: refetchZones }] = useLazyQuery(ALL_ZONES, { skip: (!zoneList, !zoneCreate) });
    const { data: storeData, loading: storeLoading, refetch: refetchData } = useQuery(STORE_DETAIL, { variables: { id: storeId, }, });

    const [storeDelete] = useMutation(STORE_DELETE_MUTATION);
    const [updateStore] = useMutation(STORE_UPDATE);
    const [createZone] = useMutation(ZONE_CREATE);


    useEffect(() => {
        setFormikStoreData(pathOr({}, ['store'], storeData));
        setCity(pathOr('', ['store', 'city'], storeData))
        setTimes(pathOr('', ['store', 'zones', [0], 'preferences', 'activeTimes'], storeData))
        setInactiveTimes(((Object.values(pathOr('', ['store', 'zones', [0], 'preferences', 'inactiveTimes'], storeData))?.map(item => (
            {
                from: moment(item?.from),
                to: moment(item?.to)
            })))))
    }, [storeData]);

    const getStoreBid = () => {
        return (
            <>
                {intl.formatMessage({ id: "storeDetail.title" })} &nbsp;
                {pathOr('', ['store', 'bid'], storeData)}
            </>
        );
    };


    const handleCreateZone = (values, resetForm) => {
        let times = [
            { weekDay: "Monday", from: "00:00", to: "23:59" },
            { weekDay: "Tuesday", from: "00:00", to: "23:59" },
            { weekDay: "Wednesday", from: "00:00", to: "23:59" },
            { weekDay: "Thursday", from: "00:00", to: "23:59" },
            { weekDay: "Friday", from: "00:00", to: "23:59" },
            { weekDay: "Saturday", from: "00:00", to: "23:59" },
            { weekDay: "Sunday", from: "00:00", to: "23:59" }
        ]
        createZone({
            variables: {
                input: {
                    storeId: Number(storeId),
                    name: values.name,
                    preferences: {
                        activeTimes: times
                    }
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "storeDetail.createZone.successful", }), { variant: 'success' });
            refetchData();
            refetchZones();
            setCreateZoneOpen(false);
            resetForm();
        }).catch((err) => {
            console.log(err);
        });
        resetForm();
        setCreateZoneOpen(false);
    }

    const handleUpdateStore = (values) => {
        const result = { ...values };
        delete result.id;
        delete result.bid;
        delete result.segment;
        delete result.zones;
        delete result.company;
        delete result.__typename;
        delete result.area;
        delete result.visitors;

        let setCityLabel = city.label;
        //times parameter settings

        for (let i = 0; i < times.length; i++) {
            if (times[i].__typename)
                delete times[i].__typename;
        }


        let setInTimes = []
        for (let i = 0; i < inactiveTimes.length; i++) {
            if (inactiveTimes[i]?.from !== undefined || inactiveTimes[i]?.to !== undefined)
                setInTimes.push({
                    from: inactiveTimes[i]?.from,
                    to: inactiveTimes[i]?.to
                });
        }
        updateStore({
            variables: {
                id: values.id,
                input: {
                    ...result,
                    //area: Number(values.zones[0].area),
                    //visitors: Number(values.zones[0].visitors),
                    contactFirstName: values.contactFirstName,
                    contactLastName: values.contactLastName,
                    contactPhone: values.contactPhone,
                    contactEmail: values.contactEmail,
                    city: setCityLabel,
                    /*preferences: {
                        segmentId: Number(values.zones[0].preferences.segment.id),
                        activeTimes: times,
                        inactiveTimes: setInTimes,
                        isOpenedDuringHolidays: (values.zones[0].preferences.isOpenedDuringHolidays),
                    }*/
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: "storeDetail.updateStore.successful", }), { variant: 'success' });
            refetchData();
        }).catch((err) => {
            console.log(err);
        });
    };

    //Delete store mutation
    const deleteStore = () => {
        storeDelete({
            variables: {
                id: storeId,
            },
        })
            .then((response) => {
                history.push(`/admin${generatePath(paths.stores.list)}`)
                enqueueSnackbar(
                    intl.formatMessage({
                        id: "storeDetail.removeStore.successful",
                    }),
                    {
                        variant: "success",
                    }
                );
            })
            .catch((err) => {
                console.log(err)
            });
    };

    // Headers Buttons
    const getActions = () => {
        let action = []
        if (zoneCreate)
            action.push({
                title: intl.formatMessage({ id: "storeDetail.createZone.title" }),
                onClick: () => setCreateZoneOpen(true),
            })
        if (storeDel)
            action.push({
                title: intl.formatMessage({ id: "storeDetail.removeStore.title", }),
                onClick: () => setRemoveStoreOpen(true),
                color: "danger"
            })
        return action
    }

    const renderRemoveStoreActions = () => {
        return (
            <>
                <Button
                    onClick={() => deleteStore()}
                    color="danger"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "companyDetail.btn.remove" })}
                </Button>
                <Button
                    onClick={() => setRemoveStoreOpen(false)}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    const renderCreateZoneActions = (formikProps, resetForm) => {
        return (
            <>
                <Button
                    onClick={(e) => formikProps.handleSubmit()}
                    color="success"
                    round
                    size="sm">
                    {intl.formatMessage({ id: "storeDetail.createZone.button" })}
                </Button>
                <Button
                    onClick={() => { setCreateZoneOpen(false); resetForm() }}
                    color="primary"
                    round
                    size="sm"
                >
                    {intl.formatMessage({ id: "companyList.addModal.cancel" })}
                </Button>
            </>
        );
    };

    if (storeLoading) return <Loading />
    return (
        <>
            <Formik
                onSubmit={(values) => { handleUpdateStore(values) }}
                enableReinitialize
                initialValues={formikStoreData}
                validationSchema={getDetailValidationSchema(intl)}
            >
                {(formikProps) => (
                    <>
                        <PageHeader
                            title={pathOr('', ['store', 'name'], storeData)}
                            subTitle={getStoreBid()}
                            actions={getActions()}
                            handleBackAction={(e) => history.goBack()}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <BasicInfoCard
                                    formikProps={formikProps}
                                    storeData={storeData}
                                    storeUpdate={storeUpdate}
                                    storeDetail={true}
                                />
                                <CompanyCard
                                    className={classes.floatRight}
                                    history={history}
                                    companyId={pathOr('', ['store', 'company', 'id'], storeData)}
                                    companyBid={pathOr('', ['store', 'company', 'bid'], storeData)}
                                    companyName={pathOr('', ['store', 'company', 'name'], storeData)}
                                    companyAddress={pathOr('', ['store', 'company', 'address'], storeData)}
                                    companyPostal={pathOr('', ['store', 'company', 'postal'], storeData)}
                                    companyCity={pathOr('', ['store', 'company', 'city'], storeData)}
                                    companyCountry={pathOr('', ['store', 'company', 'country'], storeData)}
                                    companyContact={`
                                            ${pathOr('', ['store', 'company', 'contactFirstName'], storeData)} 
                                            ${pathOr('', ['store', 'company', 'contactLastName'], storeData)} 
                                        `}
                                    companyMail={pathOr('', ['store', 'company', 'contactEmail'], storeData)}
                                    companyPhone={pathOr('', ['store', 'company', 'contactPhone'], storeData)}
                                    companyDetail={companyDetail}
                                />
                                {/*<TimePlanCard
                                    times={times}
                                    callbackTimes={(newValue) => setTimes(newValue)}
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    storeData={storeData}
                                    inactiveTimes={inactiveTimes}
                                    callbackInactiveTimes={(newValue) => setInactiveTimes(newValue)}
                                    storeId={storeId}
                                    storeUpdate={true}
                                />*/}
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={8}>
                                {(zoneList || zoneCreate) &&
                                    <IconCard
                                        icon={<GroupWorkIcon />}
                                        title={intl.formatMessage({ id: "storeDetail.zoneTable.title" })}
                                    >
                                        <ZoneTable
                                            data={dataZones}
                                            loadData={loadZones}
                                            defaultFilter={{ storeId: { eq: Number(storeId) } }}
                                            history={history}
                                            queryDataPath={['zonesTable', 'items']}
                                            queryVariables={{ storeId: storeId }}
                                        />
                                    </IconCard>
                                }
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <MaintainerCard
                                            formikProps={formikProps}
                                            storeData={storeData}
                                            storeUpdate={storeUpdate}
                                        />

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <AddressCard
                                            data={formikProps.values}
                                            formikProps={formikProps}
                                            storeData={storeData}
                                            city={city}
                                            callbackCity={(newValue) => setCity(newValue)}
                                            storeUpdate={storeUpdate}
                                        />
                                    </Grid>
                                </Grid>
                                {storeUpdate &&
                                    <div style={{ position: "fixed", bottom: "20px", right: "20px", }} >
                                        <Button
                                            round
                                            color="primary"
                                            onClick={(e) => {
                                                formikProps.handleSubmit();
                                            }}
                                        >
                                            {intl.formatMessage({ id: "userDetail.buttons.confirm" })}
                                        </Button>
                                        <Button round onClick={() => formikProps.resetForm()}>
                                            {intl.formatMessage({ id: 'settings.cancel' })}
                                        </Button>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <Modal
                            title={intl.formatMessage({ id: "storeDetail.removeStore.title" })}
                            open={removeStoreOpen}
                            onClose={() => setRemoveStoreOpen(false)}
                            actions={renderRemoveStoreActions()}
                            fullWidth
                        >
                            {`${intl.formatMessage({ id: "storeDetail.removeStore.text", })}
                            ${pathOr("", "store.name", storeData)} ${pathOr("", "store.bid", storeData)}?`}
                        </Modal>
                    </>
                )}
            </Formik>

            <Formik
                onSubmit={(values, { resetForm }) => { handleCreateZone(values, resetForm) }}
                enableReinitialize
                initialValues={{}}
                validationSchema={getDetailValidationSchemaZone(intl)}
            >
                {(formikProps) => (
                    <Modal
                        title={intl.formatMessage({ id: "storeDetail.createZone.title" })}
                        open={createZoneOpen}
                        onClose={() => { setCreateZoneOpen(false); formikProps.resetForm() }}
                        actions={renderCreateZoneActions(formikProps, formikProps.resetForm)}
                        fullWidth
                    >
                        <FormikField
                            name="name"
                            labelText={intl.formatMessage({ id: "storeDetail.createZone.name" })}
                            formikProps={formikProps}
                            highlightChange
                        />
                    </Modal>
                )}
            </Formik>
        </>
    );
};

export default injectIntl(withRouter(StoreDetailPage));
