import gql from 'graphql-tag';

const SETTINGS_QUERY = gql`
query settings {
    settings {
            creditValue
            defaultSpotPlaybackPrice
            commissionRate  
        }
}
`;

export default SETTINGS_QUERY;
