import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTableRefresh';
import { useMutation } from "@apollo/react-hooks";
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { pathOr } from "rambda";
import { useSnackbar } from "notistack";
import moment from 'moment';
import styles from "common/styles/widgets.js";
import Modal from "components-lib/Modal/Modal";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import IconButton from '@material-ui/core/IconButton';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import MediaAcceptanceStatusEnum from "common/enums/MediaAcceptanceStatus";
import MediaProcessStatusEnum from "common/enums/MediaProcessStatus";
import { DateTime } from "luxon";
import { mediaDel, mediaApp, mediaRej } from 'configFiles/roles'

import MEDIA_DOWNLOAD from "queries/MediaQueries/mediaDownload";
import MEDIA_APPROVE from "queries/MediaQueries/mediaApprove";
import MEDIA_REJECT from "queries/MediaQueries/mediaReject";
import MEDIA_DELETE from "queries/MediaQueries/mediaDelete";

const useStyles = makeStyles((theme) => styles(theme));

const MediaTable = (props) => {
    const {
        data,
        loadData,
        intl,
        defaultFilter,
        queryDataPath,
        acceptanceStatus,
        refetchMediaApproved,
        refetchMediaPending,
        refetchMediaRejected,
        rejected
    } = props;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();



    const [reasonDescription, setReasonDescription] = useState(false);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [mediaId, setMediaId] = useState(-1);

    const [mediaApprove] = useMutation(MEDIA_APPROVE);
    const [mediaReject] = useMutation(MEDIA_REJECT);
    const [mediaDelete] = useMutation(MEDIA_DELETE);
    const [mediaDownload] = useMutation(MEDIA_DOWNLOAD);


    const getDuration = (data) => {
        const durationInSeconds = pathOr(null, ['duration'], data);
        return moment.utc(durationInSeconds * 1000).format('mm:ss.SSS')
    }

    const getSize = (data, decimals = 2) => {
        const sizeInBytes = pathOr(0, ['size'], data)
        if (sizeInBytes === 0) return '0';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB'];
        const i = Math.floor(Math.log(sizeInBytes) / Math.log(k));
        return parseFloat((sizeInBytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const handleDownloadMedia = (data) => {
        let id = pathOr('', ['id'], data)
        mediaDownload({
            variables: {
                id: Number(id)
            }
        }).then(response => {
            let url = ((pathOr('', ['data', 'mediaDownload', 'url'], response)));
            window.open(url);
        }
        )
    }

    const handleApprove = (data) => {
        mediaApprove({
            variables: {
                id: mediaId
            }
        }).then((response) => {
            refetchMediaApproved()
            refetchMediaPending()
            refetchMediaRejected()
            enqueueSnackbar(intl.formatMessage({ id: "media.table.successApprove" }), { variant: "success" }
            );
        }).catch(err => {
            console.log('[error]', err)
        });
        setApproveModalOpen(false)
    }

    const handleReject = (data) => {
        let description = (reasonDescription) ? reasonDescription : null
        mediaReject({
            variables: {
                id: mediaId,
                description: description
            }
        }).then((response) => {
            refetchMediaApproved()
            refetchMediaPending()
            refetchMediaRejected()
            enqueueSnackbar(intl.formatMessage({ id: "media.table.successReject" }), { variant: "success" });
        }).catch(err => {
            console.log('[error]', err)
        });
        setRejectModalOpen(false)
    }

    const handleDelete = (data) => {
        mediaDelete({
            variables: {
                id: mediaId,
            }
        }).then((response) => {
            refetchMediaApproved()
            refetchMediaPending()
            refetchMediaRejected()
            enqueueSnackbar(intl.formatMessage({ id: "media.table.successDelete" }), { variant: "success" });
        }).catch(err => {
            console.log('[error]', err)
        });
        setDeleteModalOpen(false)
    }

    const getApproveActions = (data) => {
        return <>
            <Button
                onClick={() => handleApprove(data)}
                color="success"
                size="sm">
                {intl.formatMessage({ id: "media.table.approvedButton" })}
            </Button>
            <Button
                onClick={() => setApproveModalOpen(false)}
                color="primary"
                outlined
                round
                size="sm"
            >
                {intl.formatMessage({ id: "media.uploadModal.cancelButton" })}
            </Button>
        </>
    }

    const getRejectActions = (data) => {
        return <>
            <Button
                onClick={() => handleReject(data)}
                color="warning"
                round
                size="sm">
                {intl.formatMessage({ id: "media.table.rejectedButton" })}
            </Button>
            <Button
                onClick={() => setRejectModalOpen(false)}
                color="primary"
                outlined
                round
                size="sm"
            >
                {intl.formatMessage({ id: "media.uploadModal.cancelButton" })}
            </Button>
        </>
    }

    const getDeleteActions = (data) => {
        return <>
            <Button
                onClick={() => handleDelete(data)}
                color="danger"
                round
                size="sm">
                {intl.formatMessage({ id: "media.table.deletedButton" })}
            </Button>
            <Button
                onClick={() => setDeleteModalOpen(false)}
                color="primary"
                outlined
                round
                size="sm"
            >
                {intl.formatMessage({ id: "media.uploadModal.cancelButton" })}
            </Button>
        </>
    }

    const renderApproveModal = (data) => {
        return <>
            <Modal
                title={intl.formatMessage({ id: "media.table.approvedModalTitle" })}
                open={approveModalOpen}
                onClose={() => setApproveModalOpen(false)}
                actions={getApproveActions(data)}
                backDrop={true}
            />
        </>
    }

    const renderRejectModal = (data) => {
        return <>
            <Modal
                title={intl.formatMessage({ id: "media.table.rejectedModalTitle" })}
                open={rejectModalOpen}
                onClose={() => setRejectModalOpen(false)}
                actions={getRejectActions(data)}
                backDrop={true}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <InputLabel style={{ color: "#AAAAAA" }}>{intl.formatMessage({ id: "media.table.rejectedModalTitle" })}</InputLabel>
                        <CustomInput
                            id="approve-input"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                multiline: true,
                                rows: 5,
                                onChange: e => setReasonDescription(e.target.value)
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </Modal>
        </>
    }

    const renderDeleteModal = (data) => {
        return <>
            <Modal
                title={intl.formatMessage({ id: "media.table.deletedModalTitle" })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                actions={getDeleteActions(data)}
                backDrop={true}
            />
        </>
    }

    const getActions = (data) => {
        return <>
            {
                approveModalOpen && renderApproveModal(data)
            }
            {
                rejectModalOpen && renderRejectModal(data)
            }
            {
                deleteModalOpen && renderDeleteModal(data)
            }

            {
                acceptanceStatus === MediaAcceptanceStatusEnum.AWAITING
                    ? <>
                        {
                            (data.status === MediaProcessStatusEnum.PROCESSED) &&
                            (
                                <PlayCircleFilledIcon
                                    className={classes.pointer}
                                    onClick={() => handleDownloadMedia(data)}>
                                </PlayCircleFilledIcon>
                            )
                        }
                        {
                            data.status === MediaProcessStatusEnum.PROCESSED &&
                            (mediaApp) &&
                            <CheckCircleRoundedIcon
                                className={classes.pointer}
                                onClick={() => { setApproveModalOpen(true); setMediaId(data.id) }}
                            />
                        }
                        {
                            data.status === MediaProcessStatusEnum.PROCESSED &&
                            (mediaRej) &&
                            <CancelRoundedIcon
                                className={classes.pointer}
                                onClick={() => { setRejectModalOpen(true); setMediaId(data.id) }}
                            />
                        }
                        {
                            (mediaDel) &&
                            <DeleteRoundedIcon
                                className={classes.pointer}
                                onClick={() => { setDeleteModalOpen(true); setMediaId(data.id) }}
                            />
                        }
                    </>
                    : <>
                        {
                            data.status === MediaProcessStatusEnum.PROCESSED &&
                            (
                                <PlayCircleFilledIcon
                                    className={classes.pointer}
                                    onClick={() => handleDownloadMedia(data)}>
                                </PlayCircleFilledIcon>
                            )

                        }
                        {
                            (mediaDel) &&
                            <DeleteRoundedIcon
                                className={classes.pointer}
                                onClick={() => { setDeleteModalOpen(true); setMediaId(data.id) }}
                            />
                        }
                    </>
            }
        </>
    }

    const renderStatus = data => {
        const itemStatus = pathOr('', 'status', data)
        switch (itemStatus) {
            case MediaProcessStatusEnum.PENDING:
                return <span style={{ color: "gray" }}> {intl.formatMessage({ id: "media.table.status.pending" })} ({Math.round(data.progress, 0)}%)</span>
            case MediaProcessStatusEnum.UPLOADED:
                return <span style={{ color: "gray" }}> {intl.formatMessage({ id: "media.table.status.uploaded" })} ({Math.round(data.progress, 0)}%)</span>
            case MediaProcessStatusEnum.PROCESSING:
                return <span style={{ color: "orange" }}> {intl.formatMessage({ id: "media.table.status.processing" })} ({Math.round(data.progress, 0)}%)</span>
            case MediaProcessStatusEnum.PROCESSED:
                return <span style={{ color: "green" }}>{intl.formatMessage({ id: "media.table.status.processed" })} </span>
            case MediaProcessStatusEnum.FAILED:
                return <span style={{ color: "red" }}> {intl.formatMessage({ id: "media.table.status.failed" })} </span>
            default:
                return <span style={{ color: "red" }}> {intl.formatMessage({ id: "media.table.status.invalid" })} </span>
        }
    }

    const renderDescription = (descrtiption) => {
        return (
            <IconButton title={descrtiption}>
                <InfoRoundedIcon />
            </IconButton>
        )
    }

    const getColumns = () => {
        if (rejected) {
            return [
                {
                    Header: intl.formatMessage({ id: "media.table.id" }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.date" }),
                    accessor: (rowData) => rowData.createdAt !== null ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy, HH:mm") : <p> - </p>,
                    sortKey: 'createdAt',
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: "media.table.status" }),
                    accessor: (rowData) => renderStatus(rowData),
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: MediaProcessStatusEnum.PROCESSING,
                            label: intl.formatMessage({ id: "media.table.status.processing" })
                        },
                        {
                            value: MediaProcessStatusEnum.PROCESSED,
                            label: intl.formatMessage({ id: "media.table.status.processed" })
                        },
                        {
                            value: MediaProcessStatusEnum.INVALID,
                            label: intl.formatMessage({ id: "media.table.status.invalid" })
                        },
                        {
                            value: MediaProcessStatusEnum.FAILED,
                            label: intl.formatMessage({ id: "media.table.status.failed" })
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: "media.table.name" }),
                    accessor: 'name',
                    sortKey: 'name',
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.owner" }),
                    accessor: 'owner',
                    sortKey: 'owner',
                    filterKey: 'owner',
                    filterOperator: 'contains'
                },
                /*{
                    Header: intl.formatMessage({ id: "media.table.category" }),
                    accessor: (rowdata) => getCategory(rowdata.category),
                    sortKey: 'category',
                    filterKey: 'category',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: MediaCategoryEnum.VIDEO,
                            label: intl.formatMessage({ id: "media.table.category.video" })
                        },
                        {
                            value: MediaCategoryEnum.JINGEL,
                            label: intl.formatMessage({ id: "media.table.category.Jingel" })
                        },
                        {
                            value: MediaCategoryEnum.COMMERCIALSPOT,
                            label: intl.formatMessage({ id: "media.table.category.CommercialSpot" })
                        },
                        {
                            value: MediaCategoryEnum.INTERNALSPOT,
                            label: intl.formatMessage({ id: "media.table.category.InternalSpot" })
                        },
                        {
                            value: MediaCategoryEnum.ANNOUNCEMENT,
                            label: intl.formatMessage({ id: "media.table.category.Announcement" })
                        },
                        {
                            value: MediaCategoryEnum.BUTTONANNOUNCEMENT,
                            label: intl.formatMessage({ id: "media.table.category.ButtonAnnouncement" })
                        }
                    ]
                },*/
                {
                    Header: intl.formatMessage({ id: "media.table.reason" }),
                    id: 'acceptanceDescription',
                    accessor: (rowData) => renderDescription(rowData.acceptanceDescription),
                    sortKey: 'acceptanceDescription',
                    filterKey: 'acceptanceDescription',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.duration" }),
                    accessor: (rowdata) => getDuration(rowdata),
                    sortKey: "duration",
                    filterKey: 'duration',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.size" }),
                    accessor: (rowdata) => getSize(rowdata),
                    sortKey: "size",
                    filterKey: "size",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.actions" }),
                    accessor: (rowData) => getActions(rowData)
                },
            ]
        }
        else {
            return [
                {
                    Header: intl.formatMessage({ id: "media.table.id" }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.date" }),
                    accessor: (rowData) => rowData.createdAt !== null ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy, HH:mm") : <p> - </p>,
                    sortKey: 'createdAt',
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: "media.table.status" }),
                    accessor: (rowData) => renderStatus(rowData),
                    sortKey: 'status',
                    filterKey: 'status',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: MediaProcessStatusEnum.PROCESSING,
                            label: intl.formatMessage({ id: "media.table.status.processing" })
                        },
                        {
                            value: MediaProcessStatusEnum.PROCESSED,
                            label: intl.formatMessage({ id: "media.table.status.processed" })
                        },
                        {
                            value: MediaProcessStatusEnum.INVALID,
                            label: intl.formatMessage({ id: "media.table.status.invalid" })
                        },
                        {
                            value: MediaProcessStatusEnum.FAILED,
                            label: intl.formatMessage({ id: "media.table.status.failed" })
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: "media.table.name" }),
                    accessor: 'name',
                    sortKey: 'name',
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.owner" }),
                    accessor: 'owner',
                    sortKey: 'owner',
                    filterKey: 'owner',
                    filterOperator: 'contains'
                },
                /*{
                    Header: intl.formatMessage({ id: "media.table.category" }),
                    accessor: (rowdata) => getCategory(rowdata.category),
                    sortKey: 'category',
                    filterKey: 'category',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: MediaCategoryEnum.VIDEO,
                            label: intl.formatMessage({ id: "media.table.category.video" })
                        },
                    ]
                },*/
                {
                    Header: intl.formatMessage({ id: "media.table.duration" }),
                    accessor: (rowdata) => getDuration(rowdata),
                    sortKey: "duration",
                    filterKey: 'duration',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.size" }),
                    accessor: (rowdata) => getSize(rowdata),
                    sortKey: "size",
                    filterKey: "size",
                    filterOperator: 'eq',
                    filterDataType: 'number'
                },
                {
                    Header: intl.formatMessage({ id: "media.table.actions" }),
                    accessor: (rowData) => getActions(rowData)
                },
            ]
        }
    }

    return (
        <QueryTable
            data={data}
            loadData={loadData}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            columns={getColumns()}
        />
    )
}

export default injectIntl(MediaTable);


