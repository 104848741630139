import gql from 'graphql-tag';

export const CAMPAIGN_DETAIL = gql`
query campaign($id:ID!){
    campaign(id: $id){
        id
        bid
        name
        description
        status
        createdAt
        createdBy{
            id
            fullName
        }
        criteria{
            locations{
                id
                name
            }
            segments{
                id
                name
            }
            companies{
                id
                name
            }
            stores{
                id
                bid
                name
            }
            match{
                locationsCount
                segmentsCount
                companiesCount
                storesCount
            }
        }

        company{
            id
            bid
            name
            address
            postal
            city
            contactEmail
            contactPhone
            contactFirstName
            contactLastName
            
            stores{
                address
                postal
                city
                country
                latitude
                longtitude
                fullAddress
           }
        }
        
        media{
            id
            bid
            name
            duration
            previews{
                timemark
                width
                height
                url
            }
        }

        groups{
            id
            bid
            name
            description
        }

        activeTimes{
            from
            to
        }

        mediaStats{
            media {
                bid
                name
            }
            playCount
        }

    }
}`;

export const STORES_TABLE_BY_CAMPAIGN = gql`
    query storesTableByCampaign(
        $campaignId: ID!,
        $offset: Int, 
        $limit:Int, 
        $sort:[StoresTableSortInput!],
        $filter:[StoresTableFilterInput]
    ) {
        storesTableByCampaign(
        campaignId: $campaignId, offset: $offset, limit:$limit, sort: $sort, filter: $filter
        ) {
            totalCount,
            items {
                id
                bid
                name
                address
                zones
            }
        }
    }
`