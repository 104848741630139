import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { pathOr } from 'rambda';
import DeviceErrorCodesEnum from 'common/enums/DeviceErrorCodesEnum';
import Box from '@material-ui/core/Box';
import styles from "common/styles/widgets.js";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { zoneDetail } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme))

export const StoreTable = (props) => {
    const classes = useStyles();

    const {
        history,
        intl,
        defaultFilter,
        queryDataPath,
        data,
        loadData,
        queryVariables,
        numberOfRows
    } = props


    const getDeviceStatus = (deviceStatus) => {
        switch (deviceStatus) {
            case "Online":
                return (
                    <Tooltip title={<span style={{ textAlign: "center", color: "#dcedc8" }}>{intl.formatMessage({ id: 'deviceList.deviceStatus.tooltip.online' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ textAlign: "center", color: "green", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Offline":
                return (
                    <Tooltip title={<span style={{ color: "#ffcdd2" }}>{intl.formatMessage({ id: 'deviceList.deviceStatus.tooltip.offline' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "red", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Unassigned":
                return (
                    <Tooltip title={<span style={{ color: "#fafafa" }}>{intl.formatMessage({ id: 'zoneList.playerStatus.tooltip.without' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "gray", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return ("-");
        }
    }


    const getPlayerStatus = (playerStatus) => {
        switch (playerStatus) {
            case "Playing":
                return (
                    <Tooltip title={<span style={{ textAlign: "center", color: "#dcedc8" }}>{intl.formatMessage({ id: 'storeList.playerStatus.tooltip.online' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ textAlign: "center", color: "green", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Stopped":
                return (
                    <Tooltip title={<span style={{ color: "#ffcdd2" }}>{intl.formatMessage({ id: 'storeList.playerStatus.tooltip.offline' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "red", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            case "Inactive":
                return (
                    <Tooltip title={<span style={{ color: "#fafafa" }}>{intl.formatMessage({ id: 'zoneList.playerStatus.tooltip.without' })}</span>}>
                        <IconButton>
                            <FiberManualRecordIcon style={{ color: "gray", padding: 0 }} />
                        </IconButton>
                    </Tooltip>
                );
            default:
                return ("-");
        }
    }


    const onZoneDetail = (id) => {
        history.push(`/admin${generatePath(paths.stores.zoneDetail, { zoneId: id })}`);
    }

    const getColumnsDetail = () => {
        return (
            [
                {
                    Header: intl.formatMessage({ id: 'storeList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'zoneList.table.device' }),
                    accessor: (rowData) => (rowData.device) ? (rowData.device) : "-",
                    sortKey: "device",
                    filterKey: 'device',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'deviceList.table.state' }),
                    accessor: (rowData) => getDeviceStatus(rowData.deviceStatus),
                    show: false,
                    sortKey: 'deviceStatus',
                    filterKey: 'deviceStatus',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: 'Online',
                            label: intl.formatMessage({ id: 'storeList.online' })
                        },
                        {
                            value: 'Offline',
                            label: intl.formatMessage({ id: 'storeList.offline' })
                        },
                        {
                            value: 'Unassigned',
                            label: intl.formatMessage({ id: 'storeList.withoutDevice' })
                        },
                    ]
                },
                {
                    Header: intl.formatMessage({ id: 'storeList.table.playerStatus' }),
                    accessor: (rowData) => getPlayerStatus(rowData.playerStatus),
                    show: false,
                    sortKey: 'playerStatus',
                    filterKey: 'playerStatus',
                    filterComponent: 'select',
                    filterOperator: 'eq',
                    filterOptions: [
                        {
                            value: "Playing",
                            label: intl.formatMessage({ id: 'deviceDetail.devicePlayback.online' })
                        },
                        {
                            value: "Stopped",
                            label: intl.formatMessage({ id: 'deviceDetail.devicePlayback.noplay' })
                        },
                        {
                            value: "Inactive",
                            label: intl.formatMessage({ id: 'storeList.withoutDevice' })
                        },
                    ]
                },
                (zoneDetail) ?
                    {
                        Header: intl.formatMessage({ id: 'storeList.table.actions' }),
                        accessor: (rowData) =>
                            <Button
                                round
                                className={classes.tenantButtons}
                                size="sm"
                                color="info"
                                onClick={() => onZoneDetail(rowData.id)}
                            >
                                {intl.formatMessage({ id: 'storeList.table.actions.detail' })}
                            </Button>
                    } : { Header: " " }
            ]
        )
    }

    return (
        <QueryTable
            data={data}
            loadData={loadData}
            queryVariables={queryVariables}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            numberOfRows={numberOfRows}
            columns={getColumnsDetail()
            }
        />
    )
}

export default injectIntl(StoreTable);