import gql from 'graphql-tag';

const STORE_DETAIL = gql`
query store($id: ID!){
  store(id:$id){
      id,
      bid,
      name,
      contactFirstName,
      contactLastName,
      contactPhone,
      contactEmail,
      address,
      postal,
      latitude,
      longtitude,
      city,
      country,
      company{
        id
        name
        address
        postal
        city
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
      }
    }
  }
`;

export default STORE_DETAIL;
