import gql from 'graphql-tag';

const MEDIA_REJECT = gql`
    mutation mediaReject(
        $id: ID!
        $description: String
    ) {
      mediaReject(id: $id, description: $description) {
        id
      }
    }
`;

export default MEDIA_REJECT;