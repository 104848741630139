import QueryTable from 'components-lib/QueryTable/QueryTableRefresh';
import Button from "components/CustomButtons/Button.js";
import React, { useState, useEffect } from "react";
import { injectIntl } from 'react-intl';
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import Modal from "components-lib/Modal/Modal";
import FormikField from "components-lib/FormikField/FormikField";
import getValidationSchema from "./validationSchema";
import SELECT_PLAYLISTS from 'queries/PlaylistsQueries/selectPlaylists';
import DISPLAY_GROUP_UPDATE from 'queries/DisplaysQueries/dsiplayGroupUpdate';
import { pathOr } from "rambda";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import { getApolloClient } from "configFiles/apollo";
import { displayUpd, displayDel } from 'configFiles/roles';
import { makeStyles } from "@material-ui/core/styles";
import MEDIAS_BY_COMPANY from 'queries/MediaQueries/mediasByCompany';
import styles from "common/styles/widgets.js";
import Loading from "components-lib/Loading/Loading";

const useStyles = makeStyles((theme) => styles(theme));

export const DisplayTable = (props) => {
    const { history, queryVariables, data, loadData, refetch, intl, defaultFilter = null, numberOfRows, queryDataPath = ['displayGroupsTable', 'items'] } = props
    const { enqueueSnackbar } = useSnackbar();
    const client = getApolloClient(enqueueSnackbar, history, intl);
    const classes = useStyles();

    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [initialData] = useState({});
    const [displayGroupId, setDisplayGroupId] = useState(null);
    const [displayGroupCompany, setDisplayGroupCompany] = useState(null);

    const [playlists, setPlaylists] = useState([{}]);
    const [loadMediaApproved, { data: dataMediaApproved, loading: dataMediaApprovedLoading, refetch: refetchMediaApproved }] = useLazyQuery(MEDIAS_BY_COMPANY);

    // useEffect(() => {
    //     console.log(dataMediaApprovedLoading);
    //     if(!dataMediaApprovedLoading && dataMediaApproved){
    //         console.log(dataMediaApproved.mediasByCompany.items);
    //     }
    // }, [dataMediaApprovedLoading]);

    const [displayGroupUpdate] = useMutation(DISPLAY_GROUP_UPDATE);

    const handleDisplayGroupUpdate = (values, resetForm) => {
        displayGroupUpdate({
            variables: {
                id: displayGroupId,
                input: {
                    mediaId: (values?.media) ? values?.media : null
                }
            }
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'displayList.assignPlaylist.success' }), { variant: 'success' });
            resetForm();
            refetch();
            setAssignModalOpen(false);
        }).catch((err) => {
            console.log('[error]', err);
            refetch();
        })
        refetch();
        setAssignModalOpen(false);
    };

    const renderModalButtons = (formikProps) => {
        return (
            <div>
                <Button
                    disabled={!formikProps.values.media}
                    size="sm"
                    color="success"
                    onClick={(e) => formikProps.handleSubmit()}
                    round
                >
                    {intl.formatMessage({ id: "displayList.modal.confirmButton" })}
                </Button>
                <Button
                    size="sm"
                    color="primary"
                    onClick={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                    round
                >
                    {intl.formatMessage({ id: "displayList.modal.cancelButton" })}
                </Button>
            </div>
        );
    };

    const loadMedia = (companyId) => loadMediaApproved({
        variables:{
            companyId: companyId,
            filter: [{
                acceptanceStatus: {
                    eq: 'Approved'
                }
            }]
        }
    })

    const handleSetMediaOptions = (items) => {
        const medias = items.map((media) => {
            return {
                value: pathOr('', 'id', media),
                label: `${pathOr('', 'bid', media)} - ${pathOr('', 'name', media)}`
            }
        })

        return medias;
    };


    return (
        <>
            <QueryTable
                data={data}
                loadData={loadData}
                numberOfRows={numberOfRows}
                permanentFilter={defaultFilter}
                queryDataPath={queryDataPath}
                queryVariables={queryVariables}
                columns={[
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.ID' }),
                        accessor: 'bid',
                        sortKey: 'bid',
                        filterKey: 'bid',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.name' }),
                        accessor: "name",
                        sortKey: "name",
                        filterKey: 'name',
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.media' }),
                        accessor: (rowData) => rowData.media ? rowData.media : <p> - </p>,
                        sortKey: "media",
                        filterKey: "media",
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.company' }),
                        accessor: 'company',
                        sortKey: "company",
                        filterKey: "company",
                        filterOperator: 'contains'
                    },
                    {
                        Header: intl.formatMessage({ id: 'displayList.table.description' }),
                        accessor: (rowData) => rowData.description ? rowData.description : <p> - </p>,
                        sortKey: "description",
                        filterKey: "description",
                        filterOperator: 'contains'
                    },
                    (displayUpd) ?
                        {
                            Header: intl.formatMessage({ id: 'displayList.table.actions' }),
                            accessor: (rowData) => 
                                <Grid container spacing={1}>
                                    
                                    {(displayUpd) && 
                                        <Button round size="sm" color="info"
                                            onClick={() => {
                                                loadMedia(rowData.companyId);
                                                setDisplayGroupId(rowData.id);
                                                setAssignModalOpen(true);
                                            }}>{
                                                intl.formatMessage({ id: 'displayList.table.actions.change' })
                                            }
                                        </Button>
                                        }
                                    {/* {(displayDel) && 
                                        <Button round size="sm" color="danger"
                                            onClick={() => { setDisplayGroupId(rowData.id); setDeleteModalOpen(true) }}>
                                            {intl.formatMessage({ id: 'displayList.table.actions.delete' })}
                                        </Button>
                                    } */}
                                </Grid>
                        } : { Header: " " }

                        
                ]}
            />
            <Formik
                onSubmit={(values, { resetForm }) => handleDisplayGroupUpdate(values, resetForm)}
                initialValues={initialData}
                validationSchema={getValidationSchema(intl)}
            >
                
                {
                    (formikProps) => (
                        <Modal
                            title={intl.formatMessage({ id: "displayList.modal.title" })}
                            open={assignModalOpen}
                            onClose={() => { setAssignModalOpen(false); formikProps.resetForm() }}
                            actions={renderModalButtons(formikProps)}
                            fullWidth
                        >
                            {
                            (dataMediaApprovedLoading || !dataMediaApproved) ?
                                <Loading/>
                            :
                                <Grid container spacing={1}>
                                    <Grid item sm={12}>
                                        <FormikField
                                            type="select"
                                            name="media"
                                            labelText={intl.formatMessage({ id: "displayList.modal.media" })}
                                            options={handleSetMediaOptions(dataMediaApproved.mediasByCompany.items)}
                                            formikProps={formikProps}
                                            highlightChange
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Modal>
                    )
                }
            </Formik>

            {/* <Modal
                title={intl.formatMessage({ id: "displayList.deleteModal.title" })}
                open={deleteModalOpen}
                onClose={() => setDeleteModalOpen(false)}
                // actions={() => renderDeleteModalButtons()}
                fullWidth
                
            >
                {renderDeleteModalButtons()}
            </Modal> */}
        </>
    )
}

export default injectIntl(DisplayTable);