import React, { useState } from "react";
import styles from "common/styles/widgets.js";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { Grid } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Box from "@material-ui/core/Box";
import IconCard from "components-lib/IconCard/IconCard";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import HoursCard from "components-lib/HoursCard/HoursCard";
import InactiveHoursCard from "components-lib/HoursCard/InactiveHoursCard";
import { withStyles } from '@material-ui/core/styles';
import { colors } from "common/styles/configLayout.js";
import FormikField from "components-lib/FormikField/FormikField";
import RestoreIcon from '@material-ui/icons/Restore';
import EditIcon from '@material-ui/icons/Edit';
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";

const useStyles = makeStyles((theme) => styles(theme));

const TimePlanCard = (props) => {
    const {
        intl,
        formikProps,
        times,
        callbackTimes,
        callbackInactiveTimes,
        zoneId,
        storeUpdate,
        multipleUpdate
    } = props;

    const classes = useStyles();
    const [isOpenDuringHolidaysChange, setIsOpenDuringHolidaysChange] = useState(multipleUpdate ? false : true);
    const [openHoursChange, setOpenHoursChange] = useState(multipleUpdate ? false : true);
    const [inactiveHoursChange, setInactiveHoursChange] = useState(multipleUpdate ? false : true);
    const [spotsPlaybackStartOffsetChange, setSpotsPlaybackStartOffsetChange] = useState(multipleUpdate ? false : true);
    const [spotsPlaybackEndOffsetChange, setSpotsPlaybackEndOffsetChange] = useState(multipleUpdate ? false : true);

    const spotsInterval = [
        { value: "0", label: `0 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "5", label: `5 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "10", label: `10 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "15", label: `15 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "20", label: `20 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "25", label: `25 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
        { value: "30", label: `30 ${intl.formatMessage({ id: 'tableTexts.minutes' })}` },
    ];

    const renderOpenHours = () => {
        return (<>
            {(!openHoursChange) ?
                <MultipleValuesField
                    callback={(newValue) => setOpenHoursChange(newValue)}
                    labelText={intl.formatMessage({ id: 'storeDetail.openingHours.title' })}
                    type={'time'}
                />
                :
                <>
                    <h4>{intl.formatMessage({ id: 'storeDetail.openingHours.title' })}</h4>
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                    <HoursCard
                        times={times}
                        callbackTimes={callbackTimes}
                        formikProps={formikProps}
                        zoneId={zoneId}
                        update={storeUpdate}
                        multipleUpdate={multipleUpdate}
                        helperText={(multipleUpdate) &&
                            <span
                                className={classes.multiUpdate}
                                style={{ color: "blue" }}
                                onClick={() => { callbackTimes([]); setOpenHoursChange(false) }}
                            >
                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                            </span>
                        }
                    />
                </>
            }</>
        )
    }

    const renderSpecialCloseHours = () => {
        return (<>
            {(!inactiveHoursChange) ?
                <MultipleValuesField
                    callback={(newValue) => setInactiveHoursChange(newValue)}
                    labelText={intl.formatMessage({ id: 'storeDetail.openingHours.specialClosed' })}
                    type={'slider'}
                />
                :
                <>
                    <h4>{intl.formatMessage({ id: 'storeDetail.openingHours.specialClosed' })}</h4>
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                    <InactiveHoursCard
                        callback={callbackInactiveTimes}
                        zoneId={zoneId}
                        storeUpdate={storeUpdate}
                        multipleUpdate={multipleUpdate}
                        helperText={(multipleUpdate) &&
                            <span
                                className={classes.multiUpdate}
                                style={{ color: "blue" }}
                                onClick={() => { callbackInactiveTimes([]); setInactiveHoursChange(false) }}
                            >
                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                            </span>
                        }
                    />
                </>
            }
        </>
        )
    }

    const renderIsOpenDuringHolidays = () => {
        if (storeUpdate)
            return (<>
                {(!isOpenDuringHolidaysChange) ?
                    <MultipleValuesField
                        callback={(newValue) => setIsOpenDuringHolidaysChange(newValue)}
                        labelText={intl.formatMessage({ id: 'storeDetail.openingHours.holiday' })}
                        type={'toggle'}
                    />
                    :
                    <FormikField
                        name="preferences.isOpenedDuringHolidays"
                        labelText={intl.formatMessage({ id: 'storeDetail.openingHours.holiday' })}
                        checkedLabelText={intl.formatMessage({ id: 'storeDetail.openingHours.open' })}
                        uncheckedLabelText={intl.formatMessage({ id: 'storeDetail.openingHours.closed' })}
                        formikProps={formikProps}
                        type="switch"
                        highlightChange
                        helperText={(multipleUpdate) &&
                            <span
                                className={classes.multiUpdate}
                                style={{ color: "blue" }}
                                onClick={() => { formikProps.setFieldValue("preferences.isOpenedDuringHolidays", undefined); setIsOpenDuringHolidaysChange(false) }}
                            >
                                {intl.formatMessage({ id: 'storeList.multiUpdate.state.undoChange' })}
                            </span>
                        }
                    />
                }
            </>)
        return (
            <Grid container>
                <Grid item sm={12}>
                    {intl.formatMessage({ id: 'storeDetail.openingHours.holiday' })}
                    <Box className={classes.floatRight}>
                        {formikProps?.values?.[0]?.preferences?.isOpenedDuringHolidays
                            ? <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.open' })}</label></>
                            : <><label>{intl.formatMessage({ id: 'storeDetail.openingHours.closed' })}</label></>
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    };

    return (
        <IconCard
            title={intl.formatMessage({ id: 'campaigns.scheduleCard.title' })}
            icon={<AccessAlarmIcon />}
        >
            <Grid container spacing={0}>
                <Grid item sm={12}>
                    {renderOpenHours()}
                </Grid>

                <Grid item sm={12} style={{ paddingTop: "10px" }} >
                    {renderIsOpenDuringHolidays()}
                </Grid>
                <Grid item sm={12}>
                    <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                </Grid>
                <Grid item sm={12}>
                    {renderSpecialCloseHours()}
                </Grid>

            </Grid>
        </IconCard >
    )
};

export default injectIntl(TimePlanCard);
