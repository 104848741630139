
import gql from 'graphql-tag';

const DEVICE_DISCOVER = gql`
mutation deviceDiscover {
    deviceDiscover {
              id
      bid
    }
  }
`;

export default DEVICE_DISCOVER;

