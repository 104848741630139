import gql from 'graphql-tag';

const ALL_PARTNERS = gql`
  query allPartners($offset: Int, $limit: Int, $sort: [UserSortInput!], $filter: [UserFilterInput]){
    users(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
      totalCount,
      items{
          id,
          bid,
          firstName
          lastName,
          fullName,
          email,
          phone,
          status,
          isActivated,
          isBlocked,
          blockReason,
          createdAt,
          updatedAt
      }
    }
  }
`;

export default ALL_PARTNERS;