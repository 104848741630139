import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        area: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' }, [0])))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable(),
        visitors: Yup.number()
            .min(0, (intl.formatMessage({ id: 'validation.number.min' }, [0])))
            .typeError(intl.formatMessage({ id: 'validation.number' }))
            .nullable()
    });
