import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconCard from "components-lib/IconCard/IconCard";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import { injectIntl } from "react-intl";
import { generatePath, withRouter } from "react-router-dom";
import paths from "paths";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import StoreIcon from '@material-ui/icons/Store';

const useStyles = makeStyles((theme) => styles(theme));

const StoreCard = (props) => {
  const {
    intl,
    storeId,
    history,
    deviceId,
    storeAddress,
    storePostal,
    storeCity
  } = props;
  const classes = useStyles();

  const renderStoreGrid = () => {
    return (
      <>
        <Grid item sm={12}>
          {intl.formatMessage({ id: 'storeDetail.storeCard.name' })}
          <Box className={classes.floatRight}>
            {props.name}
          </Box>
        </Grid>
        <Grid item sm={12}>
          {intl.formatMessage({ id: 'storeDetail.storeCard.address' })}
          <Box className={classes.floatRight}>
            {(!storeAddress && !storePostal && !storeCity) ? "-" :
              (storeAddress) ? storeAddress + ", " : ""} {(storePostal) ? storePostal + ", " : ""} {(storeCity) ? storeCity : ""}
          </Box>
        </Grid>
      </>
    );
  };

  const renderTitle = () => {
    return (
      <Box>
        {intl.formatMessage({ id: "storeDetail.title" })}
      </Box>
    );
  };

  return (
    <>
      <IconCard title={renderTitle()} icon={<StoreIcon />}>
        <Grid container spacing={2}>
          {renderStoreGrid()}
        </Grid>

        <Grid item sm={12}>
          <hr />
          <Button
            className={classes.floatRight}
            color="info"
            size="sm"
            round
            onClick={() => {
              history.push(`/admin${generatePath(paths.stores.detail, {
                storeId: storeId,
                deviceId: deviceId
              })}`
              )
            }}
          >
            {intl.formatMessage({ id: "deviceDetail.store.detail" })}
          </Button>
        </Grid>
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(StoreCard));