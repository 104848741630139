import gql from 'graphql-tag';

const UPDATE_GROUP = gql`
mutation userGroupUpdate($id: ID!, $input: UserGroupUpdateInput!) {
  userGroupUpdate(id: $id, input: $input) {
    id
    name
    description
    permissions
    createdAt
    updatedAt
    createdBy{
      fullName
    }
  }
}
`;

export default UPDATE_GROUP;