import React, { useState, useEffect } from 'react';
import IconCard from 'components-lib/IconCard/IconCard';
import Business from "@material-ui/icons/Business";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { injectIntl } from 'react-intl';
import SELECT_COMPANIES from 'queries/CompaniesQueries/selectCompanies'
import { pathOr } from 'rambda';
import { useQuery } from '@apollo/react-hooks';
import Input from "@material-ui/core/Input";

const CompanyData = (props) => {
    const { callbackCompanies, intl } = props;

    const { data: companiesData } = useQuery(SELECT_COMPANIES)
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        let companiesSelectItems = pathOr([], "companies.items", companiesData).map(
            (company) => {
                return {
                    value: pathOr("", "id", company),
                    label: `${pathOr("", "name", company)}`,
                };
            }
        );
        setCompanies(companiesSelectItems);
    }, [companiesData]);

    return (
        <IconCard title={intl.formatMessage({ id: 'createUser.company.title' })} icon={<Business />}>
            <AutocompleteSelect
                style={{ marginBottom: "15px" }}
                placeholder={intl.formatMessage({ id: "companyList.assignModal.company" })}
                label={intl.formatMessage({ id: "companyList.table.company" })}
                isMulti
                options={companies}
                onChange={(e) => callbackCompanies(e)}
                input={<Input />}
            />

        </IconCard>
    )
};

export default injectIntl(CompanyData);