import gql from 'graphql-tag';

const STORE_CREATE = gql`
mutation storeCreate($input: StoreCreateInput!){
    storeCreate(input: $input){
        id
        name     
    }
}
`;

export default STORE_CREATE;