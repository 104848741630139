import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import QueueMusicIcon from '@material-ui/icons/QueueMusic';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import styles from "common/styles/widgetStyle.js";
import Button from "components/CustomButtons/Button";
import { generatePath } from "react-router-dom";
import paths from "paths";
import WarningIcon from '@material-ui/icons/Warning';
import { mediaList } from 'configFiles/roles'
import TheatersIcon from '@material-ui/icons/Theaters';

const useStyles = makeStyles((theme) => styles(theme));

const StreamsAndMediasCard = (props) => {
    const {
        history,
        intl,
        allMedia,
        awaitingMedia
    } = props;

    const classes = useStyles();

    const renderDetailButtons = () => {
        return (
            <Box>
                {mediaList &&
                    <Button
                        className={classes.floatRight}
                        color="info"
                        size="sm"
                        round
                        onClick={() => history.push({
                            pathname: `/admin${generatePath(paths.media)}`
                        })}
                    >
                        {intl.formatMessage({ id: "dashboardCard.media.button" })}
                    </Button>
                }
            </Box>
        )
    }

    const renderAwaitingMedia = () => {
        if (awaitingMedia > 0) {
            return (
                <div style={{ color: '#EE9600' }}>
                    {intl.formatMessage({ id: "dashboardCard.media.awaitingMedia" })}
                    <Box className={classes.floatRight} >
                        <WarningIcon style={{ fontSize: 18 }} className={classes.warningIcon} /><span>{awaitingMedia}</span>
                    </Box>
                </div>
            )
        }

        return <>
            {intl.formatMessage({ id: "dashboardCard.media.awaitingMedia" })}
            <Box className={classes.floatRight}>
                {awaitingMedia}
            </Box>
        </>
    }


    return <>
        <IconCard
            title={intl.formatMessage({ id: "dashboardCard.media.title" })}
            icon={<TheatersIcon />}
            height="80%"
            actions={renderDetailButtons()}
        >
            <Grid container spacing={3}>
                {mediaList && <>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "dashboardCard.media.allMedia" })}
                        <Box className={classes.floatRight}>
                            {allMedia}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {renderAwaitingMedia()}
                    </Grid>
                </>
                }
            </Grid>
        </IconCard>
    </>
};

export default injectIntl(StreamsAndMediasCard);
