import React, { Fragment } from "react";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    box: {
        maxHeight: "150px",
        overflowY: "scroll",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    chipWrapper: {
        display: "inline-block",
        margin: theme.spacing(0.25),
    },
    divider: {
        marginBottom: theme.spacing(1),
    },
}));
const Selected = ({ tree, selectedIds, nodeNamePreparer, onChange, }) => {
    const classes = useStyles();
    const handleDelete = (id) => {
        onChange(id, false);
    };
    if (selectedIds.length === 0) {
        return null;
    }
    return (React.createElement(Fragment, null,
        React.createElement(Box, { className: classes.box }, selectedIds.map((id, index) => {
            const node = tree.nodes[id];
            return (React.createElement(Box, { key: `${index}-${id}`, className: classes.chipWrapper },
                React.createElement(Chip, { label: nodeNamePreparer
                        ? nodeNamePreparer(node.name, node.level)
                        : node.name, onDelete: () => {
                        handleDelete(id);
                    } })));
        })),
        React.createElement(Divider, { className: classes.divider })));
};
export default Selected;
