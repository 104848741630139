import { Grid } from "@material-ui/core";
import RoomIcon from '@material-ui/icons/Room';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const AddressCard = (props) => {
  const { intl, readData, formikProps, loading, companyUpdate, newCompany } = props;
  const classes = useStyles();

  const renderContent = () => {
    if (!newCompany) {
      if (!companyUpdate) {
        return (
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.addressCard.name" })}
              <Box className={classes.floatRight}>
                {(readData.name) ? readData.name : "-"}
              </Box>
            </Grid>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.addressCard.address" })}
              <Box className={classes.floatRight}>
                {(readData.address) ? readData.address : "-"}
              </Box>
            </Grid>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.addressCard.postal" })}
              <Box className={classes.floatRight}>
                {(readData.postal) ? readData.postal : "-"}
              </Box>
            </Grid>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.addressCard.city" })}
              <Box className={classes.floatRight}>
                {(readData.city) ? readData.city : "-"}
              </Box>
            </Grid>
            <Grid item sm={12}>
              {intl.formatMessage({ id: "companyDetail.addressCard.country" })}
              <Box className={classes.floatRight}>
                {(readData.country) ? readData.country : "-"}
              </Box>
            </Grid>
          </Grid>
        )
      }
    }
    return (
      <Grid container>
        <Grid item sm={12}>
          <FormikField
            name="name"
            labelText={intl.formatMessage({ id: "companyDetail.addressCard.name" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="address"
            labelText={intl.formatMessage({ id: "companyDetail.addressCard.address" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="postal"
            labelText={intl.formatMessage({ id: "companyDetail.addressCard.postal" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="city"
            labelText={intl.formatMessage({ id: "companyDetail.addressCard.city" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="country"
            labelText={intl.formatMessage({ id: "companyDetail.addressCard.country" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
      </Grid>
    )
  }

  if (loading) return "Loading..."
  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "companyDetail.address.title" })}
        icon={<RoomIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(AddressCard));
