import React, { useState } from 'react';
import { generatePath, withRouter } from "react-router-dom";
import { pathOr } from 'rambda';
import { Grid } from '@material-ui/core';
import Button from "components/CustomButtons/Button.js";
import CompanyData from './UserCards/CompanyData';
import GroupData from './UserCards/GroupData';
import NewContactData from './UserCards/NewContactData';
import { useMutation } from '@apollo/react-hooks';
//import { getRoleEnumBySlug } from 'helpers/helpers';
import { useSnackbar } from 'notistack';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import paths from 'paths';
import { injectIntl } from 'react-intl';
import { Formik } from "formik";
import { getMaintainerValidationSchema } from "./validationSchema";
import USER_CREATE from 'queries/UsersQueries/userCreate';

const CreateUserPage = (props) => {
    const { history, intl } = props;
    const { enqueueSnackbar } = useSnackbar();
    let userData = ({ email: '@' });

    const [createUser] = useMutation(USER_CREATE);
    const [companiesIds, setCompaniesIds] = useState([])
    const [groupsId, setGroupsId] = useState([])

    const handleCreateUser = (values) => {
        let setCompanyIds = companiesIds.map(company => company.value);
        let setGroupIds = groupsId.map(group => group.value);
        createUser({
            variables: {
                input: {
                    ...values,
                    companyIds: setCompanyIds,
                    groupIds: setGroupIds,
                }
            }
        }).then((response) => {
            const firstName = pathOr('', ['data', 'userCreate', 'firstName'], response);
            const lastName = pathOr('', ['data', 'userCreate', 'lastName'], response);
            const id = pathOr('', ['data', 'userCreate', 'id'], response);
            enqueueSnackbar(intl.formatMessage({ id: 'createUser.success' }, [firstName, lastName]), { variant: 'success' });
            history.push(`/admin${generatePath(paths.users.detail, { userId: id })}`)
        }).catch((err) => {
            console.log('[error]', err);
        });
    };

    const getValidationSchemaByRole = () => {
        return getMaintainerValidationSchema(intl);
    };

    return (
        <>
            <PageHeader
                title={intl.formatMessage({ id: 'createUser.title' })}
                handleBackAction={(e) => history.goBack()}
            />
            <Formik
                initialValues={userData}
                onSubmit={(values) => { handleCreateUser(values); }}
                validationSchema={getValidationSchemaByRole()}
            >
                {(formikProps) =>
                    <>
                        <Grid container spacing={3}>
                            <Grid item lg={4}>
                                <NewContactData
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    created={true}
                                />
                                <GroupData
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    callbackGroups={(newValue) => setGroupsId(newValue)}
                                />
                                <CompanyData
                                    data={formikProps.values}
                                    formikProps={formikProps}
                                    callbackCompanies={(newValue) => setCompaniesIds(newValue)}
                                />

                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item lg={12}>
                                <Button
                                    round
                                    color="primary"
                                    onClick={(e) => formikProps.handleSubmit()}
                                >
                                    {intl.formatMessage({ id: 'createUser.submit' })}
                                </Button>
                                <Button
                                    round
                                    onClick={(e) => history.goBack()}
                                >
                                    {intl.formatMessage({ id: 'createUser.cancel' })}
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
            </Formik>
        </>
    );
};

export default withRouter(injectIntl(CreateUserPage));