import gql from 'graphql-tag';

const DEVICE_DETAIL = gql`
query device($id: ID!) {
  device(id: $id) {
    id
    bid
    warnings
    errors
    status
    isOnline
    onlineAt
    statusUpdatedAt
    clientVersion
    balenaUuid
    deviceType
    createdAt
    updatedAt
    player{
      status
    }
    isEnabled
    zone {
      id
      bid
      name
      area
      visitors
      rank
      createdAt
      updatedAt
      preferences {
        isOpenedDuringHolidays
        activeTimes {
          weekDay
          from
          to
        }
      }
      store {
        id
        bid
        name
        address
        postal
        city
        country
        latitude
        longtitude
        fullAddress
        contactFirstName
        contactLastName
        contactPhone
        contactEmail
        createdAt
        updatedAt
        zones{
          area
          visitors
      }
        company {
          id
          bid
          name
          ico
          dic
          icDPH
          registrationCourt
          address
          postal
          city
          country
          latitude
          longtitude
          bankName
          bankIBAN
          bankBIC
          contactFirstName
          contactLastName
          contactPhone
          contactEmail
          createdAt
          updatedAt
        }
      }
    }
  }
}
`;

export default DEVICE_DETAIL;