import Api from '../api/Api';
export default class Location extends Api {
    async get(value) {
        const response = await this.call(`
            query CampaignCrtieriaOptions($input: CampaignCriteriaOptionsInput!) {
                campaignCriteriaOptions(input: $input) {
                    locations {
                        id
                        name
                        children {
                            id
                            name
                            children {
                                id
                                name
                                children {
                                    id
                                    name
                                }
                            }
                        }
                    }
                }
            }
        `, {
            input: {
                campaign: this.campaign,
                criteria: value
            }
        });
        if (!Array.isArray(response.campaignCriteriaOptions.locations)) {
            throw new Error('Response data does not contain locations key');
        }
        return this.prepareTree(response.campaignCriteriaOptions.locations);
    }
}
