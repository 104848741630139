import * as Yup from 'yup';
import { phoneRegex } from "helpers/helpers";

export const getValidationSchema = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
        companyId: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

export const getValidationSchemaDetail = (intl) =>
    Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'validation.required' })).nullable(),
    });

