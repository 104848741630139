import React, { Fragment, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import PROFILE_QUERY from 'queries/ProfileQueries/userData';
import LANGUAGE_CHANGE from 'queries/ProfileQueries/languageChange';
import CHANGE_PASSWORD from 'queries/ProfileQueries/changePassword';
import { Grid } from '@material-ui/core';
import { PasswordValidityEnum } from 'common/enums/PasswordValidityEnum';
import ContactDataCard from './DetailCards/ContactDataCard';
import CompanyDataCard from './DetailCards/CompanyDataCard';
import CompanyInfoCard from './DetailCards/ContactInfoCard';
import IdentificatorsCard from './DetailCards/IdentificatorsCard';
import BankDataCard from './DetailCards/BankDataCard';
import ChangePasswordCard from './DetailCards/ChangePasswordCard';
import RoleEnum from 'common/enums/RoleEnum';
import { useSnackbar } from 'notistack';
import { pathOr } from 'rambda';
import { injectIntl } from 'react-intl';
import PageHeader from 'components-lib/PageHeader/PageHeader';
import IconCard from 'components-lib/IconCard/IconCard';
import LanguageIcon from '@material-ui/icons/Language';
import CustomSelect from 'components-lib/CustomSelect/CustomSelect';
import { IntlContext } from "index";
import { setPreferedLanguage } from 'helpers/helpers';
import Loading from "components-lib/Loading/Loading";

const ProfilePage = (props) => {
    const { intl } = props;
    const { loading, data } = useQuery(PROFILE_QUERY);
    const { enqueueSnackbar } = useSnackbar();

    const [newPasswordData, setNewPasswordData] = useState({ oldPassword: null, newPassword: null, passwordVerification: null, passwordValidity: PasswordValidityEnum.NONE })
    const [changePassword] = useMutation(CHANGE_PASSWORD);
    const [languageChange] = useMutation(LANGUAGE_CHANGE);
    const onChangePassword = () => {
        if (newPasswordData.newPassword !== newPasswordData.passwordVerification) {
            setNewPasswordData({ ...newPasswordData, passwordValidity: PasswordValidityEnum.NOT_SAME })
            enqueueSnackbar(intl.formatMessage({ id: 'profile.password.notEquals' }), { variant: 'error', });
            return false;
        }

        if (newPasswordData.newPassword === null || newPasswordData.newPassword === "") {
            setNewPasswordData({ ...newPasswordData, passwordValidity: PasswordValidityEnum.EMPTY })
            enqueueSnackbar(intl.formatMessage({ id: 'profile.password.empty' }), { variant: 'error', });
            return false;
        }

        setNewPasswordData({ ...newPasswordData, passwordValidity: PasswordValidityEnum.VALID })


        changePassword({
            variables: {
                oldPassword: newPasswordData.oldPassword,
                newPassword: newPasswordData.newPassword
            }
        }).then(response => {
            enqueueSnackbar(intl.formatMessage({ id: 'profile.password.success' }), { variant: 'success', })
        }).catch(err => {
            console.log('[err]', err);
        });
        return true;
    }

    const getTitle = () => {
        let text = pathOr(false, 'me.user.user.company', data) ? pathOr('', 'me.user.company.name', data) : `${pathOr('""', 'me.user.firstName', data)} ${pathOr('', 'me.user.lastName', data)}`;
        return (text);
    }

    const getSubtitle = () => {
        return <>
            {pathOr('', ['me', 'user', 'bid'], data)} <span> </span>
            {pathOr('', ['me', 'user', 'groups'], data).map((item, index) => (
                <>{((pathOr('', ['me', 'user', 'groups'], data).length) !== index + 1) ? (item.name + ", ") : (item.name)}</>
            ))}
        </>
    }

    const canRenderCompanyData = () => {
        return data.me.user.company !== undefined && data.me.user.company !== null && data.me.user.role === RoleEnum.TENANT;
    }

    let userData = pathOr(null, "me", data);

    if (userData === null) {
        return null
    };

    const handleLanguageChange = (changeLanguage, lang) => {
        changeLanguage(lang);
        setPreferedLanguage(lang);
        languageChange({
            variables: {
                input: {
                    language: lang
                }

            }
        }).then((resp) => {
            enqueueSnackbar(intl.formatMessage({ id: 'profile.language.success' }), { variant: 'success' })
        }).catch((err) => {
            console.log('[error]', err)
        })
    };

    if (loading) return <Loading />;
    return (
        <IntlContext.Consumer>{(intlContextProps) => (
            <Fragment>
                <PageHeader
                    withBackButton={false}
                    title={getTitle()}
                    subTitle={getSubtitle()}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <ContactDataCard
                            phone={pathOr('', 'user.phone', userData)}
                            email={pathOr('', 'user.email', userData)}
                            name={pathOr('', 'user.firstName', userData)}
                            surname={pathOr('', 'user.lastName', userData)}
                            groups={pathOr('', 'user.groups', userData)}
                        />
                        <ChangePasswordCard
                            setNewPasswordData={setNewPasswordData}
                            onChangePassword={onChangePassword}
                            newPasswordData={newPasswordData} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                        <IconCard title={intl.formatMessage({ id: 'profile.language' })} icon={<LanguageIcon />}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <CustomSelect
                                        onChange={(e) => handleLanguageChange(intlContextProps.handleLanguageChange, e.target.value)}
                                        value={intlContextProps.selectedLanguage}
                                        options={[
                                            {
                                                label: 'EN',
                                                value: 'en'
                                            },
                                            {
                                                label: 'SK',
                                                value: 'sk'
                                            }
                                        ]}
                                    />
                                </Grid>
                            </Grid>
                        </IconCard>

                    </Grid>
                    {canRenderCompanyData() &&
                        <Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <CompanyDataCard
                                    companyName={pathOr('', 'user.company.name', userData)}
                                    companyStreet={pathOr('', 'user.company.address', userData)}
                                    companyPostal={pathOr('', 'user.company.postal', userData)}
                                    companyCity={pathOr('', 'user.company.city', userData)}
                                    companyCountry={pathOr('', 'user.company.country', userData)}
                                />
                                <IdentificatorsCard
                                    ico={pathOr('', 'user.company.ico', userData)}
                                    dic={pathOr('', 'user.company.dic', userData)}
                                    icdph={pathOr('', 'user.company.icDPH', userData)}
                                    registerCode={pathOr('', 'user.company.registrationCourt', userData)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={4}>
                                <CompanyInfoCard
                                    firstName={pathOr('', 'user.firstName', userData)}
                                    lastName={pathOr('', 'user.lastName', userData)}
                                    gender={pathOr('', 'user.gender', userData)}
                                    phone={pathOr('', 'user.phone', userData)}
                                    email={pathOr('', 'user.email', userData)}
                                />
                                <BankDataCard
                                    iban={pathOr('', 'user.company.bank.iban', userData)}
                                    bic={pathOr('', 'user.company.bank.bic', userData)}
                                    bankName={pathOr('', 'user.company.bank.name', userData)} />
                            </Grid>
                        </Fragment>
                    }
                </Grid>
            </Fragment>)}
        </IntlContext.Consumer>
    );
};

export default injectIntl(ProfilePage);