import gql from 'graphql-tag';

const DEVICE_ASSIGN_ZONE = gql`
    mutation deviceAssignZone($id: ID!, $zoneId: ID!) {
        deviceAssignZone(id: $id, zoneId: $zoneId){
            id  
        }
    }
`;

export default DEVICE_ASSIGN_ZONE;