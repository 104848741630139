import React from 'react';
import { Grid } from '@material-ui/core';
import styles from "common/styles/widgets.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { makeStyles } from '@material-ui/core/styles';
import Subject from "@material-ui/icons/Subject";
import IconCard from 'components-lib/IconCard/IconCard';
import { injectIntl } from 'react-intl';

const useStyles = makeStyles((theme) => styles(theme))

const IdentificatorsCard = (props) => {

    const {intl} = props;
    const classes = useStyles();

    return(
        <IconCard title={intl.formatMessage({ id: 'profile.identificators.title' })} icon={<Subject/>}>
            <Grid container>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            labelText={intl.formatMessage({ id: 'profile.identificators.ico' })}
                            id="ico"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.ico === null? "" :props.ico
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            className={classes.inputMargin}
                            labelText={intl.formatMessage({ id: 'profile.identificators.dic' })}
                            id="dic"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.dic === null? "" :props.dic
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            className={classes.inputMargin}
                            labelText={intl.formatMessage({ id: 'profile.identificators.icDph' })}
                            id="icdph"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.icdph === null? "" :props.icdph
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CustomInput
                            className={classes.inputMargin}
                            labelText={intl.formatMessage({ id: 'profile.identificators.registerCode' })}
                            id="registerCode"
                            inputProps={{
                                type: "text",
                                disabled: true,
                                value: props.registerCode === null? "" :props.registerCode
                            }}
                            formControlProps={{
                                fullWidth: true
                            }}
                        />
                    </Grid>
                </Grid>
        </IconCard>
    )
}

export default injectIntl(IdentificatorsCard);