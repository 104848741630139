
import gql from 'graphql-tag';

const SELECT_COMPANIES = gql`
query companies($offset: Int, $limit: Int, $sort: [CompanySortInput!], $filter: [CompanyFilterInput]){
  companies(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    items{
      id
      bid
      name
    }
  }
}
`;

export default SELECT_COMPANIES;

