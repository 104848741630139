import gql from 'graphql-tag';

const MEDIAS = gql`
query medias($offset: Int, $limit: Int, $sort: [MediaSortInput!], $filter: [MediaFilterInput]){
  medias(offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    items{
      id
      bid
      status
      category
      name
      description
      size
      duration
      format
      progress
      acceptanceStatus
      acceptanceDescription
      createdAt
      updatedAt
      previews{
        id
        url
      }
      company{
        id
        bid
        name
      }
    }
  }
}
`;

export default MEDIAS;