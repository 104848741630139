import Api from '../api/Api';
export default class Segment extends Api {
    async get(value) {
        const response = await this.call(`
            query CampaignCrtieriaOptions($input: CampaignCriteriaOptionsInput!) {
                campaignCriteriaOptions(input: $input) {
                    segments {
                        id
                        name
                        children {
                            id
                            name
                        }
                    }
                }
            }
        `, {
            input: {
                campaign: this.campaign,
                criteria: value
            }
        });
        if (!Array.isArray(response.campaignCriteriaOptions.segments)) {
            throw new Error('Response data does not contain segmentsByCriteria key');
        }
        return this.prepareTree(response.campaignCriteriaOptions.segments);
    }
}
