import React, { useCallback, useEffect, useState } from 'react';
// import { Grid } from '@material-ui/core';
// import { useState } from 'react';
import { useQuery, useLazyQuery } from 'react-apollo';
// import { Formik } from 'formik';
// import { useEffect } from 'react';
// import { pathOr } from 'rambda';
// import FormikField from 'components-lib/FormikField/FormikField';
// import { validationSchemaStep3, validationSchemaStep3Internal } from "./validationSchema";
import { injectIntl } from 'react-intl';
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import DisplayCard from '../CampaignCreateCards/DisplayCard';
import DISPLAY_GROUPS from 'queries/DisplaysQueries/allDisplayGroups';
import STORES_TABLE_BY_COMPANY from 'queries/CompaniesQueries/getStoresByCompany';
import Loading from "components-lib/Loading/Loading";
// import COMPANY_DETAIL from 'queries/CompaniesQueries/companyDetail';
// import Form from "components-lib/CampaignWidget/component/form/Form";
// import HoursCard from "components-lib/HoursCard/HoursCard";
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// import FormHelperText from "@material-ui/core/FormHelperText";
// import colors from "common/styles/colors";
// import { commercialCampaignCreate, internalCampaignCreate } from 'configFiles/roles';

const useStyles = makeStyles((theme) => styles(theme));

const Step3 = ({ intl, callback, callbackValid, initialData, companyId, storeIds }) => {

  const { data: companyStoresData, loading: campaignStoresLoading, refetch: refetchStoresData } = useQuery(STORES_TABLE_BY_COMPANY(companyId));
  // const [ displayGroups, setDisplayGroups ] = useState([]);

  const getStoreIds = () => {
    if(storeIds[0] === "*") return companyStoresData.storesTableByCompany.items.map(store => store.id);
    else return storeIds;
  }

  const onDisplayGroupsUpdate = (groups) => {
    callback(groups);
    callbackValid(groups.length > 0);
    // setDisplayGroups(groups);
  }

  const getCampaignIdNumber = (value) => {
    return Number(value);
  }

  if (campaignStoresLoading) return <Loading/>

  return (
    <>
      {/* {callbackValid(displayGroups.length > 0)} */}
      <DisplayCard
        query={DISPLAY_GROUPS}
        queryDataPath={['displayGroupsTable', 'items']}
        queryVariables={{filter: {
          companyId: {
              eq: getCampaignIdNumber(companyId)
          }
        }}}
        refetch={refetchStoresData}
        onDisplayGroupsUpdate={onDisplayGroupsUpdate}
        initialData={initialData}
      />
    </>
  );
}

export default injectIntl(Step3);