import gql from 'graphql-tag';

const STORES_BY_USER = gql`
  query storesTableByUser($userId: ID!$offset: Int, $limit: Int, $sort: [StoresTableSortInput!], $filter: [StoresTableFilterInput]){
    storesTableByUser(userId: $userId, offset:$offset, limit: $limit, sort: $sort, filter: $filter){
    totalCount,  
    items{
        id
        bid
        name
        segment
        address
        company
        deviceStatus
        playerStatus         
      }
    }
  }
`;

export default STORES_BY_USER;