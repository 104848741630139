import gql from 'graphql-tag';

const STORE_CREATE = gql`
    mutation storeUpdate($id: ID!, $input: StoreUpdateInput!){
        storeUpdate(id: $id, input: $input){
            name
            address
            postal
            city
            country
            contactFirstName
            contactLastName
            contactPhone
            contactEmail
        }
    }
`;

export default STORE_CREATE;