import { useQuery } from "@apollo/react-hooks";
import { Grid } from "@material-ui/core";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import FormikField from "components-lib/FormikField/FormikField";
import IconCard from "components-lib/IconCard/IconCard";
import MultipleValuesField from "components-lib/MultipleValuesField/MultipleValuesField";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { pathOr } from "rambda";
import ALL_SEGMENTS from "queries/StoresQueries/allSegments";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const BasicInfoCard = (props) => {
    const { intl, formikProps, storeUpdate, storeCreate, storeDetail, multipleUpdate } = props;
    const classes = useStyles();

    const { data: segmentData } = useQuery(ALL_SEGMENTS, { skip: (!storeUpdate) });
    const [availableSegments, setAvailableSegments] = useState([]);

    const [segmentChange, setSegmentChange] = useState(multipleUpdate ? false : true)
    const [areaChange, setAreaChange] = useState(multipleUpdate ? false : true)
    const [visitorsChange, setVisitorsChange] = useState(multipleUpdate ? false : true)

    useEffect(() => {
        const segments = pathOr([], ['segments', 'items'], segmentData).map(segment => {
            return {
                label: pathOr('', 'name', segment),
                value: pathOr('', 'id', segment)
            }
        })
        setAvailableSegments(segments);
    }, [segmentData])

    const renderContent = () => {
        if (!storeUpdate) {
            return (
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "zoneDetail.basicInfoCard.name" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.name) ? formikProps.values.name : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.category" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.zones?.[0].preferences.segment?.id) ? formikProps.values.zones[0].preferences.segment?.name : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.area" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.zones?.[0].area) ? formikProps.values.zones[0].area : "-"}
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        {intl.formatMessage({ id: "storeDetail.storeCard.visitRate" })}
                        <Box className={classes.floatRight}>
                            {(formikProps.values.zones?.[0].visitors) ? formikProps.values.zones[0].visitors : "-"}
                        </Box>
                    </Grid>
                </Grid>
            )
        }

        return (
            <>
                < FormikField
                    name="name"
                    labelText={intl.formatMessage({ id: "zoneDetail.basicInfoCard.name" })}
                    formikProps={formikProps}
                    highlightChange
                />
                <FormikField
                    name={"preferences.segment.id"}
                    labelText={intl.formatMessage({ id: "storeDetail.storeCard.category" })}
                    formikProps={formikProps}
                    type="select"
                    options={availableSegments}
                />
                <FormikField
                    name={"area"}
                    labelText={intl.formatMessage({ id: "storeDetail.storeCard.area" })}
                    formikProps={formikProps}
                    highlightChange
                />
                <FormikField
                    name={"visitors"}
                    labelText={intl.formatMessage({ id: "storeDetail.storeCard.visitRate" })}
                    formikProps={formikProps}
                    highlightChange
                />
            </>
        )
    }

    return (
        <>
            <IconCard
                title={intl.formatMessage({ id: "zoneDetail.title" })}
                icon={<GroupWorkIcon />}>
                {renderContent()}
            </IconCard>
        </>
    );
};

export default withRouter(injectIntl(BasicInfoCard));
