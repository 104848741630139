import { makeStyles } from '@material-ui/core/styles';
import QueryTable from 'components-lib/QueryTable/QueryTable';
import Button from "components/CustomButtons/Button.js";
import paths from 'paths';
import React from 'react';
import { injectIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import styles from "common/styles/widgets.js";
import { DateTime } from "luxon";
import { playlistDetail } from 'configFiles/roles'

const useStyles = makeStyles((theme) => styles(theme))

export const PlaylistTable = (props) => {
    const { history, queryVariables, query, intl, defaultFilter = null, numberOfRows, queryDataPath = ['playlistsTable', 'items'] } = props
    const classes = useStyles();

    const onPlaylistDetail = (id) => {
        history.push(`/admin${generatePath(paths.playlists.detail, { playlistId: id })}`);
    }

    const onCompanyDetail = (id) => {
        history.push(`/admin${generatePath(paths.companies.detail, { companyId: id })}`);
    }

    return (
        <QueryTable
            query={query}
            numberOfRows={numberOfRows}
            permanentFilter={defaultFilter}
            queryDataPath={queryDataPath}
            queryVariables={queryVariables}
            columns={[
                {
                    Header: intl.formatMessage({ id: 'playlistList.table.ID' }),
                    accessor: 'bid',
                    sortKey: 'bid',
                    filterKey: 'bid',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'playlistList.table.name' }),
                    accessor: "name",
                    sortKey: "name",
                    filterKey: 'name',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'playlistList.table.company' }),
                    accessor: "company",
                    sortKey: "company",
                    filterKey: 'company',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'playlistList.table.media' }),
                    accessor: "media",
                    sortKey: "media",
                    filterKey: 'media',
                    filterOperator: 'contains'
                },
                {
                    Header: intl.formatMessage({ id: 'playlistList.table.description' }),
                    accessor: (rowData) => rowData.description ? rowData.description : <p> - </p>,
                    sortKey: "description",
                    filterKey: "description",
                    filterOperator: 'contains'
                },

                {
                    Header: intl.formatMessage({ id: 'playlistList.table.date' }),
                    accessor: (rowData) => rowData.createdAt !== null
                        ? DateTime.fromISO(rowData.createdAt).toFormat("dd.MM.yyyy")
                        : "",
                    sortKey: "createdAt",
                    filterKey: "createdAt",
                    filterOperator: 'between',
                    filterDataType: "dateBetween",
                    filterComponent: 'date',
                },
                {
                    Header: intl.formatMessage({ id: 'playlistList.table.createdBy' }),
                    accessor: "createdBy",
                    sortKey: "createdBy",
                    filterKey: 'createdBy',
                    filterOperator: 'contains'
                },
                (playlistDetail) ?
                    {
                        Header: intl.formatMessage({ id: 'playlistList.table.actions' }),
                        accessor: (rowData) => <Button round className={classes.tenantButtons} size="sm" color="info"
                            onClick={() => onPlaylistDetail(rowData.id)}>{intl.formatMessage({ id: 'playlistList.table.actions.detail' })}</Button>
                    } : { Header: " " }
            ]}
        />
    )
}

export default injectIntl(PlaylistTable);