import gql from 'graphql-tag';

const ALL_GROUPS = gql`
query userGroupsTable($offset: Int, $limit: Int, $sort: [UserGroupsTableSortInput!], $filter: [UserGroupsTableFilterInput]) {
    userGroupsTable (offset: $offset, limit: $limit, sort: $sort, filter: $filter) {
        totalCount,
        items {
          id
          name
          description
          members
        }
    }
}
`;

export default ALL_GROUPS;