import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { withRouter, useLocation } from 'react-router-dom';
import PageHeader from "../../components-lib/PageHeader/PageHeader";
import { Formik } from "formik";
import { Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { CAMPAIGN_DETAIL, STORES_TABLE_BY_CAMPAIGN } from "queries/CampaignsQueries/campaignDetail";
import UPDATE_CAMPAIGN from 'queries/CampaignsQueries/updateCampaign';
import DISPLAY_GROUPS_BY_STORES from 'queries/DisplaysQueries/displayGroupsByStores';
import DISPLAY_GROUPS from 'queries/DisplaysQueries/allDisplayGroups';
import { pathOr } from "rambda";
import {format, differenceInDays } from 'date-fns';
import CalendarCard from './CampaignCards/CalendarCard';
import InfoCard from "./CampaignCards/InfoCard";
import MediaCard from "./CampaignCards/MediaCard";
import MediaCountCard from "./CampaignCards/MediaCountCard";
import StoresCard from "./CampaignCards/StoresCard";
import DisplayCard from "./CampaignCards/DisplayCard";
import { makeStyles } from "@material-ui/core/styles";
import styles from "common/styles/widgets.js";
import Loading from "components-lib/Loading/Loading";
import STORES_BY_CRITERIA_OPTIONS from 'queries/StoresQueries/storesByCriteriaOptions';
import ALL_STORES from 'queries/StoresQueries/allStores';

const useStyles = makeStyles((theme) => styles(theme));

const CampaignDetailPage = (props) => {
    const { intl, history } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ campaignStoreIds, setCampaignStoreIds ] = useState([]);
    const campaignId = props.match.params.campaignId;
    const { data: campaignData, loading: campaignLoading, refetch: refetchData } = useQuery(CAMPAIGN_DETAIL, {
        variables: {
            id: campaignId
        }
    });
    const { data: campaignStoresData, loading: campaignStoresLoading, refetch: refetchStoresData } = useQuery(STORES_TABLE_BY_CAMPAIGN, {
        variables: {
            campaignId: campaignId
        }
    });

    const [loadCampaignStoresData, { data: campaignStoresDataLazy, loading: campaignStoresLoadingLazy, refetch: refetchStoresDataLazy }] = useLazyQuery(STORES_TABLE_BY_CAMPAIGN, {
        variables: {
            campaignId: campaignId
        }
    });

    const [loadStoresByCriteria, { data: storesByCriteria, loading: storesByCriteriaLoading, refetch: refetchStoresByCriteria }] = useLazyQuery(STORES_BY_CRITERIA_OPTIONS, {
        variables:{
            input: {
                criteria: {},
                campaign: {
                    companyId: pathOr('', ['campaign','company', 'id'], campaignData)
                }
            }
        }
    });

    const [loadStoresByStoreIds, { data: storesByStoreIds, loading: storesByStoreIdsLoading, refetch: refetchStoresByStoreIds }] = useLazyQuery(ALL_STORES);

    useEffect(() => {
        if(campaignData) loadStoresByCriteria();
    }, [campaignData]);

    useEffect(() => {
        if(storesByCriteria){
            const storeIds = storesByCriteria.campaignCriteriaOptions.stores.map(store => Number(store.id));
            setCampaignStoreIds(storeIds);
        }
    },[storesByCriteria]);

    useEffect(() => {
        if(campaignStoreIds.length > 0)
            loadStoresByStoreIds({
                variables:{
                    filter: {
                        id: {
                            in: [...campaignStoreIds]
                        }
                    }
                }
            });
    },[campaignStoreIds]);

    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN);

    const [displayTableRerender, setDisplayTableRerender] = React.useState(false);

    let location = useLocation();
    let status = pathOr('', ['status'], campaignData);
    let defaultFilter = location.state ? location.state : null;

    const datesToRanges = (dates) => {
        dates = dates.sort((a, b) => a.getTime() - b.getTime());
        const ranges = [];
        
        for (let i = 0; i < dates.length; i++) {
          const current = dates[i];
        
          if (i > 0) {
            const previous = dates[i - 1];
            const range = ranges[ranges.length - 1];
        
            if (differenceInDays(current, previous) === 1) {
              range.to = current;
              continue;
            }
          }
          
          ranges.push({ from: current, to: current });
        }
      
        const finalRanges = ranges.map((e) => {
            return {
                from: format(new Date(e.from), 'yyyy-MM-dd'),
                to: format(new Date(e.to), 'yyyy-MM-dd')
            }
        })

        return finalRanges;
    }

    const onStoresUpdate = (storeIds) => {
        if(storeIds.length === 0) return;

        const CampaignUpdateInput = {
            criteria: [{
                locationIds: [],
                segmentIds: [],
                companyIds: [],
                storeIds: storeIds
            }]
        }

        handleUpdateCampaign(CampaignUpdateInput);
    }

    const handleUpdateCampaign = (CampaignUpdateInput) => {
        updateCampaign({
            variables: {
            id: campaignId,
            input: CampaignUpdateInput
        },
        }).then((response) => {
            enqueueSnackbar(intl.formatMessage({ id: 'campaigns.detail.success.update' }), { variant: "success" });
            refetchData();
            setDisplayTableRerender(!displayTableRerender);
            loadCampaignStoresData();
            // callback(1)
        })
            .catch((err) => {
            console.log('[error]', err)
        });
    }

    const onMediasUpdate = (medias) => {
        if(medias.length === 0) return;

        const mediaIds = medias.map(e => e.id);

        const CampaignUpdateInput = {
            mediaIds: mediaIds,
        }

        handleUpdateCampaign(CampaignUpdateInput);
    }

    const onActiveTimesUpdate = (dates) => {
        const times = datesToRanges(dates);

        const CampaignUpdateInput = {
            activeTimes: times,
        }

        handleUpdateCampaign(CampaignUpdateInput);
    }

    const onDisplayGroupsUpdate = (displayGroups) => {
        if(displayGroups.length === 0) return;

        const CampaignUpdateInput = {
            groupIds: displayGroups
        }

        handleUpdateCampaign(CampaignUpdateInput);
    }

    const getCampaignStoreIds = (data) => {
        return data.storesTableByCampaign.items.map(store => store.id);
    }

    const getCampaignIdNumber = (value) => {
        return Number(value);
    }

    if (campaignLoading || campaignStoresLoading) return <Loading/>

    return (
        <>
            <>
                <PageHeader
                    subTitle={pathOr('', ['bid'], campaignData)}
                    handleBackAction={(e) => history.goBack()}
                    withBackButton={true}
                />

                <Formik
                    onSubmit={(values) => {  }}
                    enableReinitialize
                    initialValues={campaignData}
                >
                    {(formikProps) => (
                        <>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={12} lg={4}>
                                    <InfoCard
                                        formikProps={formikProps}
                                        name={pathOr('', ['campaign','name',], campaignData)}
                                        company={pathOr('', ['campaign','company', 'name'], campaignData)}
                                        contactFirstName={pathOr('', ['campaign','company', 'contactFirstName'], campaignData)}
                                        contactLastName={pathOr('', ['campaign','company', 'contactLastName'], campaignData)}
                                        contactEmail={pathOr('', ['campaign','company', 'contactEmail'], campaignData)}
                                        contactPhone={pathOr('', ['campaign','company', 'contactPhone'], campaignData)}
                                        description={pathOr('', ['campaign','description'], campaignData)}
                                        createdAt={pathOr(0, ['campaign','createdAt',], campaignData)}
                                        updatedAt={pathOr(0, ['campaign','updatedAt',], campaignData)}
                                        createdBy={pathOr(0, ['campaign','createdBy', 'fullName'], campaignData)}
                                        isCanceled={((status === "Rejected") || (status === "Finished"))}
                                    />
                                    <MediaCountCard
                                        history={history}
                                        queryDataPath={['campaign', 'mediaStats']}
                                        data={campaignData}
                                        loadData={() => {}}
                                        
                                    />
                                    <MediaCard
                                        medias={pathOr('', ['campaign','media',], campaignData)}
                                        company={pathOr('', ['campaign','company',], campaignData)}
                                        onMediasUpdate={onMediasUpdate}
                                    />  
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={8}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <CalendarCard
                                                acitiveTimes={pathOr('', ['campaign','activeTimes',], campaignData)}
                                                onActiveTimesUpdate={onActiveTimesUpdate}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <DisplayCard
                                                history={history}
                                                query={CAMPAIGN_DETAIL}
                                                queryDataPath={['campaign', 'groups']}
                                                queryVariables={{id: campaignId}}
                                                data={campaignData}
                                                loadData={() => {}}
                                                refetch={refetchData}
                                                modalQuery={DISPLAY_GROUPS}
                                                modalQueryVariables={{filter: {
                                                    companyId: {
                                                        eq: getCampaignIdNumber(pathOr('', ['campaign','company', 'id'], campaignData))
                                                    }
                                                }}}
                                                modalQueryDataPath={['displayGroupsTable', 'items']}
                                                onDisplayGroupsUpdate={onDisplayGroupsUpdate}
                                            />
                                        </Grid>
                                    </Grid>
                                    <StoresCard
                                        history={history}
                                        companyId={pathOr('', ['campaign','company','id'], campaignData)}
                                        query={STORES_TABLE_BY_CAMPAIGN}
                                        // defaultFilter={defaultFilter}
                                        queryVariables={{ campaignId: campaignId }}
                                        queryDataPath={['storesTableByCampaign', 'items']}
                                        queryDataPathStoresByCompany={['storesTable', 'items']}
                                        data={(campaignStoresDataLazy) ? campaignStoresDataLazy : campaignStoresData}
                                        loadData={loadCampaignStoresData}
                                        dataModal={(storesByStoreIds) ? storesByStoreIds : {storesTable: {items: []}}}
                                        modalLoadData={loadStoresByStoreIds}
                                        modalQueryVariables={{
                                            filter: {
                                                id: {
                                                    in: [...campaignStoreIds]
                                                }
                                            }
                                        }}
                                        refetch={() => {}}
                                        onAddStores={onStoresUpdate}
                                    />   
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Formik>
            </>
        </>
    )
}
 
export default injectIntl(withRouter(CampaignDetailPage))
