import { Grid } from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import IconCard from "components-lib/IconCard/IconCard";
import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import FormikField from "components-lib/FormikField/FormikField";
import AutocompleteSelect from "components-lib/AutocompleteSelect/AutocompleteSelect";
import { useQuery } from "@apollo/react-hooks";
import ALL_VILLAGES from "queries/StoresQueries/allVillages";
import { pathOr } from "rambda";
import Input from "@material-ui/core/Input";
import styles from "common/styles/widgets.js";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => styles(theme));

const AddressCard = (props) => {
  const { intl, formikProps, city, callbackCity, storeUpdate, storeCreate } = props;
  const { data: villagesData } = useQuery(ALL_VILLAGES)
  const classes = useStyles();

  const getVillagesOptions = () => {
    const villageOptions = [];
    pathOr([], ['villages', 'items'], villagesData).map(village => {
      villageOptions.push({
        label: village.name,
        value: village.id
      })
      return "";
    })
    return villageOptions
  }

  let cityTown = [
    {
      label: (!city?.label) ? city : city?.label,
      value: (city?.value) ? city?.label : 1
    }
  ]

  const renderContent = () => {
    if (!storeUpdate) {
      return (
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.address" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.name) ? formikProps.values.name : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.postal" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.address) ? formikProps.values.address : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "companyDetail.addressCard.postal" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.postal) ? formikProps.values.postal : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.city" })}
            <Box className={classes.floatRight}>
              {(city) ? city : "-"}
            </Box>
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: "storeDetail.storeAddressCard.country" })}
            <Box className={classes.floatRight}>
              {(formikProps.values.country) ? formikProps.values.country : "-"}
            </Box>
          </Grid>
        </Grid>
      )
    }
    return (
      <Grid container spacing={0}>
        <Grid item sm={12}>
          <FormikField
            name="address"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.address" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="postal"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.postal" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <br />
          {storeCreate ?
            <AutocompleteSelect
              placeholder={intl.formatMessage({ id: "storeDetail.storeAddressCard.cityPlaceholder" })}
              label={intl.formatMessage({ id: "storeDetail.storeAddressCard.city" })}
              options={getVillagesOptions()}
              //value={cityTown}
              onChange={(e) => callbackCity(e)}
              input={<Input />}
            />
            :
            <AutocompleteSelect
              placeholder={intl.formatMessage({ id: "storeDetail.storeAddressCard.cityPlaceholder" })}
              label={intl.formatMessage({ id: "storeDetail.storeAddressCard.city" })}
              options={getVillagesOptions()}
              value={cityTown}
              onChange={(e) => callbackCity(e)}
              input={<Input />}
            />
          }
        </Grid>

        <Grid item sm={12}>
          <FormikField
            name="country"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.country" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        {/*<Grid item sm={12}>
          <FormikField
            name="latitude"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.latitude" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>
        <Grid item sm={12}>
          <FormikField
            name="longtitude"
            labelText={intl.formatMessage({ id: "storeDetail.storeAddressCard.longtitude" })}
            formikProps={formikProps}
            highlightChange
          />
        </Grid>*/}
      </Grid>
    )
  }

  return (
    <>
      <IconCard
        title={intl.formatMessage({ id: "storeDetail.storeAddressCard.title" })}
        icon={<LocationOnIcon />}
      >
        {renderContent()}
      </IconCard>
    </>
  );
};

export default withRouter(injectIntl(AddressCard));
