import React from 'react';
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Card from "components/Card/Card.js";

import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
//const useStyless = makeStyles((theme) => styles(theme));

const IconCardOneItem = (props) => {
    const { icon = null, color = 'primary', className, isCustom = false, headerText, bodyText, unitText } = props;

    const cardIconStyles = useStyles();
    const classes = useStyles();

    const cardIconClasses = classNames({
        [cardIconStyles.cardIcon]: true,
        [cardIconStyles[color + "CardHeader"]]: color,
        [className]: className !== undefined
    });

    return (
        <Card className={className}>
            <CardHeader color={color} stats icon>
                {icon && !isCustom && (
                    <CardIcon color={color}>
                        {icon}
                    </CardIcon>
                )}
                {icon && isCustom && (
                    <div className={cardIconClasses}>
                        {icon}
                    </div>
                )}
                <p className={classes.cardCategory}>{headerText}</p>
                <h3 className={classes.cardTitle}>
                    {bodyText} <small>{unitText}</small>
                </h3>
            </CardHeader>
            <br />
        </Card>
    );
};

export default IconCardOneItem;